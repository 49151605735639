import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import { Box, IconButton } from '@material-ui/core';

// Icons
import CategoryIcon from '@material-ui/icons/Category';
import Delete from '@material-ui/icons/Delete';

// Components
import { ModalDelete, ModalCreate, ModalConfirm } from './components/modals';
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';

// Utils
import { dateToFormat } from 'utils/functionsForDate';

// Hooks
import { useTableParams } from 'common/hooks/useTableParams';
import { useModal } from 'hooks/useModal';

// Services
import {
  createCharityEvent,
  deleteCharityEventById,
  getCharityEvents,
} from 'services/charity.services';

// Helpers
import { queryClient } from 'helpers/queryClient';

// Actions
import { alertActions } from 'features/Alert';

export const Events = () => {
  const dispatch = useDispatch();

  const [queryParams] = useTableParams({ initial: { sortBy: 'date' } });

  // Modal delete
  const [modalDeleteIsOpen, onShowModalDelete, onHideModalDelete, modalDeletePayload] = useModal();

  // Confirm delete
  const [modalConfirmationIsOpen, onShowModalConfirmation, onHideModalConfirmation] = useModal();

  // Modal create
  const [modalCreateIsOpen, onShowModalCreate, onHideModalCreate] = useModal();

  const hideOpenedModals = useCallback(() => {
    onHideModalDelete();
    onHideModalConfirmation();
    onHideModalCreate();
  }, [onHideModalConfirmation, onHideModalCreate, onHideModalDelete]);

  // Create event
  const createEventMutation = useMutation({
    mutationFn: (newEvent) => {
      return createCharityEvent(newEvent);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
      hideOpenedModals();
      dispatch(alertActions.success('Event was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  // Delete event
  const deleteEventMutation = useMutation({
    mutationFn: () => {
      return deleteCharityEventById(modalDeletePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['events'] });
      hideOpenedModals();
      dispatch(alertActions.success('Event was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const tableColumnsSettings = useMemo(
    () => [
      { selector: '_id', name: 'ID' },
      { selector: 'name', name: 'Name of the event', sortable: true },
      {
        selector: 'date',
        name: 'Date of the event',
        sortable: true,
        format: ({ date }) => dateToFormat(date),
      },
      { selector: 'address.city', name: 'Where' },
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: ({ _id }) => (
          <IconButton onClick={onShowModalDelete.bind(null, _id)}>
            <Delete />
          </IconButton>
        ),
      },
    ],
    [onShowModalDelete],
  );

  const renderHeader = useMemo(
    () => (
      <Box className="btn btn-outline-dark" onClick={onShowModalCreate}>
        Create a new event <CategoryIcon />
      </Box>
    ),
    [onShowModalCreate],
  );

  const { isLoading, data } = useQuery({
    queryKey: ['events', queryParams],
    queryFn: () => getCharityEvents(queryParams),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Events">
      <TableWithQueryFilters
        title="List of Charity Events"
        renderHeader={renderHeader}
        progressPending={isLoading}
        data={data?.rows}
        columns={tableColumnsSettings}
        paginationTotalRows={data?.count}
        defaultSortFieldId={queryParams.sortBy}
      />

      {modalDeleteIsOpen && (
        <ModalDelete
          onAccept={deleteEventMutation.mutate}
          onDismiss={hideOpenedModals}
          name={modalDeletePayload}
          isLoading={deleteEventMutation.isLoading}
        />
      )}

      {modalCreateIsOpen && (
        <ModalCreate
          onAccept={createEventMutation.mutate}
          onDismiss={onShowModalConfirmation}
          isLoading={createEventMutation.isLoading}
        />
      )}

      {modalConfirmationIsOpen && (
        <ModalConfirm onAccept={hideOpenedModals} onDismiss={onHideModalConfirmation} />
      )}
    </LayoutShowcase>
  );
};
