import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { TableWithFilters } from 'components/base/TableWithFilters/TableWithFilters';
import { Modal } from 'components/base/Modal/Modal';
import { ModalConfirmUnsaved } from 'components/base/Modals/ModalConfirmUnsaved';
import { ModalConfirm } from '../Modals/ModalConfirm';
import { FormPromotion } from '../Forms/FormPromotion/FormPromotion';
import { TableDropdownMenu } from '../TableDropdownMenu/TableDropdownMenu';
import { OrderSwitcher } from 'components/base/OrderSwitcher/OrderSwitcher';

// State
import promotionsActions from 'features/Promotions/store/actions';
import { alertActions } from 'features/Alert';
import { promotionsListSelector } from 'features/Promotions/store/selectors';

// Constants
import { MODALS_ACTIONS_TYPES } from 'constants/modals.constants';
import { MODAL_INITIAL_SETTINGS } from '../../utils/constants';
import { ORDER_TYPES_ENUM } from 'components/base/OrderSwitcher/OrderSwitcher.constants';

// Models
import { PROMOTION_MODEL } from 'features/Promotions/utils/models';

// Services
import { updatePromotionOrderById } from 'services/promotions.service';

// Hooks
import { invalidateNotices } from 'common/hooks/api/useNotices';
import { normalizeNewPromotionValues } from './PromotionsList.utils';

const PromotionsList = ({
  isWithOrder = false,
  items = [],
  count = 0,
  loadedItemsCount = 0,
  title = '',
  dropdownMenuProps,
  getPromotions,
  renderSubHeader,
  tableColumnsSettings = [],
}) => {
  const dispatch = useDispatch();

  const [modalSettings, changeModalSettings] = useState(MODAL_INITIAL_SETTINGS);
  const [isModalConfirmationShown, changeIsModalConfirmationShown] = useState(false);

  const { isPromotionsLoading, isStatusChanging, filters } = useSelector(promotionsListSelector);

  const onChangeFilters = useCallback(
    (filter) => {
      dispatch(promotionsActions.aChangeFilters(filter));
    },
    [dispatch],
  );

  const onClickDropdown = useCallback((row, option) => {
    changeModalSettings({
      isShown: true,
      action: MODALS_ACTIONS_TYPES.confirm,
      payload: {
        id: row[PROMOTION_MODEL.id],
        label: option.name,
        status: option.value,
      },
    });
  }, []);

  const showConfirmationModal = () => changeIsModalConfirmationShown(true);

  const hideConfirmationModal = () => changeIsModalConfirmationShown(false);

  const hideModals = useCallback(() => {
    changeModalSettings(MODAL_INITIAL_SETTINGS);
    hideConfirmationModal();
  }, []);

  const createPromotion = useCallback(
    (newPromotion) => {
      const normalizedValues = normalizeNewPromotionValues(newPromotion)
      dispatch(promotionsActions.aCreatePromotion(normalizedValues))
        .then(getPromotions)
        .then(hideModals)
        .then(invalidateNotices);
    },
    [dispatch, getPromotions, hideModals],
  );

  const updatePromotionStatus = useCallback(
    (id, status) => {
      dispatch(promotionsActions.aUpdatePromotionStatusById(id, status))
        .then(getPromotions)
        .then(hideModals)
        .then(invalidateNotices);
    },
    [dispatch, getPromotions, hideModals],
  );

  const showCreatePromotionModal = useCallback(() => {
    changeModalSettings({ action: MODALS_ACTIONS_TYPES.create, isShown: true });
  }, []);

  const changeTableColumnOrder = useCallback(
    (promotionId, index, actionType) => {
      const targetIndex = actionType === ORDER_TYPES_ENUM.up ? --index : ++index;
      const targetItem = items[targetIndex];
      const requestData = {
        targetOrder: targetItem[PROMOTION_MODEL.order],
      };

      updatePromotionOrderById(promotionId, requestData)
        .then(getPromotions)
        .catch((error) => {
          dispatch(alertActions.error(error.message));
        });
    },
    [dispatch, getPromotions, items],
  );

  const renderHeader = useMemo(
    () => (
      <button className="btn btn-primary" onClick={showCreatePromotionModal}>
        Create a new promotion
      </button>
    ),
    [showCreatePromotionModal],
  );

  const columnsSettings = useMemo(() => {
    const defaultColumns = [
      ...tableColumnsSettings,
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: (row) => (
          <TableDropdownMenu menuProps={dropdownMenuProps} row={row} onClick={onClickDropdown} />
        ),
      },
    ];

    if (isWithOrder) {
      defaultColumns.unshift({
        selector: PROMOTION_MODEL.order,
        name: 'Order',
        ignoreRowClick: true,
        width: '110px',
        sortable: true,
        style: {
          paddingLeft: 7,
        },
        cell: (row, index) => {
          const onClickUp = (...props) => changeTableColumnOrder(row[PROMOTION_MODEL.id], ...props);
          const onClickDown = (...props) =>
            changeTableColumnOrder(row[PROMOTION_MODEL.id], ...props);

          return (
            <OrderSwitcher
              position={index}
              isDisabledUp={index === 0}
              isDisabledDown={loadedItemsCount - 1 === index}
              onClickUp={onClickUp}
              onClickDown={onClickDown}
            />
          );
        },
      });
    }

    return defaultColumns;
  }, [
    changeTableColumnOrder,
    dropdownMenuProps,
    isWithOrder,
    loadedItemsCount,
    onClickDropdown,
    tableColumnsSettings,
  ]);

  useLayoutEffect(getPromotions, [getPromotions, filters]);

  useLayoutEffect(
    () => () => {
      dispatch(promotionsActions.aResetFilters());
    },
    [dispatch],
  );

  return (
    <LayoutShowcase title="Promotions">
      <TableWithFilters
        title={title}
        progressPending={isPromotionsLoading}
        renderHeader={renderHeader}
        renderSubHeader={renderSubHeader}
        data={items}
        columns={columnsSettings}
        paginationTotalRows={count}
        filters={filters}
        onChangeFilters={onChangeFilters}
        defaultSortFieldId={filters.sortBy}
      />

      {modalSettings.action === MODALS_ACTIONS_TYPES.confirm && modalSettings.isShown && (
        <ModalConfirm
          onAccept={updatePromotionStatus}
          onDismiss={hideModals}
          isLoading={isStatusChanging}
          {...modalSettings.payload}
        />
      )}

      {modalSettings.action === MODALS_ACTIONS_TYPES.create && modalSettings.isShown && (
        <Modal name="Create new promotion" show={true} handleClose={hideModals}>
          <FormPromotion
            isLoading={false}
            onAccept={createPromotion}
            onDismiss={showConfirmationModal}
          />
        </Modal>
      )}

      {isModalConfirmationShown && (
        <ModalConfirmUnsaved onAccept={hideModals} onDismiss={hideConfirmationModal} />
      )}
    </LayoutShowcase>
  );
};

export default PromotionsList;
