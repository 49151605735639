import { createSelector } from 'reselect';

import { rootSelector } from 'features/Charity/utils/rootSelector';

const giveawaysRootSelector = createSelector(rootSelector, (charity) => charity.giveaways);

export const giveawaysListSelector = createSelector(
  giveawaysRootSelector,
  (giveaways) => giveaways.giveawaysList,
);

export const giveawayItemSelector = createSelector(
  giveawaysRootSelector,
  (giveaways) => giveaways.giveawayItem,
);
