import React, { useMemo } from 'react';
import cn from 'classnames';

// UIKit
import IconButton from '@material-ui/core/IconButton';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
// import ReplayIcon from '@material-ui/icons/Replay';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

// Utilities
import { fileTypes, getFileTypeByFileName } from 'utils/functionsForFiles';

// Styles
import styles from '../FilesManagement.module.scss';

/**
 * Component for working with files
 *
 * @param {string}    className     Css modifier
 * @param {string}    id            Id for label
 * @param {array}     files         Available files
 * @param {string}    accept        Types of Files for upload
 * @param {boolean}   isDisabled    Disabled
 * @param {function}  onChange      Callback for change file
 * @param {function}  removeFile    Callback for remove file
 */
export const PreviewComponent = ({
  className,
  id,
  file,
  accept,
  onChange,
  removeFile,
  isDisabled,
  canRemove = true,
}) => {
  const fileType = getFileTypeByFileName(file);

  const PreviewComponent = useMemo(() => {
    if (fileType === fileTypes.image) {
      return <img className={styles.fileImage} src={file} alt="img" />;
    }

    if (fileType === fileTypes.video) {
      return (
        <video className={styles.fileImage} controls>
          <source src={file} />
        </video>
      );
    }

    return (
      <div className={styles.filePreview}>
        <InsertDriveFileIcon fontSize="large" className={styles.filePreviewIcon} />
      </div>
    );
  }, [file, fileType]);


  // TODO: the document update functionality is temporarily commented.

  return (
    <div className={cn(styles.previewImage, className)}>
      <input className={styles.input} accept={accept} id={id} onChange={onChange} type="file" />

      {PreviewComponent}

      <div className={styles.fileName}>{file}</div>
      <div className={styles.fileActions}>
        {/*<label className={styles.label} htmlFor={isDisabled ? '' : id}>*/}
        {/*  <IconButton variant="contained" component="span" disabled={isDisabled}>*/}
        {/*    <ReplayIcon />*/}
        {/*  </IconButton>*/}
        {/*</label>*/}
        {canRemove && (
          <IconButton variant="contained" onClick={removeFile} disabled={isDisabled}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
