import { alertActions } from 'features/Alert';

import actionTypes from 'constants/transactions.constants';

import transactionsService from 'services/transactions.service';

export default {
  aGetTransactionsHistoryByUserId,
  aUpdateTransactionById,
  aUpdateTransactionsByIds,
};

export function aGetTransactionsHistoryByUserId(userId, params) {
  return (dispatch) => {
    dispatch(request());

    return transactionsService.getTransactionsHistoryByUserId(userId, params).then(
      (data) => {
        dispatch(success(data));

        return Promise.resolve(data);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionTypes.GET_TRANSACTIONS_REQUEST };
  }

  function success(data) {
    return { type: actionTypes.GET_TRANSACTIONS_SUCCESS, data };
  }

  function failure(error) {
    return { type: actionTypes.GET_TRANSACTIONS_FAILURE, error };
  }
}

export function aUpdateTransactionById(transactionId, transactionData) {
  return async (dispatch) => {
    dispatch(request());

    return transactionsService.patchTransactionById(transactionId, transactionData).then(
      () => {
        dispatch(success(transactionId, transactionData));

        return Promise.resolve();
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionTypes.UPDATE_TRANSACTION_REQUEST };
  }

  function success(transactionId, transactionData) {
    return { type: actionTypes.UPDATE_TRANSACTION_SUCCESS, transactionId, transactionData };
  }

  function failure(error) {
    return { type: actionTypes.UPDATE_TRANSACTION_FAILURE, error };
  }
}

export function aUpdateTransactionsByIds(transactionItems, transactionData) {
  return (dispatch) => {
    const requestsList = transactionItems.map(({ _id }) =>
      transactionsService.patchTransactionById(_id, transactionData),
    );

    dispatch(request());

    return Promise.all(requestsList).then(
      () => {
        dispatch(success(transactionItems, transactionData));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function request() {
    return { type: actionTypes.UPDATE_TRANSACTIONS_BY_IDS_REQUEST };
  }

  function success(transactionItems, transactionData) {
    return {
      type: actionTypes.UPDATE_TRANSACTIONS_BY_IDS_SUCCESS,
      transactionItems,
      transactionData,
    };
  }

  function failure(error) {
    return { type: actionTypes.UPDATE_TRANSACTIONS_BY_IDS_FAILURE, error };
  }
}
