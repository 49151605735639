import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { categoriesActions } from 'actions/categories.actions';

import { Modal } from 'components/base/Modal/Modal';

const DeleteCategoryModal = ({ state, setState, page }) => {
  const dispatch = useDispatch();

  const handleDeleteCategory = () => {
    dispatch(categoriesActions.remove({ id: state.body._id, page }));
    setState({ status: false, action: '', body: {} });
  };

  const onClose = useCallback(() => setState({ status: false, action: '', body: {} }), [setState]);

  return (
    <Modal show={state.status} handleClose={onClose} name={'Confirm delete category'}>
      <div className="modal-body">
        <h5 className="text-center">
          Do you really want to delete this category? Please be aware that all category's data will
          be deleted along with the category
        </h5>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={() => handleDeleteCategory()}>
          Yes
        </button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
          No
        </button>
      </div>
    </Modal>
  );
};

export default DeleteCategoryModal;
