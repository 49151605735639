import React from 'react';
import { Controller } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';

// Components
import { FormControl } from 'components/base/FormControl/FormControl';

// Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Utils
import {
  getDisabledOption,
  getOptionForValue,
  getOptionLabel,
  getOptionSelected,
} from 'utils/functionsForAutocomplete';

// Models
import { LOCALIZED_MODEL } from 'features/Partners/utils/models';

export const DescriptionPreview = ({
  idx,
  control,
  name,
  inputSize,
  languages,
  disabledLanguages,
  canRemove = true,
  onRemove,
  names = {},
}) => (
  <div className="partnerForm__column">
    <div className="partnerForm__interactiveItem">
      <div className="partnerForm__interactiveContent">
        <div className="partnerForm__row">
          <Controller
            name={names[LOCALIZED_MODEL.locale] || `${name}.${idx}.${LOCALIZED_MODEL.locale}`}
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                size={inputSize}
                disableClearable={true}
                options={languages}
                getOptionLabel={getOptionLabel('title')}
                getOptionSelected={getOptionSelected('title')}
                getOptionDisabled={getDisabledOption(disabledLanguages)}
                value={getOptionForValue({
                  value: field.value,
                  options: languages,
                  valueKey: 'title',
                  optionKey: 'value',
                })}
                onChange={(_, newValue) => {
                  field.onChange(newValue.value);
                }}
                onBlur={field.onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={inputSize}
                    label="Language:"
                    variant="outlined"
                    error={fieldState.invalid}
                    helperText={fieldState.invalid && fieldState.error.message}
                  />
                )}
              />
            )}
          />
        </div>
        <div className="partnerForm__row">
          <FormControl
            placeholder="Description:"
            name={names[LOCALIZED_MODEL.value] || `${name}.${idx}.${LOCALIZED_MODEL.value}`}
            control={control}
            multiline
          />
        </div>
      </div>

      <IconButton disabled={!canRemove} onClick={onRemove}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </div>
  </div>
);

export default DescriptionPreview;
