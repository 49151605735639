import { PARTNER_LOCATION_MODEL, PARTNER_MODEL } from '../models';

const reduceLocation = (result, location) => {
  if (typeof location === 'object') {
    return (result += `${location[PARTNER_LOCATION_MODEL.name]}; `);
  }

  return (result += `${location} `);
};

export const mapProductsToValues = ({ products }) => {
  if (!Array.isArray(products) || !products.length) return [];

  return products.map(({ _id, ...otherValues }) => otherValues);
};

export const getPartnerResponseTransform = ({ _id, ...otherFields }) => {
  return {
    ...otherFields,

    [PARTNER_MODEL.products]: mapProductsToValues(otherFields[PARTNER_MODEL.category]),
    [PARTNER_MODEL.category]:
      otherFields[PARTNER_MODEL.category]?.name ?? otherFields[PARTNER_MODEL.category],
    [PARTNER_MODEL.locations]: otherFields[PARTNER_MODEL.locations].reduce(reduceLocation, ''),
  };
};
