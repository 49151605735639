import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const findByTypeSlug = (array, slug) => array.find((arrayItem) => arrayItem.types.includes(slug));

const getCoordinatesByAddress = async (address) => {
  const geoCodes = await geocodeByAddress(address);
  const geoCode = geoCodes[0];
  const latLng = await getLatLng(geoCode);

  return {
    lat: latLng.lat,
    long: latLng.lng,
  };
};

export { findByTypeSlug, getCoordinatesByAddress };
