// TODO: Remove REDUX from user detail

import { userActionsTypes } from 'constants/user.constants';
import { alertActions } from 'features/Alert';
import { userService } from 'services/user.service';

export const userActions = {
  getUserById,
  deleteUserById,
  aResetUserState,
  aUpdateUserById,
};

function getUserById(userId) {
  return function (dispatch) {
    dispatch(request());

    userService.getUserById(userId).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function request() {
    return { type: userActionsTypes.GET_USER_BY_ID_REQUEST };
  }

  function success(data) {
    return { type: userActionsTypes.GET_USER_BY_ID_SUCCESS, data };
  }

  function failure(error) {
    return { type: userActionsTypes.GET_USER_BY_ID_FAILURE, error };
  }
}

function deleteUserById(userId) {
  return async function (dispatch) {
    dispatch(request());

    return userService.deleteUserById(userId).catch((error) => {
      dispatch(failure(error));
      dispatch(alertActions.error(error.message));

      return Promise.reject(error);
    });
  };

  function request() {
    return { type: userActionsTypes.DELETE_USER_BY_ID_REQUEST };
  }

  function failure(error) {
    return { type: userActionsTypes.DELETE_USER_BY_ID_FAILURE, error };
  }
}

function aResetUserState() {
  return { type: userActionsTypes.RESET_USER_STATE };
}

function aUpdateUserById(userId, data) {
  return async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(request());

    return userService.updateUserById(userId, data).then(
      (response) => {
        dispatch(alertActions.success('Your changes are successfully saved'));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.message));
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return { type: userActionsTypes.UPDATE_USER_REQUEST };
  }

  function success(data) {
    return { type: userActionsTypes.UPDATE_USER_SUCCESS, data };
  }

  function failure(error) {
    return { type: userActionsTypes.UPDATE_USER_FAILURE, error };
  }
}
