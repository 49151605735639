import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CategoryIcon from '@material-ui/icons/Category';
import DeleteIcon from '@material-ui/icons/Delete';
import PollIcon from '@material-ui/icons/Poll';

// Components
import { ModalDelete, ModalCreate, ModalConfirm } from './modals';
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';

// Constants
import { ROUTES } from 'constants/routes.constants';

// Actions
import { alertActions } from 'features/Alert';

// Services
import { createPoll, deletePollById, getPolls } from 'services/charity.services';

// Utils
import { dateToFormat } from 'utils/functionsForDate';
import { generatePathByParams, getLastPath } from 'utils/functionsForRouter';

// Models
import { POLL_MODEL } from './utils/models';

// Hooks
import { useModal } from 'hooks/useModal';
import { useTableParams } from 'common/hooks/useTableParams';

// Helpers
import { queryClient } from 'helpers/queryClient';

export const Polls = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Modal delete
  const [modalDeleteIsOpen, onShowModalDelete, onHideModalDelete, modalDeletePayload] = useModal();

  // Confirm delete
  const [modalConfirmationIsOpen, onShowModalConfirmation, onHideModalConfirmation] = useModal();

  // Modal create
  const [modalCreateIsOpen, onShowModalCreate, onHideModalCreate] = useModal();

  const hideOpenedModals = useCallback(() => {
    onHideModalDelete();
    onHideModalConfirmation();
    onHideModalCreate();
  }, [onHideModalConfirmation, onHideModalCreate, onHideModalDelete]);

  const toResultsPage = useCallback(
    (row) => {
      const path = generatePathByParams({
        parent: pathname,
        id: row[POLL_MODEL.id],
        results: getLastPath(ROUTES.charityPollsResults),
      });

      navigate(path);
    },
    [navigate, pathname],
  );

  const createPollMutation = useMutation({
    mutationFn: (newPoll) => {
      return createPoll(newPoll);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['polls'] });
      hideOpenedModals();
      dispatch(alertActions.success('Poll was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const deletePollMutation = useMutation({
    mutationFn: () => {
      return deletePollById(modalDeletePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['polls'] });
      hideOpenedModals();
      dispatch(alertActions.success('Poll was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const tableColumnsSettings = useMemo(
    () => [
      { selector: POLL_MODEL.id, name: 'ID', width: '220px' },
      { selector: POLL_MODEL.question, name: 'Title', sortable: true, width: '200px' },
      {
        selector: POLL_MODEL.description,
        name: 'Description',
        format: (row) => `${row[POLL_MODEL.description].slice(0, 100)}...`,
      },
      {
        selector: POLL_MODEL.createdAt,
        name: 'Date of creation',
        sortable: true,
        width: '140px',
        format: ({ createdAt }) => dateToFormat(createdAt),
      },
      { selector: POLL_MODEL.city, name: 'City', maxWidth: '250px' },
      {
        name: 'View results',
        ignoreRowClick: true,
        button: true,
        cell: (row) => {
          const isUserVoted = Object.values(row[POLL_MODEL.results]).find((value) => value);

          if (isUserVoted) {
            return (
              <IconButton onClick={toResultsPage.bind(null, row)}>
                <PollIcon />
              </IconButton>
            );
          }
        },
      },
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: ({ _id }) => (
          <IconButton onClick={onShowModalDelete.bind(null, _id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [onShowModalDelete, toResultsPage],
  );

  const tableActionsMemo = useMemo(
    () => (
      <Box className="btn btn-outline-dark" onClick={onShowModalCreate}>
        Create a new poll <CategoryIcon />
      </Box>
    ),
    [onShowModalCreate],
  );

  const [queryParams] = useTableParams({ initial: { sortBy: POLL_MODEL.createdAt } });

  const { isLoading, data } = useQuery({
    queryKey: ['polls', queryParams],
    queryFn: () => getPolls(queryParams),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Polls">
      <TableWithQueryFilters
        title="List of Charity Polls"
        renderHeader={tableActionsMemo}
        progressPending={isLoading}
        data={data?.rows}
        columns={tableColumnsSettings}
        paginationTotalRows={data?.count}
        defaultSortFieldId={queryParams.sortBy}
      />

      {modalDeleteIsOpen && (
        <ModalDelete
          onAccept={deletePollMutation.mutate}
          onDismiss={hideOpenedModals}
          name={modalDeletePayload}
          isLoading={deletePollMutation.isLoading}
        />
      )}

      {modalCreateIsOpen && (
        <ModalCreate
          onAccept={createPollMutation.mutate}
          onDismiss={onShowModalConfirmation}
          isLoading={createPollMutation.isLoading}
        />
      )}

      {modalConfirmationIsOpen && (
        <ModalConfirm onAccept={hideOpenedModals} onDismiss={onHideModalConfirmation} />
      )}
    </LayoutShowcase>
  );
};
