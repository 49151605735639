import { combineReducers } from 'redux';

import { countries } from '../Countries/store/reducers';
import { languages } from '../Languages/store/reducers';
import { categories } from '../Categories/store/reducers';
import { notifications } from '../Notifications/store/reducers';
import { promotions } from '../Promotions/store/reducers';

export const dictionaries = combineReducers({
  countries,
  languages,
  categories,
  notifications,
  promotions,
});
