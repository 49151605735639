import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

// Components
import ListManager from 'components/base/ListManager/ListManager';

import { Preview } from './Preview';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getPoliciesInfo } from 'services/policies.services';
import { alertActions } from 'features/Alert';

const newItemModel = {
  lang: null,
  type: '',
  link: [],
};

export const PolicyFiles = ({ maxItems }) => {
  const dispatch = useDispatch();

  const policyFiles = useQuery({
    queryKey: ['policyFiles'],
    queryFn: getPoliciesInfo,
    select: (response) => {
      return response?.items
        .filter(({ type }) => type === 'md')
        .map(({ lang, type, link }) => ({ lang, type, link: [link] }));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const { control, reset } = useForm({
    defaultValues: {
      files: [],
    },
  });

  useEffect(() => {
    if (policyFiles?.data) {
      reset({ files: policyFiles.data });
    }
  }, [reset, policyFiles.data]);

  return (
    <ListManager
      buttonTitle="Add files and localization"
      name="files"
      control={control}
      newItemModel={newItemModel}
      maxItems={maxItems}
      PreviewComponent={<Preview />}
    />
  );
};
