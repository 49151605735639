import React, { useMemo } from 'react';

import { FormFilesManagement } from 'components/base/FormFilesManagement/FormFilesManagement';
import { LanguagesSelect } from 'components/base/Selects';

import s from './PolicyFiles.module.scss';
import { useWatch } from 'react-hook-form';
import { updatePolicy } from 'services/policies.services';
import { useDispatch } from 'react-redux';
import { alertActions } from 'features/Alert';

const inputSize = 'small';

export const Preview = ({ idx, control, name }) => {
  const dispatch = useDispatch();

  const lang = useWatch({
    control,
    name: `${name}.${idx}.lang`,
  });

  const service = useMemo(
    () => ({
      addNewFile: async (newFile, fileType) => {
        const data = {
          params: {
            lang,
          },
          reqData: {
            newFile,
            fileType,
          },
        };

        try {
          const response = await updatePolicy(data);
          const file = response?.items.find((item) => item.lang === lang && item.type === 'md');

          dispatch(alertActions.success('File was successfully uploaded'));

          return new Promise((resolve) => resolve({ fileUrl: file.link }));
        } catch (error) {
          dispatch(alertActions.error(error.message));

          return new Promise((_, reject) => reject(error));
        }
      },

      deleteFile: () => new Promise((resolve) => resolve()),
    }),
    [dispatch, lang],
  );

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.row}>
          <LanguagesSelect
            control={control}
            name={`${name}.${idx}.lang`}
            size={inputSize}
            label="Localization:"
          />
        </div>
        <div className={s.row}>
          <FormFilesManagement
            buttonLabel="Add new file"
            name={`${name}.${idx}.link`}
            control={control}
            id="filesPolicy"
            maxFiles={1}
            canRemove={false}
            service={service}
            isDisabled={!Boolean(lang)}
            validate={false}
            accept="text/markdown"
          />
        </div>
      </div>
    </div>
  );
};
