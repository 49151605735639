import { useEffect, useRef, useState } from 'react';
import { PARTNER_MODEL } from 'features/Partners/utils/models';
import { getPartnerById } from 'services/partner.service';

export default function usePartnerLocations(partnerId) {
  const cachedDataRef = useRef(null);
  const [state, setState] = useState({
    isLoading: true,
    options: [],
    error: null,
  });

  useEffect(() => {
    if (!partnerId || cachedDataRef.current === partnerId) return;

    let active = true;

    const fetch = async () => {
      try {
        const options = await getPartnerById(partnerId);

        if (active) {
          setState({
            isLoading: false,
            options: options[PARTNER_MODEL.locations],
            error: null,
          });
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          error,
        }));
      }
    };

    fetch();

    return () => {
      active = false;
    };
  }, [partnerId]);

  return state;
}
