import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js-light';

// Colors
import {
  blueGrey,
  grey,
  brown,
  yellow,
  amber,
  orange,
  lime,
  lightGreen,
  green,
} from '@material-ui/core/colors';

// Styles
import './LinearProgress.styles.css';

const colors = {
  0: grey[100],
  10: grey[400],
  20: blueGrey[400],
  30: brown[400],
  40: yellow[400],
  50: amber[400],
  60: orange[400],
  70: lime[400],
  80: lightGreen[400],
  90: green[400],
  100: green[800],
};

function getProgressColor(percent) {
  const index = new Decimal(percent).div(10).todp(0).times(10).toNumber();
  return colors[index] || colors[100];
}

export const LinearProgress = ({ progress, total }) => {
  const percent = progress ? new Decimal(progress).times(100).div(total).todp(0).toNumber() : 0;

  const color = getProgressColor(percent);

  return (
    <div className="LinearProgress">
      <div
        className="LinearProgress__value"
        style={{ backgroundColor: color, width: `${percent}%` }}
      >
        {percent}%
      </div>
    </div>
  );
};

LinearProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};
