export default {
  GET_GIVEAWAYS_REQUEST: 'GET_GIVEAWAYS_REQUEST',
  GET_GIVEAWAYS_SUCCESS: 'GET_GIVEAWAYS_SUCCESS',
  GET_GIVEAWAYS_FAILURE: 'GET_GIVEAWAYS_FAILURE',

  CREATE_GIVEAWAY_REQUEST: 'CREATE_GIVEAWAY_REQUEST',
  CREATE_GIVEAWAY_SUCCESS: 'CREATE_GIVEAWAY_SUCCESS',
  CREATE_GIVEAWAY_FAILURE: 'CREATE_GIVEAWAY_FAILURE',

  DELETE_GIVEAWAY_REQUEST: 'DELETE_GIVEAWAY_REQUEST',
  DELETE_GIVEAWAY_SUCCESS: 'DELETE_GIVEAWAY_SUCCESS',
  DELETE_GIVEAWAY_FAILURE: 'DELETE_GIVEAWAY_FAILURE',

  GET_GIVEAWAY_REQUEST: 'GET_GIVEAWAY_REQUEST',
  GET_GIVEAWAY_SUCCESS: 'GET_GIVEAWAY_SUCCESS',
  GET_GIVEAWAY_FAILURE: 'GET_GIVEAWAY_FAILURE',

  UPDATE_GIVEAWAY_REQUEST: 'UPDATE_GIVEAWAY_REQUEST',
  UPDATE_GIVEAWAY_SUCCESS: 'UPDATE_GIVEAWAY_SUCCESS',

  RESET_GIVEAWAY_SUCCESS: 'RESET_GIVEAWAY_SUCCESS',

  CHANGE_GIVEAWAY_FILTER: 'CHANGE_CHARITY_GIVEAWAY_FILTER',
};

export const WINNER_TYPES = Object.freeze({
  GET_WINNER_REQUEST: 'GET_WINNER_REQUEST',
  GET_WINNER_SUCCESS: 'GET_WINNER_SUCCESS',
  GET_WINNER_FAILURE: 'GET_WINNER_FAILURE',
});
