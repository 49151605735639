import { object, string, number, array } from 'yup';

// Models
import { LOCALIZED_MODEL, PARTNER_MODEL, PRODUCT_MODEL } from '../models';

const fieldName = { [PARTNER_MODEL.name]: string().label('Partner name').min(2).required() };

const fieldCategory = {
  [PARTNER_MODEL.category]: string()
    .label('Category name')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
};

const fieldEmail = {
  [PARTNER_MODEL.email]: string().label('Email for logging').email().required(),
};

const fieldPassword = {
  [PARTNER_MODEL.password]: string().min(6).label('Password').required(),
};

const fieldPercent = {
  [PARTNER_MODEL.percent]: number()
    .label('Percent')
    .typeError(({ label }) => `${label} is a required field`)
    .required()
    .min(1)
    .max(100),
};

const fieldClientPercent = {
  [PARTNER_MODEL.clientPercent]: number()
    .label('Client')
    .typeError(({ label }) => `${label} is a required field`)
    .required()
    .test('match', '', function (value) {
      const { path, parent, createError } = this;
      const { percent, adminPercent } = parent;

      return (
        percent === value + adminPercent ||
        createError({
          path,
          message: `Fields in the sum must be equal to ${percent || 'Percent field'}`,
        })
      );
    }),
};

const fieldAdminPercent = {
  [PARTNER_MODEL.adminPercent]: number()
    .label('Admin')
    .typeError(({ label }) => `${label} is a required field`)
    .required()
    .test('match', '', function (value) {
      const { path, parent, createError } = this;
      const { percent, clientPercent } = parent;

      return (
        percent === value + clientPercent ||
        createError({
          path,
          message: `Fields in the sum must be equal to ${percent || 'Percent field'}`,
        })
      );
    }),
};

const fieldCountry = {
  [PARTNER_MODEL.country]: string()
    .label('Country')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
};

const fieldDescription = {
  [PARTNER_MODEL.description]: string()
    .label('Description')
    .required(({ label }) => `${label} is a required field`),
};

const fieldLocalizedDescriptions = {
  [PARTNER_MODEL.localizedDescriptions]: array()
    .of(
      object({
        [LOCALIZED_MODEL.locale]: string()
          .label('Localization')
          .typeError(({ label }) => `${label} is a required field`)
          .required(),
        [LOCALIZED_MODEL.value]: string().label('Description').required(),
      }),
    )
    .required(),
};

const fieldLocalization = {
  [PARTNER_MODEL.localization]: string()
    .label('Localization')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
};

const fieldAvatar = {
  avatar: string()
    .label('Avatar')
    .required(({ label }) => `${label} is a required field`),
};

export const creationValidationSchema = object({
  ...fieldName,
  ...fieldCategory,
  ...fieldCountry,
  ...fieldEmail,
  ...fieldPassword,
  ...fieldPercent,
  ...fieldClientPercent,
  ...fieldAdminPercent,
  ...fieldDescription,
  ...fieldLocalizedDescriptions,
  ...fieldAvatar,
  ...fieldLocalization,
}).required();

export const editValidationSchema = object({
  ...fieldName,
  ...fieldCategory,
  ...fieldCountry,
  ...fieldEmail,
  ...fieldPercent,
  ...fieldClientPercent,
  ...fieldAdminPercent,
  ...fieldDescription,
  ...fieldLocalizedDescriptions,
  ...fieldAvatar,
  ...fieldLocalization,
}).required();

export const creationProductValidationSchema = object({
  [PRODUCT_MODEL.title]: string().label('Product name').min(2).required(),

  [PRODUCT_MODEL.clientPercent]: number()
    .min(1)
    .max(100)
    .label('Client percent')
    .typeError(({ label }) => `${label} is a required field`)
    .test('match', '', function (value) {
      const { path, parent, createError } = this;
      const { adminPercent } = parent;

      return (
        100 >= value + adminPercent ||
        createError({
          path,
          message: "'Admin percent' and 'Client percent' in the sum must be equal or less than 100",
        })
      );
    })
    .required(),

  [PRODUCT_MODEL.localization]: array().of(
    object({
      [LOCALIZED_MODEL.locale]: string()
        .label('Localization')
        .typeError(({ label }) => `${label} is a required field`)
        .required(),
      [LOCALIZED_MODEL.value]: string().label('Name of product').required(),
    }),
  ),
}).required();

export const CHANGE_PASSWORD_SCHEMA = object({
  ...fieldPassword,
}).required();
