import Api from 'apis/default.api';

export default {
  getPromotions,
  createPromotion,
  getPromotionById,
  updatePromotionById,
  updatePromotionOrderById,
  updatePromotionStatusById,
};

const ROOT_ENDPOINT = '/promotions';
const STATUS_ENDPOINT = 'changeStatus';
const ORDER_ENDPOINT = 'setOrder';

export function getPromotions(params) {
  return Api.get(ROOT_ENDPOINT, { params });
}

export function createPromotion(data) {
  return Api.post(ROOT_ENDPOINT, data);
}

export function getPromotionById(id) {
  return Api.get(`${ROOT_ENDPOINT}/${id}`);
}

export function updatePromotionById(promotionId, data) {
  return Api.patch(`${ROOT_ENDPOINT}/${promotionId}`, data);
}

export function updatePromotionStatusById(promotionId, data) {
  return Api.patch(`${ROOT_ENDPOINT}/${promotionId}/${STATUS_ENDPOINT}`, data);
}

export function updatePromotionOrderById(promotionId, data) {
  return Api.patch(`${ROOT_ENDPOINT}/${promotionId}/${ORDER_ENDPOINT}`, data);
}
