import React from 'react';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { UsersTable } from './components/UsersTable/UsersTable';

export const Users = () => (
  <LayoutShowcase title="Users">
    <UsersTable />
  </LayoutShowcase>
);
