import { browserStorage } from './browserStorage';

export const getAuthTokens = () => ({
  // refreshToken: browserStorage.getItem('refresh-token'),
  accessToken: browserStorage.getItem('token'),
});

export const removeAuthTokens = () => {
  // browserStorage.removeItem('refresh-token');
  browserStorage.removeItem('token');
};

export const getBearerToken = () => {
  const accessToken = getAuthTokens().accessToken;
  return accessToken || '';
};
