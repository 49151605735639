// Models
import { PARTNER_MODEL } from './models';

export const defaultValues = {
  [PARTNER_MODEL.name]: '',
  [PARTNER_MODEL.email]: '',
  [PARTNER_MODEL.password]: '',
  [PARTNER_MODEL.percent]: '',
  [PARTNER_MODEL.clientPercent]: '',
  [PARTNER_MODEL.adminPercent]: '',
  [PARTNER_MODEL.website]: '',
  [PARTNER_MODEL.facebook]: '',
  [PARTNER_MODEL.instagram]: '',
  [PARTNER_MODEL.logo]: '',
  [PARTNER_MODEL.category]: null,
  [PARTNER_MODEL.contactsOfPartner]: '',
  [PARTNER_MODEL.emailForContact]: '',
  [PARTNER_MODEL.localization]: null,
  [PARTNER_MODEL.country]: "",
  [PARTNER_MODEL.personalEmail]: '',
  [PARTNER_MODEL.personalPhone]: '',
  [PARTNER_MODEL.description]: '',
  [PARTNER_MODEL.localizedDescriptions]: [],
  [PARTNER_MODEL.worksOnline]: false,
};
