import { dayjsDate } from 'utils/functionsForDate';

import { DATE_UNITS_ENUM } from 'constants/dates';
import { QUERY_PARAMS } from './queryParams';

const getToday = () => dayjsDate().toDate();

export const TRANSITIONS_SELECT_OPTIONS = [
  {
    title: 'Per all time',
    [QUERY_PARAMS.dateFrom]: null,
    [QUERY_PARAMS.dateTo]: null,
  },
  {
    title: 'Per day',
    [QUERY_PARAMS.dateFrom]: dayjsDate().startOf(DATE_UNITS_ENUM.date).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per week',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(1, DATE_UNITS_ENUM.week).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per two week',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(2, DATE_UNITS_ENUM.week).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per month',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(1, DATE_UNITS_ENUM.month).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per quarter of the year',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(3, DATE_UNITS_ENUM.month).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per half of the year',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(6, DATE_UNITS_ENUM.month).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
  {
    title: 'Per year',
    [QUERY_PARAMS.dateFrom]: dayjsDate().subtract(1, DATE_UNITS_ENUM.year).toDate(),
    [QUERY_PARAMS.dateTo]: getToday(),
  },
];
