import React from 'react';
import { Outlet } from 'react-router-dom';

import { NavBar } from '../NavBar/NavBar';

// HOC
import { withResources } from 'hocs/withResources';

// Styles
import styles from './MainLayout.module.scss';

import { useNotices } from 'common/hooks/api/useNotices';

let MainLayout = () => {
  const noticesState = useNotices();

  return (
    <div className={styles.root}>
      <NavBar notices={noticesState.notices} />

      <main>
        <Outlet />
      </main>
    </div>
  );
};

MainLayout = withResources(MainLayout);

export { MainLayout };
