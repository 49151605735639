import { object, string } from 'yup';

// Models
import { VERSION_MODEL } from './models';

const FIELDS_PLACEHOLDERS = {
  [VERSION_MODEL.version]: 'Version',
  [VERSION_MODEL.appType]: 'Type',
  [VERSION_MODEL.platform]: 'Platform',
  [VERSION_MODEL.mandatory]: 'Mandatory',
};

const DEFAULT_VALUES = {
  [VERSION_MODEL.platform]: '',
  [VERSION_MODEL.appType]: '',
  [VERSION_MODEL.version]: '',
  [VERSION_MODEL.mandatory]: false,
};

const VALIDATION_SCHEMA = object({
  [VERSION_MODEL.version]: string()
    .label(FIELDS_PLACEHOLDERS[VERSION_MODEL.version])
    .typeError(({ label }) => `${label} is a required field`)
    .matches(/^(\d+\.)(\d+\.)(\d+)$/, 'Must be only digits and string as x.x.x')
    .required(),
});

export { DEFAULT_VALUES, VALIDATION_SCHEMA, FIELDS_PLACEHOLDERS };
