import React from 'react';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export const ErrorMessage = ({ style, code, message, withTry = true }) => {
  return (
    <Paper style={style}>
      {code && (
        <Typography align="center" variant="h6" color="error">
          {code}
        </Typography>
      )}
      {message && (
        <Typography align="center" variant="h6" color="error">
          {message}
        </Typography>
      )}

      {withTry && (
        <Typography align="center" variant="h6" color="error">
          Please try again later.
        </Typography>
      )}
    </Paper>
  );
};
