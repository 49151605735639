import { authConstants } from '../constants';
import { authService } from '../services';
import { alertActions } from 'features/Alert';

export const authActions = {
  login,
  logout,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    return authService.login(username, password).then(
      (user) => {
        dispatch(success(user));

        return Promise.resolve(user);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response?.data?.error?.message ?? error.response));

        return Promise.reject(error);
      },
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function logout(message) {
  return (dispatch) => {
    dispatch(logoutAction(message));

    authService.logout();
    return Promise.resolve();
  };

  function logoutAction(message) {
    return { type: authConstants.LOGOUT, message };
  }
}
