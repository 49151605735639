import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import FormSelect from 'components/base/FormSelect/FormSelect';

// Services
import { getPromotions as getPromotionsService } from 'services/promotions.service';

// Models
import { PROMOTION_MODEL } from 'features/Promotions/utils/models';
import { PROMOTION_ACTIVE_ENUM, PROMOTION_STATUS_ENUM } from 'features/Promotions/utils/enums';

const PromotionsSelect = ({
  isPublished = false,
  partnerId,
  status = PROMOTION_STATUS_ENUM.pubStart,
  ...props
}) => {
  const cachedPartnerIdRef = useRef(partnerId);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    isLoading: false,
    options: [],
    error: null,
  });

  const showLoader = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      error: null,
    }));
  }, []);

  const onRequestResolve = useCallback(
    (promotions) => {
      setState({
        isLoading: false,
        options: isPublished ? promotions.items.map(getPublished) : promotions.items,
        error: null,
      });
    },
    [isPublished],
  );

  const onRequestReject = useCallback((error) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      error: error,
    }));
  }, []);

  const getPromotions = useCallback(() => {
    if (!partnerId || !isOpen || cachedPartnerIdRef.current === partnerId) return;

    showLoader();

    getPromotionsService({
      partnerId,
      publishStatus: status,
      active: PROMOTION_ACTIVE_ENUM.enabled,
    })
      .then(onRequestResolve)
      .catch(onRequestReject)
      .finally(() => (cachedPartnerIdRef.current = partnerId));
  }, [isOpen, onRequestReject, onRequestResolve, partnerId, showLoader, status]);

  useEffect(getPromotions, [getPromotions]);

  return (
    <FormSelect
      placeholder="Promotion"
      inputName={PROMOTION_MODEL.title}
      inputValue={PROMOTION_MODEL.id}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...props}
      options={state.options}
      isLoading={state.isLoading}
      isError={Boolean(state.error)}
      helperText={state.error?.message}
    />
  );
};

export default PromotionsSelect;

function getPublished(item) {
  return item[PROMOTION_MODEL.published] ?? item;
}
