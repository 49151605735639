import { alertActions } from 'features/Alert';

import promotionsService from 'services/promotions.service';
import { getPaginationOffsetByFilters } from 'utils/functionsForPagination';
import { mapPromotionToRequest, mapResponseToPromotion } from '../utils/transformSnippets';
import { promotionsListSelector } from './selectors';

import actionsTypes from './types';

export default {
  aGetPromotions,
  aCreatePromotion,
  aGetPromotionById,
  aUpdatePromotionById,
  aUpdatePromotionStatusById,
  aChangeFilters,
  aResetFilters,
};

export function aGetPromotions(manualFilters) {
  return (dispatch, getState) => {
    const { filters } = promotionsListSelector(getState());
    const params = { ...getPaginationOffsetByFilters(filters), ...manualFilters };

    dispatch(actionRequest());

    promotionsService.getPromotions(params).then(
      (res) => dispatch(actionSuccess(res)),
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.GET_PROMOTIONS_REQUEST,
    };
  }

  function actionSuccess(promotionsList) {
    return {
      type: actionsTypes.GET_PROMOTIONS_SUCCESS,
      promotionsList,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.GET_PROMOTIONS_FAILURE,
      error,
    };
  }
}

export function aGetPromotionById(id) {
  return (dispatch) => {
    dispatch(actionRequest());

    promotionsService.getPromotionById(id).then(
      (res) => {
        const promotion = mapResponseToPromotion(res);
        dispatch(actionSuccess(promotion));
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.GET_PROMOTION_REQUEST,
    };
  }

  function actionSuccess(promotion) {
    return {
      type: actionsTypes.GET_PROMOTION_SUCCESS,
      promotion,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.GET_PROMOTION_FAILURE,
      error,
    };
  }
}

export function aCreatePromotion(newPromotion) {
  return (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(actionRequest());

    return promotionsService.createPromotion(mapPromotionToRequest(newPromotion)).then(
      (res) => {
        dispatch(actionSuccess(res));
        dispatch(alertActions.success('Promotion was created'));

        return Promise.resolve(res);
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject(error);
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.CREATE_REQUEST,
    };
  }

  function actionSuccess() {
    return {
      type: actionsTypes.CREATE_SUCCESS,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.CREATE_FAILURE,
      error,
    };
  }
}

export function aUpdatePromotionById(promotionId, data) {
  return (dispatch) => {
    dispatch(actionRequest());

    return promotionsService.updatePromotionById(promotionId, mapPromotionToRequest(data)).then(
      () => {
        dispatch(actionSuccess());
        dispatch(alertActions.success('Promotion was updated'));

        return Promise.resolve();
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject();
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.UPDATE_REQUEST,
    };
  }

  function actionSuccess() {
    return {
      type: actionsTypes.UPDATE_SUCCESS,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.UPDATE_FAILURE,
      error,
    };
  }
}
export function aUpdatePromotionStatusById(promotionId, status) {
  return (dispatch) => {
    dispatch(actionRequest());

    return promotionsService.updatePromotionStatusById(promotionId, status).then(
      () => {
        dispatch(actionSuccess());

        return Promise.resolve();
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject();
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.UPDATE_STATUS_REQUEST,
    };
  }

  function actionSuccess() {
    return {
      type: actionsTypes.UPDATE_STATUS_SUCCESS,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.UPDATE_STATUS_FAILURE,
      error,
    };
  }
}

export function aChangeFilters(filters) {
  return {
    type: actionsTypes.CHANGE_PROMOTIONS_FILTERS,
    filters,
  };
}

export function aResetFilters() {
  return {
    type: actionsTypes.RESET_PROMOTIONS_FILTERS,
  };
}
