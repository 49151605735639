import React from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirm = ({ onAccept, onDismiss }) => (
  <Modal handleClose={onDismiss} name="Would you like to exit?" show={true}>
    <div className="modal-body">
      <h5 className="text-center">Unsaved changes will be lost. Would you like to exit?</h5>
    </div>
    <div className="modal-footer">
      <button className="btn btn-primary" onClick={onAccept} type="button">
        Yes, exit
      </button>
      <button className="btn btn-secondary" onClick={onDismiss} type="button" data-dismiss="modal">
        No, continue editing
      </button>
    </div>
  </Modal>
);
