import { useCallback } from 'react';
import { useSearchParams as useDomSearchParams } from 'react-router-dom';
import { isUndef } from 'utils/functionsForCoreJS';

export function useSearchParams() {
  const [searchParams, updateSearchParams] = useDomSearchParams();

  const setSearchParams = useCallback(
    (props) => {
      for (const [key, value] of Object.entries(props)) {
        if (isUndef(value)) searchParams.delete(key);
        else searchParams.set(key, value);
      }

      updateSearchParams(searchParams);
    },
    [searchParams, updateSearchParams],
  );

  return [searchParams, setSearchParams];
}

export default useSearchParams;

// import { useMemo, useCallback } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';

// export const useSearchParams = () => {
//   const history = useHistory();
//   const { search } = useLocation();

//   const searchParams = useMemo(() => new URLSearchParams(search), [search]);

//   const updateURL = useCallback(() => {
//     history.push({
//       search: searchParams.toString(),
//     });
//   }, [history, searchParams]);

//   const setSearchParams = useCallback(
//     (params = {}) => {
//       for (const [key, value] of Object.entries(params)) {
//         if (!value) searchParams.delete(key);
//         else searchParams.set(key, value);
//       }

//       updateURL();
//     },
//     [searchParams, updateURL],
//   );

//   return [searchParams, setSearchParams];
// };

// export default useSearchParams;
