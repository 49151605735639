import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

// UIKit
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// Components
import { ListManager } from 'components/base/ListManager/ListManager';
import { DescriptionPreview } from '../DescriptionPreview/DescriptionPreview';
import { FormControl } from 'components/base/FormControl/FormControl';
import { CountriesSelect } from 'components/base/Selects';
import { CategoriesSelect } from 'components/base/Selects';
import { LanguagesSelect } from 'components/base/Selects';
import ImageUploaderWithPreview from 'components/base/ImageUploaderWithPreview/ImageUploaderWithPreview';

// Models
import { PARTNER_MODEL, LOCALIZED_MODEL } from '../../utils/models';

// Selectors
import {
  languagesWithoutEnglishLengthSelector,
  languagesWithoutEnglishSelector,
} from 'features/Dictionaries/Languages/store/selectors';

// Hooks
import useDisabledLanguages from 'hooks/useDisabledLanguages';

const primaryColor = red[500];

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 0,
  },
}));

const inputSize = 'small';

const newItemModel = {
  [LOCALIZED_MODEL.locale]: null,
  [LOCALIZED_MODEL.value]: '',
};

const percentFieldsNames = [
  PARTNER_MODEL.percent,
  PARTNER_MODEL.adminPercent,
  PARTNER_MODEL.clientPercent,
];

export const FormOfCreation = ({ control, trigger, watch }) => {
  const classes = useStyles();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const languagesWithoutEnglish = useSelector(languagesWithoutEnglishSelector);
  const maxLangItems = useSelector(languagesWithoutEnglishLengthSelector);

  const disabledLanguages = useDisabledLanguages(watch, PARTNER_MODEL.localizedDescriptions);

  const watchPercents = useWatch({
    name: percentFieldsNames,
    control,
  });

  const togglePassVisibility = useCallback(
    () => setIsShowPassword(!isShowPassword),
    [isShowPassword],
  );

  const validatePercents = useCallback(() => {
    if (watchPercents.some((percent) => percent)) {
      trigger(percentFieldsNames);
    }
  }, [trigger, watchPercents]);

  useEffect(validatePercents, [validatePercents]);

  return (
    <form noValidate className="partnerForm">
      <div style={{ width: '100%' }} className="partnerForm__row">
        <Controller
          name={PARTNER_MODEL.avatar}
          control={control}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <ImageUploaderWithPreview
              src={value}
              onChange={onChange}
              isError={invalid}
              helperText={invalid && error.message}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Partner name:"
          name={PARTNER_MODEL.name}
          control={control}
          isRequired
        />
      </div>

      <div className="partnerForm__column">
        <CategoriesSelect name={PARTNER_MODEL.category} control={control} />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Email for logging in to Partner app:"
          name={PARTNER_MODEL.email}
          control={control}
          type="email"
          isRequired
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Password:"
          name={PARTNER_MODEL.password}
          control={control}
          type={isShowPassword ? 'text' : 'password'}
          isRequired
          icon={
            <IconButton
              size={inputSize}
              aria-label="toggle password visibility"
              onClick={togglePassVisibility}
              edge="end"
            >
              {isShowPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
      </div>

      <div className="partnerForm__column">
        <CountriesSelect control={control} name={PARTNER_MODEL.country} isRequire />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.worksOnline}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              classes={classes}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  checked={field.value}
                  label="Works online"
                  onChange={field.onChange}
                />
              }
              label="Works online"
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Percent:"
          name={PARTNER_MODEL.percent}
          control={control}
          type="number"
          isRequired
        />
      </div>

      <div className="partnerForm__column" />

      <div className="partnerForm__column">
        <FormControl
          placeholder="Client percent:"
          name={PARTNER_MODEL.clientPercent}
          control={control}
          type="number"
          isRequired
          helperText="Fields in the sum must be equal to 'Cashback percent' field value"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Admin percent:"
          name={PARTNER_MODEL.adminPercent}
          control={control}
          type="number"
          isRequired
          helperText="Fields in the sum must be equal to 'Cashback percent' field value"
        />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.contactsOfPartner}
          control={control}
          render={({ field, fieldState }) => (
            <NumberFormat
              {...field}
              type="tel"
              prefix="+"
              size={inputSize}
              label="Phone for client app:"
              variant="outlined"
              fullWidth
              error={fieldState.invalid}
              helperText={fieldState.invalid && fieldState.error.message}
              customInput={TextField}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Email for client app:"
          name={PARTNER_MODEL.emailForContact}
          control={control}
          type="email"
        />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.personalPhone}
          control={control}
          render={({ field, fieldState }) => (
            <NumberFormat
              {...field}
              type="tel"
              prefix="+"
              size={inputSize}
              label="Personal phone:"
              variant="outlined"
              fullWidth
              error={fieldState.invalid}
              helperText={fieldState.invalid && fieldState.error.message}
              customInput={TextField}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Personal email:"
          name={PARTNER_MODEL.personalEmail}
          control={control}
          type="email"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="https://example.com/"
          name={PARTNER_MODEL.website}
          control={control}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="https://facebook.com/example"
          name={PARTNER_MODEL.facebook}
          control={control}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="https://instagram.com/example"
          name={PARTNER_MODEL.instagram}
          control={control}
        />
      </div>

      <div className="partnerForm__column" />

      <div className="partnerForm__column">
        <LanguagesSelect
          name={PARTNER_MODEL.localization}
          control={control}
          label={
            <Fragment>
              Language for emails:<span style={{ color: primaryColor }}>*</span>
            </Fragment>
          }
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Default description [EN]:"
          name={PARTNER_MODEL.description}
          control={control}
          isRequired
          multiline
          minRows={4}
        />
      </div>

      <ListManager
        className="partnerForm__row"
        name={PARTNER_MODEL.localizedDescriptions}
        control={control}
        newItemModel={newItemModel}
        maxItems={maxLangItems}
        PreviewComponent={
          <DescriptionPreview
            inputSize={inputSize}
            languages={languagesWithoutEnglish}
            disabledLanguages={disabledLanguages}
          />
        }
      />
    </form>
  );
};
