import Api from 'apis/default.api';

export default {
  getTransactions,
  patchTransactionById,
  getTransactionsHistoryByUserId,
  getTransactionsFinancialReportByUserId,
  downloadFileOfTransactionsFinancialReportByUserId,
};

const ROOT_ENDPOINT = 'transaction';
const HISTORY_ENDPOINT = 'history';
const USER_ENDPOINT = 'user';
const DOWNLOAD_ENDPOINT = 'download';
const FINANCIAL_REPORTS_ENDPOINT = 'financialReports';

export async function getTransactions(userId, params) {
  return Api.get(`${ROOT_ENDPOINT}/${HISTORY_ENDPOINT}/${USER_ENDPOINT}/${userId}`, { params });
}

export async function getTransactionsHistoryByUserId(userId, params) {
  return Api.get(`${ROOT_ENDPOINT}/${HISTORY_ENDPOINT}/${USER_ENDPOINT}/${userId}`, { params });
}

export async function patchTransactionById(transactionId, data) {
  return Api.patch(`${ROOT_ENDPOINT}/${transactionId}`, data);
}

export async function getTransactionsFinancialReportByUserId(userId, params) {
  return Api.get(`${ROOT_ENDPOINT}/${FINANCIAL_REPORTS_ENDPOINT}/${USER_ENDPOINT}/${userId}`, {
    params,
  });
}

export async function downloadFileOfTransactionsFinancialReportByUserId(userId, params) {
  return Api.get(
    `${ROOT_ENDPOINT}/${DOWNLOAD_ENDPOINT}/${FINANCIAL_REPORTS_ENDPOINT}/${USER_ENDPOINT}/${userId}`,
    { params },
  );
}
