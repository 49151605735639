import React from 'react';

// Components
import { Modal } from 'components/base/Modal/Modal';

export const ModalWinner = ({ onDismiss, children }) => (
  <Modal
    handleClose={onDismiss}
    name="Giveaway winner"
    show={true}
    renderFooter={
      <button className="btn btn-secondary" onClick={onDismiss} type="button" data-dismiss="modal">
        Exit
      </button>
    }
  >
    {children}
  </Modal>
);
