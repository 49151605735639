export const truncateToTwoDecimals = (value) => {
    if (typeof value === 'number' && !isNaN(value)) {
        const valueStr = value.toString();
        // Regular expression to find the decimal point and following digits
        const regex = /(\.\d{2})\d*/;
        const match = valueStr.match(regex);

        if (match) {
            // Truncate to two decimal places
            return valueStr.replace(regex, match[1]);
        }
    }
    return value;
}