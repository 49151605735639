import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

// Components
import { Loader } from 'components/base/Loader/Loader';
import { FormControl } from 'components/base/FormControl/FormControl';
import { FormFilesManagement } from 'components/base/FormFilesManagement/FormFilesManagement';
import CountriesSelect from 'components/base/Selects/CountriesSelect';
import FormDatePicker from 'components/base/FormDatePicker/FormDatePicker';

// Utilities
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';

// Models
import { EVENT_FORM_MODEL } from '../utils/models';

// Hooks
import useParamsSelector from 'hooks/useParamsSelector';

// Selectors
import { getCityByIdSelector } from 'features/Dictionaries/Countries/store/selectors';

const inputSize = 'small';

export const EventForm = ({
  isLoading = false,
  control,
  placesAutocompleteValue,
  onChangePlacesAutocomplete,
}) => {
  const [searchOptions, setSearchOptions] = useState({});

  const countryWatch = useWatch({ name: EVENT_FORM_MODEL.country, control });
  const cityWatch = useWatch({ name: EVENT_FORM_MODEL.city, control });

  const cities = useParamsSelector(getCityByIdSelector, countryWatch);

  const onSelectPlaceAutocomplete = useCallback(
    (onChangeCallback) => (_, address) => {
      const value = address ? address.description : '';

      onChangeCallback(value);
      onChangePlacesAutocomplete(value);
    },
    [onChangePlacesAutocomplete],
  );

  useEffect(() => {
    const getSearchOptions = async () => {
      if (!cityWatch) return;

      const geoCodes = await geocodeByAddress(cityWatch);
      const geoCode = geoCodes[0];

      setSearchOptions({
        location: geoCode.geometry.location,
        bounds: geoCode.geometry.bounds,
      });
    };

    getSearchOptions();
  }, [cityWatch]);

  return (
    <form noValidate style={{ position: 'relative' }}>
      {isLoading && <Loader withBackdrop />}

      <div style={{ marginBottom: '0.5rem' }}>
        <FormControl name={EVENT_FORM_MODEL.name} control={control} placeholder="Name" />
      </div>

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <FormControl
          name={EVENT_FORM_MODEL.description}
          placeholder="Description"
          control={control}
          multiline
          minRows={4}
        />
      </div>

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <FormDatePicker
          name={EVENT_FORM_MODEL.date}
          control={control}
          placeholder="Date of event"
        />
      </div>

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <CountriesSelect control={control} name={EVENT_FORM_MODEL.country} />
      </div>

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <Controller
          name={EVENT_FORM_MODEL.city}
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              getOptionLabel={getOptionLabel()}
              getOptionSelected={getOptionSelected()}
              options={cities}
              onChange={(_, newValue) => field.onChange(newValue)}
              value={field.value}
              disableClearable={true}
              disabled={!cities.length}
              size={inputSize}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="outlined"
                  error={fieldState.invalid}
                  helperText={fieldState.error && fieldState.error.message}
                />
              )}
            />
          )}
        />
      </div>

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <Controller
          name={EVENT_FORM_MODEL.place}
          control={control}
          render={({ field, fieldState }) => (
            <PlacesAutocomplete
              googleCallbackName="initPlacesAutocomplete"
              value={placesAutocompleteValue}
              onChange={onChangePlacesAutocomplete}
              searchOptions={searchOptions}
            >
              {({ getInputProps, suggestions }) => {
                const inputProps = getInputProps();
                return (
                  <Autocomplete
                    id="placeAutocomplete"
                    getOptionLabel={getOptionLabel('description')}
                    options={suggestions}
                    onChange={onSelectPlaceAutocomplete(field.onChange)}
                    value={inputProps.value}
                    fullWidth={true}
                    autoComplete={true}
                    includeInputInList={true}
                    filterSelectedOptions={true}
                    filterOptions={(x) => x}
                    size={inputSize}
                    disabled={Boolean(!cityWatch)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Place"
                        variant="outlined"
                        error={fieldState.invalid}
                        helperText={fieldState.invalid && fieldState.error.message}
                      />
                    )}
                    onInputChange={(_, value) => {
                      inputProps.onChange({ target: { value } });
                    }}
                  />
                );
              }}
            </PlacesAutocomplete>
          )}
        />
      </div>

      <div style={{ marginTop: '0.5rem' }}>
        <FormFilesManagement name={EVENT_FORM_MODEL.images} control={control} maxFiles={10} />
      </div>
    </form>
  );
};
