import { EVENT_FORM_MODEL, EVENT_ITEM_ADDRESS_MODEL } from '../utils/models';
import { getCoordinatesByAddress } from './functionsForPlacesAutocomplete';

function mapEventItemToFormValues({ _id, address, ...otherProps }) {
  return {
    ...otherProps,
    [EVENT_FORM_MODEL.country]: address[EVENT_ITEM_ADDRESS_MODEL.country],
    [EVENT_FORM_MODEL.city]: address[EVENT_ITEM_ADDRESS_MODEL.city],
    [EVENT_FORM_MODEL.place]: address[EVENT_ITEM_ADDRESS_MODEL.place],
  };
}

async function mapFormValuesToEventItem({ country, city, place, ...otherValues }) {
  const coordinates = await getCoordinatesByAddress(place);
  return { ...otherValues, address: { country, city, place, coordinates } };
}

export { mapEventItemToFormValues, mapFormValuesToEventItem };
