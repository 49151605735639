import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { Tabs } from 'components/base/Tabs/Tabs';
import { TabsPage } from 'components/base/Tabs/TabsPage';
import { FormPromotion } from './components/Forms/FormPromotion/FormPromotion';

// Actions
import { alertActions } from 'features/Alert';

// Hooks
import { useGoBack } from 'hooks/useGoBack';

// Utils
import { mapPromotionToRequest, mapResponseToPromotion } from './utils/transformSnippets';

// Services
import { getPromotionById, updatePromotionById } from 'services/promotions.service';

// Helpers
import { invalidateQueries } from 'helpers/queryClient';

export const PromotionsDetails = () => {
  const dispatch = useDispatch();

  const { id: promotionId } = useParams();

  const getPromotionQuery = useQuery({
    queryKey: ['promotion', promotionId],
    queryFn: () => getPromotionById(promotionId),
    select: mapResponseToPromotion,
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const publishedFields = getPromotionQuery.data?.published;
  const hasPublication = Boolean(publishedFields);

  const tabsList = useMemo(() => {
    const items = ['Moderation status'];

    if (hasPublication) {
      items.push('Publication Status');
    }

    return items;
  }, [hasPublication]);

  const goBack = useGoBack();

  const updatePartnerMutation = useMutation({
    mutationFn: (formValues) => {
      return updatePromotionById(promotionId, mapPromotionToRequest(formValues));
    },
    onSuccess: async () => {
      await invalidateQueries({ queryKey: ['promotion'] });
      dispatch(alertActions.success('Promotion was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <PageDetails title="View and edit the promotion">
      <Tabs list={tabsList}>
        <TabsPage>
          <FormPromotion
            isLoading={getPromotionQuery.isFetching || updatePartnerMutation.isLoading}
            defaultValues={getPromotionQuery.data}
            onAccept={updatePartnerMutation.mutate}
            onDismiss={goBack}
          />
        </TabsPage>
        <TabsPage>
          {hasPublication && (
            <FormPromotion
              isPreviewMode={true}
              isLoading={getPromotionQuery.isFetching}
              defaultValues={publishedFields}
              onDismiss={goBack}
            />
          )}
        </TabsPage>
      </Tabs>
    </PageDetails>
  );
};

export default PromotionsDetails;
