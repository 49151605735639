import Api from 'apis/default.api';

const ROOT_ENDPOINT = '/termsAndConditions';

export const getTermsInfo = () => Api.get(`${ROOT_ENDPOINT}/info`);

export const updateTerms = ({ params, reqData }) => {
  const { newFile, fileType } = reqData;

  return Api.put(ROOT_ENDPOINT, newFile, {
    params,
    headers: {
      'Content-Type': fileType || newFile.type,
    },
  });
};

export const deleteTerm = (params) => {
  return Api.delete(
    ROOT_ENDPOINT,
    {
      params,
    },
  );
};
