import produce from 'immer';

import { EMPTY_ARRAY } from 'constants/empties';

import actionsTypes from './types';

const initialState = {
  isCountriesLoaded: false,
  isCountriesLoading: false,
  countriesList: EMPTY_ARRAY,
  citiesList: EMPTY_ARRAY,
  error: null,
};

export const countries = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionsTypes.GET_REQUEST: {
      draft.isCountriesLoaded = false;
      draft.isCountriesLoading = true;

      return draft;
    }
    case actionsTypes.GET_SUCCESS: {
      draft.isCountriesLoaded = true;
      draft.isCountriesLoading = false;
      draft.countriesList = action.data.countriesList;
      draft.citiesList = action.data.citiesList;

      return draft;
    }
    case actionsTypes.GET_FAILURE: {
      draft.isCountriesLoaded = false;
      draft.isCountriesLoading = false;
      draft.error = action.error;

      return draft;
    }

    default:
      return draft;
  }
});
