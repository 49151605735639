import React from 'react';

// UIKit
import Grid from '@material-ui/core/Grid';

// Components
import { Form } from 'components/base/Form/Form';
import { FormControl } from 'components/base/FormControl/FormControl';
import { FormCheckbox } from 'components/base/FormCheckbox/FormCheckbox';

// Utils
import { VERSION_MODEL } from '../../utils/models';
import { FIELDS_PLACEHOLDERS } from '../../utils/form.utils';

export const FormMutate = ({ control }) => {
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            placeholder={FIELDS_PLACEHOLDERS[VERSION_MODEL.platform]}
            name={VERSION_MODEL.platform}
            control={control}
            isDisabled
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            placeholder={FIELDS_PLACEHOLDERS[VERSION_MODEL.appType]}
            name={VERSION_MODEL.appType}
            control={control}
            isDisabled
          />
        </Grid>
        <Grid item xs={10}>
          <FormControl
            placeholder={FIELDS_PLACEHOLDERS[VERSION_MODEL.version]}
            name={VERSION_MODEL.version}
            control={control}
          />
        </Grid>
        <Grid item xs={2} container>
          <FormCheckbox
            placeholder={FIELDS_PLACEHOLDERS[VERSION_MODEL.mandatory]}
            name={VERSION_MODEL.mandatory}
            control={control}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
