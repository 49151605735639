import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';

import CategoryIcon from '@material-ui/icons/Category';
import { Box, IconButton } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';

import { categoriesActions } from 'actions/categories.actions';
import {
  UpdateCategoryModal,
  DeleteCategoryModal,
  CreateCategoryModal,
} from './ConfirmCategoryModals';
import { categoriesSelector } from 'selectors/categories.selectors';

import './style.css';

export function CategoriesTable() {
  const [rowPageSize, setRowPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const { isCategoriesLoading, data, meta } = useSelector(categoriesSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(categoriesActions.findCategories(page, rowPageSize));
  }, [dispatch, page, rowPageSize]);

  const [openModal, setOpenModal] = useState({
    status: false,
    action: '',
    body: {},
  });

  const columns = [
    {
      selector: 'avatar',
      width: '7%',
      name: 'Logo',
      cell: (row) => <img className="categoryAvatar" src={row.avatar} alt="logo" />,
      ignoreRowClick: true,
      allowOverflow: true,
    },
    { selector: 'name', name: 'Name', width: '81%', sortable: true },
    {
      width: '6%',
      cell: (body) => (
        <IconButton
          onClick={() => {
            setOpenModal({
              status: true,
              action: 'update',
              body,
            });
          }}
        >
          <Create />
        </IconButton>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      width: '6%',
      cell: (body) => (
        <IconButton
          onClick={() =>
            setOpenModal({
              status: true,
              action: 'delete',
              body,
            })
          }
        >
          <Delete />
        </IconButton>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="categories-page">
      {openModal.status && openModal.action === 'create' && (
        <CreateCategoryModal state={openModal} setState={setOpenModal} page={page} />
      )}
      {openModal.status && openModal.action === 'delete' && (
        <DeleteCategoryModal state={openModal} setState={setOpenModal} page={page} />
      )}
      {openModal.status && openModal.action === 'update' && (
        <UpdateCategoryModal state={openModal} setState={setOpenModal} page={page} />
      )}
      <Box
        onClick={() => setOpenModal({ status: true, action: 'create', body: {} })}
        className="btn btn-outline-dark"
      >
        Create a new category{'  '} <CategoryIcon />
      </Box>
      <div style={{ height: 400, width: '100%' }}>
        <DataTable
          progressPending={isCategoriesLoading}
          onChangeRowsPerPage={(size) => setRowPageSize(size)}
          paginationTotalRows={meta.total}
          onChangePage={(page) => setPage(page)}
          data={data}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          paginationPerPage={rowPageSize}
          columns={columns}
          progressComponent={<div className="lds-hourglass"></div>}
          highlightOnHover
          paginationServer
          pagination
        />
      </div>
    </div>
  );
}
