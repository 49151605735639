import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Styles
import styles from './FormPromotion.module.scss';

// Components
import Loader from 'components/base/Loader/Loader';
import FormControl from 'components/base/FormControl/FormControl';
import PartnersSelect from 'components/base/Selects/PartnersSelect';
import FormDatePicker from 'components/base/FormDatePicker/FormDatePicker';
import FormFilesManagement from 'components/base/FormFilesManagement/FormFilesManagement';
import FormCheckbox from 'components/base/FormCheckbox/FormCheckbox';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

// Utils
import { getDirtyValues } from 'utils/functionsForForm';
import dateUtils, { generateRangeDates } from 'utils/functionsForDate';

// Constants
// import { DATE_UNITS_ENUM } from 'constants/dates';
import {
  DEFAULT_VALUES,
  FIELDS_LABELS,
  FORM_FIELDS_NAMES,
  newLocalizeFormItem,
  newLocationFormItem,
  VALIDATION_SCHEMA,
} from './FormPromotion.constants';
import ListManager from 'components/base/ListManager/ListManager';
import { languagesWithoutEnglishLengthSelector } from 'features/Dictionaries/Languages/store/selectors';
import useDisabledLanguages from 'hooks/useDisabledLanguages';
import { PROMOTION_LOCALIZE_MODEL } from 'features/Promotions/utils/models';
import NewLocalization from '../../NewLocalization/NewLocalization';
import { useSelector } from 'react-redux';
import { NewLocation } from '../../NewLocation/NewLocalization';
import { Input } from 'components/base/Input/Input';
import { useGetPartner } from 'hooks/api';
import { normalizeFormValues } from './FormPromotions.utils';
import { DEFAULT_DATEPICKER_DATE_FORMAT } from 'constants/dates';

// const currentDate = new Date();

export const FormPromotion = ({
  isLoading = false,
  defaultValues = null,
  isPreviewMode = false,
  onAccept = () => {},
  onDismiss,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    resetField,
    reset,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  useBlockerPrompt(isDirty);

  const languagesWithoutEnglishLength = useSelector(languagesWithoutEnglishLengthSelector);

  const watchPartnerId = useWatch({ name: FORM_FIELDS_NAMES.partnerId, control });
  const watchStartDate = dateUtils.isoToDate(
    useWatch({ name: FORM_FIELDS_NAMES.startDate, control }),
  );
  const watchEndDate = dateUtils.isoToDate(useWatch({ name: FORM_FIELDS_NAMES.endDate, control }));
  // const watchPublicationDate = dateUtils.isoToDate(
  //   useWatch({ name: FORM_FIELDS_NAMES.publicationDate, control }),
  // );

  const { data: partnerData } = useGetPartner({ partnerID: watchPartnerId });

  const isEditMode = Boolean(defaultValues);

  const excludeDates = useMemo(() => {
    if (!partnerData?.busyDates?.length) return [];

    const rangeArray = [];

    partnerData.busyDates.forEach((busyDate) => {
      generateRangeDates({ ...busyDate, rangeArray });
    });

    return rangeArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerData?.busyDates]);

  const disabledLanguages = useDisabledLanguages(
    watch,
    FORM_FIELDS_NAMES.localizations,
    PROMOTION_LOCALIZE_MODEL.locale,
  );

  // const startTimeMinMax = useMemo(() => {
  //   const timeRange = {
  //     minTime: dateUtils.getToday(),
  //     maxTime: dateUtils.getEndOf(),
  //   };

  //   if (dateUtils.dayjsDate(watchStartDate).isBefore(currentDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.minTime = dateUtils.getEndOf();
  //     timeRange.maxTime = dateUtils.getEndOf();
  //   }

  //   if (dateUtils.dayjsDate(watchStartDate).isSame(watchEndDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.maxTime = watchEndDate;
  //   }

  //   if (dateUtils.dayjsDate(watchStartDate).isAfter(currentDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.minTime = dateUtils.getStartOf();
  //   }

  //   return timeRange;
  // }, [watchEndDate, watchStartDate]);

  // const endTimeMinMax = useMemo(() => {
  //   const timeRange = {
  //     minTime: dateUtils.dayjsDate(watchStartDate).add(1, DATE_UNITS_ENUM.hour).toDate(),
  //     maxTime: dateUtils.getEndOf(),
  //   };

  //   if (dateUtils.dayjsDate(watchEndDate).isAfter(watchStartDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.minTime = dateUtils.getStartOf();
  //   }

  //   return timeRange;
  // }, [watchEndDate, watchStartDate]);

  // const publishTimeMinMax = useMemo(() => {
  //   const timeRange = {
  //     minTime: dateUtils.getToday(),
  //     maxTime: dateUtils.getEndOf(),
  //   };

  //   const publicationDate = watchPublicationDate ?? dateUtils.getToday();

  //   if (dateUtils.dayjsDate(publicationDate).isBefore(currentDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.minTime = dateUtils.getEndOf();
  //     timeRange.maxTime = dateUtils.getEndOf();
  //   }

  //   if (dateUtils.dayjsDate(publicationDate).isSame(watchStartDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.maxTime = watchStartDate;
  //   }

  //   if (dateUtils.dayjsDate(publicationDate).isAfter(currentDate, DATE_UNITS_ENUM.date)) {
  //     timeRange.minTime = dateUtils.getStartOf();
  //   }

  //   return timeRange;
  // }, [watchPublicationDate, watchStartDate]);

  const handleAccept = useCallback(
    (formValues) => {
      formValues.startDate.setUTCHours(0, 0, 0, 0);
      formValues.endDate.setUTCHours(0, 0, 0, 0);
      formValues.publicationDate.setUTCHours(0, 0, 0, 0);

      if (isEditMode) {
        const normalizeValues = normalizeFormValues(formValues);
        const changedValues = getDirtyValues(dirtyFields, normalizeValues);

        return onAccept(changedValues);
      }

      onAccept(formValues);
    },
    [dirtyFields, isEditMode, onAccept],
  );

  // Reset the Localization field when change the Partner field
  useEffect(() => {
    if (!watchPartnerId) return;

    resetField(FORM_FIELDS_NAMES.locations);
  }, [resetField, watchPartnerId]);

  useEffect(() => {
    if (defaultValues) {
      reset({ ...DEFAULT_VALUES, ...defaultValues });
    }
  }, [defaultValues, reset]);

  return (
    <form noValidate className={styles.form}>
      {isLoading && <Loader withBackdrop />}

      {isEditMode && (
        <div className={styles.formRow}>
          <Input
            placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.status]}
            value={defaultValues.status}
            isDisabled={true}
          />
        </div>
      )}
      <div className={styles.formRow}>
        <FormControl
          name={FORM_FIELDS_NAMES.title}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.title]}
          control={control}
          isDisabled={isPreviewMode}
        />
      </div>
      <div className={styles.formRow}>
        <FormControl
          name={FORM_FIELDS_NAMES.description}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.description]}
          control={control}
          multiline
          isDisabled={isPreviewMode}
        />
      </div>
      <div className={styles.formRow}>
        <FormControl
          name={FORM_FIELDS_NAMES.clientPercent}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.clientPercent]}
          control={control}
          type="number"
          isDisabled={isPreviewMode}
        />
      </div>
      <div className={styles.formRow}>
        <PartnersSelect
          name={FORM_FIELDS_NAMES.partnerId}
          control={control}
          isDisabled={isEditMode || isPreviewMode}
        />
      </div>
      <div className={styles.formRow}>
        <ListManager
          buttonTitle={FIELDS_LABELS[FORM_FIELDS_NAMES.locations]}
          name="locations"
          control={control}
          newItemModel={newLocationFormItem}
          maxItems={partnerData?.locations?.length ?? 1}
          isDisabled={isPreviewMode || !watchPartnerId}
          PreviewComponent={
            <NewLocation
              watch={watch}
              setValue={setValue}
              isDisabled={isPreviewMode}
              partnerId={watchPartnerId}
            />
          }
        />
      </div>
      <div className={styles.formRow}>
        <FormDatePicker
          name={FORM_FIELDS_NAMES.startDate}
          control={control}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.startDate]}
          selectsStart={true}
          startDate={watchStartDate}
          endDate={watchEndDate}
          dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
          // minTime={startTimeMinMax.minTime}
          // maxTime={startTimeMinMax.maxTime}
          isDisabled={isPreviewMode}
          excludeDates={excludeDates}
          showTimeSelect={false}
        />
      </div>
      <div className={styles.formRow}>
        <FormDatePicker
          name={FORM_FIELDS_NAMES.endDate}
          control={control}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.endDate]}
          selectsEnd={true}
          startDate={watchStartDate}
          endDate={watchEndDate}
          minDate={watchStartDate}
          dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
          // minTime={endTimeMinMax.minTime}
          // maxTime={endTimeMinMax.maxTime}
          isDisabled={!watchStartDate || isPreviewMode}
          excludeDates={excludeDates}
          showTimeSelect={false}
        />
      </div>
      <div className={styles.formRow}>
        <FormDatePicker
          name={FORM_FIELDS_NAMES.publicationDate}
          control={control}
          placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.publicationDate]}
          maxDate={watchStartDate}
          dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
          // minTime={publishTimeMinMax.minTime}
          // maxTime={publishTimeMinMax.maxTime}
          isDisabled={!watchEndDate || isPreviewMode}
          excludeDates={excludeDates}
          showTimeSelect={false}
        />
      </div>

      {partnerData?.category?.applyForAllTransactionInPromotion && (
        <div className={styles.formRow}>
          <FormCheckbox
            name={FORM_FIELDS_NAMES.applyForAll}
            control={control}
            placeholder={FIELDS_LABELS[FORM_FIELDS_NAMES.applyForAll]}
            isDisabled={isPreviewMode}
          />
        </div>
      )}
      <div className={styles.formRow}>
        <FormFilesManagement
          name={FORM_FIELDS_NAMES.mediaFiles}
          control={control}
          buttonLabel={FIELDS_LABELS[FORM_FIELDS_NAMES.mediaFiles]}
          maxFiles={40}
          id="mediaFiles"
          accept="image/png, image/jpg, image/jpeg, video/avi, video/mp4, video/mpeg"
          isDisabled={isPreviewMode}
        />
      </div>
      <div className={styles.formRow}>
        <ListManager
          buttonTitle={FIELDS_LABELS[FORM_FIELDS_NAMES.localizations]}
          name={FORM_FIELDS_NAMES.localizations}
          control={control}
          newItemModel={newLocalizeFormItem}
          maxItems={languagesWithoutEnglishLength}
          isDisabled={isPreviewMode}
          PreviewComponent={
            <NewLocalization
              isDisabled={isPreviewMode}
              inputSize="small"
              disabledLanguages={disabledLanguages}
            />
          }
        />
      </div>

      {!isPreviewMode && (
        <div className={styles.footer}>
          <button
            className="btn btn-primary"
            disabled={isLoading || !isDirty}
            onClick={handleSubmit(handleAccept)}
            type="button"
          >
            Yes, save
          </button>
          <button
            className="btn btn-secondary"
            onClick={onDismiss}
            type="button"
            data-dismiss="modal"
          >
            No
          </button>
        </div>
      )}
    </form>
  );
};

export default FormPromotion;
