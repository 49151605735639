import React from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import cn from 'classnames';

// UIKit
import { Typography, Badge, ListItem } from '@material-ui/core';

export const NavBarSubItem = ({ classes, title, path, badgeContent = 0 }) => {
  const resolved = useResolvedPath(path);
  const isActive = useMatch({ path: resolved.pathname, end: true });

  return (
    <ListItem button className={classes.nested}>
      <Badge badgeContent={badgeContent} color="primary" overlap="rectangular">
        <NavLink to={path}>
          <Typography
            classes={{ root: classes.listItemTitle }}
            className={cn({
              [classes.active]: isActive,
            })}
          >
            {title}
          </Typography>
        </NavLink>
      </Badge>
    </ListItem>
  );
};
