import produce from 'immer';

import actionsTypes from './types';

const initialState = {
  isStatisticLoading: false,
  data: {},
  error: null,
};

export const statistic = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionsTypes.GET_STATISTIC_REQUEST: {
      draft.isStatisticLoading = true;
      draft.error = null;

      return draft;
    }
    case actionsTypes.GET_STATISTIC_SUCCESS: {
      draft.isStatisticLoading = false;
      draft.data = action.statistics;

      return draft;
    }
    case actionsTypes.GET_STATISTIC_FAILURE: {
      draft.isStatisticLoading = false;
      draft.error = action.error;

      return draft;
    }

    default:
      return draft;
  }
});
