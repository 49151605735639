export const NOTIFICATION_LOCALIZATION_VALUE_MODEL = Object.freeze({
  title: 'title',
  message: 'message',
});

export const NOTIFICATION_LOCALIZATION_MODEL = Object.freeze({
  locale: 'locale',
  value: 'value',
});

export const NOTIFICATION_FORM_MODEL = Object.freeze({
  type: 'type',
  title: 'title',
  personalizedTitle: 'personalizedTitle',
  message: 'message',
  personalizedMessage: 'personalizedMessage',
  city: 'city',
  country: 'country',
  localizedContent: 'localizedContent',
  partner: 'partner',
  promotion: 'promotion',
});
