export function mapResponseToFormValues(response) {
  const { giveaway } = response;
  const { _id, userNotified, transactions, finished, ...otherItems } = giveaway;

  return otherItems;
}

export function mapFormValuesToRequest(formValues) {
  const { answerOption, ...otherItems } = formValues;

  return otherItems;
}
