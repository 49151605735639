import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalDelete = ({ name, isLoading, onAccept, onDismiss }) => (
  <Modal
    handleClose={onDismiss}
    name={'Delete item of Charity Giveaway'}
    show={true}
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" disabled={isLoading} onClick={onAccept} type="button">
          Yes, delete
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          type="button"
          data-dismiss="modal"
        >
          No, cancel
        </button>
      </Fragment>
    }
  >
    {`Do you want to delete giveaway '${name}'?`}
  </Modal>
);
