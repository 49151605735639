import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

// Components
import ListManager from 'components/base/ListManager/ListManager';

import { Preview } from './Preview';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getTermsInfo, deleteTerm } from 'services/terms.services';
import { alertActions } from 'features/Alert';

import { queryClient } from 'helpers/queryClient';

const newItemModel = {
  lang: null,
  type: '',
  link: [],
};

export const TermsFiles = ({ maxItems }) => {
  const dispatch = useDispatch();

  const terms = useQuery({
    queryKey: ['termsFiles'],
    queryFn: getTermsInfo,
    select: (response) => {
      return response?.items
        .filter(({ type }) => type === 'md')
        .map(({ lang, link }) => ({ lang, link: [link] }));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const deleteTermMutation = useMutation({
    mutationFn: async (params) => {
      return deleteTerm(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termsFiles'] });
      dispatch(alertActions.success('Term was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const { control, reset } = useForm({
    defaultValues: {
      files: [],
    },
  });

  const files = useWatch({
    control,
    name: 'files',
  });

  const disabledLanguages = useMemo(() => files.map(({ lang }) => lang), [files]);

  useEffect(() => {
    if (terms?.data) {
      reset({ files: terms.data });
    }
  }, [reset, terms.data]);

  return (
    <ListManager
      buttonTitle="Add files and localization"
      name="files"
      control={control}
      newItemModel={newItemModel}
      maxItems={maxItems}
      PreviewComponent={
        <Preview disabledLanguages={disabledLanguages} onDelete={deleteTermMutation.mutate} />
      }
    />
  );
};
