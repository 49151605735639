import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import { Table } from 'components/base/Table/Table';

// Constants
import { SORTING_ORDER_TYPES } from 'constants/filters';

export const TableWithFilters = ({ filters, onChangeFilters, ...otherProps }) => {
  const currentPage = useRef(filters.page);
  const navigate = useNavigate();

  const toDetailsPage = useCallback(
    (row) => {
      navigate(row._id);
    },
    [navigate],
  );

  const onChangeRowsPerPage = useCallback(
    (size) => onChangeFilters({ limit: size }),
    [onChangeFilters],
  );

  const onChangePage = useCallback(
    (page) => {
      currentPage.current = page;
      onChangeFilters({ page });
    },
    [onChangeFilters],
  );

  const onChangeSort = useCallback(
    ({ selector }, orderBy) => {
      onChangeFilters({ sortBy: selector, orderBy });
    },
    [onChangeFilters],
  );

  return (
    <Table
      highlightOnHover={true}
      onRowClicked={toDetailsPage}
      pagination={true}
      paginationServer={true}
      paginationDefaultPage={currentPage.current}
      paginationComponentOptions={{
        noRowsPerPage: true,
      }}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      sortServer={true}
      onSort={onChangeSort}
      {...otherProps}
    />
  );
};

TableWithFilters.propTypes = {
  filters: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    sortBy: PropTypes.string,
    orderBy: PropTypes.oneOf([SORTING_ORDER_TYPES.asc, SORTING_ORDER_TYPES.desc]),
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
};

export default TableWithFilters;
