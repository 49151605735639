import { AUTH_POST_MESSAGES } from 'constants/auth.constants';
import { removeAuthTokens } from './auth';

const getValidError = (error) => {
  if (!error.response) {
    return { code: 500, message: error.message };
  }

  if (!error.response.data?.error) {
    return { code: error.response.status, message: error.response.statusText };
  }

  if (typeof error.response.data?.error === 'string') {
    return { code: error.response.status, message: error.response.data.error };
  }

  return error.response.data.error;
};

export const handleError = (error) => {
  console.dir(error);

  const statusCode = error.response ? error.response.status : 500;
  const errorForReject = getValidError(error);

  if (statusCode === 401) {
    removeAuthTokens();

    window.postMessage({
      type: AUTH_POST_MESSAGES.notAuthorized,
    });
  }

  if (statusCode === 403) {
    removeAuthTokens();

    window.postMessage({
      type: AUTH_POST_MESSAGES.forbidden,
    });
  }

  return Promise.reject(errorForReject);
};
