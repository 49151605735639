import { Maths } from 'helpers/maths';
import { isPlainObject } from './functionsForCoreJS';

const filtersFields = (dirtyField, values) => {
  const currentField = values[dirtyField];

  if (isPlainObject(currentField)) {
    const result = Object.keys(currentField).filter((item) => filtersFields(item, currentField));

    return Boolean(result.length);
  }

  if (Array.isArray(currentField)) {
    const arrayFiltered = currentField.filter((items) => {
      const filterObject = Object.values(items).filter((item) => Boolean(item));
      return Boolean(filterObject.length);
    });

    if (arrayFiltered.length) {
      return currentField;
    }

    return false;
  }

  return Boolean(currentField);
};

/**
 * Get form fields based on object with boolean flags.
 *
 * @param {object} dirtyFields  the form's fields with boolean flags which we need to.
 * @param {object} allValues    the form's values which we need to het get in.
 * @returns {object} with fields which we get with dirtyFields
 */
export function getDirtyValues(dirtyFields, allValues) {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  const fields = Object.keys(dirtyFields)
    .filter((field) => filtersFields(field, dirtyFields))
    .map((key) => [key, getDirtyValues(dirtyFields[key], allValues[key])]);

  return Object.fromEntries(fields);
}
/**
 * Convert bytes MBytes.
 *
 * @param {number} bytes  amount bytes
 * @returns {number} converted bytes to bytes
 */
export function bytesToMB(bytes) {
  if (!bytes) return 0;

  const k = 1000;
  return Maths(bytes).abs().div(k).div(k).toNumber();
}
