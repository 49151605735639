import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UIKit
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Redux
import { aGetStatistic } from './store/actions';
import { statisticSelector } from './store/selectors';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { Input } from 'components/base/Input/Input';
import { IconPopover } from 'components/base/IconPopover/IconPopover';

// Utilities
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';
import { truncateToTwoDecimals } from './utils/numbers'

// Constants
import { TRANSITIONS_SELECT_OPTIONS } from './constants/transitionsSelect';

export const Analytics = () => {
  const dispatch = useDispatch();

  const [selectValue, setSelectValue] = useState(TRANSITIONS_SELECT_OPTIONS[0]);

  const { isStatisticLoading, data } = useSelector(statisticSelector);

  const changeFilter = useCallback((_, v) => {
    setSelectValue(v);
  }, []);

  const getStatistics = useCallback(() => {
    const { title, ...queries } = selectValue;

    dispatch(aGetStatistic(queries));
  }, [dispatch, selectValue]);

  return (
    <LayoutShowcase title="Analytics" isLoading={isStatisticLoading} onLoading={getStatistics}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            id="transactionsPer"
            options={TRANSITIONS_SELECT_OPTIONS}
            getOptionLabel={getOptionLabel('title')}
            getOptionSelected={getOptionSelected('title')}
            onChange={changeFilter}
            value={selectValue}
            size="small"
            fullWidth={true}
            disableClearable={true}
            renderInput={(params) => (
              <TextField label="Transactions per" variant="outlined" {...params} />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Number of clients" value={truncateToTwoDecimals(data.clientCount)} disabled={true} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Number of Partners" value={truncateToTwoDecimals(data.partnerCount)} disabled={true} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input label="Number of transactions" value={truncateToTwoDecimals(data.transactionCount)} disabled={true} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label={
              <IconPopover label="Total Commission Due for Aller-Retour">
                Total Amount of Money that the Partner needs to pay Aller-Retour
              </IconPopover>
            }
            value={truncateToTwoDecimals(data.totalCashBackToAdmin)}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label={
              <IconPopover label="Total Virtual Points Due">
                Total amount of Virtual Points the Partner owes Clients
              </IconPopover>
            }
            value={truncateToTwoDecimals(data.totalCashBackToClient)}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label={
              <IconPopover label="Total value received from Transactions">
                Total sum of all the payments made through the Aller-Retour Application
              </IconPopover>
            }
            value={truncateToTwoDecimals(data.sumOfPrice)}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label={
              <IconPopover label="Total Virtual Points Owed">
                Total amount of Virtual Points Aller-Retour owes the Partner
              </IconPopover>
            }
            value={truncateToTwoDecimals(data.adminPayToPartner)}
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Input
            label={
              <IconPopover label="Current Balance">
                The difference between the Owed and Due Virtual Points
              </IconPopover>
            }
            value={truncateToTwoDecimals(data.currentBalanceAdmin)}
            disabled={true}
          />
        </Grid>
      </Grid>
    </LayoutShowcase>
  );
};
