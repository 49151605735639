import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Components
import NotificationTypesSelect from 'components/base/Selects/NotificationTypesSelect';
import CountriesSelect from 'components/base/Selects/CountriesSelect';
import ListManager from 'components/base/ListManager/ListManager';
import Loader from 'components/base/Loader/Loader';
import NewLocalization from '../NewLocalization/NewLocalization';
import PartnersSelect from 'components/base/Selects/PartnersSelect';
import PromotionsSelect from 'components/base/Selects/PromotionsSelect';
import FormCheckbox from 'components/base/FormCheckbox/FormCheckbox';

import { NOTIFICATION_ENUM } from 'features/Dictionaries/Notifications/utils/enums';

// Utilities
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';

// Hooks
import useParamsSelector from 'hooks/useParamsSelector';
import useDisabledLanguages from 'hooks/useDisabledLanguages';

import { getCityByIdSelector } from 'features/Dictionaries/Countries/store/selectors';
import {
  languagesLengthSelector,
  languagesWithoutEnglishLengthSelector,
} from 'features/Dictionaries/Languages/store/selectors';
import { TermsFiles } from '../TermsFiles/TermsFiles';
import { PolicyFiles } from '../PolicyFiles/PolicyFiles';

const inputSize = 'small';

const newLocalizationItemModel = {
  locale: null,
  value: {
    title: '',
    message: '',
  },
};

export const FormNotification = ({ control, watch, isLoading, onSubmit, isDirty, onClearPartnerValue }) => {
  const countryWatch = useWatch({ control, name: 'country' });
  const typeWatch = useWatch({ control, name: 'type' });
  const partnerWatch = useWatch({ control, name: 'partner' });

  const languagesLength = useSelector(languagesLengthSelector);

  const languagesWithoutEnglishLength = useSelector(languagesWithoutEnglishLengthSelector);

  const cities = useParamsSelector(getCityByIdSelector, countryWatch);

  const disabledLanguages = useDisabledLanguages(watch, 'localizedContent', 'locale');

  useEffect(() => {
    const listOfFieldsWithPartnerSelect = [NOTIFICATION_ENUM.PROMOTION, NOTIFICATION_ENUM.PARTNER];
    const isElementDoesntIncludePartnerField = !listOfFieldsWithPartnerSelect.includes(String(typeWatch));

    if (partnerWatch && isElementDoesntIncludePartnerField) {
      onClearPartnerValue();
    }
  }, [typeWatch]);

  const showedItems = useMemo(() => {
    const items = ['title', 'message', 'country', 'localizedContent'];

    if (typeWatch === NOTIFICATION_ENUM.PARTNER) {
      return [...items, 'partner'];
    }

    if (typeWatch === NOTIFICATION_ENUM.PROMOTION) {
      return [...items, 'partner', 'promotion'];
    }

    if (typeWatch === NOTIFICATION_ENUM.NEW_TERMS_AND_CONDITIONS) {
      return ['country', 'terms'];
    }

    if (typeWatch === NOTIFICATION_ENUM.NEW_PRIVACY_POLICY) {
      return ['country', 'policy'];
    }

    return items;
  }, [typeWatch]);

  return (
    <form noValidate style={{ position: 'relative' }}>
      {isLoading && <Loader withBackdrop />}

      <div style={{ marginBottom: '1rem' }}>
        <NotificationTypesSelect isRequire control={control} name="type" />
      </div>

      {showedItems.includes('partner') && (
        <div style={{ marginBottom: '1rem' }}>
          <PartnersSelect freeSolo isRequire control={control} name="partner" />
        </div>
      )}

      {showedItems.includes('promotion') && (
        <div style={{ marginBottom: '1rem' }}>
          <PromotionsSelect
            isPublished={true}
            control={control}
            name="promotion"
            partnerId={partnerWatch}
            disabled={!partnerWatch}
          />
        </div>
      )}

      {showedItems.includes('title') && (
        <>
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  label="Title"
                  variant="outlined"
                  fullWidth
                  size={inputSize}
                  error={fieldState.invalid}
                  helperText={fieldState.error && fieldState.error.message}
                />
              )}
            />
          </div>

          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <FormCheckbox
              control={control}
              name="personalizedTitle"
              placeholder="Personalized Title"
            />
          </div>
        </>
      )}

      {showedItems.includes('message') && (
        <>
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Controller
              name="message"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  {...field}
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  size={inputSize}
                  minRows={4}
                  error={fieldState.invalid}
                  helperText={fieldState.error && fieldState.error.message}
                />
              )}
            />
          </div>

          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <FormCheckbox
              control={control}
              name="personalizedMessage"
              placeholder="Personalized Message"
            />
          </div>
        </>
      )}

      {showedItems.includes('country') && (
        <>
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <CountriesSelect isRequire control={control} name="country" />
          </div>

          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Controller
              name="city"
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  getOptionLabel={getOptionLabel()}
                  getOptionSelected={getOptionSelected()}
                  options={cities}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  value={field.value}
                  disableClearable={true}
                  disabled={!cities.length}
                  size={inputSize}
                  fullWidth={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="City"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState.error && fieldState.error.message}
                    />
                  )}
                />
              )}
            />
          </div>
        </>
      )}

      {showedItems.includes('localizedContent') && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <ListManager
            buttonTitle="Add new localization"
            name="localizedContent"
            control={control}
            newItemModel={newLocalizationItemModel}
            maxItems={languagesWithoutEnglishLength}
            PreviewComponent={
              <NewLocalization inputSize={inputSize} disabledLanguages={disabledLanguages} />
            }
          />
        </div>
      )}

      {showedItems.includes('terms') && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <TermsFiles maxItems={languagesLength} />
        </div>
      )}

      {showedItems.includes('policy') && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <PolicyFiles maxItems={languagesLength} />
        </div>
      )}

      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <button
          className="btn btn-primary"
          disabled={isLoading || !isDirty}
          onClick={onSubmit}
          type="button"
        >
          Create notification
        </button>
      </div>
    </form>
  );
};
