import { DEFAULT_CITY, DEFAULT_COUNTRY } from 'constants/countries.constants';
import { NOTIFICATION_ENUM } from 'features/Dictionaries/Notifications/utils/enums';
import { array, object, string } from 'yup';

// Models
import {
  NOTIFICATION_FORM_MODEL,
  NOTIFICATION_LOCALIZATION_MODEL,
  NOTIFICATION_LOCALIZATION_VALUE_MODEL,
} from './models';

const defaultValues = {
  [NOTIFICATION_FORM_MODEL.title]: '',
  [NOTIFICATION_FORM_MODEL.type]: null,
  [NOTIFICATION_FORM_MODEL.country]: DEFAULT_COUNTRY,
  [NOTIFICATION_FORM_MODEL.city]: DEFAULT_CITY,
  [NOTIFICATION_FORM_MODEL.localizedContent]: [],
  [NOTIFICATION_FORM_MODEL.message]: '',
};

const validationSchema = object({
  type: string()
    .label('Type')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),

  partner: string().when('type', {
    is: (type) =>
      type !== NOTIFICATION_ENUM.ESSENTIAL &&
      type !== NOTIFICATION_ENUM.NEW_PRIVACY_POLICY &&
      type !== NOTIFICATION_ENUM.NEW_TERMS_AND_CONDITIONS,
    then: string()
      .label('Partner')
      .typeError(({ label }) => `${label} is a required field`)
      .required(),
    otherwise: string().nullable(),
  }),

  promotion: string().when(NOTIFICATION_FORM_MODEL.type, {
    is: (type) =>
      type === NOTIFICATION_ENUM.PROMOTION &&
      type !== NOTIFICATION_ENUM.NEW_PRIVACY_POLICY &&
      type !== NOTIFICATION_ENUM.NEW_TERMS_AND_CONDITIONS,
    then: string()
      .label('Promotion')
      .typeError(({ label }) => `${label} is a required field`)
      .required(),
    otherwise: string().nullable(),
  }),

  title: string().when(NOTIFICATION_FORM_MODEL.type, {
    is: (type) =>
      type !== NOTIFICATION_ENUM.NEW_PRIVACY_POLICY &&
      type !== NOTIFICATION_ENUM.NEW_TERMS_AND_CONDITIONS,

    then: string().trim().label('Title').min(2).required(),
    otherwise: string().trim().nullable(),
  }),

  message: string().when(NOTIFICATION_FORM_MODEL.type, {
    is: (type) =>
      type !== NOTIFICATION_ENUM.NEW_PRIVACY_POLICY &&
      type !== NOTIFICATION_ENUM.NEW_TERMS_AND_CONDITIONS,

    then: string().trim().label('Message').min(2).required(),
    otherwise: string().trim().nullable(),
  }),

  localizedContent: array().of(
    object({
      [NOTIFICATION_LOCALIZATION_MODEL.locale]: string()
        .label('Localization')
        .typeError(({ label }) => `${label} is a required field`)
        .required(),
      [NOTIFICATION_LOCALIZATION_MODEL.value]: object({
        [NOTIFICATION_LOCALIZATION_VALUE_MODEL.title]: string().label('Title').required(),
        [NOTIFICATION_LOCALIZATION_VALUE_MODEL.message]: string().label('Message').required(),
      }),
    }),
  ),
});

export { defaultValues, validationSchema };
