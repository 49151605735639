export const GIVEAWAY_TRANSACTION_CLIENT_MODEL = Object.freeze({
  name: 'name',
  surname: 'surname',
  id: '_id',
});

export const GIVEAWAY_TRANSACTION_MODEL = Object.freeze({
  client: 'client',
  sum: 'sum',
  transactionDate: 'transactionDate',
  transactionId: 'transactionId',
  userId: 'userId',
});

export const GIVEAWAY_MODEL = Object.freeze({
  city: 'city',
  country: 'country',
  description: 'description',
  finishAt: 'finishAt',
  finished: 'finished',
  images: 'images',
  minPrice: 'minPrice',
  name: 'name',
  userNotified: 'userNotified',
  winner: 'winner',
  transactions: 'transactions',
  id: '_id',
});
