import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Helpers
import { browserStorage } from 'helpers/browserStorage';

// Constants
import { ROUTES } from 'constants/routes.constants';
import { AUTH_POST_MESSAGES } from 'constants/auth.constants';

// Hooks
import useRouterChange from 'hooks/useRouterChange';

export const RequireAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isSignIn, setIsSignIn] = useState(Boolean(browserStorage.getItem('token')));

  const checkIsSignIn = useCallback(() => {
    setIsSignIn(Boolean(browserStorage.getItem('token')));
  }, []);

  useEffect(() => {
    const checkServerError = (event) => {
      const { type } = event.data;

      if (type === AUTH_POST_MESSAGES.notAuthorized) {
        return navigate(ROUTES.login, { state: { from: location } });
      }

      if (type === AUTH_POST_MESSAGES.forbidden) {
        return navigate(ROUTES.forbidden, { state: { from: location } });
      }
    };

    window.addEventListener('message', checkServerError, false);

    return () => {
      window.removeEventListener('message', checkServerError, false);
    };
  }, [dispatch, location, location.pathname, navigate]);

  useRouterChange(checkIsSignIn);

  return isSignIn ? <Outlet /> : <Navigate to={ROUTES.login} state={{ from: location }} replace />;
};
