import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import Grid from '@material-ui/core/Grid';

// Models
import { FAQ_MODEL, FAQ_ROLE_TYPES } from './utils/models';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { FaqTable } from './components/FaqTable';
import { createFaq, deleteFaq, getFaq, updateFaq } from 'services/faq.service';
import { useModal } from 'hooks/useModal';
import { queryClient } from 'helpers/queryClient';
import { alertActions } from 'features/Alert';
import { ModalDelete } from './modals/ModalDelete';
import { ModalMutate } from './modals/ModalMutate';
import { ModalConfirmExit } from './modals/ModalConfirmExit';

export const Faq = () => {
  const dispatch = useDispatch();

  // Modal delete
  const [modalDeleteIsOpen, onShowModalDelete, onHideModalDelete, modalDeletePayload] = useModal();

  // Confirm exit
  const [modalConfirmExitIsOpen, onShowModalConfirmExit, onHideModalConfirmExit] = useModal();

  // Modal create
  const [modalCreateIsOpen, onShowModalCreate, onHideModalCreate, modalCreatePayload] = useModal();

  // Modal update
  const [modalUpdateIsOpen, onShowModalUpdate, onHideModalUpdate, modalUpdatePayload] = useModal();

  const hideOpenedModals = useCallback(() => {
    onHideModalCreate();
    onHideModalUpdate();
    onHideModalDelete();
    onHideModalConfirmExit();
  }, [onHideModalConfirmExit, onHideModalCreate, onHideModalDelete, onHideModalUpdate]);

  const { isLoading, data } = useQuery({
    queryKey: ['faq'],
    queryFn: () => getFaq(),
    select: ({ items }) => {
      const clientItems = items.filter((item) => item.role === FAQ_ROLE_TYPES.client);
      const partnerItems = items.filter((item) => item.role === FAQ_ROLE_TYPES.partner);

      return {
        clientItems,
        partnerItems,
      };
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const createMutation = useMutation({
    mutationFn: (newFaq) => {
      const formValues = { ...newFaq, role: modalCreatePayload };

      return createFaq(formValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faq'] });
      hideOpenedModals();
      dispatch(alertActions.success('FAQ was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const updateMutation = useMutation({
    mutationFn: (newFaq) => {
      return updateFaq(modalUpdatePayload[FAQ_MODEL.id], newFaq);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faq'] });
      hideOpenedModals();
      dispatch(alertActions.success('FAQ was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return deleteFaq(modalDeletePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['faq'] });
      hideOpenedModals();
      dispatch(alertActions.success('FAQ was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Faq">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FaqTable
            title="Client"
            isLoading={isLoading}
            list={data?.clientItems}
            role={FAQ_ROLE_TYPES.client}
            onCreateModal={onShowModalCreate}
            onUpdateModal={onShowModalUpdate}
            onRemoveModal={onShowModalDelete}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FaqTable
            title="Partner"
            isLoading={isLoading}
            list={data?.partnerItems}
            role={FAQ_ROLE_TYPES.partner}
            onCreateModal={onShowModalCreate}
            onUpdateModal={onShowModalUpdate}
            onRemoveModal={onShowModalDelete}
          />
        </Grid>
      </Grid>

      {modalCreateIsOpen && (
        <ModalMutate
          onAccept={createMutation.mutate}
          onDismiss={onShowModalConfirmExit}
          isLoading={createMutation.isLoading}
        />
      )}

      {modalUpdateIsOpen && (
        <ModalMutate
          onAccept={updateMutation.mutate}
          onDismiss={onShowModalConfirmExit}
          isLoading={updateMutation.isLoading}
          values={modalUpdatePayload}
        />
      )}

      {modalDeleteIsOpen && (
        <ModalDelete
          onAccept={deleteMutation.mutate}
          onDismiss={hideOpenedModals}
          isLoading={deleteMutation.isLoading}
        />
      )}

      {modalConfirmExitIsOpen && (
        <ModalConfirmExit onAccept={hideOpenedModals} onDismiss={onHideModalConfirmExit} />
      )}
    </LayoutShowcase>
  );
};
