import React, { Fragment } from 'react';

import Modal from 'components/base/Modal/Modal';

export const ModalConfirmUnsaved = ({ onAccept, onDismiss, show = true }) => (
  <Modal
    handleClose={onDismiss}
    name="Would you like to exit?"
    show={show}
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" onClick={onAccept} type="button">
          Yes, exit
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          type="button"
          data-dismiss="modal"
        >
          No, continue editing
        </button>
      </Fragment>
    }
  >
    Unsaved changes will be lost. Would you like to exit?
  </Modal>
);

export default ModalConfirmUnsaved;
