import Api from 'apis/default.api';

const ROOT_ENDPOINT = '/privacyPolicy';

export const getPoliciesInfo = () => Api.get(`${ROOT_ENDPOINT}/info`);

export const updatePolicy = ({ params, reqData }) => {
  const { newFile, fileType } = reqData;

  return Api.put(ROOT_ENDPOINT, newFile, {
    params,
    headers: {
      'Content-Type': fileType || newFile.type,
    },
  });
};
