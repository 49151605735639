import axios from 'axios';
import { serverConfig } from 'config/config';

import { handleError } from 'helpers';

import { globalRequestInterceptor, globalResponseInterceptor } from 'helpers/axiosInterceptors';

const DefaultApi = axios.create({
  baseURL: `${serverConfig._baseURL}/`,
  timeout: 20000,
});

DefaultApi.interceptors.request.use(globalRequestInterceptor);

DefaultApi.interceptors.response.use(globalResponseInterceptor, handleError);

export default DefaultApi;
