import React, { Fragment, useCallback, useState } from 'react';

// UIKit
import TextField from '@material-ui/core/TextField';

// Components
import { Modal } from 'components/base/Modal/Modal';

// Utils
import { removeMultipleSpaces } from 'utils/functionsForInput';

const CHARACTER_LIMIT = 1;

export const ConfirmCancelTransaction = ({ isLoading, onDismiss, onAccept }) => {
  const [value, setValue] = useState('');

  const isDisabledButton = value.trim().length < CHARACTER_LIMIT || isLoading;

  const onChange = useCallback((e) => setValue(e.target.value), []);

  const handleSubmit = () => {
    onAccept(removeMultipleSpaces(value));
  };

  return (
    <Modal
      show={true}
      handleClose={onDismiss}
      name="Confirm cancel transaction"
      isLoading={isLoading}
      renderFooter={
        <Fragment>
          <button
            type="button"
            className="btn btn-primary"
            disabled={isDisabledButton}
            onClick={handleSubmit}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onDismiss}
          >
            No
          </button>
        </Fragment>
      }
    >
      <p>Please add canceling reason message</p>
      <TextField
        id="transaction_canceling_reason_message"
        label="Cancel reason message"
        multiline
        fullWidth
        minRows={4}
        value={value}
        variant="outlined"
        onChange={onChange}
      />
    </Modal>
  );
};
