// TODO: Need to get from API
export const POLL_ANSWER_OPTION_ENUM = Object.freeze({
  open: 'OPEN',
  singleSelect: 'SINGLE_SELECT',
});

export const POLL_ANSWER_OPTION_MODEL = Object.freeze({
  value: 'value',
  description: 'description',
  type: 'type',
  _id: '_id',
});

export const POLL_USER_ANSWERS_MODEL = Object.freeze({
  answerOption: 'answerOption',
  other: 'other',
  userId: 'userId',
  _id: '_id',
});

export const POLL_MODEL = Object.freeze({
  answerOption: 'answerOption',
  createdAt: 'createdAt',
  city: 'city',
  country: 'country',
  description: 'description',
  finishAt: 'finishAt',
  isVoted: 'isVoted',
  question: 'question',
  results: 'results',
  userAnswers: 'userAnswers',
  id: '_id',
});
