import './ModalCreate.style.css';

import React, { Fragment, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { FormOfCreation } from '../../FormOfCreation/FormOfCreation';

// Utils
import { defaultValues } from '../../../utils/formCreate';
import { creationValidationSchema } from '../../../utils/validations';
import { getDirtyValues } from 'utils/functionsForForm';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

export const ModalCreate = ({ onDismiss, onAccept, isLoading }) => {
  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { dirtyFields, isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(creationValidationSchema),
  });

  const submitForm = useCallback(
    (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, formValues);
      onAccept(changedValues);
    },
    [dirtyFields, onAccept],
  );

  useBlockerPrompt(isDirty);

  return (
    <Modal
      handleClose={onDismiss}
      name="Add new partner"
      show={true}
      isLoading={isLoading}
      renderFooter={
        <Fragment>
          <button
            className="btn btn-primary"
            disabled={isLoading}
            onClick={handleSubmit(submitForm)}
            type="button"
          >
            Save
          </button>
          <button className="btn btn-secondary" onClick={onDismiss} type="button">
            Cancel
          </button>
        </Fragment>
      }
    >
      <FormOfCreation control={control} trigger={trigger} watch={watch} />
    </Modal>
  );
};
