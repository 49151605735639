import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import cn from 'classnames';

// UIKit
import { Badge, ListItemIcon, Typography, List, ListItem, Collapse } from '@material-ui/core';
import { NavBarSubItem } from './NavBarSubItem';

import UseStyles from './Styling';

const NavBarItem = ({ title, path, icon: IconTag, submenu, noticeKey, notices }) => {
  const classes = UseStyles();
  const resolved = useResolvedPath(path);
  const isActive = Boolean(useMatch({ path: resolved.pathname, end: false }));

  const [isOpen, setIsOpen] = useState(isActive);
  const [isHover, setIsHover] = useState(false);

  const hasCollapse = Boolean(submenu);

  const LinkElement = useMemo(() => (hasCollapse ? 'div' : NavLink), [hasCollapse]);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  const onClick = () => {
    if (hasCollapse) {
      setIsOpen(!isOpen);
    }
  };

  const isShowLabel = isActive || isHover || isOpen;

  const badgeContent = notices[noticeKey] ?? 0;

  useEffect(() => {
    if (!isActive) {
      setIsOpen(false);
    }
  }, [isActive]);

  return (
    <Fragment>
      <ListItem
        button={true}
        classes={{ root: classes.listItem }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <LinkElement className={classes.listItemContainer} onClick={onClick} to={path}>
          <Badge badgeContent={!isOpen ? badgeContent : 0} color="primary" overlap="rectangular">
            <ListItemIcon
              classes={{ root: classes.listItemIcon }}
              className={cn({
                [classes.hide]: isShowLabel,
              })}
            >
              <IconTag fontSize="large" />
            </ListItemIcon>
            <Typography
              classes={{ root: classes.listItemTitle }}
              className={cn({
                [classes.show]: isShowLabel,
                [classes.hide]: !isShowLabel,
              })}
            >
              {title}
            </Typography>
          </Badge>
        </LinkElement>
      </ListItem>

      {hasCollapse && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {submenu.map(({ title, path, noticeKey }) => (
              <NavBarSubItem
                key={title}
                badgeContent={notices[noticeKey] ?? 0}
                title={title}
                path={path}
                classes={classes}
              />
            ))}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
};

export default NavBarItem;
