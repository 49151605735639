export const NOTICES_KEYS = Object.freeze({
  users: 'users',
  partners: 'partners',
  categories: 'categories',
  charities: 'charities',
  events: 'events',
  vlogs: 'vlogs',
  polls: 'polls',
  giveaways: 'giveaways',
  analytics: 'analytics',
  promotions: 'promotions',
  pending: 'pending',
  published: 'published',
});
