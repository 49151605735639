import axios from 'axios';

import { browserStorage } from 'helpers/browserStorage';

import { serverConfig } from '../config';

export const authService = {
  login,
  logout,
};

const LOGIN_ENDPOINT = 'api/v1/auth/login';

function login(email, password) {
  return axios
    .post(`${serverConfig._domain}/${LOGIN_ENDPOINT}`, {
      email: email,
      password: password,
    })
    .then((response) => {
      browserStorage.setItem('token', `Bearer ${response.data.token}`);

      return response;
    });
}

function logout() {
  browserStorage.removeItems(['token', 'refreshToken', 'accaunt']);
}
