import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRouterChange = (callback = () => {}) => {
  const location = useLocation();

  useEffect(() => {
    callback && callback();
  }, [location, callback]);
};

export default useRouterChange;
