import { POLL_ANSWER_OPTION_MODEL } from './models';

export function mapResponseToFormValues(response) {
  const { _id, results, userAnswers, ...otherItems } = response;

  return {
    ...otherItems,
  };
}

export function mapFormValuesToRequest(formValues) {
  const { answerOption, ...otherItems } = formValues;
  return {
    ...otherItems,
    answerOption: answerOption.map(mapAnswerOptionToRequest),
  };
}

export function mapAnswerOptionToDisabled(answerOption) {
  return {
    _id: answerOption[POLL_ANSWER_OPTION_MODEL._id],
    option: true,
  };
}

function mapAnswerOptionToRequest(answerOption) {
  const { _id, ...options } = answerOption;
  return options;
}
