import { useQuery } from '@tanstack/react-query';
import { getCountries } from 'services';

export const useCountries = () => {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['countries'],
    queryFn: getCountries,
    refetchOnWindowFocus: false,
  });

  return {
    isCountriesFetching: isFetching || isLoading,
    isCountriesError: isError,
    countries: data ?? [],
  };
};
