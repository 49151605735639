import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

// Icons
import CategoryIcon from '@material-ui/icons/Category';
import DeleteIcon from '@material-ui/icons/Delete';
import PollIcon from '@material-ui/icons/Poll';

// Components
import { ModalDelete, ModalCreate, ModalConfirm } from './components/modals';
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';

// Actions
import { alertActions } from 'features/Alert';

// Services
import { createGiveaway, deleteGiveawayById, getGiveaways } from 'services/charity.services';

// Utils
import { dateToFormat, sortingDates } from 'utils/functionsForDate';

// Constants
import { ROUTES } from 'constants/routes.constants';

// Models
import { GIVEAWAY_MODEL } from './utils/models';

// Hooks
import { useModal } from 'hooks/useModal';
import { useTableParams } from 'common/hooks/useTableParams';

// Helpers
import { queryClient } from 'helpers/queryClient';
import { generatePathByParams, getLastPath } from 'utils/functionsForRouter';

export const Giveaways = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Modal delete
  const [modalDeleteIsOpen, onShowModalDelete, onHideModalDelete, modalDeletePayload] = useModal();

  // Confirm delete
  const [modalConfirmationIsOpen, onShowModalConfirmation, onHideModalConfirmation] = useModal();

  // Modal create
  const [modalCreateIsOpen, onShowModalCreate, onHideModalCreate] = useModal();

  const hideOpenedModals = useCallback(() => {
    onHideModalDelete();
    onHideModalConfirmation();
    onHideModalCreate();
  }, [onHideModalConfirmation, onHideModalCreate, onHideModalDelete]);

  const createMutation = useMutation({
    mutationFn: (newGiveaway) => {
      return createGiveaway(newGiveaway);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['giveaways'] });
      hideOpenedModals();
      dispatch(alertActions.success('Giveaway was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      return deleteGiveawayById(modalDeletePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['giveaways'] });
      hideOpenedModals();
      dispatch(alertActions.success('Giveaway was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const toResultsPage = useCallback(
    (row) => {
      const path = generatePathByParams({
        parent: pathname,
        id: row[GIVEAWAY_MODEL.id],
        results: getLastPath(ROUTES.charityGiveawaysResults),
      });

      navigate(path);
    },
    [navigate, pathname],
  );

  const tableColumnsSettings = useMemo(
    () => [
      { selector: GIVEAWAY_MODEL.name, name: 'Name', sortable: true },
      {
        selector: GIVEAWAY_MODEL.finishAt,
        name: 'End date',
        sortable: true,
        maxWidth: '200px',
        sortFunction: (rowA, rowB) => {
          return sortingDates(rowA[GIVEAWAY_MODEL.finishAt], rowB[GIVEAWAY_MODEL.finishAt]);
        },
        format: ({ finishAt }) => dateToFormat(finishAt),
      },
      { selector: GIVEAWAY_MODEL.country, name: 'Country', sortable: true, maxWidth: '250px' },
      { selector: GIVEAWAY_MODEL.city, name: 'City', maxWidth: '250px' },
      {
        selector: GIVEAWAY_MODEL.finished,
        name: 'Status',
        maxWidth: '250px',
        format: (row) => (row[GIVEAWAY_MODEL.finished] ? 'Finished' : 'Not finished'),
      },
      {
        name: 'View results',
        ignoreRowClick: true,
        button: true,
        cell: (row) =>
          row[GIVEAWAY_MODEL.finished] && (
            <IconButton onClick={toResultsPage.bind(null, row)}>
              <PollIcon />
            </IconButton>
          ),
      },
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: ({ _id }) => (
          <IconButton onClick={onShowModalDelete.bind(null, _id)}>
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [onShowModalDelete, toResultsPage],
  );

  const tableActionsMemo = useMemo(
    () => (
      <Box className="btn btn-outline-dark" onClick={onShowModalCreate}>
        Create a new giveaway <CategoryIcon />
      </Box>
    ),
    [onShowModalCreate],
  );

  const [queryParams] = useTableParams({ initial: { sortBy: GIVEAWAY_MODEL.finishAt } });

  const { isLoading, data } = useQuery({
    queryKey: ['giveaways', queryParams],
    queryFn: () => getGiveaways(queryParams),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Giveaways">
      <TableWithQueryFilters
        title="List of Charity Giveaways"
        renderHeader={tableActionsMemo}
        progressPending={isLoading}
        data={data?.rows}
        columns={tableColumnsSettings}
        paginationTotalRows={data?.count}
        defaultSortFieldId={queryParams.sortBy}
      />

      {modalDeleteIsOpen && (
        <ModalDelete
          onAccept={deleteMutation.mutate}
          onDismiss={hideOpenedModals}
          name={modalDeletePayload}
          isLoading={deleteMutation.isLoading}
        />
      )}

      {modalCreateIsOpen && (
        <ModalCreate
          onAccept={createMutation.mutate}
          onDismiss={onShowModalConfirmation}
          isLoading={createMutation.isLoading}
        />
      )}

      {modalConfirmationIsOpen && (
        <ModalConfirm onAccept={hideOpenedModals} onDismiss={onHideModalConfirmation} />
      )}
    </LayoutShowcase>
  );
};
