import React, { useCallback, useMemo, useState, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// UIKit
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { Table } from 'components/base/Table/Table';
import { ModalWinner } from './components/modals';

// Reducers
import { winnerInitialState, winnerReducer } from './store/reducers';

// Actions
import { aGetGiveawayById, aResetGiveaway, winnerActions } from './store/actions';

// Selectors
import { giveawayItemSelector } from './store/selectors';

// Services
import { getGiveawayWinnerById } from 'services/charity.services';

// Utils
import { dateToFormat } from 'utils/functionsForDate';

// Models
import {
  GIVEAWAY_MODEL,
  GIVEAWAY_TRANSACTION_CLIENT_MODEL,
  GIVEAWAY_TRANSACTION_MODEL,
} from './utils/models';

const columnsSettings = [
  {
    name: 'User Name',
    cell: (row) => {
      if (row[GIVEAWAY_TRANSACTION_MODEL.client]) {
        return `${row[GIVEAWAY_TRANSACTION_MODEL.client][GIVEAWAY_TRANSACTION_CLIENT_MODEL.name]} ${
          row[GIVEAWAY_TRANSACTION_MODEL.client][GIVEAWAY_TRANSACTION_CLIENT_MODEL.surname]
        }`;
      }

      return 'Deleted user';
    },
  },
  { selector: GIVEAWAY_TRANSACTION_MODEL.transactionId, name: 'Transaction ID' },
  { selector: GIVEAWAY_TRANSACTION_MODEL.sum, name: 'Sum' },
  {
    selector: GIVEAWAY_TRANSACTION_MODEL.transactionDate,
    name: 'Transaction date',
    format: (row) => dateToFormat(row[GIVEAWAY_TRANSACTION_MODEL.transactionDate]),
  },
];

export const GiveawayResults = () => {
  const dispatch = useDispatch();
  const { id: slug } = useParams();

  const [isModalShown, changeIsModalShown] = useState(false);

  const { isGiveawayLoading, data, error } = useSelector(giveawayItemSelector);
  const { giveaway } = data;

  const [winnerState, winnerDispatch] = useReducer(winnerReducer, winnerInitialState);

  const showModal = () => changeIsModalShown(true);

  const hideModal = () => changeIsModalShown(false);

  const getGiveaway = useCallback(() => dispatch(aGetGiveawayById(slug)), [dispatch, slug]);

  const resetGiveaway = useCallback(() => () => dispatch(aResetGiveaway()), [dispatch]);

  const getWinnerById = useCallback(() => {
    if (giveaway && giveaway[GIVEAWAY_MODEL.finished]) {
      winnerDispatch(winnerActions.aGetWinnerRequest());

      getGiveawayWinnerById(slug)
        .then((res) => winnerDispatch(winnerActions.aGetWinnerSuccess(res)))
        .catch((error) => winnerDispatch(winnerActions.aGetWinnerFailure(error)));
    }
  }, [giveaway, slug]);

  const transactions = useMemo(() => {
    if (isGiveawayLoading) return [];

    return giveaway[GIVEAWAY_MODEL.transactions];
  }, [giveaway, isGiveawayLoading]);

  const renderHeader = useMemo(() => {
    if (isGiveawayLoading || !giveaway[GIVEAWAY_MODEL.finished]) return;

    return (
      <button className="btn btn-primary" onClick={showModal}>
        See the winner
      </button>
    );
  }, [giveaway, isGiveawayLoading]);

  const renderSubHeader = useMemo(() => {
    if (isGiveawayLoading) return;

    const date = giveaway[GIVEAWAY_MODEL.finishAt];
    const city = giveaway[GIVEAWAY_MODEL.city];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            value={dateToFormat(date)}
            label="Date"
            variant="outlined"
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            value={city}
            label="City"
            variant="outlined"
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
    );
  }, [giveaway, isGiveawayLoading]);

  useEffect(getWinnerById, [getWinnerById]);

  useEffect(resetGiveaway, []);

  return (
    <PageDetails
      title="Results of the giveaway"
      isLoading={isGiveawayLoading}
      onLoading={getGiveaway}
      errorMessage={error && error.message}
    >
      <Table
        title="Participants of the Giveaway"
        progressPending={isGiveawayLoading}
        data={transactions}
        columns={columnsSettings}
        pagination={false}
        renderHeader={renderHeader}
        renderSubHeader={renderSubHeader}
      />

      {isModalShown && (
        <ModalWinner onDismiss={hideModal}>
          <Table
            title="List transactions of winner"
            progressPending={winnerState.isWinnerLoading}
            columns={columnsSettings}
            data={winnerState.winner.transactions}
            pagination={false}
            renderSubHeader={renderSubHeader}
          />
        </ModalWinner>
      )}
    </PageDetails>
  );
};
