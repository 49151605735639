import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Table from 'components/base/Table/Table';

// Constants
import { FILTERS_ENUM } from 'constants/filters';

// Utils
import { getCurrentPage } from 'utils/functionsForPagination';
import useSearchParams from 'hooks/useSearchParams';

export const TableWithQueryFilters = (props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = getCurrentPage(searchParams.get(FILTERS_ENUM.page));

  const initialPage = useRef(currentPage);

  const toDetailsPage = useCallback(
    (row) => {
      navigate(row._id);
    },
    [navigate],
  );

  const onChangePage = useCallback(
    (page) => {
      initialPage.current = page;
      setSearchParams({ page });
    },
    [setSearchParams],
  );

  const onChangeSort = useCallback(
    ({ selector, sortField }, orderBy) => {
      const sortBy = sortField ?? selector;

      setSearchParams({ sortBy, orderBy });
    },
    [setSearchParams],
  );

  return (
    <Table
      highlightOnHover={true}
      onRowClicked={toDetailsPage}
      pagination={true}
      paginationServer={true}
      paginationDefaultPage={initialPage.current}
      paginationComponentOptions={{
        noRowsPerPage: true,
      }}
      onChangePage={onChangePage}
      sortServer={true}
      onSort={onChangeSort}
      {...props}
    />
  );
};

export default TableWithQueryFilters;
