import actionsTypes from './types';
import { getDictionaryOfPromotions } from 'services/public.service';
import { rootSelector } from 'features/Dictionaries/Promotions/store/selectors';

export default {
  aGetDictionaryOfPromotions,
  aGetDictionaryOfPromotionsIfNotLoaded,
};

export function aGetDictionaryOfPromotionsIfNotLoaded(dictionaryType) {
  return (dispatch, getStore) => {
    const store = getStore();
    const dictionaries = rootSelector(store);
    const dictionary = dictionaries[dictionaryType];

    if (!dictionary) {
      return dispatch(aGetDictionaryOfPromotions(dictionaryType));
    }
  };
}

export function aGetDictionaryOfPromotions(dictionaryType) {
  return (dispatch) => {
    dispatch(request(dictionaryType));

    return getDictionaryOfPromotions(dictionaryType).then(
      (response) => {
        dispatch(success(dictionaryType, response));
      },
      (error) => {
        dispatch(failure(dictionaryType, error));
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_REQUEST, dictionaryType };
  }

  function success(dictionaryType, data) {
    return { type: actionsTypes.GET_SUCCESS, dictionaryType, data };
  }

  function failure(dictionaryType, error) {
    return { type: actionsTypes.GET_FAILURE, dictionaryType, error };
  }
}
