import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirmExit = ({ onAccept, onDismiss }) => (
  <Modal
    handleClose={onDismiss}
    name="Would you like to exit?"
    show={true}
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" onClick={onAccept} type="button">
          Yes, exit
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          type="button"
          data-dismiss="modal"
        >
          No, continue editing
        </button>
      </Fragment>
    }
  >
    <div className="modal-body">
      <h5 className="text-center">Unsaved changes will be lost. Would you like to exit?</h5>
    </div>
  </Modal>
);
