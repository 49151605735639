import { TABLE_ROWS_PER_PAGE } from './table';

export const SORTING_ORDER_TYPES = {
  asc: 'asc',
  desc: 'desc',
};

export const FILTERS_ENUM = Object.freeze({
  offset: 'offset',
  limit: 'limit',
  orderBy: 'orderBy',
  sortBy: 'sortBy',
  page: 'page',
});

export const INITIAL_FILTERS = {
  [FILTERS_ENUM.offset]: 0,
  [FILTERS_ENUM.limit]: TABLE_ROWS_PER_PAGE,
  [FILTERS_ENUM.orderBy]: SORTING_ORDER_TYPES.desc,
};

export const INITIAL_FILTERS_STATE = {
  offset: 0,
  limit: 10,
  page: 1,
  orderBy: SORTING_ORDER_TYPES.desc,
};
