import Api from 'apis/default.api';

export default {
  createNewNotification,
  getNotificationTypes,
};

const ROOT_ENDPOINT = '/customNotifications';
const TYPES_ENDPOINT = 'types';

export async function createNewNotification(notification) {
  return Api.post(ROOT_ENDPOINT, notification);
}

export async function getNotificationTypes() {
  return Api.get(`${ROOT_ENDPOINT}/${TYPES_ENDPOINT}`);
}
