import { USER_TYPES } from 'models/users';

function formatReferral(tableRow) {
  if (tableRow.isReferral) {
    return tableRow.promoterType === USER_TYPES.celebrity ? 'Celebrity Referral' : 'Referral';
  }

  return 'Not Referral';
}

export { formatReferral };
