import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

// Pages
import { LoginPage } from './LoginPage/LoginPage';
import { ForbiddenPage } from './ForbiddenPage/ForbiddenPage';
import { NotFoundPage } from './NotFoundPage/NotFoundPage';

// Components
import { RequireAuth } from 'components/layouts/RequireAuth/RequireAuth';
import { MainLayout } from 'components/layouts/MainLayout/MainLayout';

// Features
import { Users, UserDetails } from 'features/Users';
import { Partners, PartnerDetails, PartnerProducts, PartnerTransactions } from 'features/Partners';
import { Categories } from 'features/Categories';
import {
  EventDetails,
  Events,
  GiveawayDetails,
  GiveawayResults,
  Giveaways,
  PollDetails,
  PollResults,
  Polls,
  VlogDetails,
  Vlogs,
} from 'features/Charity';
import { Analytics } from 'features/Analytics';
import { Moderation, PromotionsDetails, Published } from 'features/Promotions';
import { Notifications } from 'features/Notifications';
import { Faq } from 'features/Faq';
import { Versions } from 'features/Versions';

// Constants
import { ROUTES } from 'constants/routes.constants';

export const Routing = () => {
  return (
    <Routes>
      <Route path={ROUTES.login} element={<LoginPage />} />
      <Route path={ROUTES.forbidden} element={<ForbiddenPage />} />

      <Route element={<RequireAuth />}>
        <Route element={<MainLayout />}>
          <Route path={ROUTES.users} element={<Outlet />}>
            <Route index element={<Users />} />
            <Route path={ROUTES.userDetails} element={<UserDetails />} />
          </Route>

          <Route path={ROUTES.partners} element={<Outlet />}>
            <Route index element={<Partners />} />
            <Route path={ROUTES.partnerDetails} element={<PartnerDetails />} />
            <Route path={ROUTES.partnerProducts} element={<PartnerProducts />} />
            <Route path={ROUTES.partnerTransactions} element={<PartnerTransactions />} />
          </Route>

          <Route path={ROUTES.categories} element={<Outlet />}>
            <Route index element={<Categories />} />
          </Route>

          <Route path={ROUTES.charity} element={<Outlet />}>
            <Route path={ROUTES.charityEvents} element={<Events />} />
            <Route path={ROUTES.charityEventsDetails} element={<EventDetails />} />

            <Route path={ROUTES.charityVlogs} element={<Vlogs />} />
            <Route path={ROUTES.charityVlogsDetails} element={<VlogDetails />} />

            <Route path={ROUTES.charityPolls} element={<Polls />} />
            <Route path={ROUTES.charityPollsDetails} element={<PollDetails />} />
            <Route path={ROUTES.charityPollsResults} element={<PollResults />} />

            <Route path={ROUTES.charityGiveaways} element={<Giveaways />} />
            <Route path={ROUTES.charityGiveawaysDetails} element={<GiveawayDetails />} />
            <Route path={ROUTES.charityGiveawaysResults} element={<GiveawayResults />} />

            <Route path={ROUTES.charity} element={<Navigate to={ROUTES.charityEvents} replace />} />
          </Route>

          <Route path={ROUTES.analytics} element={<Outlet />}>
            <Route index element={<Analytics />} />
          </Route>

          <Route path={ROUTES.promotions} element={<Outlet />}>
            <Route path={ROUTES.promotionsModeration} element={<Moderation />} />
            <Route path={ROUTES.promotionsModerationDetails} element={<PromotionsDetails />} />

            <Route path={ROUTES.promotionPublished} element={<Published />} />
            <Route path={ROUTES.promotionPublishedDetails} element={<PromotionsDetails />} />

            <Route
              path={ROUTES.promotions}
              element={<Navigate to={ROUTES.promotionsModeration} replace />}
            />
          </Route>

          <Route path={ROUTES.notifications} element={<Outlet />}>
            <Route index element={<Notifications />} />
          </Route>

          <Route path={ROUTES.faq} element={<Outlet />}>
            <Route index element={<Faq />} />
          </Route>

          <Route path={ROUTES.versions} element={<Outlet />}>
            <Route index element={<Versions />} />
          </Route>
        </Route>
      </Route>

      <Route path={ROUTES.root} element={<Navigate to={ROUTES.users} replace={true} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
