import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';

// Selectors
import { categoriesSelector } from 'features/Dictionaries/Categories/store/selectors';

// Utils
import {
  getOptionForValue,
  getOptionLabel,
  getOptionSelected,
} from 'utils/functionsForAutocomplete';

const primaryColor = red[500];
const inputSize = 'small';

export const CategoriesSelect = ({ control, name }) => {
  const { isCategoriesLoading, data = [], error } = useSelector(categoriesSelector);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const isError = fieldState.invalid || Boolean(error);
        const helperText =
          (fieldState.invalid && fieldState.error.message) || (error && error.message);

        return (
          <Autocomplete
            size={inputSize}
            disableClearable={true}
            options={data}
            loading={isCategoriesLoading}
            fullWidth
            getOptionLabel={getOptionLabel('name')}
            getOptionSelected={getOptionSelected('name')}
            value={getOptionForValue({
              value: field.value,
              options: data,
              valueKey: 'name',
              optionKey: '_id',
            })}
            onChange={(_, newValue) => {
              field.onChange(newValue._id);
            }}
            onBlur={field.onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={
                  <Fragment>
                    Category:<span style={{ color: primaryColor }}>*</span>
                  </Fragment>
                }
                error={isError}
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isCategoriesLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};
