export const ROUTES = Object.freeze({
  root: '/',

  login: '/login',
  forbidden: '/forbidden',
  notfound: '/notfound',

  users: '/users',
  userDetails: '/users/:id',

  partners: '/partners',
  partnerDetails: '/partners/:id',
  partnerProducts: '/partners/:id/products',
  partnerTransactions: '/partners/:id/transactions',

  categories: '/categories',

  charity: '/charity',
  charityEvents: '/charity/events',
  charityEventsDetails: '/charity/events/:id',
  charityVlogs: '/charity/vlogs',
  charityVlogsDetails: '/charity/vlogs/:id',
  charityPolls: '/charity/polls',
  charityPollsDetails: '/charity/polls/:id',
  charityPollsResults: '/charity/polls/:id/results',
  charityGiveaways: '/charity/giveaways',
  charityGiveawaysDetails: '/charity/giveaways/:id',
  charityGiveawaysResults: '/charity/giveaways/:id/results',

  analytics: '/analytics',

  promotions: '/promotions',
  promotionsModeration: '/promotions/moderation',
  promotionsModerationDetails: '/promotions/moderation/:id',
  promotionPublished: '/promotions/published',
  promotionPublishedDetails: '/promotions/published/:id',

  notifications: '/notifications',
  faq: '/faq',
  versions: '/versions',
});
