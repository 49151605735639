import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';

// Components
import { VlogForm } from './components/VlogForm';
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';

// Actions
import { alertActions } from 'features/Alert';

// Hooks

import { useCountries } from 'common/hooks/api/useCountries';
import { useCities } from 'common/hooks/api/useCities';

import { useBlockerPrompt } from 'hooks/useBlockerPrompt';
import { useGoBack } from 'hooks/useGoBack';

// Utilities
import {
  defaultValues,
  VLOG_FIELDS_NAMES,
  mapFormValuesToRequest,
  mapResponseToFormValues,
  schema,
} from './utils/form';
import { getDirtyValues } from 'utils/functionsForForm';

// Services
import { getVlogById, updateVlogById } from 'services/charity.services';

// Helpers
import { queryClient } from 'helpers/queryClient';

export const VlogDetails = () => {
  const dispatch = useDispatch();

  const { id: vlogId } = useParams();

  const { isCountriesFetching, countries } = useCountries();
  const [cities, setCities] = useCities();

  const goBack = useGoBack();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const updateVlogMutation = useMutation({
    mutationFn: async (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, mapFormValuesToRequest(formValues));

      return updateVlogById(vlogId, changedValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vlog'] });
      dispatch(alertActions.success('Vlog was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const renderedFooter = useMemo(
    () => (
      <Fragment>
        <button
          className="btn btn-primary"
          style={{ marginRight: '1rem' }}
          onClick={handleSubmit(updateVlogMutation.mutate)}
          type="button"
          disabled={!isDirty}
        >
          Save
        </button>
        <button className="btn btn-secondary" onClick={goBack} type="button">
          Cancel
        </button>
      </Fragment>
    ),
    [goBack, handleSubmit, isDirty, updateVlogMutation.mutate],
  );

  const resetCity = useCallback(() => {
    setValue(VLOG_FIELDS_NAMES.city, null);
  }, [setValue]);

  const { isLoading, data } = useQuery({
    queryKey: ['vlog', vlogId],
    queryFn: () => getVlogById(vlogId),
    select: mapResponseToFormValues,
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      reset({ ...defaultValues, ...data });
    }
  }, [data, isLoading, reset]);

  useBlockerPrompt(isDirty);

  return (
    <PageDetails title="View and edit the vlog" isLoading={isLoading} renderFooter={renderedFooter}>
      <VlogForm
        isLoading={updateVlogMutation.isLoading}
        control={control}
        countries={countries}
        isCountriesLoading={isCountriesFetching}
        cities={cities}
        onCitiesChange={setCities}
        resetCity={resetCity}
      />
    </PageDetails>
  );
};
