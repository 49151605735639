export const LOCATION_MODEL = Object.freeze({
  name: 'name',
  publicTransport: 'publicTransport',
  carParking: 'carParking',
  address: 'address',
  workingDays: 'workingDays',
  geoPoint: 'geoPoint',
  photos: 'photos',
  isActive: 'isActive',
  id: '_id',
});
