import React, { Component } from 'react';

// Pages
import { ErrorLayout } from '../ErrorLayout/ErrorLayout';

export class ErrorBoundary extends Component {
  state = {
    isRenderErrorInfo: null,
  };

  componentDidCatch(_, isRenderErrorInfo) {
    this.setState({
      isRenderErrorInfo,
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.isRenderError) {
      this.setState({
        isRenderErrorInfo: null,
      });
    }
  }

  render() {
    const { isRenderErrorInfo } = this.state;
    const { children } = this.props;

    if (isRenderErrorInfo) {
      return <ErrorLayout />;
    }

    return children;
  }
}
