import { DEFAULT_CITY, DEFAULT_COUNTRY } from 'constants/countries.constants';
import { object, string, date, array, number } from 'yup';

import { GIVEAWAY_MODEL } from './models';

export const defaultValues = {
  [GIVEAWAY_MODEL.name]: '',
  [GIVEAWAY_MODEL.description]: '',
  [GIVEAWAY_MODEL.minPrice]: '',
  [GIVEAWAY_MODEL.country]: DEFAULT_COUNTRY,
  [GIVEAWAY_MODEL.city]: DEFAULT_CITY,
  [GIVEAWAY_MODEL.finishAt]: null,
  [GIVEAWAY_MODEL.images]: [],
};

const schemaOfDate = date()
  .default(() => new Date())
  .required()
  .nullable();

export const schema = object({
  [GIVEAWAY_MODEL.name]: string().min(2).required(),
  [GIVEAWAY_MODEL.description]: string().min(2).required(),
  [GIVEAWAY_MODEL.minPrice]: number().positive().required(),
  [GIVEAWAY_MODEL.country]: string().required('country must be a selected').nullable(),
  [GIVEAWAY_MODEL.city]: string().required('city must be a selected').nullable(),
  [GIVEAWAY_MODEL.finishAt]: schemaOfDate,
  [GIVEAWAY_MODEL.images]: array().label('Giveaway images').min(1).max(5).required(),
}).required();
