import Api from 'apis/default.api';

export default {
  getCountries,
};

const ROOT_ENDPOINT = 'countries';

export async function getCountries() {
  return Api.get(`/${ROOT_ENDPOINT}`);
}
