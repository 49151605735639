import { date, object, string, number, array, boolean } from 'yup';

import { PROMOTION_LOCALIZE_MODEL, PROMOTION_MODEL } from 'features/Promotions/utils/models';

export const FORM_FIELDS_LOCALIZATION_NAMES = Object.freeze({
  locale: 'locale',
  title: 'title',
  description: 'description',
});

export const FORM_FIELDS_NAMES = Object.freeze({
  status: PROMOTION_MODEL.status,
  title: PROMOTION_MODEL.title,
  description: PROMOTION_MODEL.description,
  clientPercent: PROMOTION_MODEL.clientPercent,
  partnerId: PROMOTION_MODEL.partnerId,
  locations: PROMOTION_MODEL.locations,
  startDate: PROMOTION_MODEL.startDate,
  endDate: PROMOTION_MODEL.endDate,
  publicationDate: PROMOTION_MODEL.publicationDate,
  applyForAll: PROMOTION_MODEL.applyForAll,
  mediaFiles: PROMOTION_MODEL.mediaFiles,
  localizations: 'localizations',
});

export const FIELDS_LABELS = Object.freeze({
  [FORM_FIELDS_NAMES.status]: 'Status',
  [FORM_FIELDS_NAMES.title]: 'Title',
  [FORM_FIELDS_NAMES.description]: 'Description',
  [FORM_FIELDS_NAMES.clientPercent]: 'Custom cashback percent',
  [FORM_FIELDS_NAMES.partnerId]: 'Partner',
  [FORM_FIELDS_NAMES.locations]: 'Locations',
  [FORM_FIELDS_NAMES.startDate]: 'Start date of the promotion (your current time zone)',
  [FORM_FIELDS_NAMES.endDate]: 'End date of the promotion (your current time zone)',
  [FORM_FIELDS_NAMES.publicationDate]: 'Date of publication (your current time zone)',
  [FORM_FIELDS_NAMES.applyForAll]: 'Applied for all services',
  [FORM_FIELDS_NAMES.mediaFiles]: 'Upload media files',
  [FORM_FIELDS_NAMES.localizations]: 'Add localization',
});

export const DEFAULT_VALUES = Object.freeze({
  [FORM_FIELDS_NAMES.title]: '',
  [FORM_FIELDS_NAMES.description]: '',
  [FORM_FIELDS_NAMES.clientPercent]: '',
  [FORM_FIELDS_NAMES.partnerId]: '',
  [FORM_FIELDS_NAMES.locations]: [],
  [FORM_FIELDS_NAMES.startDate]: null,
  [FORM_FIELDS_NAMES.endDate]: null,
  [FORM_FIELDS_NAMES.publicationDate]: null,
  [FORM_FIELDS_NAMES.applyForAll]: false,
  [FORM_FIELDS_NAMES.mediaFiles]: [],
  [FORM_FIELDS_NAMES.localizations]: [],
});

export const newLocalizeFormItem = Object.freeze({
  [FORM_FIELDS_LOCALIZATION_NAMES.locale]: null,
  [FORM_FIELDS_LOCALIZATION_NAMES.title]: '',
  [FORM_FIELDS_LOCALIZATION_NAMES.description]: '',
});

export const newLocationFormItem = Object.freeze({
  location: '',
  dishes: [],
});

export const VALIDATION_SCHEMA = object({
  [FORM_FIELDS_NAMES.title]: string()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.title])
    .min(2)
    .required(),

  [FORM_FIELDS_NAMES.description]: string()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.description])
    .min(5)
    .required(),

  [FORM_FIELDS_NAMES.clientPercent]: number()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.clientPercent])
    .typeError(({ label }) => `${label} is a required field`)
    .min(0)
    .max(100)
    .required(),

  [FORM_FIELDS_NAMES.partnerId]: string()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.partnerId])
    .required(),

  [FORM_FIELDS_NAMES.locations]: array()
    .of(
      object({
        location: string().label('Location').required(),
        dishes: array().label('Dishes'),
      }),
    )
    .label('Locations')
    .min(1)
    .required(),

  [FORM_FIELDS_NAMES.startDate]: date()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.startDate])
    .default(() => new Date())
    .required()
    .nullable(),

  [FORM_FIELDS_NAMES.endDate]: date()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.endDate])
    .default(() => new Date())
    .required()
    .nullable(),

  [FORM_FIELDS_NAMES.publicationDate]: date()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.publicationDate])
    .default(() => new Date())
    .required()
    .nullable(),

  [FORM_FIELDS_NAMES.applyForAll]: boolean()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.applyForAll])
    .required(),

  [FORM_FIELDS_NAMES.mediaFiles]: array()
    .label(FIELDS_LABELS[FORM_FIELDS_NAMES.mediaFiles])
    .min(1)
    .max(40)
    .required(),

  [FORM_FIELDS_NAMES.localizations]: array().of(
    object({
      [PROMOTION_LOCALIZE_MODEL.locale]: string()
        .label('Localization')
        .typeError(({ label }) => `${label} is a required field`)
        .required(),
      [FORM_FIELDS_NAMES.title]: string().label('Title').required(),
      [FORM_FIELDS_NAMES.description]: string().label('Description').required(),
    }),
  ),
}).required();
