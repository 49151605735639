import React, { useMemo, useCallback, useRef } from 'react';
import { useController } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';

// Utils
import dateUtils from 'utils/functionsForDate';

// Constants
import {
  DATE_UNITS_ENUM,
  DEFAULT_DATEPICKER_DATES_FORMAT,
  DEFAULT_DATEPICKER_TIME_FORMAT,
} from 'constants/dates';
import { DATEPICKER_PORTAL_ID } from 'constants/portal';

export const FormDatePicker = ({
  control,
  name,
  helperText,
  rules = null,
  minDate = dateUtils.getToday(),
  minTime = dateUtils.getToday(),
  maxTime = dateUtils.getEndOf(),
  showTimeInput = false,
  showTimeSelect = true,
  timeIntervals = 5,
  dateFormat = DEFAULT_DATEPICKER_DATES_FORMAT,
  timeFormat = DEFAULT_DATEPICKER_TIME_FORMAT,
  placeholder = 'Date',
  size = 'small',
  isDisabled = false,
  ...otherProps
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  const isTimeSelectedRef = useRef(false);

  const time = useMemo(() => {
    const activeDate = dateUtils.dayjsDate(value);
    const timeRange = {
      minTime,
      maxTime,
    };

    if (dateUtils.dayjsDate().isBefore(activeDate, DATE_UNITS_ENUM.date)) {
      timeRange.minTime = dateUtils.getStartOf();
    }

    return timeRange;
  }, [maxTime, minTime, value]);

  const handleChange = useCallback(
    (date, event) => {
      // Change time
      if (event && !isTimeSelectedRef.current) {
        const currentHours = dateUtils.dayjsDate().get(DATE_UNITS_ENUM.hour);
        const currentMinutes = dateUtils.dayjsDate().get(DATE_UNITS_ENUM.minute);
        const timeToRound = 10 - (currentMinutes % 5);

        const dateWithCurrentTime = dateUtils
          .dayjsDate(date)
          .set(DATE_UNITS_ENUM.hour, currentHours)
          .set(DATE_UNITS_ENUM.minute, currentMinutes + timeToRound)
          .toDate();

        return onChange(dateWithCurrentTime);
      }

      isTimeSelectedRef.current = true;
      return onChange(date);
    },
    [onChange],
  );

  return (
    <DatePicker
      onChange={handleChange}
      onBlur={onBlur}
      selected={dateUtils.isoToDate(value)}
      wrapperClassName="asBlock"
      popperPlacement="auto-start"
      timeFormat={timeFormat}
      // shouldCloseOnSelect={false}
      dateFormat={dateFormat}
      showTimeInput={showTimeInput}
      showTimeSelect={showTimeSelect}
      // withPortal={false}
      disabled={isDisabled}
      timeIntervals={timeIntervals}
      portalId={DATEPICKER_PORTAL_ID}
      minDate={minDate}
      minTime={time.minTime}
      maxTime={time.maxTime}
      customInput={
        <TextField
          label={placeholder}
          variant="outlined"
          fullWidth={true}
          size={size}
          error={invalid}
          helperText={invalid && error.message}
        />
      }
      {...otherProps}
    />
  );
};

export default FormDatePicker;
