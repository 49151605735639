import React from 'react';

// UIKit
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// Components
import { Modal } from 'components/base/Modal/Modal';

export const AdditionalInformation = ({ onClose, data }) => {
  return (
    <Modal
      show={true}
      handleClose={onClose}
      name="Additional information"
      renderFooter={
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
          Close modal
        </button>
      }
    >
      {data && (
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>date:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.date} variant="outlined" size="small" />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>isPaid:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.isPaid} variant="outlined" size="small" />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>type:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.type} variant="outlined" size="small" />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>money:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.money} variant="outlined" size="small" />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>bonuses:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.bonuses} variant="outlined" size="small" />
            </Grid>

            <Grid item xs={3}>
              <label style={{ margin: 0 }}>price:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField disabled fullWidth value={data.price} variant="outlined" size="small" />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>adminBonuses:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                value={data.adminIncomeBonuses}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>clientBonuses:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                value={data.clientBonuses}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>senderClientBonuses:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                value={data.senderClientBonuses}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>partnerBonuses:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                value={data.partnerBonuses}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>Client balance:</label>
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                fullWidth
                value={data.isCanceled ? data.clientBonusesAfterCancel : data.clientBonusesAfter}
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>transaction cancellation message:</label>
            </Grid>
            <Grid item xs={9}>
              <TextField
                disabled
                multiline
                fullWidth
                minRows={4}
                value={data.canceledDescription.trim()}
                variant="outlined"
                size="small"
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>transactions:</label>
            </Grid>
            <Grid item xs={9}>
              {data.transactions.length &&
                data.transactions.map((transaction) => (
                  <div key={transaction._id} style={{ marginBottom: '1rem' }}>
                    <div>
                      <span>id:</span>
                      <span>{transaction._id}</span>
                    </div>
                    <div>
                      <span>type:</span>
                      <span>{transaction.type}</span>
                    </div>
                  </div>
                ))}
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>user id:</label>
            </Grid>
            <Grid item xs={9}>
              {data.clientId}
            </Grid>
            <Grid item xs={3}>
              <label style={{ margin: 0 }}>product name:</label>
            </Grid>
            <Grid item xs={9}>
              {data.product && data.product.title}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};
