import React, { Fragment, useCallback, useMemo } from 'react';

// Components
import Modal from 'components/base/Modal/Modal';
import FormReject from '../Forms/FormReject/FormReject';

import { PROMOTION_STATUS_ENUM } from 'features/Promotions/utils/enums';

export const ModalConfirm = ({ label = 'approve', status, id, isLoading, onAccept, onDismiss }) => {
  const isShowForm = status === PROMOTION_STATUS_ENUM.rejected;

  const handleAccept = useCallback(
    (props) => {
      let payload = { status };

      if (isShowForm) {
        payload = { ...payload, ...props };
      }

      onAccept(id, payload);
    },
    [id, isShowForm, onAccept, status],
  );

  const renderFooter = useMemo(() => {
    return (
      <Fragment>
        <button className="btn btn-primary" onClick={handleAccept} disabled={isLoading}>
          Yes
        </button>
        <button className="btn btn-secondary" onClick={onDismiss}>
          No
        </button>
      </Fragment>
    );
  }, [handleAccept, isLoading, onDismiss]);

  return (
    <Modal
      handleClose={onDismiss}
      name="Change the promotion status"
      show={true}
      isLoading={isLoading}
      renderFooter={!isShowForm && renderFooter}
    >
      {isShowForm && <FormReject onAccept={handleAccept} onDismiss={onDismiss} />}

      {!isShowForm && `Do you want to ${label} the promotion?`}
    </Modal>
  );
};

export default ModalConfirm;
