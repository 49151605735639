import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

// Components
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';
import { UsersTableSubhead } from './UsersTableSubhead';

// Hooks
import { useTableParams } from 'common/hooks/useTableParams';

// Services
import { getUsers } from 'services';

// Actions
import { alertActions } from 'features/Alert';

// Constants
import { tableColumnsSettings, userTypesFilters } from 'features/Users/utils/constants';

export const UsersTable = () => {
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useTableParams({
    params: ['search', 'filter', 'lastUsedFrom', 'lastUsedTo'],
  });

  const params = useMemo(() => {
    const { filter, ...rest } = queryParams;
    const userFilter = userTypesFilters[filter];

    return { ...userFilter, ...rest };
  }, [queryParams]);

  const { isLoading, isError, data } = useQuery({
    queryKey: ['users', params],
    queryFn: () => getUsers(params),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <TableWithQueryFilters
      title="List of Users"
      progressPending={isLoading}
      columns={tableColumnsSettings}
      data={data?.items}
      paginationTotalRows={data?.count}
      renderSubHeader={
        <UsersTableSubhead
          queryParams={queryParams}
          isDisabled={isError}
          onChange={setQueryParams}
        />
      }
    />
  );
};
