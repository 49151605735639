export const PROMOTION_PUBLISHED_MODEL = Object.freeze({
  title: 'title',
  description: 'description',
  partnerId: 'partnerId',
  startDate: 'startDate',
  endDate: 'endDate',
  publicationDate: 'publicationDate',
  locations: 'locations',
  locationsV2: 'locationsV2',
  mediaFiles: 'mediaFiles',
  clientPercent: 'clientPercent',
  applyForAll: 'applyForAll',
  status: 'status',
  localizeTitle: 'localizeTitle',
  localizedDescriptions: 'localizedDescriptions',
  id: '_id',
});

export const PROMOTION_MODEL = Object.freeze({
  ...PROMOTION_PUBLISHED_MODEL,
  adminMessage: 'adminMessage',
  order: 'order',
  active: 'active',
  published: 'published',
});

export const PROMOTION_LOCALIZE_MODEL = Object.freeze({
  locale: 'locale',
  value: 'value',
});
