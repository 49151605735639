import React, { useState } from 'react';

// Components
import FormSelect from 'components/base/FormSelect/FormSelect';

// Models
import { LOCATION_MODEL } from 'models/dictionaries';

// Utils
import { getDisabledOption } from 'utils/functionsForAutocomplete';

// Hooks
import usePartnerLocations from 'hooks/usePartnerLocations';

export const LocationsSelect = ({
  partnerId,
  placeholder = 'Locations',
  name,
  control,
  isMultiple = true,
  disabledOptions = [],
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const locationsState = usePartnerLocations(partnerId);

  return (
    <FormSelect
      placeholder={placeholder}
      inputName={LOCATION_MODEL.name}
      inputValue={LOCATION_MODEL.id}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      control={control}
      name={name}
      options={locationsState.options}
      isLoading={locationsState.isLoading}
      isError={Boolean(locationsState.error)}
      helperText={locationsState.error?.message}
      isMultiple={isMultiple}
      getOptionDisabled={getDisabledOption(disabledOptions, '_id')}
      {...otherProps}
    />
  );
};

export default LocationsSelect;
