import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirmDelete = ({ onDismiss, onAccept }) => (
  <Modal
    show={true}
    handleClose={onDismiss}
    name={'Confirm delete user'}
    renderFooter={
      <Fragment>
        <button type="button" className="btn btn-primary" onClick={onAccept}>
          Yes
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onDismiss}
        >
          No
        </button>
      </Fragment>
    }
  >
    <h5 className="text-center">
      Do you really want to delete this user ? Please be aware that all user's transactions will be
      deleted along with the user
    </h5>
  </Modal>
);
