import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';

import Select from 'components/base/Select/Select';

const FormSelect = ({
  control,
  name,
  isError,
  isSingleSelect,
  isDisabled,
  helperText,
  rules,
  options,
  isMultiple,
  ...otherProps
}) => {
  const {
    field: { value, onBlur, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  // Automatic disabled if it has only one option item
  // See https://lampalampateam.atlassian.net/browse/AR-2001 for details
  const isHandleDisabled = useMemo(() => {
    if (isDisabled) return isDisabled;

    return isSingleSelect && isMultiple && options.length === 1;
  }, [isDisabled, isMultiple, isSingleSelect, options.length]);

  return (
    <Select
      isMultiple={isMultiple}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isError={invalid || isError}
      helperText={error?.message || helperText}
      isDisabled={isHandleDisabled}
      isSingleSelect={isSingleSelect}
      options={options}
      {...otherProps}
    />
  );
};

export default FormSelect;
