import axios from 'axios';
import { serverConfig } from '../config';
import Api from 'apis/default.api';
import { serialize } from 'object-to-formdata';

export const categoriesService = {
  findAll,
  update,
  remove,
  createCategory,
};

const FIND_ALL_ENDPOINT = 'api/v1/categories';
const UPDATE_ENDPOINT = 'api/v1/categories';
const REMOVE_ENDPOINT = 'api/v1/categories';

function findAll(page, limit, category) {
  const offset = (page - 1) * limit;
  const search = category ? `&category=${category}` : '';
  const token = localStorage.getItem('token');
  return axios.get(
    `${serverConfig._domain}/${FIND_ALL_ENDPOINT}?offset=${offset}&limit=${limit}${search}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
}

function update({ id, data }) {
  const token = localStorage.getItem('token');
  const bodyFormData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      bodyFormData.append(key, data[key]);
    }
  }

  const config = {
    headers: {
      Authorization: token,
      'content-type': 'multipart/form-data',
    },
  };

  return axios.put(`${serverConfig._domain}/${UPDATE_ENDPOINT}/${id}`, bodyFormData, config);
}

function remove(id) {
  const token = localStorage.getItem('token');

  return axios.delete(`${serverConfig._domain}/${REMOVE_ENDPOINT}/${id}`, {
    headers: {
      Authorization: token,
    },
  });
}

const ROOT_ENDPOINT = 'categories';

export async function createCategory(data) {
  const formData = serialize(data, {
    indices: true,
  });
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return Api.post(`/${ROOT_ENDPOINT}`, formData, config);
}

export async function getCategories() {
  const params = { limit: 1000 };
  return Api.get(`/${ROOT_ENDPOINT}`, { params });
}

export default {
  createCategory,
  getCategories,
};
