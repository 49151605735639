import actionsTypes from './types';
import service from 'services/public.service';
import { languagesSelector } from './selectors';

export default {
  aGetDictionaryOfLanguages,
  aGetDictionaryOfLanguagesIfNotLoaded,
};

export function aGetDictionaryOfLanguagesIfNotLoaded() {
  return (dispatch, getStore) => {
    const store = getStore();
    const { isLanguagesLoaded, isLanguagesLoading } = languagesSelector(store);

    if (!isLanguagesLoaded && !isLanguagesLoading) {
      return dispatch(aGetDictionaryOfLanguages());
    }
  };
}

export function aGetDictionaryOfLanguages() {
  return (dispatch) => {
    dispatch(request());

    return service.getDictionaryOfLanguages().then(
      ({ dictionaryOfLanguages }) => {
        dispatch(success(dictionaryOfLanguages));
        return Promise.resolve();
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_REQUEST };
  }

  function success(data) {
    return { type: actionsTypes.GET_SUCCESS, data };
  }

  function failure() {
    return { type: actionsTypes.GET_FAILURE };
  }
}
