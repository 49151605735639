import React, { Fragment, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';

// UIKit
import { Button, IconButton } from '@material-ui/core';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { Table } from 'components/base/Table/Table';
import { ModalConfirmDeleteProduct, ModalConfirmExit, ModalNewProduct } from './components/modals';

// Actions
import { alertActions } from 'features/Alert';

// Models
import { PARTNER_MODEL, PRODUCT_MODEL } from './utils/models';

// Services
import { getPartnerById, updatePartnerById } from 'services/partner.service';

// Hooks
import { useModal } from 'hooks/useModal';

// Helpers
import { queryClient } from 'helpers/queryClient';

// Utils
import { mapProductsToValues } from './utils/transforms/partnerResponse';

const defaultValues = {
  [PRODUCT_MODEL.title]: '',
  [PRODUCT_MODEL.adminPercent]: '',
  [PRODUCT_MODEL.clientPercent]: '',
  [PRODUCT_MODEL.localization]: [],
};

export const PartnerProducts = () => {
  const dispatch = useDispatch();
  const { id: partnerId } = useParams();

  // Get partner
  const getPartnerQuery = useQuery({
    queryKey: ['partner', partnerId],
    queryFn: () => getPartnerById(partnerId),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const products = useMemo(() => {
    if (getPartnerQuery.isLoading || !getPartnerQuery.data) return [];

    return mapProductsToValues(getPartnerQuery.data);
  }, [getPartnerQuery.data, getPartnerQuery.isLoading]);

  const adminPercent = useMemo(() => {
    if (getPartnerQuery.isLoading) return '';

    return getPartnerQuery.data[PRODUCT_MODEL.adminPercent];
  }, [getPartnerQuery.data, getPartnerQuery.isLoading]);

  // Modal create product
  const [modalCreateIsOpen, showModalCreate, hideModalCreate] = useModal();

  // Modal update product
  const [modalUpdateIsOpen, showModalUpdate, hideModalUpdate, modalUpdatePayload] = useModal();

  // Modal confirm delete product
  const [
    modalConfirmDeleteIsOpen,
    showModalConfirmDelete,
    hideModalConfirmDelete,
    modalConfirmDeletePayload,
  ] = useModal();

  // Modal confirm exit product
  const [modalConfirmExitIsOpen, showModalConfirmExit, hideModalConfirmExit] = useModal();

  const hideOpenedModals = useCallback(() => {
    hideModalCreate();
    hideModalUpdate();
    hideModalConfirmExit();
    hideModalConfirmDelete();
  }, [hideModalConfirmDelete, hideModalConfirmExit, hideModalCreate, hideModalUpdate]);

  // Update partner
  const updatePartnerMutation = useMutation({
    mutationFn: (formValues) => {
      const partnerId = getPartnerQuery.data[PARTNER_MODEL.userId];
      return updatePartnerById(partnerId, formValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partner'] });
      hideOpenedModals();
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const createProduct = useCallback(
    async (newProduct) => {
      const newPartner = { products: [...products, newProduct] };

      await updatePartnerMutation.mutateAsync(newPartner);
      alertActions.success('Product was created');
    },
    [products, updatePartnerMutation],
  );

  const updateProduct = useCallback(
    async (newProduct) => {
      const productsCopy = JSON.parse(JSON.stringify(products));
      productsCopy.splice(modalUpdatePayload, 1, newProduct);

      await updatePartnerMutation.mutateAsync({ products: productsCopy });
      dispatch(alertActions.success('Product was updated'));
    },
    [dispatch, modalUpdatePayload, products, updatePartnerMutation],
  );

  const deleteProduct = useCallback(async () => {
    const newPartner = produce(products, (draft) => {
      draft.splice(modalConfirmDeletePayload, 1);
    });

    await updatePartnerMutation.mutateAsync({ products: newPartner });
    dispatch(alertActions.success('Product was deleted'));
  }, [dispatch, modalConfirmDeletePayload, products, updatePartnerMutation]);

  const tableColumnsSettings = useMemo(
    () => [
      { selector: PRODUCT_MODEL.title, name: 'Name of product', sortable: true },
      { selector: PRODUCT_MODEL.adminPercent, name: 'Admin percent', sortable: true },
      { selector: PRODUCT_MODEL.clientPercent, name: 'Client percent', sortable: true },
      {
        name: 'Actions',
        button: true,
        cell: (_, index) => (
          <Fragment>
            <IconButton onClick={showModalUpdate.bind(null, index)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={showModalConfirmDelete.bind(null, index)}>
              <DeleteIcon />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    [showModalConfirmDelete, showModalUpdate],
  );

  const renderHeader = useMemo(
    () => (
      <Button color="primary" variant="contained" onClick={showModalCreate}>
        + New product
      </Button>
    ),
    [showModalCreate],
  );

  return (
    <PageDetails title="View and edit the products" isLoading={false}>
      <Table
        columns={tableColumnsSettings}
        data={products}
        renderHeader={renderHeader}
        progressPending={getPartnerQuery.isLoading}
        pagination={true}
        highlightOnHover={true}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />

      {modalCreateIsOpen && (
        <ModalNewProduct
          isLoading={updatePartnerMutation.isLoading}
          onAccept={createProduct}
          onDismiss={showModalConfirmExit}
          defaults={{ ...defaultValues, [PRODUCT_MODEL.adminPercent]: adminPercent }}
          isNewProduct={true}
        />
      )}

      {modalUpdateIsOpen && (
        <ModalNewProduct
          isLoading={updatePartnerMutation.isLoading}
          onAccept={updateProduct}
          onDismiss={showModalConfirmExit}
          defaults={products[modalUpdatePayload]}
        />
      )}

      {modalConfirmDeleteIsOpen && (
        <ModalConfirmDeleteProduct
          isLoading={updatePartnerMutation.isLoading}
          onAccept={deleteProduct}
          onDismiss={hideOpenedModals}
        />
      )}

      {modalConfirmExitIsOpen && (
        <ModalConfirmExit onAccept={hideOpenedModals} onDismiss={hideModalConfirmExit} />
      )}
    </PageDetails>
  );
};
