import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useNavigateTo(path) {
  const navigate = useNavigate();

  const navigateTo = useCallback(() => navigate(path), [navigate, path]);

  return navigateTo;
}

export default useNavigateTo;
