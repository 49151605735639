export const categoriesConstants = {
  FIND_ALL_SUCCESS: 'FIND_ALL_CATEGORIES_SUCCESS',
  FIND_ALL_FAILURE: 'FIND_ALL_CATEGORIES_FAILURE',
  FIND_ALL_REQUEST: 'FIND_ALL_CATEGORIES_REQUEST',

  CREATE_SUCCESS: 'CREATE_CATEGORIES_SUCCESS',
  CREATE_FAILURE: 'CREATE_CATEGORIES_FAILURE',
  CREATE_REQUEST: 'CREATE_CATEGORIES_REQUEST',

  UPDATE_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_CATEGORIES_FAILURE',
  UPDATE_REQUEST: 'UPDATE_CATEGORIES_REQUEST',

  REMOVE_SUCCESS: 'REMOVE_CATEGORIES_SUCCESS',
  REMOVE_FAILURE: 'REMOVE_CATEGORIES_FAILURE',
  REMOVE_REQUEST: 'REMOVE_CATEGORIES_REQUEST',
};
