import { categoriesConstants } from '../constants';
import { alertActions } from 'features/Alert';
import { categoriesService } from '../services';

export const categoriesActions = {
  findCategories,
  create,
  update,
  remove,
};

export function findCategories(page, limit) {
  return (dispatch) => {
    dispatch(request());

    return categoriesService.findAll(page, limit).then(
      ({ data: { data = [], meta= {}} }) => {
        dispatch(success({ data, meta }));

        return Promise.resolve({ data, meta });
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response?.data?.message));

        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: categoriesConstants.FIND_ALL_REQUEST };
  }

  function success(categories) {
    return { type: categoriesConstants.FIND_ALL_SUCCESS, categories };
  }

  function failure(error) {
    return { type: categoriesConstants.FIND_ALL_FAILURE, error };
  }
}

function create({ category, page }) {
  return (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(request());

    categoriesService.createCategory(category).then(
      (category) => {
        dispatch(alertActions.success('Category was created'));
        dispatch(success(category));
        dispatch(findCategories(page, 10));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error?.message));
      },
    );
  };

  function request() {
    return { type: categoriesConstants.CREATE_REQUEST };
  }

  function success(response) {
    return { type: categoriesConstants.CREATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: categoriesConstants.CREATE_FAILURE, error };
  }
}

function update({ id, data, page }) {
  return (dispatch) => {
    dispatch(request());

    if (!data.logo) {
      delete data.logo;
    }

    categoriesService.update({ id, data }).then(
      (category) => {
        dispatch(alertActions.success('Category was updated'));
        dispatch(success(category));
        dispatch(findCategories(page, 10));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.response.data.error));
      },
    );
  };

  function request() {
    return { type: categoriesConstants.UPDATE_REQUEST };
  }

  function success(response) {
    return { type: categoriesConstants.UPDATE_SUCCESS, response };
  }

  function failure(error) {
    return { type: categoriesConstants.UPDATE_FAILURE, error };
  }
}

function remove({ id, page }) {
  return (dispatch) => {
    dispatch(request());

    categoriesService.remove(id).then(
      (category) => {
        dispatch(alertActions.success('Category was removed'));
        dispatch(success(category));
        dispatch(findCategories(page, 10));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error?.response?.data?.error?.message));
      },
    );
  };

  function request() {
    return { type: categoriesConstants.REMOVE_REQUEST };
  }

  function success(response) {
    return { type: categoriesConstants.REMOVE_SUCCESS, response };
  }

  function failure(error) {
    return { type: categoriesConstants.REMOVE_FAILURE, error };
  }
}
