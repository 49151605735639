import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// UIKit
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/ExitToAppTwoTone';

import {
  AppBar,
  Drawer,
  List,
  Toolbar,
  Container,
  Box,
  IconButton,
  Hidden,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import { authActions } from 'actions/auth.actions';

import { ROUTES } from 'constants/routes.constants';

import menuItems from './NavBarData';
import UseStyles from './Styling';
import NavBarItem from './NavBarItem';

export const NavBar = ({ notices }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = UseStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const logout = useCallback(() => {
    dispatch(authActions.logout()).then(() => {
      navigate(ROUTES.root);
    });
  }, [dispatch, navigate]);

  const DrawerData = useMemo(
    () => (
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box component="div" className={classes.navBarItems}>
          <List component="div">
            {menuItems.map((item) => (
              <NavBarItem key={item.title} notices={notices} {...item} />
            ))}
          </List>
        </Box>

        <Box component="div" className={classes.navBarPushedItem}>
          <Container className={classes.listItemContainer}>
            <IconButton onClick={logout} classes={{ root: classes.listItemIcon }}>
              <LogoutIcon fontSize="large" />
            </IconButton>
          </Container>
        </Box>
      </Drawer>
    ),
    [
      classes.drawer,
      classes.drawerPaper,
      classes.listItemContainer,
      classes.listItemIcon,
      classes.navBarItems,
      classes.navBarPushedItem,
      logout,
      notices,
    ],
  );

  return (
    <>
      <Hidden smUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.appBarIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {DrawerData}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {DrawerData}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};
