import Api from 'apis/default.api';

export default {
  getPartners,
  createPartner,
  getPartnerById,
  updatePartnerById,
  removePartnerById,
  getAllAdminPartners,
};

const ROOT_ENDPOINT = 'partner';

export function getPartners(params) {
  return Api.get(`${ROOT_ENDPOINT}/all-admin`, { params });
}

export function createPartner(data) {
  return Api.post(`${ROOT_ENDPOINT}`, data);
}

export function getPartnerById(partnerId) {
  return Api.get(`${ROOT_ENDPOINT}/${partnerId}`);
}

export function removePartnerById(partnerId) {
  return Api.delete(`${ROOT_ENDPOINT}/${partnerId}`);
}

export function updatePartnerById(partnerId, data) {
  return Api.put(`${ROOT_ENDPOINT}/${partnerId}`, data);
}

export function getAllAdminPartners() {
  const params = {
    limit: 1000,
  };

  return getPartners(params);
}
