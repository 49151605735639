import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { ModalConfirmDelete } from './components/modals/ModalConfirmDelete';
import { ModalUpdatePassword } from './components/modals/ModalUpdatePassword';
import { DetailsForm } from './components/DetailsForm/DetailsForm';

// Models
import { PARTNER_MODEL } from './utils/models';

// Constants
import { ROUTES } from 'constants/routes.constants';

// Utilities
import { getLastPath } from 'utils/functionsForRouter';
import { getPartnerResponseTransform } from './utils/transforms/partnerResponse';

// Hooks
import { useModal } from 'hooks/useModal';

// Services
import { getPartnerById, removePartnerById, updatePartnerById } from 'services/partner.service';

// Store
import { alertActions } from 'features/Alert';

// Helpers
import { queryClient } from 'helpers/queryClient';

export const PartnerDetails = () => {
  const { id: partnerId } = useParams();
  const navigate = useNavigate();

  // Confirm delete
  const [modalConfirmDeleteIsOpen, onShowModalConfirmDelete, onHideModalConfirmDelete] = useModal();

  // Confirm password update
  const [modalPasswordUpdateIsOpen, onShowModalPasswordUpdate, onHideModalPasswordUpdate] =
    useModal();

  const dispatch = useDispatch();

  const goToProductPage = useCallback(() => {
    navigate(getLastPath(ROUTES.partnerProducts));
  }, [navigate]);

  const goToTransactionPage = useCallback(() => {
    navigate(getLastPath(ROUTES.partnerTransactions));
  }, [navigate]);

  // Get partner
  const getPartnerQuery = useQuery({
    queryKey: ['partner', partnerId],
    queryFn: () => getPartnerById(partnerId),
    select: getPartnerResponseTransform,
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  // Update partner
  const updatePartnerMutation = useMutation({
    mutationFn: (formValues) => {
      return updatePartnerById(getPartnerQuery.data[PARTNER_MODEL.userId], formValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partner'] });
      onHideModalPasswordUpdate();
      dispatch(alertActions.success('Partner was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  // Remove partner
  const removePartnerMutation = useMutation({
    mutationFn: () => {
      return removePartnerById(partnerId);
    },
    onSuccess: () => {
      navigate(ROUTES.partners);
      dispatch(alertActions.success('Partner was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <div className="partner-details">
      <PageDetails
        title="View and edit the partner"
        renderHeader={
          <Box className="btn btn-outline-dark" onClick={goToProductPage}>
            <AddCircleIcon /> Add a new product
          </Box>
        }
        isLoading={getPartnerQuery.isLoading}
        isUpdating={updatePartnerMutation.isLoading}
      >
        <DetailsForm
          initialValues={getPartnerQuery.data}
          onPartnerUpdate={updatePartnerMutation.mutate}
          goToTransactionPage={goToTransactionPage}
          onShowModalConfirmDelete={onShowModalConfirmDelete}
          onShowModalPasswordUpdate={onShowModalPasswordUpdate}
        />
      </PageDetails>

      {modalConfirmDeleteIsOpen && (
        <ModalConfirmDelete
          onAccept={removePartnerMutation.mutate}
          onDismiss={onHideModalConfirmDelete}
        />
      )}

      {modalPasswordUpdateIsOpen && (
        <ModalUpdatePassword
          onAccept={updatePartnerMutation.mutate}
          onDismiss={onHideModalPasswordUpdate}
        />
      )}
    </div>
  );
};
