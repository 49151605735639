import React, { Fragment } from 'react';

// Components
import { Modal } from 'components/base/Modal/Modal';

export const MakeTransactionSettlement = ({ onClose, onSubmit }) => (
  <Modal
    show={true}
    handleClose={onClose}
    name={'Confirm change Settlement status manually'}
    renderFooter={
      <Fragment>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Yes
        </button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
          No
        </button>
      </Fragment>
    }
  >
    Do you really want to change Settlement status manually?
  </Modal>
);
