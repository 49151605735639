import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Styles
import styles from './FormReject.module.scss';

// Components
import Loader from 'components/base/Loader/Loader';
import FormControl from 'components/base/FormControl/FormControl';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

// Utils
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from './FormReject.constants';

const FormReject = ({ isLoading = false, onAccept, onDismiss }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  useBlockerPrompt(isDirty);

  return (
    <form noValidate className={styles.form}>
      {isLoading && <Loader withBackdrop />}
      <FormControl
        name="adminMessage"
        control={control}
        placeholder="Reason message"
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
      />
      <div className={styles.footer}>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={handleSubmit(onAccept)}
          type="button"
        >
          Yes, reject
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          type="button"
          data-dismiss="modal"
        >
          No
        </button>
      </div>
    </form>
  );
};

export default FormReject;
