import actionsTypes from './types';
import service from 'services/statistic.service';

import { alertActions } from 'features/Alert';

export default {
  aGetStatistic,
};

export function aGetStatistic(queryParams) {
  return (dispatch) => {
    dispatch(request());

    service.getStatistic(queryParams).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_STATISTIC_REQUEST };
  }

  function success(statistics) {
    return { type: actionsTypes.GET_STATISTIC_SUCCESS, statistics };
  }

  function failure(error) {
    return { type: actionsTypes.GET_STATISTIC_FAILURE, error };
  }
}
