import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Components
import Loader from 'components/base/Loader/Loader';
import PollsManagement from 'components/base/PollsManagement/PollsManagement';
import FormDatePicker from 'components/base/FormDatePicker/FormDatePicker';

// Utilities
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';

// Models
import { POLL_MODEL } from '../utils/models';

const inputSize = 'small';

export const FormCreatingPoll = ({
  isLoading = false,
  isCountriesFetching,
  countries,
  cities,
  onCitiesChange,
  control,
  resetCity,
  disabledFields = [],
}) => {
  const onCountryChange = useCallback(
    (onChangeCallback) =>
      (_, { cities, country }) => {
        onCitiesChange(cities);
        onChangeCallback(country);
        resetCity();
      },
    [onCitiesChange, resetCity],
  );

  const onCityChange = useCallback(
    (onChangeCallback) => (_, city) => {
      onChangeCallback(city);
    },
    [],
  );

  return (
    <form noValidate style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {isLoading && <Loader withBackdrop />}
      <Controller
        name={POLL_MODEL.question}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Name"
            variant="outlined"
            size={inputSize}
            fullWidth
            style={{ marginBottom: '0.5rem' }}
            error={fieldState.invalid}
            helperText={fieldState.error && fieldState.error.message}
            disabled={disabledFields[POLL_MODEL.question]}
          />
        )}
      />
      <Controller
        name={POLL_MODEL.description}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            size={inputSize}
            minRows={4}
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            error={fieldState.invalid}
            helperText={fieldState.error && fieldState.error.message}
            disabled={disabledFields[POLL_MODEL.description]}
          />
        )}
      />
      <Controller
        name={POLL_MODEL.country}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            id={POLL_MODEL.country}
            getOptionLabel={getOptionLabel('country')}
            getOptionSelected={getOptionSelected('country')}
            options={countries}
            onChange={onCountryChange(field.onChange)}
            value={field.value}
            loading={isCountriesFetching}
            disableClearable={true}
            disabled={disabledFields[POLL_MODEL.country]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                fullWidth
                size={inputSize}
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
              />
            )}
          />
        )}
      />
      <Controller
        name={POLL_MODEL.city}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            id={POLL_MODEL.city}
            getOptionLabel={getOptionLabel('country')}
            getOptionSelected={getOptionSelected}
            options={cities}
            onChange={onCityChange(field.onChange)}
            value={field.value}
            disableClearable={true}
            disabled={disabledFields[POLL_MODEL.city]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                size={inputSize}
                variant="outlined"
                fullWidth
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                error={fieldState.invalid}
                helperText={fieldState.error && fieldState.error.message}
              />
            )}
          />
        )}
      />

      <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
        <FormDatePicker name={POLL_MODEL.finishAt} control={control} placeholder="Poll end date" />
      </div>

      <PollsManagement
        name={POLL_MODEL.answerOption}
        control={control}
        style={{ marginTop: '0.5rem' }}
        disabledFields={disabledFields[POLL_MODEL.answerOption]}
      />
    </form>
  );
};
