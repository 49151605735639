import React, { Fragment } from 'react';

// Components
import { Modal } from 'components/base/Modal/Modal';

export const ModalDelete = ({ onAccept, onDismiss, isLoading }) => {
  return (
    <Modal
      handleClose={onDismiss}
      name={'Delete item of FAQ'}
      show={true}
      isLoading={isLoading}
      renderFooter={
        <Fragment>
          <button onClick={onAccept} disabled={isLoading} className="btn btn-primary" type="button">
            Yes
          </button>
          <button className="btn btn-secondary" onClick={onDismiss} type="button">
            No
          </button>
        </Fragment>
      }
    >
      <h5 className="text-center">
        Do you really want to delete this FAQ? <br />
        Please be aware that all FAQ's data will be deleted along with the FAQ
      </h5>
    </Modal>
  );
};
