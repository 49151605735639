import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';
import { Loader } from 'components/base/Loader/Loader';

export const ModalDelete = ({ name, isLoading, onAccept, onDismiss }) => (
  <Modal
    handleClose={onDismiss}
    name={'Delete item of Charity Event'}
    show={true}
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" disabled={isLoading} onClick={onAccept} type="button">
          Yes, delete
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          type="button"
          data-dismiss="modal"
        >
          No, cancel
        </button>
      </Fragment>
    }
  >
    {isLoading && <Loader withBackdrop />}
    <h5 className="text-center">{`Do you want to delete event '${name}'?`}</h5>
  </Modal>
);
