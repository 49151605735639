import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import PromotionsList from './components/PromotionsList/PromotionsList';
import PromotionStatusSelect from 'components/base/Selects/PromotionStatusSelect';

// Constants
import { PENDING_CONSTANTS } from './components/PromotionsList/PromotionsList.constants';
import { moderationColumnSettings } from './utils/constants';

// Models
import { PROMOTION_DICTIONARY_STATUS_TYPES_ENUM } from 'features/Dictionaries/Promotions/utils/enums';

// Store
import { aGetPromotions } from './store/actions';
import { promotionsListAllCountSelector, promotionsListItemsSelector } from './store/selectors';

export const Moderation = () => {
  const dispatch = useDispatch();

  const count = useSelector(promotionsListAllCountSelector);
  const items = useSelector(promotionsListItemsSelector);

  const [moderatedQueries, setModeratedQueries] = useState({
    status: null,
  });

  const onChangeModeratedQueries = useCallback((status) => {
    setModeratedQueries((prevProps) => ({ ...prevProps, status }));
  }, []);

  const getPendingPromotions = useCallback(() => {
    dispatch(aGetPromotions(moderatedQueries));
  }, [dispatch, moderatedQueries]);

  return (
    <PromotionsList
      title="List of moderated Promotions"
      items={items}
      count={count}
      tableColumnsSettings={moderationColumnSettings}
      dropdownMenuProps={PENDING_CONSTANTS.dropdownMenuProps}
      getPromotions={getPendingPromotions}
      renderSubHeader={
        <PromotionStatusSelect
          value={moderatedQueries.status}
          onChange={onChangeModeratedQueries}
          dictionaryTypeKey={PROMOTION_DICTIONARY_STATUS_TYPES_ENUM.moderation}
        />
      }
    />
  );
};
