import { FilesApi } from 'apis/files.api';

const ROOT_ENDPOINT = 'files';

function getFileByPathAndName(path, name) {
  return FilesApi.get(`/${ROOT_ENDPOINT}/${path}/${name}`);
}

function addNewFile(newFile, fileType) {
  return FilesApi.post(`/${ROOT_ENDPOINT}`, newFile, {
    headers: {
      'Content-Type': fileType || newFile.type,
    },
  });
}

function deleteFile(path) {
  return FilesApi.request(path, { method: 'DELETE' });
}

export default {
  addNewFile,
  deleteFile,
  getFileByPathAndName,
};
