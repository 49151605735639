import { useState, useCallback } from 'react';

export const useModal = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [modalPayload, setPayload] = useState();

  const showModal = useCallback((payload) => {
    setIsOpen(true);
    setPayload(payload);
  }, []);

  const hideModal = useCallback((payload) => {
    setIsOpen(false);
    setPayload(payload);
  }, []);

  return [isOpen, showModal, hideModal, modalPayload];
};

export default useModal;
