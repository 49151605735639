import { useEffect, useState } from 'react';
import { LOCALIZED_MODEL } from 'features/Partners/utils/models';

export default function useDisabledLanguages(
  watchFunc,
  rootFieldName,
  langFieldName = LOCALIZED_MODEL.locale,
) {
  const [disabledLanguages, setDisabledLanguages] = useState([]);

  useEffect(() => {
    const handleChange = (formValues, { name }) => {
      if (!name) return;

      if (name.includes(rootFieldName)) {
        const val = formValues[rootFieldName] ?? [];
        const selectedLanguages = val.map((localization) => localization[langFieldName]);

        setDisabledLanguages(selectedLanguages);
      }
    };

    const subscription = watchFunc(handleChange);
    return subscription.unsubscribe;
  }, [langFieldName, rootFieldName, watchFunc]);

  return disabledLanguages;
}
