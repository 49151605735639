import React from 'react';

// UIKit
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ORDER_TYPES_ENUM } from './OrderSwitcher.constants';

export const OrderSwitcher = ({
  position,
  isDisabledUp = false,
  isDisabledDown = false,
  onClickUp,
  onClickDown,
}) => {
  const handleClickUp = () => {
    onClickUp(position, ORDER_TYPES_ENUM.up);
  };
  const handleClickDown = () => {
    onClickDown(position, ORDER_TYPES_ENUM.down);
  };

  return (
    <ButtonGroup variant="text">
      <Button onClick={handleClickUp} disabled={isDisabledUp}>
        <ExpandLessIcon />
      </Button>
      <Button onClick={handleClickDown} disabled={isDisabledDown}>
        <ExpandMoreIcon />
      </Button>
    </ButtonGroup>
  );
};

export default OrderSwitcher;
