import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Loader } from 'components/base/Loader/Loader';
import { ErrorMessage } from 'components/base/ErrorMessage/ErrorMessage';

// Actions
import { aGetDictionaryOfCategoriesIfNotLoaded } from 'features/Dictionaries/Categories/store/actions';
import { aGetDictionaryOfCountriesIfNotLoaded } from 'features/Dictionaries/Countries/store/actions';
import { aGetDictionaryOfLanguagesIfNotLoaded } from 'features/Dictionaries/Languages/store/actions';
import { aGetDictionaryOfNotificationTypesIfNotLoaded } from 'features/Dictionaries/Notifications/store/actions';

const actions = [
  aGetDictionaryOfCategoriesIfNotLoaded(),
  aGetDictionaryOfCountriesIfNotLoaded(),
  aGetDictionaryOfLanguagesIfNotLoaded(),
  aGetDictionaryOfNotificationTypesIfNotLoaded(),
];

export function withResources(Component) {
  return function (props) {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null);

    const showLoader = useCallback(() => setIsLoading(true), []);
    const hideLoader = useCallback(() => setIsLoading(false), []);

    const showLoadingError = useCallback((error) => setLoadingError(error), []);
    const hideLoadingError = useCallback(() => setLoadingError(null), []);

    const getResources = useCallback(() => {
      const actionsWithDispatch = actions.map((action) => dispatch(action));

      showLoader();

      Promise.all(actionsWithDispatch)
        .then(hideLoadingError)
        .catch(showLoadingError)
        .finally(hideLoader);
    }, [dispatch, hideLoader, hideLoadingError, showLoader, showLoadingError]);

    useEffect(getResources, [getResources]);

    if (isLoading) return <Loader withBackdrop={true} />;

    if (loadingError) return <ErrorMessage {...loadingError} />;

    return <Component {...props} />;
  };
}
