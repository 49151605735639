import axios from 'axios';
import { serverConfig } from 'config/config';

import { handleError } from 'helpers';

import { globalRequestInterceptor, globalResponseInterceptor } from 'helpers/axiosInterceptors';

export const FilesApi = axios.create({
  baseURL: `${serverConfig._baseURL}/`,
});

FilesApi.interceptors.request.use(globalRequestInterceptor);

FilesApi.interceptors.response.use(globalResponseInterceptor, handleError);
