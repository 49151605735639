import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { authActions } from 'actions/auth.actions';

import { ROUTES } from 'constants/routes.constants';

import styles from './LoginPage.module.scss';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || ROUTES.root;

  const [state, setState] = useState({
    username: '',
    password: '',
    submitted: false,
    loggingIn: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = state;

    if (username && password) {
      setState((prevState) => ({ ...prevState, submitted: true, loggingIn: true }));

      dispatch(authActions.login(username, password))
        .then(() => {
          navigate(from, { replace: true });
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, loggingIn: false }));
        });
    }
  };

  return (
    <div className={styles.root}>
      <form name="form" onSubmit={handleSubmit}>
        <div className={'form-group' + (state.submitted && !state.username ? ' has-error' : '')}>
          <label htmlFor="username">Username</label>
          <input
            type="email"
            className={'form-control' + (state.submitted && !state.password ? ' is-invalid' : '')}
            name="username"
            value={state.username}
            onChange={handleChange}
          />
        </div>

        <div className={'form-group' + (state.submitted && !state.password ? ' has-error' : '')}>
          <label htmlFor="password" className="col-form-label">
            Password
          </label>
          <input
            type="password"
            className={'form-control' + (state.submitted && !state.password ? ' is-invalid' : '')}
            name="password"
            value={state.password}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <button className="btn btn-primary">
            Login{' '}
            {state.loggingIn && (
              <img
                alt="loading..."
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
