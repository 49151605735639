import FAQIcon from '@material-ui/icons/HelpOutlineTwoTone';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PeopleIcon from '@material-ui/icons/People';
import PartnersIcon from '@material-ui/icons/PeopleOutlineRounded';
import AnalyticsIcon from '@material-ui/icons/ShowChart';
import CategoryIcon from '@material-ui/icons/Category';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PromotionsIcon from '@material-ui/icons/Grade';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

// Constants
import { ROUTES } from 'constants/routes.constants';
import { NOTICES_KEYS } from 'constants/notices';

const menuItems = [
  {
    title: 'List of Users',
    icon: PeopleIcon,
    path: ROUTES.users,
    noticeKey: NOTICES_KEYS.users,
  },
  {
    title: 'List of Partners',
    icon: PartnersIcon,
    path: ROUTES.partners,
    noticeKey: NOTICES_KEYS.partners,
  },
  {
    title: 'List of Categories',
    icon: CategoryIcon,
    path: ROUTES.categories,
    noticeKey: NOTICES_KEYS.categories,
  },
  {
    title: 'Charities',
    icon: AccessibilityIcon,
    path: ROUTES.charity,
    noticeKey: NOTICES_KEYS.charities,
    submenu: [
      {
        title: 'Events',
        path: ROUTES.charityEvents,
        noticeKey: NOTICES_KEYS.events,
      },
      {
        title: 'Vlogs',
        path: ROUTES.charityVlogs,
        noticeKey: NOTICES_KEYS.vlogs,
      },
      {
        title: 'Polls',
        path: ROUTES.charityPolls,
        noticeKey: NOTICES_KEYS.polls,
      },
      {
        title: 'Giveaways',
        path: ROUTES.charityGiveaways,
        noticeKey: NOTICES_KEYS.giveaways,
      },
    ],
  },

  {
    title: 'Analytics',
    icon: AnalyticsIcon,
    path: ROUTES.analytics,
    noticeKey: NOTICES_KEYS.analytics,
  },
  {
    title: 'Promotions',
    icon: PromotionsIcon,
    path: ROUTES.promotions,
    noticeKey: NOTICES_KEYS.promotions,
    submenu: [
      {
        title: 'In moderation',
        path: ROUTES.promotionsModeration,
        noticeKey: NOTICES_KEYS.pending,
      },
      {
        title: 'Published',
        path: ROUTES.promotionPublished,
        noticeKey: NOTICES_KEYS.published,
      },
    ],
  },
  {
    title: 'Push notifications',
    icon: NotificationsIcon,
    path: ROUTES.notifications,
  },
  {
    title: 'FAQ',
    icon: FAQIcon,
    path: ROUTES.faq,
  },
  {
    title: 'Versions',
    icon: DeviceHubIcon,
    path: ROUTES.versions,
  },
];

export default menuItems;
