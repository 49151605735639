import React from 'react';

// UIKit
import IconButton from '@material-ui/core/IconButton';

// Components
import { FormControl } from 'components/base/FormControl/FormControl';
import { LanguagesSelect } from 'components/base/Selects';

// Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import styles from './NewLocalization.module.scss';

export const NewLocalization = ({
  idx,
  control,
  name,
  inputSize,
  isDisabled = false,
  disabledLanguages,
  canRemove = true,
  onRemove,
}) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.row}>
        <LanguagesSelect
          control={control}
          name={`${name}.${idx}.locale`}
          size={inputSize}
          label="Localization:"
          disabledLanguages={disabledLanguages}
          withoutLang
          isDisabled={isDisabled}
        />
      </div>
      <div className={styles.row}>
        <FormControl
          placeholder="Title:"
          name={`${name}.${idx}.title`}
          control={control}
          isDisabled={isDisabled}
        />
      </div>
      <div className={styles.row}>
        <FormControl
          placeholder="Description:"
          name={`${name}.${idx}.description`}
          control={control}
          multiline
          isDisabled={isDisabled}
        />
      </div>
    </div>

    <IconButton disabled={!canRemove || isDisabled} onClick={onRemove}>
      <RemoveCircleOutlineIcon />
    </IconButton>
  </div>
);

export default NewLocalization;
