import { makeStyles, createStyles } from '@material-ui/core';

const drawerWidth = 150;

const UseStyles = makeStyles((theme) =>
  createStyles({
    hide: {
      display: 'none !important',
    },
    show: {
      display: 'block !important',
    },
    root: {
      display: 'flex',
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: '#0a1c33',
      boxShadow: 'none',
    },
    appBarIcon: {
      fill: 'white',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#0a1c33',
    },
    listItem: {
      padding: '0 !important',
      height: '5rem !important',
      justifyContent: 'center !important',
    },
    listItemTitle: {
      color: 'white !important',
      fontSize: '0.8rem !important',
      textAlign: 'center !important',
      '&:hover': {
        color: 'white !important',
        textDecoration: 'none !important',
      },
    },
    listItemIcon: {
      minWidth: 'fit-content !important',
      padding: '0 !important',
      color: 'white !important',
    },
    listItemContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: '0',
      alignItems: 'center',
      justifyContent: 'center',
    },
    navBarPushedItem: {
      backgroundColor: 'rgba(255, 255, 251, 0.1)',
      padding: '20px 0',
    },
    navBarItems: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },
    '@keyframes animate': {
      '0%': {
        transform: 'translateY(0) rotate(0deg)',
        'border-radius': 0,
      },
      '100%': {
        transform: 'translateY(-1000px) rotate(720deg)',
        opacity: 0,
      },
    },
    squares: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: 0,
      margin: 0,
    },
    square: {
      position: 'absolute',
      display: 'block',
      'list-style': 'none',
      animation: '$animate 25s linear infinite',
      bottom: '-150px',
      opacity: '0.4',
      left: (props) => props.left + '%',
      width: (props) => props.size + 'px',
      height: (props) => props.size + 'px',
      'animation-delay': (props) => props.animationDelay + 's',
      'animation-duration': (props) => props.animationDuration + 's',
      background: (props) => props.colour,
    },

    nested: {
      justifyContent: 'center !important',
    },

    active: {
      textDecoration: 'underline',
    },
  }),
);

export default UseStyles;
