import { useCallback } from 'react';
import { useBlocker } from './useBlocker';

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
export function useBlockerPrompt(
  when = true,
  message = 'You have unsaved changes, do you want to cancel editing?',
) {
  const blocker = useCallback(
    (tx) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
}
