import React, { useRef } from 'react';

// UIKit
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { debounce } from '@material-ui/core';

export const SearchInput = ({ label = '', isDisabled = false, defaultValue = '', onChange }) => {
  const searchFieldRef = useRef(null);

  const handleChangeDebounced = debounce(onChange, 300);

  const onInputChange = (event) => {
    const { value } = event.target;

    searchFieldRef.current.value = value;

    handleChangeDebounced(value);
  };

  return (
    <TextField
      size="small"
      label={label}
      variant="outlined"
      fullWidth={true}
      defaultValue={defaultValue}
      onChange={onInputChange}
      inputRef={searchFieldRef}
      disabled={isDisabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={true} size="small" edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
