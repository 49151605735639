import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { PollsResultsView } from 'components/base/PollsResultsView/PollsResultsView';

// Models
import { POLL_ANSWER_OPTION_ENUM, POLL_ANSWER_OPTION_MODEL, POLL_MODEL } from './utils/models';

// Services
import { getPollById } from 'services/charity.services';
import { useDispatch } from 'react-redux';
import { alertActions } from 'features/Alert';

export const PollResults = () => {
  const { id: pollId } = useParams();
  const dispatch = useDispatch();

  const { isLoading, data, isError, error } = useQuery({
    queryKey: ['poll', pollId],
    queryFn: () => getPollById(pollId),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const title = isLoading ? 'Loading...' : `Results of the "${data[POLL_MODEL.question]}" poll`;

  const otherOptionName = useMemo(() => {
    if (isLoading || isError) return;

    const option = data[POLL_MODEL.answerOption].find(
      (answerOption) =>
        answerOption[POLL_ANSWER_OPTION_MODEL.type] === POLL_ANSWER_OPTION_ENUM.open,
    );

    return option ? option[POLL_ANSWER_OPTION_MODEL.value] : '';
  }, [data, isError, isLoading]);

  return (
    <PageDetails title={title} isLoading={isLoading} errorMessage={isError && error.message}>
      {!isLoading && !error && (
        <PollsResultsView
          title={data[POLL_MODEL.question]}
          endDate={data[POLL_MODEL.finishAt]}
          results={data[POLL_MODEL.results]}
          additions={data[POLL_MODEL.userAnswers]}
          otherOptionName={otherOptionName}
        />
      )}
    </PageDetails>
  );
};
