import React from 'react';
import { IconPopover } from 'components/base/IconPopover/IconPopover';
import { dateToFormat } from 'utils/functionsForDate';
import { LIGHT_CLIENT_MODEL } from './models';

export const tableColumnsSettings = [
  { selector: LIGHT_CLIENT_MODEL.name, name: 'Name' },
  { selector: LIGHT_CLIENT_MODEL.email, name: 'Email' },
  {
    selector: LIGHT_CLIENT_MODEL.removed,
    name: 'User was removed',
    format: ({ removed }) => removed && 'Yes',
  },
  {
    selector: LIGHT_CLIENT_MODEL.isVerified,
    name: 'Verified',
    format: ({ isVerified }) => (isVerified ? 'Yes' : 'No'),
  },
  {
    selector: LIGHT_CLIENT_MODEL.lastUsed,
    name: (
      <IconPopover label="Last used">
        Last used - is the date of the last transaction of the user.
      </IconPopover>
    ),
    format: ({ lastUsed }) => dateToFormat(lastUsed),
  },
];

export const userTypesOptions = [
  { label: 'Verified', value: 1 },
  { label: 'Not verified', value: 2 },
  { label: 'Removed', value: 3 },
  { label: 'Not removed', value: 4 },
];

export const userTypesFilters = {
  1: { verified: true },
  2: { verified: false },
  3: { removed: true },
  4: { removed: false },
};
