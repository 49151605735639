import { MENU_OPTIONS } from 'features/Promotions/utils/constants';
import { PROMOTION_STATUS_ENUM } from 'features/Promotions/utils/enums';
import { PROMOTION_MODEL } from 'features/Promotions/utils/models';

export const PUBLISHED_CONSTANTS = Object.freeze({
  searchFilters: [
    PROMOTION_STATUS_ENUM.new,
    PROMOTION_STATUS_ENUM.pending,
    PROMOTION_STATUS_ENUM.rejected,
    PROMOTION_STATUS_ENUM.approved,
  ],

  dropdownMenuProps: {
    menuAvailableOptions: [MENU_OPTIONS.enable, MENU_OPTIONS.disable],
    menuCheckedField: PROMOTION_MODEL.active,
    menuEnabledOption: {
      [PROMOTION_STATUS_ENUM.enabled]: [MENU_OPTIONS.disable.name],
      [PROMOTION_STATUS_ENUM.disabled]: [MENU_OPTIONS.enable.name],
    },
  },
});

export const PENDING_CONSTANTS = Object.freeze({
  searchFilters: [
    PROMOTION_STATUS_ENUM.new,
    PROMOTION_STATUS_ENUM.pending,
    PROMOTION_STATUS_ENUM.rejected,
    PROMOTION_STATUS_ENUM.approved,
  ],
  dropdownMenuProps: {
    menuAvailableOptions: [MENU_OPTIONS.approve, MENU_OPTIONS.reject],
    menuCheckedField: PROMOTION_MODEL.status,
    menuEnabledOption: {
      [PROMOTION_STATUS_ENUM.pending]: [MENU_OPTIONS.approve.name, MENU_OPTIONS.reject.name],
    },
  },
});
