import React, { useState, useEffect, useMemo, useCallback, useRef, forwardRef } from 'react';
import cn from 'classnames/bind';

// Styles
import styles from './Input.styles.css';

const cx = cn.bind(styles);

export const Input = forwardRef(
  (
    {
      placeholder = '',
      isRequired = false,
      type = 'text',
      icon = null,
      isError,
      className,
      onChange = () => {},
      onFocus,
      onBlur,
      isDisabled = false,
      helperText = '',
      multiline = false,
      minRows = 4,
      value,
      label = '',
      ...rest
    },
    ref,
  ) => {
    const refField = useRef();
    const [isFocus, setIsFocus] = useState(null);
    const [isFilled, setIsFilled] = useState(null);

    const isIcon = useMemo(() => Boolean(icon), [icon]);

    const InputElement = useMemo(() => (multiline ? 'textarea' : 'input'), [multiline]);

    const createRef = useCallback(
      (node) => {
        refField.current = node;
        ref && ref(node);
      },
      [ref],
    );

    const handleFocus = useCallback(() => {
      setIsFocus(true);
      setIsFilled(true);

      Boolean(onFocus) && onFocus(refField.current.value);
    }, [setIsFilled, onFocus]);

    const handleBlur = useCallback(() => {
      setIsFocus(false);
      setIsFilled(Boolean(refField.current.value));

      Boolean(onBlur) && onBlur(refField.current);
    }, [setIsFilled, onBlur]);

    useEffect(() => {
      setIsFilled(Boolean(refField.current.value) || isFocus || value);
    }, [isFocus, value]);

    const classNameBlock = cx('Input', className);

    const classNameControl = cx('Input__control', {
      Input__control_focus: isFocus,
      Input__control_error: isError,
      Input__control_disabled: isDisabled,
    });

    const classNameIcon = cx('Input__icon');
    const classNameField = cx('Input__field', {
      Input__field_error: isError,
      Input__field_focus: isFocus,
      Input__field_disabled: isDisabled,
    });
    const classNamePlaceholder = cx('Input__placeholder', {
      Input__placeholder_focus: isFocus,
      Input__placeholder_filled: isFilled || type === 'file',
      Input__placeholder_required: isRequired,
      Input__placeholder_error: isError,
      Input__placeholder_disabled: isDisabled,
    });
    const classNameLabel = cx('Input__label', {
      Input__label_disabled: isDisabled,
    });

    const classNameHelper = cx('Input__helper', {
      Input__helper_error: isError,
    });

    return (
      <div className={classNameBlock}>
        {label && <div className={classNameLabel}>{label}</div>}

        <div className={classNameControl}>
          {isIcon && <div className={classNameIcon}>{icon}</div>}

          <InputElement
            ref={createRef}
            className={classNameField}
            type={type}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isDisabled}
            value={value}
            rows={multiline ? minRows : 0}
            {...rest}
          />
          <div className={classNamePlaceholder}>
            {placeholder} {isRequired && <span className={cx('Input__mark')}>*</span>}
          </div>
        </div>
        {helperText && <p className={classNameHelper}>{helperText}</p>}
      </div>
    );
  },
);
