import React from 'react';
import cn from 'classnames';

// Styles
import './Loader.styles.css';

export const Loader = ({ withBackdrop = false }) => {
  return (
    <div className={cn('lds-hourglass', { 'lds-hourglass_withBackdrop': withBackdrop })}></div>
  );
};

export default Loader;
