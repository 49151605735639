import React, { useState, useEffect, useCallback, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';

// UIKit
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

// Services
import {
  downloadFileOfTransactionsFinancialReportByUserId,
  getTransactionsFinancialReportByUserId,
} from 'services/transactions.service';

// Actions
import { alertActions } from 'features/Alert';
import {
  aGetTransactionsHistoryByUserId,
  aUpdateTransactionsByIds,
  aUpdateTransactionById,
} from 'actions/transactions.actions';

// Components
import { PartnerTotalTransactions } from './Total/Total';
import { ConfirmCancelTransaction } from './modals/ConfirmCancelTransaction';
import { AdditionalInformation } from './modals/AdditionalInformation';
import { MakeTransactionsSettlement } from './modals/MakeTransactionsSettlement';
import { MakeTransactionSettlement } from './modals/MakeTransactionSettlement';
import { Table } from 'components/base/Table/Table';

// Utils
import downloadBlobFileFromResponse from 'utils/functionsForFiles';
import { dateToFormat, isoToDate } from 'utils/functionsForDate';
import { formatReferral } from 'utils/functionsForTransactions';
import { formatLongRowByAddTitle } from 'utils/functionsForTable';

// Helpers
import { cancelSource } from 'helpers/axiosCancelSource';

// Constants
import { DEFAULT_DATEPICKER_DATE_FORMAT } from 'constants/dates';
import { SORTING_ORDER_TYPES } from 'constants/filters';

// Selectors
import { transactionsSelector } from 'selectors/transactions.selectors';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import './PartnerTransactions.style.css';

const TABLE_ROWS_PER_PAGE = 5;

const paymentType = {
  MONEY: 'Money',
  MONEY_BONUSES: 'Money bonuses',
  SHARING_BONUSES: 'Sharing bonuses',
  BONUSES: 'Bonuses',
};

const modalTypes = {
  transactionsSettlement: 'transactionsSettlement',
  transactionSettlement: 'transactionSettlement',
  transactionCancel: 'transactionCancel',
  information: 'information',
};

const filterTypes = {
  limit: 'limit',
  offset: 'offset',
  sortBy: 'sortBy',
  orderBy: 'orderBy',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  paid: 'paid',
  canceled: 'canceled',
  referral: 'referral',
};

const initialFilters = {
  [filterTypes.limit]: TABLE_ROWS_PER_PAGE,
  [filterTypes.offset]: 0,
  [filterTypes.sortBy]: 'date',
  [filterTypes.orderBy]: SORTING_ORDER_TYPES.asc,
  [filterTypes.dateFrom]: null,
  [filterTypes.dateTo]: null,
  [filterTypes.paid]: null,
  [filterTypes.canceled]: null,
  [filterTypes.referral]: null,
};

const initialFinancialReport = {
  adminPayToPartner: '',
  adminPercent: '',
  amountCancelledTransactions: '',
  amountOfCelebrityReferralBonuses: '',
  amountOfDefaultReferralBonuses: '',
  celebrityReferralBonuses: '',
  clientPercent: '',
  currentBalanceAdmin: '',
  currentBalancePartner: '',
  dateFrom: '',
  defaultReferralBonuses: '',
  partnerPayToAdmin: '',
  sumOfBonus: '',
  sumOfPrice: '',
  totalAmountOfReferralBonuses: '',
  totalCashBack: '',
  totalCashBackToAdmin: '',
  totalCashBackToClient: '',
  totalCashBackToPartner: '',
  totalCashbackToAR: '',
};

const filterSelectItems = Object.freeze({
  allTransactions: 'All transactions',
  paymentMade: 'Payment made',
  notPaid: 'Not Paid',
  canceled: 'Canceled',
  notCanceled: 'Not canceled',
  referral: 'Referral',
  notReferral: 'Not referral',
});

const filtersEnum = Object.freeze({
  allTransactions: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: null,
    [filterTypes.canceled]: null,
    [filterTypes.referral]: null,
  },
  paymentMade: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: true,
    [filterTypes.canceled]: null,
    [filterTypes.referral]: null,
  },
  notPaid: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: false,
    [filterTypes.canceled]: null,
    [filterTypes.referral]: null,
  },
  canceled: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: null,
    [filterTypes.canceled]: true,
    [filterTypes.referral]: null,
  },
  notCanceled: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: null,
    [filterTypes.canceled]: false,
    [filterTypes.referral]: null,
  },
  referral: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: null,
    [filterTypes.canceled]: null,
    [filterTypes.referral]: true,
  },
  notReferral: {
    [filterTypes.offset]: 0,
    [filterTypes.paid]: null,
    [filterTypes.canceled]: null,
    [filterTypes.referral]: false,
  },
});

export function PartnerTransactions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: userId } = useParams();

  const { isLoading: isTransactionsLoading, data } = useSelector(transactionsSelector);
  const { count: tableTotalRows, payments } = data;

  const [filters, setFilters] = useState(initialFilters);

  const [financialReport, setFinancialReport] = useState(initialFinancialReport);
  const [isTransactionCanceling, setIsTransactionCanceling] = useState(false);
  const [isDownloadingFileOfFinReport, setIsDownloadingFileOfFinReport] = useState(false);

  const [showModal, setShowModal] = useState({
    status: false,
    data: null,
    paid: '',
  });

  const tableColumns = useMemo(
    () => [
      {
        selector: 'date',
        name: 'Date',
        maxWidth: '100px',
        sortable: true,
      },
      { selector: 'type', name: 'Type', maxWidth: '120px' },
      { selector: 'amount', name: 'Paid amount', maxWidth: '120px', sortable: true },
      {
        selector: 'adminIncomeBonuses',
        name: 'Cashback for admin',
        maxWidth: '150px',
      },
      {
        selector: 'clientName',
        name: 'Client name',
        minWidth: '150px',
      },
      {
        selector: 'clientIncomeBonuses',
        name: 'Description',
        sortable: true,
        minWidth: '300px',
        wrap: true,
        format: ({ clientIncomeBonuses }) => formatLongRowByAddTitle(clientIncomeBonuses),
      },
      {
        selector: 'Referral',
        name: 'Referral',
        maxWidth: '120px',
        format: formatReferral,
      },
      {
        selector: 'isPaid',
        name: 'Settlement Status',
        ignoreRowClick: true,
        maxWidth: '150px',
        format: ({ isPaid }) => (isPaid ? 'Settled' : 'Unsettled'),
      },
      {
        width: '240px',
        button: true,
        cell: ({ id, isPaid, isCanceled }) => {
          return (
            !isCanceled && (
              <button
                onClick={() =>
                  setShowModal({
                    status: true,
                    data: { id, isPaid },
                    paid: modalTypes.transactionSettlement,
                  })
                }
                className="btn btn-info"
              >
                Change status manually
              </button>
            )
          );
        },
      },
      {
        selector: 'isCanceled',
        name: 'Cancellations Status',
        width: '150px',
        format: ({ isCanceled }) => (isCanceled ? 'Canceled' : 'Not canceled'),
      },
      {
        width: '200px',
        button: true,
        cell: ({ id, isCanceled, isPaid }) => {
          if (!isCanceled && !isPaid) {
            return (
              <button
                onClick={() =>
                  setShowModal({ status: true, data: id, paid: modalTypes.transactionCancel })
                }
                className="btn btn-info"
              >
                Cancel transaction
              </button>
            );
          }
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    if (payments && payments.length) {
      return payments.map(mapPaymentsToTableRows);
    }

    return [];
  }, [payments]);

  const dataOfSelectedTableRow = useMemo(() => {
    return tableData.find((row) => row.id === showModal.data);
  }, [showModal.data, tableData]);

  const closeModal = useCallback(() => {
    setShowModal({
      status: false,
      data: null,
      paid: '',
    });
  }, []);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const changeFilter = useCallback(
    (filter) => setFilters((filters) => ({ ...filters, ...filter })),
    [],
  );

  const onChangeDateFrom = useCallback(
    (date) => {
      changeFilter({ [filterTypes.dateFrom]: date });
    },
    [changeFilter],
  );

  const onChangeDateTo = useCallback(
    (date) => {
      changeFilter({ [filterTypes.dateTo]: date });
    },
    [changeFilter],
  );

  const onChangeTableOrder = useCallback(
    ({ selector }, orderBy) => {
      changeFilter({ [filterTypes.sortBy]: selector, [filterTypes.orderBy]: orderBy });
    },
    [changeFilter],
  );

  const onChangeTablePage = useCallback(
    (page) => {
      const offset = filters.limit * (page - 1);
      changeFilter({ [filterTypes.offset]: offset });
    },
    [changeFilter, filters.limit],
  );

  const onClickTableRow = useCallback(({ id }) => {
    setShowModal({ status: true, data: id, paid: modalTypes.information });
  }, []);

  const getTransactionsFinancialReport = useCallback(() => {
    const params = {
      paid: filters.paid,
      canceled: false,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
    };

    getTransactionsFinancialReportByUserId(userId, params)
      .then(setFinancialReport)
      .catch((error) => dispatch(alertActions.error(error.message)));
  }, [dispatch, userId, filters.dateFrom, filters.dateTo, filters.paid]);

  const toggleSettlementStatus = useCallback(() => {
    const {
      data: { id, isPaid },
    } = showModal;

    dispatch(aUpdateTransactionById(id, { isPaid: !isPaid })).then(getTransactionsFinancialReport);

    closeModal();
  }, [showModal, dispatch, getTransactionsFinancialReport, closeModal]);

  const payedAllSettlementStatus = useCallback(() => {
    const transactionsForChange = payments.filter(
      ({ isCanceled, isPaid }) => !isCanceled && !isPaid,
    );
    dispatch(aUpdateTransactionsByIds(transactionsForChange, { isPaid: true })).then(
      getTransactionsFinancialReport,
    );
    closeModal();
  }, [closeModal, dispatch, getTransactionsFinancialReport, payments]);

  const exportToCsv = useCallback(() => {
    setIsDownloadingFileOfFinReport(true);

    const params = {
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      paid: filters.paid
    };

    downloadFileOfTransactionsFinancialReportByUserId(userId, params)
      .then(downloadBlobFileFromResponse)
      .catch((error) => dispatch(alertActions.error(error.message)))
      .finally(() => setIsDownloadingFileOfFinReport(false));
  }, [dispatch, filters.dateFrom, filters.dateTo, filters.paid, userId]);

  const onAcceptCancelTransaction = useCallback(
    (message) => {
      setIsTransactionCanceling(true);

      const { data: id } = showModal;

      const data = {
        isCanceled: true,
        canceledDescription: message,
      };

      dispatch(aUpdateTransactionById(id, data))
        .then(getTransactionsFinancialReport)
        .finally(() => {
          setIsTransactionCanceling(false);
          closeModal();
        });
    },
    [showModal, dispatch, getTransactionsFinancialReport, closeModal],
  );

  const onDismissCancelTransaction = useCallback(() => {
    cancelSource.cancel('Operation canceled by the user.');
    closeModal();
  }, [closeModal]);

  const setDatesPeriod = useCallback(
    (transactionResponse) => {
      if (!filters.dateFrom && transactionResponse.payments.length) {
        const date = transactionResponse.payments[0].date;

        const dateFrom = new Date(date)
        const dateTo = new Date()

        date && changeFilter({ dateFrom, dateTo });
      }
    },
    [changeFilter, filters.dateFrom],
  );

  const getTransactionsHistoryByUserId = useCallback(() => {
    if (userId) {
      dispatch(aGetTransactionsHistoryByUserId(userId, filters)).then(setDatesPeriod);
    }
  }, [dispatch, filters, setDatesPeriod, userId]);

  const onChangeSelect = ({ target }) => {
    changeFilter(filtersEnum[target.value]);
  };

  useEffect(getTransactionsHistoryByUserId, [getTransactionsHistoryByUserId]);

  useEffect(getTransactionsFinancialReport, [getTransactionsFinancialReport]);

  return (
    <Fragment>
      {showModal.status && showModal.paid === modalTypes.transactionSettlement && (
        <MakeTransactionSettlement onClose={closeModal} onSubmit={toggleSettlementStatus} />
      )}

      {showModal.status && showModal.paid === modalTypes.transactionsSettlement && (
        <MakeTransactionsSettlement onClose={closeModal} onSubmit={payedAllSettlementStatus} />
      )}

      {showModal.status && showModal.paid === modalTypes.transactionCancel && (
        <ConfirmCancelTransaction
          isLoading={isTransactionCanceling}
          onDismiss={onDismissCancelTransaction}
          onAccept={onAcceptCancelTransaction}
        />
      )}

      {showModal.status && showModal.paid === modalTypes.information && (
        <AdditionalInformation onClose={closeModal} data={dataOfSelectedTableRow} />
      )}

      <div className="partner-transactions">
        <div className="btn btn-outline-dark mb-2" onClick={goBack}>
          <KeyboardBackspaceIcon />
        </div>
        <div></div>

        <div className="d-flex justify-content-between flex-wrap mt-2">
          <div className="d-flex flex-wrap">
            <div className="mr-5 mb-2">
              <select className="form-control-sm" onChange={onChangeSelect}>
                {Object.keys(filterSelectItems).map((key) => {
                  const item = filterSelectItems[key];
                  return (
                    <option key={key} value={key}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex flex-wrap">
              <div className="mr-5 mb-2">
                <span className="date-filter-label mr-1">Start date:</span>
                <DatePicker
                  selected={isoToDate(filters.dateFrom)}
                  onSelect={onChangeDateFrom}
                  selectsStart
                  startDate={filters.dateFrom}
                  endDate={filters.dateTo}
                  dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
                />
              </div>
              <div className="mr-5">
                <span className="date-filter-label mr-1">End date:</span>
                <DatePicker
                  selected={isoToDate(filters.dateTo)}
                  onSelect={onChangeDateTo}
                  selectsEnd
                  startDate={filters.dateFrom}
                  endDate={filters.dateTo}
                  minDate={filters.dateFrom}
                  dateFormat={DEFAULT_DATEPICKER_DATE_FORMAT}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <button
              onClick={exportToCsv}
              disabled={isDownloadingFileOfFinReport}
              className="btn btn-secondary mt-1 mr-1"
            >
              Export to csv
            </button>
            <button
              onClick={() =>
                setShowModal({ status: true, paid: modalTypes.transactionsSettlement })
              }
              className="btn btn-warning mt-1"
            >
              Make a settlement
            </button>
          </div>
        </div>

        <div className="partner-transaction-data-table">
          <Table
            columns={tableColumns}
            data={tableData}
            highlightOnHover={true}
            pagination={true}
            progressPending={isTransactionsLoading}
            paginationServer={true}
            paginationTotalRows={tableTotalRows}
            paginationPerPage={TABLE_ROWS_PER_PAGE}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={onChangeTablePage}
            sortServer={true}
            defaultSortFieldId={filters.sortBy}
            onSort={onChangeTableOrder}
            onRowClicked={onClickTableRow}
          />
        </div>
      </div>

      <PartnerTotalTransactions values={financialReport} />
    </Fragment>
  );
}

function mapPaymentsToTableRows(payment) {
  return {
    date: dateToFormat(payment.date),
    amount: payment.price,
    isPaid: payment.isPaid,
    adminIncomeBonuses: payment.adminBonuses,
    clientIncomeBonuses: `+${payment.clientBonuses} bonuses from ${
      payment.senderClientName || payment.partnerName || 'partner which was deleted'
    }`,
    id: payment._id,
    transactions: payment.transactions,
    money: payment.money,
    bonuses: payment.bonuses,
    price: payment.price,
    clientBonuses: payment.clientBonuses,
    senderClientBonuses: payment.senderClientBonuses,
    partnerBonuses: payment.partnerBonuses,
    clientBonusesAfter: payment.clientBonusesAfter,
    clientBonusesAfterCancel: payment.clientBonusesAfterCancel,
    isCanceled: payment.isCanceled,
    canceledDescription: payment.canceledDescription,
    isReferral: payment.isReferral,
    type: paymentType[payment?.type] || 'Combine',
    clientName: payment.clientName,
    clientId: payment.clientId,
    product: payment.product,
  };
}
