const PROMOTION_STATUS_ENUM = Object.freeze({
  all: null,
  new: 'NEW',
  approved: 'APPROVED',
  rejected: 'REJECTED',
  pending: 'PENDING',
  finished: 'FINISHED',
  enabled: 'ENABLED',
  disabled: 'DISABLED',
  started: 'STARTED',
  pubStart: 'PUBLISHED_AND_STARTED',
});

const PROMOTION_ACTIVE_ENUM = Object.freeze({
  enabled: 'ENABLED',
  disabled: 'DISABLED',
});

export { PROMOTION_STATUS_ENUM, PROMOTION_ACTIVE_ENUM };
