import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';

// Components
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';
import { EventForm } from './components/EventForm';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';
import { useGoBack } from 'hooks/useGoBack';

// Utilities
import { defaultValues, validationSchema } from './utils/form';
import { mapEventItemToFormValues, mapFormValuesToEventItem } from './utils/mappers';

// Services
import { getCharityEventById, updateCharityEventById } from 'services/charity.services';

// Helpers
import { queryClient } from 'helpers/queryClient';

// Actions
import { alertActions } from 'features/Alert';

export const EventDetails = () => {
  const dispatch = useDispatch();
  const { id: eventId } = useParams();

  const [placesAutocompleteValue, onChangePlacesAutocomplete] = useState('');

  const goBack = useGoBack();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isLoading, data } = useQuery({
    queryKey: ['event', eventId],
    queryFn: () => getCharityEventById(eventId),
    select: mapEventItemToFormValues,
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const updateEventMutation = useMutation({
    mutationFn: async (formValues) => {
      const eventItem = await mapFormValuesToEventItem(formValues);
      return updateCharityEventById(eventId, eventItem);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['event'] });
      dispatch(alertActions.success('Event was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const footerActions = useMemo(
    () => (
      <Fragment>
        <button
          className="btn btn-primary"
          style={{ marginRight: '1rem' }}
          onClick={handleSubmit(updateEventMutation.mutate)}
          type="button"
          disabled={!isDirty}
        >
          Save
        </button>
        <button className="btn btn-secondary" onClick={goBack} type="button">
          Cancel
        </button>
      </Fragment>
    ),
    [goBack, handleSubmit, isDirty, updateEventMutation.mutate],
  );

  useEffect(() => {
    if (isLoading || !data) return;

    onChangePlacesAutocomplete(data.place);
    reset(data);
  }, [data, isLoading, reset]);

  useBlockerPrompt(isDirty);

  return (
    <PageDetails title="View and edit the event" isLoading={isLoading} renderFooter={footerActions}>
      <EventForm
        isLoading={updateEventMutation.isLoading}
        control={control}
        placesAutocompleteValue={placesAutocompleteValue}
        onChangePlacesAutocomplete={onChangePlacesAutocomplete}
      />
    </PageDetails>
  );
};
