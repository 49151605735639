import React, { useLayoutEffect } from 'react';

// UIKit
import { Typography, Grid } from '@material-ui/core';

// Components
import { Loader } from 'components/base/Loader/Loader';

import './LayoutShowcase.styles.css';

export const LayoutShowcase = ({
  title,
  isLoading = false,
  onLoading = () => {},
  children,
  renderFooter,
}) => {
  // Fetch data from server
  useLayoutEffect(onLoading, [onLoading]);

  return (
    <div className="LayoutShowcase">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="div" gutterBottom align="center">
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {isLoading && <Loader />}
          {!isLoading && children}
        </Grid>

        {renderFooter && !isLoading && (
          <Grid item xs={12} align="right">
            {renderFooter}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LayoutShowcase;
