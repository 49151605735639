import React, { Fragment, useCallback, useEffect, useMemo } from 'react';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import Chip from '@material-ui/core/Chip';

const primaryColor = red[500];

export const Select = ({
  value,
  onChange = () => {},
  onBlur = () => {},
  placeholder = 'Placeholder',
  options = [],
  helperText = '',
  inputSize = 'small',
  inputName = '',
  inputValue = '',
  isError = false,
  isDisabled = false,
  isRequire = false,
  isLoading = false,
  isFullWidth = true,
  isMultiple = false,
  isSingleSelect = false,
  disableClearable = true,
  freeSolo = false,
  ...otherProps
}) => {
  const Placeholder = useMemo(() => {
    if (isRequire) {
      return (
        <Fragment>
          {placeholder}:<span style={{ color: primaryColor }}>*</span>
        </Fragment>
      );
    }

    return placeholder;
  }, [isRequire, placeholder]);

  const getOptionName = useCallback(
    (option) => {
      return typeof option === 'string' ? option : option[inputName];
    },
    [inputName],
  );

  const getOptionValue = useCallback(
    (option) => {
      if (!option) return option;

      return typeof option === 'string' ? option : option[inputValue];
    },
    [inputValue],
  );

  const getOptionSelected = useCallback(
    (option, value) => {
      if (!value) return false;

      return getOptionValue(option) === getOptionValue(value);
    },
    [getOptionValue],
  );

  const handleChange = useCallback(
    (event, value) => {
      const values = Array.isArray(value) ? value.map(getOptionValue) : getOptionValue(value);

      onChange(values, event);
    },
    [getOptionValue, onChange],
  );

  const getInputText = useCallback(
    (localValue) => {
      if (!localValue || !inputName || !inputValue) {
        return localValue;
      }

      if (Array.isArray(localValue)) {
        return localValue.map(getInputText);
      }

      const currentOption = options.find(
        (option) => getOptionValue(option) === getOptionValue(localValue),
      );

      if (!currentOption?.[inputName]) {
        return localValue[inputName];
      }

      return currentOption[inputName];
    },
    [getOptionValue, inputName, inputValue, options],
  );

  const renderSingleInput = useCallback(
    (params) => {
      const inputValue = getInputText(params.inputProps.value);

      return (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            value: freeSolo ? params.inputProps.value : inputValue,
            'data-testid': 'select-input',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          variant="outlined"
          label={Placeholder}
          error={isError}
          helperText={helperText}
        />
      );
    },
    [Placeholder, freeSolo, getInputText, helperText, isError, isLoading],
  );

  const renderTags = useCallback(
    (tags, getTagProps) => {
      return tags.map((tag) => {
        const tagProps = getTagProps(tag);
        const label = getInputText(tag);
        const key = getOptionValue(tag);

        return <Chip {...tagProps} key={key} label={label} size="small" />;
      });
    },
    [getInputText, getOptionValue],
  );

  // Automatic selection of one option item
  useEffect(() => {
    if (isSingleSelect && isMultiple && options.length === 1) {
      handleChange(null, [options[0]]);
    }
  }, [handleChange, isMultiple, isSingleSelect, options]);

  return (
    <Autocomplete
      data-testid="select"
      size={inputSize}
      disableClearable={disableClearable}
      options={options}
      loading={isLoading}
      fullWidth={isFullWidth}
      disabled={isDisabled}
      multiple={isMultiple}
      value={value}
      onBlur={onBlur}
      getOptionLabel={getOptionName}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      renderTags={renderTags}
      renderInput={renderSingleInput}
      ListboxProps={{ 'data-testid': 'list-box' }}
      freeSolo={freeSolo}
      {...otherProps}
    />
  );
};

export default Select;
