import React from 'react';
import { useController } from 'react-hook-form';

// Components
import FilesManagement from 'components/base/FilesManagement';

// Service
import filesService from 'services/files.service';

export const FormFilesManagement = ({
  name,
  control,
  rules,
  service = filesService,
  onDelete,
  ...rest
}) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FilesManagement
      id={name}
      files={value}
      onChange={onChange}
      isError={invalid}
      helperText={invalid && error.message}
      service={service}
      onDelete={onDelete}
      {...rest}
    />
  );
};

export default FormFilesManagement;
