import React, { Fragment } from 'react';

// Components
import { Modal } from 'components/base/Modal/Modal';

export const MakeTransactionsSettlement = ({ onClose, onSubmit }) => (
  <Modal
    show={true}
    handleClose={onClose}
    name={'Confirm change all Settlement status'}
    renderFooter={
      <Fragment>
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Yes
        </button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
          No
        </button>
      </Fragment>
    }
  >
    Do you really want to change all Settlement status?
  </Modal>
);
