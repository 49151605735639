import actionsTypes from './types';
import { getCategories } from 'services/categories.service';
import { categoriesSelector } from './selectors';

export default {
  aGetDictionaryOfCategories,
  aGetDictionaryOfCategoriesIfNotLoaded,
};

export function aGetDictionaryOfCategoriesIfNotLoaded() {
  return (dispatch, getStore) => {
    const store = getStore();
    const { isCategoriesLoaded, isCategoriesLoading } = categoriesSelector(store);

    if (!isCategoriesLoaded && !isCategoriesLoading) {
      return dispatch(aGetDictionaryOfCategories());
    }
  };
}

export function aGetDictionaryOfCategories() {
  return (dispatch) => {
    dispatch(request());

    return getCategories().then(
      ({ data }) => {
        dispatch(success(data));
        return Promise.resolve();
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_REQUEST };
  }

  function success(data) {
    return { type: actionsTypes.GET_SUCCESS, data };
  }

  function failure() {
    return { type: actionsTypes.GET_FAILURE };
  }
}
