import React from 'react';
import { useController } from 'react-hook-form';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export const FormCheckbox = ({
  control,
  name,
  rules,
  placeholder,
  size = 'small',
  isDisabled = false,
}) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FormControl>
      <FormControlLabel
        label={placeholder}
        disabled={isDisabled}
        control={
          <Checkbox
            size={size}
            color="primary"
            checked={value}
            label={placeholder}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
      />

      {invalid && (
        <FormHelperText error={invalid} variant="outlined">
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormCheckbox;
