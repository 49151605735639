import React from 'react';

import { IconPopover } from 'components/base/IconPopover/IconPopover';

export const PartnerTotalTransactions = ({ values }) => {
  return (
    <div className="partner-transactions__form">
      <div className="partner-transactions__form-item">
        <label htmlFor="totalCashBackToAdmin" className="partner-transactions__form-item-label">
          <IconPopover label="Total Commission Due for Aller-Retour">
            Total Amount of Money that the Partner needs to pay Aller-Retour
          </IconPopover>
        </label>
        <input
          type="text"
          className="partner-transactions__form-item-input-money"
          disabled
          name="totalCashBackToAdmin"
          value={values.totalCashBackToAdmin}
        />
      </div>

      <div className="partner-transactions__form-item">
        <label htmlFor="totalCashBackToClient" className="partner-transactions__form-item-label">
          <IconPopover label="Total Virtual Points Due">
            Total amount of Virtual Points the Partner owes Clients
          </IconPopover>
        </label>
        <input
          type="text"
          className="partner-transactions__form-item-input-money"
          disabled
          name="totalCashBackToClient"
          value={values.totalCashBackToClient}
        />
      </div>

      <div className="partner-transactions__form-item">
        <label htmlFor="sumOfPrice" className="partner-transactions__form-item-label">
          <IconPopover label="Total value received from Transactions">
            Total sum of all the payments made through the Aller-Retour Application
          </IconPopover>
        </label>
        <input
          type="text"
          className="partner-transactions__form-item-input-money"
          disabled
          name="sumOfPrice"
          value={values.sumOfPrice}
        />
      </div>

      <div className="partner-transactions__form-item">
        <label htmlFor="adminPayToPartner" className="partner-transactions__form-item-label">
          <IconPopover label="Total Virtual Points Owed">
            Total amount of Virtual Points Aller-Retour owes the Partner
          </IconPopover>
        </label>
        <input
          type="text"
          className="partner-transactions__form-item-input-money"
          disabled
          name="adminPayToPartner"
          value={values.adminPayToPartner}
        />
      </div>

      <div className="partner-transactions__form-item">
        <label
          htmlFor="currentBalanceAdmin"
          className="partner-transactions__form-item-label-special"
        >
          <IconPopover label="Current Balance">
            The difference between the Owed and Due Virtual Points
          </IconPopover>
        </label>
        <input
          type="text"
          className="partner-transactions__form-item-input-money-special"
          disabled
          name="currentBalanceAdmin"
          value={values.currentBalanceAdmin}
        />
      </div>
    </div>
  );
};
