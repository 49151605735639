import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Components
import { Form } from 'components/base/Form/Form';
import { FormControl } from 'components/base/FormControl/FormControl';
import { FormDatePicker } from 'components/base/FormDatePicker/FormDatePicker';
import { FormFilesManagement } from 'components/base/FormFilesManagement/FormFilesManagement';

// Utilities
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';
import { VLOG_FIELDS_NAMES, VLOG_FIELDS_PLACEHOLDERS } from '../utils/form';
import { getStartOf } from 'utils/functionsForDate';

// Models
import { VLOG_MODEL } from '../utils/models';

const inputSize = 'small';

export const VlogForm = ({
  isLoading = false,
  isCountriesLoading,
  countries,
  cities,
  onCitiesChange,
  control,
  resetCity,
}) => {
  const onCountryChange = useCallback(
    (onChangeCallback) =>
      (_, { cities, country }) => {
        onCitiesChange(cities);
        onChangeCallback(country);
        resetCity();
      },
    [onCitiesChange, resetCity],
  );

  const onCityChange = useCallback(
    (onChangeCallback) => (_, city) => {
      onChangeCallback(city);
    },
    [],
  );

  return (
    <Form isLoading={isLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormFilesManagement
            name={VLOG_FIELDS_NAMES.video}
            control={control}
            id="video"
            buttonLabel={VLOG_FIELDS_PLACEHOLDERS.video}
            maxFiles={1}
            accept="video/avi, video/mp4, video/mpeg"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl
            name={VLOG_FIELDS_NAMES.name}
            placeholder={VLOG_FIELDS_PLACEHOLDERS.name}
            control={control}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl
            name={VLOG_FIELDS_NAMES.description}
            placeholder={VLOG_FIELDS_PLACEHOLDERS.description}
            control={control}
            multiline
            minRows={4}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFilesManagement
            name={VLOG_FIELDS_NAMES.preview}
            control={control}
            id="vlogPreview"
            buttonLabel={VLOG_FIELDS_PLACEHOLDERS.preview}
            maxFiles={1}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDatePicker
            name={VLOG_FIELDS_NAMES.eventDate}
            placeholder={VLOG_FIELDS_PLACEHOLDERS.eventDate}
            control={control}
            minDate={null}
            minTime={getStartOf()}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            name={VLOG_FIELDS_NAMES.collectedMoney}
            placeholder={VLOG_FIELDS_PLACEHOLDERS.collectedMoney}
            control={control}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={VLOG_FIELDS_NAMES.country}
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                id="country"
                getOptionLabel={getOptionLabel(VLOG_MODEL.country)}
                getOptionSelected={getOptionSelected(VLOG_MODEL.country)}
                options={countries}
                onChange={onCountryChange(field.onChange)}
                value={field.value}
                loading={isCountriesLoading}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={VLOG_FIELDS_PLACEHOLDERS.country}
                    variant="outlined"
                    size={inputSize}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={VLOG_FIELDS_NAMES.city}
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                id="city"
                getOptionLabel={getOptionLabel(VLOG_MODEL.country)}
                getOptionSelected={getOptionSelected(VLOG_MODEL.country)}
                options={cities}
                onChange={onCityChange(field.onChange)}
                value={field.value}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={VLOG_FIELDS_PLACEHOLDERS.city}
                    variant="outlined"
                    size={inputSize}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error && fieldState.error.message}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFilesManagement
            id="vlogPhotos"
            name={VLOG_FIELDS_NAMES.images}
            control={control}
            buttonLabel={VLOG_FIELDS_PLACEHOLDERS.images}
            maxFiles={9}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
