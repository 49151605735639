import { object, string } from 'yup';

// Models
import { FAQ_MODEL } from './models';

export const defaultValues = {
  [FAQ_MODEL.title]: '',
  [FAQ_MODEL.subject]: '',
};

export const validationSchema = object({
  [FAQ_MODEL.title]: string().min(2, 'Required field, minimum length 2 characters').required(),
  [FAQ_MODEL.subject]: string().min(2, 'Required field, minimum length 2 characters').required(),
}).required();
