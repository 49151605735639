import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import PromotionsList from './components/PromotionsList/PromotionsList';
import PromotionStatusSelect from 'components/base/Selects/PromotionStatusSelect';

// Constants
import { PUBLISHED_CONSTANTS } from './components/PromotionsList/PromotionsList.constants';
import { publishedColumnSettings } from './utils/constants';

// Models
import { PROMOTION_DICTIONARY_STATUS_TYPES_ENUM } from 'features/Dictionaries/Promotions/utils/enums';
import { PROMOTION_MODEL } from './utils/models';

// Store
import { aGetPromotions } from './store/actions';
import {
  promotionsListAllCountSelector,
  promotionsListItemsSelector,
  promotionsListLoadedCountSelector,
} from './store/selectors';

export const Published = () => {
  const dispatch = useDispatch();

  const count = useSelector(promotionsListAllCountSelector);
  const items = useSelector(promotionsListItemsSelector);
  const loadedItemsCount = useSelector(promotionsListLoadedCountSelector);

  const [publishedQueries, setPublishedQueries] = useState({
    published: true,
    publishStatus: null,
  });

  const onChangePublishedQueries = useCallback((publishStatus) => {
    setPublishedQueries((prevProps) => ({ ...prevProps, publishStatus }));
  }, []);

  const getPublishedPromotions = useCallback(() => {
    dispatch(aGetPromotions(publishedQueries));
  }, [dispatch, publishedQueries]);

  const finishedPromotionItems = useMemo(() => {
    return items.map((promotion) => {
      if (promotion[PROMOTION_MODEL.published]) {
        return {
          ...promotion[PROMOTION_MODEL.published],
          [PROMOTION_MODEL.id]: promotion[PROMOTION_MODEL.id],
          [PROMOTION_MODEL.order]: promotion[PROMOTION_MODEL.order],
          [PROMOTION_MODEL.active]: promotion[PROMOTION_MODEL.active],
        };
      }

      return promotion;
    });
  }, [items]);

  return (
    <PromotionsList
      isWithOrder={true}
      title="List of published Promotions"
      items={finishedPromotionItems}
      count={count}
      loadedItemsCount={loadedItemsCount}
      tableColumnsSettings={publishedColumnSettings}
      dropdownMenuProps={PUBLISHED_CONSTANTS.dropdownMenuProps}
      getPromotions={getPublishedPromotions}
      renderSubHeader={
        <PromotionStatusSelect
          value={publishedQueries.publishStatus}
          onChange={onChangePublishedQueries}
          dictionaryTypeKey={PROMOTION_DICTIONARY_STATUS_TYPES_ENUM.published}
        />
      }
    />
  );
};
