import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import { Box, IconButton } from '@material-ui/core';

// Icons
import CategoryIcon from '@material-ui/icons/Category';
import Delete from '@material-ui/icons/Delete';

// Components
import { ModalDelete, ModalCreate, ModalConfirm } from './modals';
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { Image } from 'components/base/Image/Image';
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';

// Actions
import { alertActions } from 'features/Alert';

// Services
import { createVlog, deleteVlogById, getVlogs } from 'services/charity.services';

// Utils
import { dateToFormat, sortingDates } from 'utils/functionsForDate';

// Models
import { VLOG_MODEL } from './utils/models';

// Hooks
import { useTableParams } from 'common/hooks/useTableParams';
import { useModal } from 'hooks/useModal';

// Helpers
import { queryClient } from 'helpers/queryClient';

export const Vlogs = () => {
  const dispatch = useDispatch();

  const [queryParams] = useTableParams({
    initial: { sortBy: VLOG_MODEL.createdAt },
  });

  // Modal delete
  const [modalDeleteIsOpen, onShowModalDelete, onHideModalDelete, modalDeletePayload] = useModal();

  // Confirm delete
  const [modalConfirmationIsOpen, onShowModalConfirmation, onHideModalConfirmation] = useModal();

  // Modal create
  const [modalCreateIsOpen, onShowModalCreate, onHideModalCreate] = useModal();

  const hideOpenedModals = useCallback(() => {
    onHideModalDelete();
    onHideModalConfirmation();
    onHideModalCreate();
  }, [onHideModalConfirmation, onHideModalCreate, onHideModalDelete]);

  const createVlogMutation = useMutation({
    mutationFn: (newVlog) => {
      return createVlog(newVlog);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vlogs'] });
      hideOpenedModals();
      dispatch(alertActions.success('Vlog was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const deleteVlogMutation = useMutation({
    mutationFn: () => {
      return deleteVlogById(modalDeletePayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vlogs'] });
      hideOpenedModals();
      dispatch(alertActions.success('Vlog was deleted'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const tableColumnsSettings = useMemo(
    () => [
      {
        selector: VLOG_MODEL.preview,
        name: 'Preview',
        compact: true,
        grow: 0,
        width: '120px',
        cell: ({ preview }) => <Image src={preview} style={{ objectFit: 'cover' }} />,
      },
      { selector: VLOG_MODEL.name, name: 'Name of the vlog', maxWidth: '300px', sortable: true },
      { selector: 'description', name: 'Description', maxWidth: '600px' },
      {
        selector: VLOG_MODEL.createdAt,
        name: 'Date of creation',
        sortable: true,
        maxWidth: '200px',
        sortFunction: (rowA, rowB) => sortingDates(rowA.createdAt, rowB.createdAt),
        format: ({ createdAt }) => dateToFormat(createdAt),
      },
      { selector: VLOG_MODEL.city, name: 'City' },
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: ({ _id }) => (
          <IconButton onClick={onShowModalDelete.bind(null, _id)}>
            <Delete />
          </IconButton>
        ),
      },
    ],
    [onShowModalDelete],
  );

  const tableActionsMemo = useMemo(
    () => (
      <Box className="btn btn-outline-dark" onClick={onShowModalCreate}>
        Create a new vlog <CategoryIcon />
      </Box>
    ),
    [onShowModalCreate],
  );

  const { isLoading, data } = useQuery({
    queryKey: ['vlogs', queryParams],
    queryFn: () => getVlogs(queryParams),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Vlogs">
      <TableWithQueryFilters
        title="List of Charity Vlogs"
        renderHeader={tableActionsMemo}
        progressPending={isLoading}
        data={data?.rows}
        columns={tableColumnsSettings}
        paginationTotalRows={data?.count}
        defaultSortFieldId={queryParams.sortBy}
      />

      {modalDeleteIsOpen && (
        <ModalDelete
          onAccept={deleteVlogMutation.mutate}
          onDismiss={hideOpenedModals}
          eventName={modalDeletePayload}
          isLoading={deleteVlogMutation.isLoading}
        />
      )}

      {modalCreateIsOpen && (
        <ModalCreate
          onAccept={createVlogMutation.mutate}
          onDismiss={onShowModalConfirmation}
          isLoading={createVlogMutation.isLoading}
        />
      )}

      {modalConfirmationIsOpen && (
        <ModalConfirm onAccept={hideOpenedModals} onDismiss={onHideModalConfirmation} />
      )}
    </LayoutShowcase>
  );
};
