export default {
  GET_TRANSACTIONS_REQUEST: 'GET_TRANSACTIONS_REQUEST',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',

  UPDATE_TRANSACTION_REQUEST: 'UPDATE_TRANSACTION_REQUEST',
  UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
  UPDATE_TRANSACTION_FAILURE: 'UPDATE_TRANSACTION_FAILURE',

  UPDATE_TRANSACTIONS_BY_IDS_REQUEST: 'UPDATE_TRANSACTIONS_BY_IDS_REQUEST',
  UPDATE_TRANSACTIONS_BY_IDS_SUCCESS: 'UPDATE_TRANSACTIONS_BY_IDS_SUCCESS',
  UPDATE_TRANSACTIONS_BY_IDS_FAILURE: 'UPDATE_TRANSACTIONS_BY_IDS_FAILURE',
};
