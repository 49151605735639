import { createSelector } from 'reselect';
import { LANGUAGE_MODEL } from '../utils/models';

export const languagesSelector = (store) => store.dictionaries.languages;
export const languagesDataSelector = (store) => store.dictionaries.languages.data;

export const languagesWithoutEnglishSelector = createSelector(languagesDataSelector, (languages) =>
  languages.filter((lang) => lang[LANGUAGE_MODEL.value] !== 'EN'),
);

export const languagesLengthSelector = createSelector(
  languagesDataSelector,
  (languages) => languages.length,
);
export const languagesWithoutEnglishLengthSelector = createSelector(
  languagesWithoutEnglishSelector,
  (languages) => languages.length,
);
