import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// UIKit
import 'react-datepicker/dist/react-datepicker.css';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { EventForm } from '../EventForm';

// Utilities
import { defaultValues, validationSchema } from '../../utils/form';
import { mapFormValuesToEventItem } from '../../utils/mappers';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

export const ModalCreate = ({ onAccept, onDismiss, isLoading }) => {
  const [placesAutocompleteValue, onChangePlacesAutocomplete] = useState('');

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = useCallback(
    async (formValues) => {
      const values = await mapFormValuesToEventItem(formValues);
      onAccept && onAccept(values);
    },
    [onAccept],
  );

  const renderedFooter = useMemo(
    () => (
      <Fragment>
        <div>After you tap save, users will get a push notification.</div>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={handleSubmit(handleFormSubmit)}
          type="button"
        >
          Save
        </button>
        <button className="btn btn-secondary" onClick={onDismiss} type="button">
          Cancel
        </button>
      </Fragment>
    ),
    [handleFormSubmit, handleSubmit, isLoading, onDismiss],
  );

  useBlockerPrompt(isDirty);

  return (
    <Modal
      show={true}
      handleClose={onDismiss}
      name="Adding a new event"
      renderFooter={renderedFooter}
      isLoading={isLoading}
    >
      <EventForm
        control={control}
        placesAutocompleteValue={placesAutocompleteValue}
        onChangePlacesAutocomplete={onChangePlacesAutocomplete}
      />
    </Modal>
  );
};
