import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';

import { DATE_UNITS_ENUM, DEFAULT_DATES_FORMAT } from 'constants/dates';
import { SORTING_ORDER_TYPES } from 'constants/filters';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(isBetween);

const localTimeZone = dayjs.tz.guess();

export function dayjsDate(date = new Date()) {
  return dayjs.utc(date).tz(localTimeZone);
}

/**
 * Date validation.
 *
 * @param {string} date
 * @returns {boolean} is valid.
 */
export const isValidDate = (date) => dayjsDate(date).isValid();

/**
 * To format date as an ISO 8601 string.
 *
 * @param {string} date
 * @returns {string} ISO 8601.
 */
export const dateToIsoString = (date) => (isValidDate(date) ? dayjsDate(date).toISOString() : '');

/**
 * To format ISO 8601 as an date.
 *
 * @param {string} ISO date
 * @returns {string} date.
 */
export const isoToDate = (isoDate) => (isValidDate(isoDate) ? new Date(isoDate) : isoDate);

/**
 * Sorting two dates.
 * @param {string} dateA first date for sorting
 * @param {string} dateB second date for sorting
 * @param {string} order sort order
 * @returns {number}
 */
export const sortingDates = (dateA, dateB, order = SORTING_ORDER_TYPES.desc) => {
  if (order === SORTING_ORDER_TYPES.desc) return new Date(dateA) - new Date(dateB);
  return new Date(dateB) - new Date(dateA);
};

/**
 * Convert date to format
 * @param {string} date     Date to formatting
 * @param {string} format   To format
 * @returns {string} Formatted date
 */
export const dateToFormat = (date, format = DEFAULT_DATES_FORMAT) => {
  if (!isValidDate(date)) return date;

  return dayjsDate(date).format(format);
};

export const getToday = () => {
  return dayjsDate().toDate();
};

export const getStartOf = (units = DATE_UNITS_ENUM.day) => {
  return dayjsDate().startOf(units).toDate();
};

export const getEndOf = (units = DATE_UNITS_ENUM.day) => {
  return dayjsDate().endOf(units).toDate();
};

export const getTomorrow = () => {
  return dayjsDate().add(1, DATE_UNITS_ENUM.day).toDate();
};

export const generateRangeDates = ({ startDate, endDate, rangeArray }) => {
  const dayjsStartDateInstance = dayjs(startDate);
  const dayjsEndDateInstance = dayjs(endDate);

  const startOfRange = dayjs.min(dayjsStartDateInstance, dayjsEndDateInstance);
  const endOfRange = dayjs.max(dayjsStartDateInstance, dayjsEndDateInstance);
  let currentDate = startOfRange;

  while (
    currentDate.isBetween(startOfRange, endOfRange, 'date') ||
    currentDate.isSame(startOfRange, 'date') ||
    currentDate.isSame(endOfRange, 'date')
  ) {
    rangeArray.push(currentDate.toDate());
    currentDate = currentDate.add(1, 'day');
  }

  return rangeArray;
};

export default {
  getEndOf,
  getStartOf,
  getToday,
  isoToDate,
  dayjsDate,
};
