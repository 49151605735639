import { useMemo } from 'react';

import { FILTERS_ENUM, SORTING_ORDER_TYPES } from 'constants/filters';

import useSearchParams from 'hooks/useSearchParams';

import { getCurrentPage, getPaginationOffsetByPage } from 'utils/functionsForPagination';

export const useTableParams = ({ params = [], initial = {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = getCurrentPage(searchParams.get(FILTERS_ENUM.page));
  const orderBy = searchParams.get(FILTERS_ENUM.orderBy) ?? SORTING_ORDER_TYPES.desc;
  const sortBy = searchParams.get(FILTERS_ENUM.sortBy) ?? initial.sortBy;

  const queryParams = useMemo(() => {
    const keys = params.reduce((acc, curr) => {
      acc[curr] = searchParams.get(curr);
      return acc;
    }, {});

    const pagination = { ...initial, ...keys, sortBy, orderBy, page };

    return getPaginationOffsetByPage(pagination);
  }, [initial, orderBy, page, params, searchParams, sortBy]);

  return [queryParams, setSearchParams];
};
