import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import produce from 'immer';

// UIKit
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Components
import { PreviewComponent } from './preview/PreviewComponent';

// Styles
import styles from './FilesManagement.module.scss';

// Utils
import { getFileExtensionByFileName, getFileFromEvent } from 'utils/functionsForFiles';
import { bytesToMB } from 'utils/functionsForForm';

/**
 * Component for working with files
 *
 * @param {string}    id                  Id for label
 * @param {array}     files               Available files
 * @param {function}  onChange            Callback for change file
 * @param {boolean}   isError             Has error
 * @param {string}    errorMessage        Message for showed error
 * @param {string}    accept              Types of Files for upload
 * @param {number}    maxFiles            Maximum number of files to upload
 * @param {number}    maxFileSize         Maximum size of each file (MB)
 * @param {object}    service             API service for working with server
 * @param {string}    buttonLabel         Text of button to upload files
 * @param {object}    additionalHeaders   Custom header for file type
 * @param {boolean}   isDisabled          Is disabled
 */
export const FilesManagement = ({
  id,
  files,
  onChange,
  isError = false,
  helperText,
  accept = 'image/svg, image/jpg, image/jpeg, image/png',
  maxFiles = 10,
  maxFileSize = 200,
  style,
  service,
  buttonLabel = 'Upload file',
  additionalHeaders,
  isDisabled = false,
  canRemove = true,
  validate = true,
  onDelete,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const filesCount = files.length;

  const acceptFileArray = accept.split(', ');

  // const hasFiles = Boolean(filesCount);

  const isMaxedFiles = maxFiles <= filesCount;

  const resetErrorText = useCallback(() => setErrorText(''), []);

  const removeFileFromServer = useCallback(
    async (file) => service.deleteFile(file).catch((error) => console.error(error)),
    [service],
  );

  const removeFile = useCallback(
    (file, fileIndex) => async () => {
      setLoading(true);
      await removeFileFromServer(file);

      const newFiles = produce(files, (draft) => {
        draft.splice(fileIndex, 1);
      });

      onChange(newFiles);
      resetErrorText();
      setLoading(false);
    },
    [files, onChange, removeFileFromServer, resetErrorText],
  );

  const updateFile = useCallback(
    (filePath, fileIndex) => (event) => {
      const file = getFileFromEvent(event);

      if (!file) return;

      if (validate) {
        if (!acceptFileArray.includes(file.type)) {
          return setErrorText(`The file should has one of the next extensions: ${accept}`);
        }

        if (bytesToMB(file.size) > maxFileSize) {
          return setErrorText(`The file should be not more than ${maxFileSize}mb`);
        }
      }

      setLoading(true);

      const fileType = mapFileTypeToRequestHeader(file, additionalHeaders);

      removeFileFromServer(filePath)
        .then(() => service.addNewFile(file, fileType))
        .then((newFile) => {
          const newFiles = produce(files, (draft) => {
            draft[fileIndex] = newFile.fileUrl;
          });

          onChange(newFiles);
          resetErrorText();
        })
        .catch((error) => setErrorText(error.message))
        .finally(() => setLoading(false));
    },
    [
      accept,
      acceptFileArray,
      additionalHeaders,
      files,
      maxFileSize,
      onChange,
      removeFileFromServer,
      resetErrorText,
      service,
      validate,
    ],
  );

  const addNewFile = useCallback(
    (event) => {
      const file = getFileFromEvent(event);
      if (!file) return;

      if (validate) {
        if (!acceptFileArray.includes(file.type)) {
          return setErrorText(`The file should has one of the next extensions: ${accept}`);
        }

        if (bytesToMB(file.size) > maxFileSize) {
          return setErrorText(`The file should be not more than ${maxFileSize}mb`);
        }
      }

      setLoading(true);

      const fileType = mapFileTypeToRequestHeader(file, additionalHeaders);

      service
        .addNewFile(file, fileType)
        .then((newFile) => {
          onChange(
            produce(files, (draft) => {
              draft.push(newFile.fileUrl);
            }),
          );
          resetErrorText();
        })
        .catch((error) => setErrorText(error.message))
        .finally(() => setLoading(false));
    },
    [
      accept,
      acceptFileArray,
      additionalHeaders,
      files,
      maxFileSize,
      onChange,
      resetErrorText,
      service,
      validate,
    ],
  );

  return (
    <div className={styles.root} style={style}>
      {!isMaxedFiles && !isDisabled && (
        <div className={styles.fileButton}>
          <input
            className={styles.input}
            accept={accept}
            id={id}
            onChange={addNewFile}
            type="file"
          />
          <label className={cn(styles.label, styles.label_main)} htmlFor={isLoading ? '' : id}>
            <span className={cn('btn btn-primary', styles.button, { disabled: isLoading })}>
              {buttonLabel}
              {isLoading ? (
                <CircularProgress size={16} color="secondary" className={styles.buttonIcon} />
              ) : (
                <CloudUploadIcon className={styles.buttonIcon} />
              )}
            </span>
          </label>
        </div>
      )}

      <div className={styles.fileList}>
        {files.map((file, index) => {
          const updateLocalFile = updateFile(file, index);
          // const removeLocalFile = removeFile(file, index);

          return (
            <PreviewComponent
              className={styles.fileItem}
              key={index}
              id={`${id}-${index}`}
              file={file}
              accept={accept}
              onChange={updateLocalFile}
              removeFile={onDelete}
              canRemove={canRemove}
              isDisabled={isDisabled || isLoading}
            />
          );
        })}
      </div>

      {errorText && (
        <FormHelperText error={true} variant="outlined">
          Error message: {errorText}
        </FormHelperText>
      )}

      {helperText && (
        <FormHelperText error={isError} variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

function mapFileTypeToRequestHeader(file, acceptFiles) {
  if (!acceptFiles) return;

  const type = getFileExtensionByFileName(file.name);

  if (acceptFiles[type]) return acceptFiles[type];
}
