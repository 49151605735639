import actionsTypes from './types';
import { getNotificationTypes } from 'services/notifications.service';
import { notificationsSelector } from './selectors';

export default {
  aGetDictionaryOfNotificationTypes,
  aGetDictionaryOfNotificationTypesIfNotLoaded,
};

export function aGetDictionaryOfNotificationTypesIfNotLoaded() {
  return (dispatch, getStore) => {
    const store = getStore();
    const { isNotificationTypesLoaded, isNotificationTypesLoading } = notificationsSelector(store);

    if (!isNotificationTypesLoaded && !isNotificationTypesLoading) {
      return dispatch(aGetDictionaryOfNotificationTypes());
    }
  };
}

export function aGetDictionaryOfNotificationTypes() {
  return (dispatch) => {
    dispatch(request());

    return getNotificationTypes().then(
      (response) => {
        dispatch(success(response));
        return Promise.resolve();
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_REQUEST };
  }

  function success(data) {
    return { type: actionsTypes.GET_SUCCESS, data };
  }

  function failure() {
    return { type: actionsTypes.GET_FAILURE };
  }
}
