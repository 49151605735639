import { createSelector } from 'reselect';

export const promotionsListSelector = (store) => store.promotions.promotionsList;

export const promotionsListItemsSelector = (store) => store.promotions.promotionsList.data.items;

export const promotionsListAllCountSelector = (store) => store.promotions.promotionsList.data.count;

export const promotionsListLoadedCountSelector = createSelector(
  promotionsListItemsSelector,
  (items) => items.length,
);

export const promotionItemSelector = (store) => store.promotions.promotionItem;
