import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { FormControl } from 'components/base/FormControl/FormControl';
import { CHANGE_PASSWORD_SCHEMA } from 'features/Partners/utils/validations';
import { PARTNER_MODEL } from 'features/Partners/utils/models';

export const ModalUpdatePassword = ({ onAccept, onDismiss }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      [PARTNER_MODEL.password]: '',
    },
    resolver: yupResolver(CHANGE_PASSWORD_SCHEMA),
  });

  const handleAccept = ({ password }) => {
    onAccept({ newPassword: password });
  };

  return (
    <Modal
      show={true}
      handleClose={onDismiss}
      name="Confirm update password"
      renderFooter={
        <Fragment>
          <button
            className="btn btn-primary"
            disabled={!isDirty}
            onClick={handleSubmit(handleAccept)}
          >
            Yes
          </button>
          <button className="btn btn-secondary" onClick={onDismiss}>
            No
          </button>
        </Fragment>
      }
    >
      <div style={{ paddingBottom: '1em' }}>
        Do you really want to update this partner's password? <br />
        Please be aware that the partner's password will be replaced with a new one
      </div>

      <FormControl
        className="partner-details__form-item-input"
        placeholder="New password"
        name={PARTNER_MODEL.password}
        control={control}
        type="password"
      />
    </Modal>
  );
};
