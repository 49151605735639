import React, { cloneElement, useState } from 'react';

// UIKit
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const DropdownMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClick = (event) => setAnchorEl(event.currentTarget);

  const onClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {React.Children.map(children, (child) => cloneElement(child, { onClose }))}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
