import React from 'react';

// Components
import { ErrorLayout } from 'components/layouts/ErrorLayout/ErrorLayout';

import { ROUTES } from 'constants/routes.constants';

export const NotFoundPage = () => {
  return (
    <ErrorLayout
      to={ROUTES.root}
      title="Sorry page not found!"
      caption="It looks like that page doesn't exist - please check the URL and try again!"
    />
  );
};
