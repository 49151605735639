import React from 'react';
import cn from 'classnames';

// Components
import { Loader } from 'components/base/Loader/Loader';

import './style.css';

export const Modal = ({
  show,
  renderFooter,
  children,
  name,
  handleClose,
  width,
  height,
  top,
  left,
  isLoading = false,
}) => (
  <div
    className={cn('modal', {
      'display-block': show,
      'display-none': !show,
    })}
  >
    <section
      className="modal-main rounded-left rounded-right"
      style={{
        width: width,
        height: height,
        top: top,
        left: left,
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title">{name}</h5>
        <button type="button" className="close" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        {isLoading && <Loader withBackdrop />}

        {children}
      </div>

      {renderFooter && <div className="modal-footer">{renderFooter}</div>}
    </section>
  </div>
);

export default Modal;
