import { getBearerToken } from './auth';
import { cancelSource } from './axiosCancelSource';

export const globalRequestInterceptor = async (request) => {
  const token = getBearerToken();

  request.cancelToken = cancelSource.token;

  if (token && !request.headers?.Authorization) {
    request.headers = {
      ...request.headers,
      Authorization: token,
    };
  }

  return request;
};

export const globalResponseInterceptor = async (response) => {
  if (response.headers['content-type'] === 'application/json; charset=utf-8') {
    return response.data;
  }

  return response;
};
