import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

// UIKit
import FormHelperText from '@material-ui/core/FormHelperText';

// Icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// Components
import { PollItem } from './components/PollItem';

// Utilities
import { singleAnswerOption } from 'features/Charity/Polls/utils/form';

// Models
import {
  POLL_ANSWER_OPTION_MODEL,
  POLL_ANSWER_OPTION_ENUM,
} from 'features/Charity/Polls/utils/models';

// Styles
import './PollsManagement.styles.css';

const rootClassName = 'PollsManagement';

const openOptionLabels = {
  option: `Other`,
  description: `Description for Other`,
};

export const PollsManagement = ({
  name,
  control,
  helperText,
  style,
  minPolls = 2,
  buttonLabel = 'Add new polling field',
  pollOption = singleAnswerOption,
  pollModel = POLL_ANSWER_OPTION_MODEL,
  pollTypes = POLL_ANSWER_OPTION_ENUM,
  disabledFields = [],
  inputSize = 'small',
}) => {
  const { fields, insert, remove } = useFieldArray({
    control,
    name,
  });
  const pollsCount = fields.length;

  const addPollItem = useCallback(
    () => insert(pollsCount - 1, pollOption),
    [insert, pollOption, pollsCount],
  );

  const removePollItem = useCallback((index) => () => remove(index), [remove]);

  return (
    <div className={rootClassName} style={style}>
      {fields && (
        <ul className={`${rootClassName}__list`}>
          {fields.map((field, index) => {
            const serialNumber = index + 1;

            const names = {
              option: `${name}.${index}.${pollModel.value}`,
              description: `${name}.${index}.${pollModel.description}`,
            };

            const labels = {
              option: `Option for #${serialNumber}`,
              description: `Description for #${serialNumber}`,
            };

            const isOpenItem = field[pollModel.type] === pollTypes.open;

            const itemDisabledFields = disabledFields.find(
              (disabledField) => disabledField._id === field[pollModel._id],
            );

            const isRemovableOption = !isOpenItem && pollsCount > minPolls && !itemDisabledFields;

            return (
              <li key={field.id} className={`${rootClassName}__row`}>
                <PollItem
                  rootClassName={rootClassName}
                  control={control}
                  names={names}
                  labels={isOpenItem ? openOptionLabels : labels}
                  disabledFields={itemDisabledFields}
                  canRemove={isRemovableOption}
                  onRemove={removePollItem(index)}
                  inputSize={inputSize}
                />
              </li>
            );
          })}
        </ul>
      )}

      <button
        type="button"
        className={`btn btn-primary ${rootClassName}__button`}
        onClick={addPollItem}
      >
        {buttonLabel}
        <AddCircleOutlineIcon className={`${rootClassName}__buttonIcon`} />
      </button>

      {helperText && <FormHelperText variant="outlined">{helperText}</FormHelperText>}
    </div>
  );
};

PollsManagement.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  helperText: PropTypes.string,
  minPolls: PropTypes.number,
  buttonLabel: PropTypes.string,
  pollOption: PropTypes.shape(singleAnswerOption),
  pollModel: PropTypes.shape(POLL_ANSWER_OPTION_MODEL),
  pollTypes: PropTypes.shape(POLL_ANSWER_OPTION_ENUM),
  disabledFields: PropTypes.array,
};

export default PollsManagement;
