import { DEFAULT_CITY, DEFAULT_COUNTRY } from 'constants/countries.constants';
import { object, string, date, array } from 'yup';

import { EVENT_FORM_MODEL } from './models';

const defaultValues = {
  [EVENT_FORM_MODEL.name]: '',
  [EVENT_FORM_MODEL.description]: '',
  [EVENT_FORM_MODEL.images]: [],
  [EVENT_FORM_MODEL.date]: null,
  [EVENT_FORM_MODEL.country]: DEFAULT_COUNTRY,
  [EVENT_FORM_MODEL.city]: DEFAULT_CITY,
  [EVENT_FORM_MODEL.place]: null,
};

const validationSchema = object({
  [EVENT_FORM_MODEL.name]: string().label('Name').min(2).required(),
  [EVENT_FORM_MODEL.description]: string().label('Description').min(2).required(),
  [EVENT_FORM_MODEL.images]: array().label('Images').min(1).max(10).required(),
  [EVENT_FORM_MODEL.date]: date()
    .label('Date')
    .typeError(({ label }) => `${label} is a required field`)
    .default(() => new Date()),
  [EVENT_FORM_MODEL.city]: string()
    .label('City')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
  [EVENT_FORM_MODEL.country]: string()
    .label('Country')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
  [EVENT_FORM_MODEL.place]: string()
    .label('Place')
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
}).required();

export { defaultValues, validationSchema };
