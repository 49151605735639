import produce from 'immer';

import { userActionsTypes } from 'constants/user.constants';

const initialState = {
  isUserLoading: true,
  isUserDeleting: false,
  data: {},
  error: null,
};

export const user = produce((draft = initialState, action) => {
  switch (action.type) {
    case userActionsTypes.GET_USER_BY_ID_REQUEST: {
      draft.isUserLoading = true;

      return draft;
    }

    case userActionsTypes.GET_USER_BY_ID_SUCCESS: {
      draft.isUserLoading = false;
      draft.data = action.data;

      return draft;
    }

    case userActionsTypes.GET_USER_BY_ID_FAILURE: {
      draft.isUserLoading = false;
      draft.error = action.error;

      return draft;
    }

    case userActionsTypes.UPDATE_USER_REQUEST: {
      draft.isUserLoading = true;

      return draft;
    }

    case userActionsTypes.UPDATE_USER_SUCCESS: {
      draft.isUserLoading = false;
      draft.data = action.data;

      return draft;
    }

    case userActionsTypes.UPDATE_USER_FAILURE: {
      draft.isUserLoading = false;
      draft.error = action.error;

      return draft;
    }

    case userActionsTypes.DELETE_USER_BY_ID_REQUEST: {
      draft.isUserDeleting = true;

      return draft;
    }

    case userActionsTypes.DELETE_USER_BY_ID_FAILURE: {
      draft.isUserDeleting = false;
      draft.error = action.error;

      return draft;
    }

    case userActionsTypes.RESET_USER_STATE: {
      draft = initialState;

      return draft;
    }

    default: {
      return draft;
    }
  }
});
