const baseEndpointUrlConfig = {
  production: {
    _domain: 'https://backend-prod.allerretour.ch',
    _baseURL: 'https://backend-prod.allerretour.ch/api/v1',
  },
  development: {
    _domain: 'https://aller-retour-backend.lampawork.com',
    _baseURL: 'https://aller-retour-backend.lampawork.com/api/v1',
  },
  preprod: {
    _domain: 'https://preprod.aller-retour-backend.lampawork.com',
    _baseURL: 'https://preprod.aller-retour-backend.lampawork.com/api/v1',
  },
};

const getBaseEndpointUrl = (type) => {
  const environment = process.env.REACT_APP_NODE_ENV;
  const baseEndpointUrl = baseEndpointUrlConfig[environment];
  return baseEndpointUrl[type];
};

export const serverConfig = {
  _domain: getBaseEndpointUrl('_domain'),
  _baseURL: getBaseEndpointUrl('_baseURL'),
};
