import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { categoriesActions } from 'actions/categories.actions';

import { Modal } from 'components/base/Modal/Modal';

import { Checkbox } from '@material-ui/core';

import './style.css';

const CreateCategoryModal = ({ state, setState, page }) => {
  const [data, setData] = useState({
    name: '',
    logo: null,
    oneActivePromotions: false,
    applyForAllTransactionInPromotion: false,
  });
  const dispatch = useDispatch();

  const handleAddCategory = () => {
    dispatch(categoriesActions.create({ category: data, page }));
    setState({ status: false, action: '', body: {} });
  };

  const onClose = useCallback(() => setState({ status: false, action: '', body: {} }), [setState]);

  return (
    <Modal show={state.status} handleClose={onClose} name={'Create a new category'}>
      <form className="create-category__form">
        <div className="create-category__form-item">
          <label htmlFor="name" className="create-category__form-item-label">
            Category name:
          </label>
          <input
            type="text"
            className="create-category__form-item-input"
            name="name"
            onChange={({ target: { value } }) => setData({ ...data, name: value })}
            value={data.name}
          />
        </div>
        <div className="create-category__form-item">
          <label htmlFor="logo" className="create-category__form-item-label">
            Avatar:
          </label>
          <input
            type="file"
            className="create-category__form-item-input"
            name="file"
            onChange={({ target: { files } }) => setData({ ...data, logo: files[0] })}
          />
        </div>
        <div className="create-category__form-item">
          <label htmlFor="oneActivePromotions" className="create-category__form-item-label">
            Partner can have only 1 active promotion at the same time:
          </label>
          <div>
            <Checkbox
              name="oneActivePromotions"
              color="default"
              checked={data.oneActivePromotions}
              onChange={(_, value) => setData({ ...data, oneActivePromotions: value })}
            />
          </div>
        </div>
        <div className="create-category__form-item">
          <label htmlFor="logo" className="create-category__form-item-label">
            Partners will apply promotion cashback percent to all transactions they make:
          </label>
          <div>
            <Checkbox
              name="applyForAllTransactionInPromotion"
              color="default"
              checked={data.applyForAllTransactionInPromotion}
              onChange={(_, value) =>
                setData({ ...data, applyForAllTransactionInPromotion: value })
              }
            />
          </div>
        </div>
      </form>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={handleAddCategory}>
          Save
        </button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default CreateCategoryModal;
