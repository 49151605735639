export const authConstants = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',

  LOGOUT: 'LOGOUT',
};

export const AUTH_POST_MESSAGES = {
  forbidden: 'Forbidden',
  notAuthorized: 'NotAuthorized',
};
