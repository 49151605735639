import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

import { App } from './components/App/App';
import { store } from './store/redux/store';
import { queryClient } from 'helpers/queryClient';

import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import { ErrorBoundary } from 'components/layouts/ErrorBoundary/ErrorBoundary';

const theme = unstable_createMuiStrictModeTheme();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
