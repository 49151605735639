import React, { forwardRef } from 'react';

// UIKit
import MenuItem from '@material-ui/core/MenuItem';

const DropdownMenuItem = forwardRef(({ isDisabled, children, onClick, onClose }, ref) => {
  const handleClick = () => {
    onClick && onClick();
    onClose && onClose();
  };

  return (
    <MenuItem disabled={isDisabled} onClick={handleClick} ref={ref}>
      {children}
    </MenuItem>
  );
});

export default DropdownMenuItem;
