import { EMPTY_OBJECT } from 'constants/empties';
import { createSelector } from 'reselect';

const rootSelector = (store) => store.dictionaries.promotions;

const selectDictionaryType = (_, dictionaryType) => dictionaryType;

const dictionaryOfPromotionsSelector = createSelector(
  rootSelector,
  selectDictionaryType,
  (promotions, dictionaryType) => {
    return promotions[dictionaryType] || EMPTY_OBJECT;
  },
);

export { rootSelector, dictionaryOfPromotionsSelector };
