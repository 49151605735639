import React from 'react';
import { Controller } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';

// Models
import { FAQ_MODEL } from '../utils/models';

export const FormFaq = ({ control }) => (
  <form noValidate style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
    <Controller
      name={FAQ_MODEL.title}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          size="small"
          label="Question"
          variant="outlined"
          fullWidth={true}
          style={{ marginBottom: '0.5rem' }}
          error={fieldState.invalid}
          helperText={fieldState.error && fieldState.error.message}
        />
      )}
    />

    <Controller
      name={FAQ_MODEL.subject}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          size="small"
          label="Answer"
          variant="outlined"
          fullWidth={true}
          multiline={true}
          minRows={4}
          style={{ marginTop: '0.5rem' }}
          error={fieldState.invalid}
          helperText={fieldState.error && fieldState.error.message}
        />
      )}
    />
  </form>
);
