import React, { useCallback, useEffect, useRef, useState } from 'react';

// Services
import { getAllAdminPartners } from 'services/partner.service';

// Components
import FormSelect from 'components/base/FormSelect/FormSelect';

// Models
import { PARTNER_MODEL } from 'features/Partners/utils/models';

const PartnersSelect = (props) => {
  const cachedDataRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({
    isLoading: false,
    options: [],
    error: null,
  });

  const showLoader = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      error: null,
    }));
  }, []);

  const onRequestResolve = useCallback((partners) => {
    setState({
      isLoading: false,
      options: partners.items,
      error: null,
    });
    cachedDataRef.current = partners.items;
  }, []);

  const onRequestReject = useCallback((error) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      error,
    }));

    cachedDataRef.current = null;
  }, []);

  const getPromotions = useCallback(() => {
    if (cachedDataRef.current) return;

    showLoader();

    getAllAdminPartners().then(onRequestResolve).catch(onRequestReject);
  }, [onRequestReject, onRequestResolve, showLoader]);

  useEffect(getPromotions, [getPromotions]);

  return (
    <FormSelect
      placeholder="Partner"
      inputName={PARTNER_MODEL.name}
      inputValue={PARTNER_MODEL.id}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...props}
      options={state.options}
      isLoading={state.isLoading}
      isError={Boolean(state.error)}
      helperText={state.error?.message}
    />
  );
};

export default PartnersSelect;
