import produce from 'immer';

import { INITIAL_FILTERS_STATE } from 'constants/filters';

import actionsTypes, { WINNER_TYPES } from './types';
import { GIVEAWAY_MODEL } from '../utils/models';

const initialState = {
  giveawaysList: {
    isGiveawaysLoading: true,
    isGiveawayCreating: false,
    isGiveawayDeleting: false,
    filters: {
      ...INITIAL_FILTERS_STATE,
      sortBy: GIVEAWAY_MODEL.finishAt,
    },
    data: [],
    meta: {
      count: 0,
    },
    error: null,
  },

  giveawayItem: {
    isGiveawayLoading: true,
    isGiveawayUpdating: false,
    data: {},
    error: null,
  },
};

export const giveaways = produce((draft = initialState, action) => {
  switch (action.type) {
    // -------------------------------------------------------- GET GIVEAWAYS start
    case actionsTypes.GET_GIVEAWAYS_REQUEST: {
      draft.giveawaysList.isGiveawaysLoading = true;
      draft.giveawaysList.error = null;

      return draft;
    }
    case actionsTypes.GET_GIVEAWAYS_SUCCESS: {
      draft.giveawaysList.isGiveawaysLoading = false;
      draft.giveawaysList.meta.count = action.data.count;
      draft.giveawaysList.data = action.data.rows;

      return draft;
    }
    case actionsTypes.GET_GIVEAWAYS_FAILURE: {
      draft.giveawaysList.isGiveawaysLoading = false;
      draft.giveawaysList.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- GET GIVEAWAYS end

    // -------------------------------------------------------- CREATE GIVEAWAY start
    case actionsTypes.CREATE_GIVEAWAY_REQUEST: {
      draft.giveawaysList.isGiveawayCreating = true;

      return draft;
    }
    case actionsTypes.CREATE_GIVEAWAY_SUCCESS: {
      draft.giveawaysList.isGiveawayCreating = false;
      draft.giveawaysList.meta.count = draft.giveawaysList.meta.count + 1;
      draft.giveawaysList.data.unshift(action.newGiveaway);

      return draft;
    }
    case actionsTypes.CREATE_GIVEAWAY_FAILURE: {
      draft.giveawaysList.isGiveawayCreating = false;

      return draft;
    }
    // -------------------------------------------------------- CREATE GIVEAWAY end

    // -------------------------------------------------------- DELETE GIVEAWAY start
    case actionsTypes.DELETE_GIVEAWAY_REQUEST: {
      draft.giveawaysList.isGiveawayDeleting = true;

      return draft;
    }
    case actionsTypes.DELETE_GIVEAWAY_SUCCESS: {
      const index = draft.giveawaysList.data.findIndex(({ _id }) => _id === action.id);

      if (index !== -1) {
        draft.giveawaysList.data.splice(index, 1);
      }

      draft.giveawaysList.isGiveawayDeleting = false;
      draft.giveawaysList.meta.count = draft.giveawaysList.meta.count - 1;

      return draft;
    }
    case actionsTypes.DELETE_GIVEAWAY_FAILURE: {
      draft.giveawaysList.isGiveawayDeleting = false;

      return draft;
    }
    // -------------------------------------------------------- DELETE GIVEAWAY end

    // -------------------------------------------------------- GET GIVEAWAY start
    case actionsTypes.GET_GIVEAWAY_REQUEST: {
      draft.giveawayItem.isGiveawayLoading = true;

      return draft;
    }
    case actionsTypes.GET_GIVEAWAY_SUCCESS: {
      draft.giveawayItem = {
        isGiveawayLoading: false,
        data: action.data,
      };

      return draft;
    }
    case actionsTypes.GET_GIVEAWAY_FAILURE: {
      draft.giveawayItem.isGiveawayLoading = false;
      draft.giveawayItem.isGiveawayUpdating = false;
      draft.giveawayItem.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- GET GIVEAWAY end

    // -------------------------------------------------------- RESET GIVEAWAY start
    case actionsTypes.RESET_GIVEAWAY_SUCCESS: {
      draft.giveawayItem = initialState.giveawayItem;

      return draft;
    }
    // -------------------------------------------------------- RESET GIVEAWAY end

    // -------------------------------------------------------- UPDATE GIVEAWAY start
    case actionsTypes.UPDATE_GIVEAWAY_REQUEST: {
      draft.giveawayItem.isGiveawayUpdating = true;

      return draft;
    }
    case actionsTypes.UPDATE_GIVEAWAY_SUCCESS: {
      draft.giveawayItem.isGiveawayUpdating = false;
      draft.giveawayItem.data = action.data;

      return draft;
    }
    // -------------------------------------------------------- UPDATE GIVEAWAY end

    // -------------------------------------------------------- CHANGE FILTERS start
    case actionsTypes.CHANGE_GIVEAWAY_FILTER: {
      draft.giveawaysList.filters = { ...draft.giveawaysList.filters, ...action.filters };

      return draft;
    }
    // -------------------------------------------------------- CHANGE FILTERS end

    default: {
      return draft;
    }
  }
});

export const winnerInitialState = {
  isWinnerLoading: false,
  winner: {},
  winnerError: null,
};

export const winnerReducer = produce((draft = winnerInitialState, action) => {
  switch (action.type) {
    case WINNER_TYPES.GET_WINNER_REQUEST: {
      draft.isWinnerLoading = true;

      return draft;
    }
    case WINNER_TYPES.GET_WINNER_SUCCESS: {
      draft.isWinnerLoading = false;
      draft.winner = action.winner;

      return draft;
    }
    case WINNER_TYPES.GET_WINNER_FAILURE: {
      draft.isWinnerLoading = false;
      draft.error = action.error;

      return draft;
    }

    default: {
      return draft;
    }
  }
});
