import React, { Fragment } from 'react';

import InfoIcon from '@material-ui/icons/Info';
import { IconButton, Popper } from '@material-ui/core';
import { usePopperState } from 'common/hooks/usePopperState';

export const IconPopover = ({ label, children }) => {
  const [isPopperOpen, popperRef, onPopperToggle, popperClasses] = usePopperState();

  return (
    <Fragment>
      {label}
      <IconButton size="small" className={popperClasses.trigger} onClick={onPopperToggle}>
        <InfoIcon fontSize="small" />
      </IconButton>
      <Popper id="popover" open={isPopperOpen} anchorEl={popperRef}>
        <div className={popperClasses.paper}>{children}</div>
      </Popper>
    </Fragment>
  );
};
