export const mapPromotionToRequest = (promotion) => {
  const { localizations, published, ...otherItems } = promotion;

  const toLocalizeTitle = (localization) => ({
    locale: localization.locale,
    value: localization.title,
  });

  const toLocalizedDescriptions = (localization) => ({
    locale: localization.locale,
    value: localization.description,
  });

  if (Array.isArray(localizations)) {
    return {
      ...otherItems,
      localizeTitle: localizations.map(toLocalizeTitle),
      localizedDescriptions: localizations.map(toLocalizedDescriptions),
    };
  }

  return otherItems;
};

export const mapResponseToPromotion = (response) => {
  const {
    title,
    description,
    clientPercent,
    partnerId,
    // locationsV2,
    startDate,
    endDate,
    publicationDate,
    applyForAll,
    mediaFiles,
    status,
    localizeTitle,
    localizedDescriptions,
    published,
    locations,
  } = response;

  const getLocalization = (title) => {
    const currentDescriptions = localizedDescriptions.find(
      (description) => description.locale === title.locale,
    );

    return {
      locale: title?.locale,
      title: title?.value,
      description: currentDescriptions?.value,
    };
  };

  const normalizeLocations = (locations) => {
    return locations.map(
      (item) => ({ location: item._id }),
    );
  };

  const result = {
    title,
    description,
    clientPercent,
    partnerId,
    startDate,
    endDate,
    publicationDate,
    applyForAll,
    mediaFiles,
    status,
    locations: normalizeLocations(locations),
    localizations: localizeTitle.map(getLocalization),
  };

  if (published) {
    result.published = mapResponseToPromotion(published);
  }

  return result;
};
