import React from 'react';
import { Controller } from 'react-hook-form';

// UIKit
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

// Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export const PollItem = ({
  rootClassName,
  control,
  names,
  labels,
  disabledFields = {},
  onRemove,
  canRemove = true,
  inputSize = 'small',
}) => {
  return (
    <div className={`${rootClassName}__item`}>
      <div className={`${rootClassName}__col`}>
        <Controller
          name={names.option}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label={labels.option}
              variant="outlined"
              error={fieldState.invalid}
              size={inputSize}
              helperText={fieldState.error && fieldState.error.message}
              disabled={disabledFields.option}
            />
          )}
        />
      </div>
      <div className={`${rootClassName}__col`}>
        <Controller
          name={names.description}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label={labels.description}
              variant="outlined"
              size={inputSize}
              error={fieldState.invalid}
              helperText={fieldState.error && fieldState.error.message}
              disabled={disabledFields.description}
            />
          )}
        />
      </div>
      <IconButton disabled={!canRemove} onClick={onRemove} size={inputSize}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </div>
  );
};
