import actionsTypes from './types';

export const alertActions = {
  success,
  error,
  clear,
};

function success(message) {
  return { type: actionsTypes.SUCCESS, message };
}

function error(message = 'Something went wrong. Please try again later!') {
  return { type: actionsTypes.ERROR, message };
}

function clear() {
  return { type: actionsTypes.CLEAR };
}
