import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';

// UIKit
import { TextField, Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import { FormControl } from 'components/base/FormControl/FormControl';
import { ImageUploaderWithPreview } from 'components/base/ImageUploaderWithPreview/ImageUploaderWithPreview';
import { ListManager } from 'components/base/ListManager/ListManager';
import { DescriptionPreview } from '../DescriptionPreview/DescriptionPreview';
import { ModalConfirmAction } from '../modals/ModalConfirmAction';
import { CategoriesSelect, CountriesSelect, LanguagesSelect } from 'components/base/Selects';

// Store
import {
  languagesWithoutEnglishLengthSelector,
  languagesWithoutEnglishSelector,
} from 'features/Dictionaries/Languages/store/selectors';

// Models
import { PARTNER_MODEL } from '../../utils/models';

// Constants
import { defaultValues, newItemModel } from '../PartnerDetails/PartnerDetails.constants';

// Utilities
import { getDirtyValues } from 'utils/functionsForForm';
import { editValidationSchema } from '../../utils/validations';

// Hooks
import useModal from 'hooks/useModal';
import useDisabledLanguages from 'hooks/useDisabledLanguages';
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

const inputSize = 'small';

const useStyles = makeStyles(() => ({
  checkboxRoot: {
    marginBottom: 0,
    marginLeft: 0,
  },
  modalText: {
    display: 'block',
    marginBottom: '1rem',
  },
  modalLabel: {
    marginRight: '1rem',
  },
}));

const percentFieldsNames = [
  PARTNER_MODEL.percent,
  PARTNER_MODEL.adminPercent,
  PARTNER_MODEL.clientPercent,
];

export const DetailsForm = ({
  initialValues,
  onShowModalConfirmDelete,
  onShowModalPasswordUpdate,
  goToTransactionPage,
  onPartnerUpdate,
}) => {
  const classes = useStyles();

  const languagesWithoutEnglish = useSelector(languagesWithoutEnglishSelector);
  const maxLangItems = useSelector(languagesWithoutEnglishLengthSelector);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(editValidationSchema),
  });

  const watchWorksOnline = watch(PARTNER_MODEL.worksOnline);

  const disabledLanguages = useDisabledLanguages(watch, PARTNER_MODEL.localizedDescriptions);

  const watchPercents = watch(percentFieldsNames);

  const handlePartnerUpdate = useCallback(
    (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, formValues);
      onPartnerUpdate(changedValues);
    },
    [dirtyFields, onPartnerUpdate],
  );

  // Validate percents
  useEffect(() => {
    if (watchPercents.some((percent) => percent)) {
      trigger(percentFieldsNames);
    }
  }, [trigger, watchPercents]);

  // Get default values
  useEffect(() => {
    if (initialValues) {
      reset({ ...defaultValues, ...initialValues });
    }
  }, [initialValues, reset]);

  // Confirm toggle online
  const [modalConfirmActionIsOpen, onShowModalConfirmAction, onHideModalConfirmAction] = useModal();

  const toggleWorksOnline = useCallback(() => {
    setValue(PARTNER_MODEL.worksOnline, !watchWorksOnline, { shouldDirty: true });
    onHideModalConfirmAction();
  }, [onHideModalConfirmAction, setValue, watchWorksOnline]);

  useBlockerPrompt(isDirty);

  return (
    <form noValidate className="partnerForm">
      <div style={{ width: '100%' }} className="partnerForm__row">
        <Controller
          name={PARTNER_MODEL.avatar}
          control={control}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <ImageUploaderWithPreview
              src={value}
              onChange={onChange}
              isError={invalid}
              helperText={error}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Partner name:"
          name={PARTNER_MODEL.name}
          control={control}
          type="email"
          isRequired
        />
      </div>
      <div className="partnerForm__column">
        <CategoriesSelect control={control} name={PARTNER_MODEL.category} />
      </div>

      <div className="partnerForm__column">
        <CountriesSelect control={control} name={PARTNER_MODEL.country} isRequire={true} />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.worksOnline}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              classes={{ root: classes.checkboxRoot }}
              label="Works online"
              value={field.value}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  label="Works online"
                  checked={field.value}
                  onChange={onShowModalConfirmAction}
                />
              }
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Locations:"
          name={PARTNER_MODEL.locations}
          control={control}
          isDisabled={true}
        />
      </div>

      <div className="partnerForm__column" />

      <div className="partnerForm__column">
        <FormControl
          placeholder="Cashback percent:"
          name={PARTNER_MODEL.percent}
          control={control}
          type="email"
          isRequired
        />
      </div>

      <div className="partnerForm__column" />

      <div className="partnerForm__column">
        <FormControl
          placeholder="Client percent:"
          name={PARTNER_MODEL.clientPercent}
          control={control}
          type="email"
          isRequired
          helperText="Fields in the sum must be equal to 'Cashback percent' field value"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Admin percent:"
          name={PARTNER_MODEL.adminPercent}
          control={control}
          type="email"
          isRequired
          helperText="Fields in the sum must be equal to 'Cashback percent' field value"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          isRequired
          placeholder="Email for logging in to Partner app:"
          name={PARTNER_MODEL.email}
          control={control}
          type="email"
        />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.contactsOfPartner}
          control={control}
          render={({ field }) => (
            <NumberFormat
              value={field.value}
              onChange={field.onChange}
              prefix="+"
              decimalSeparator="."
              displayType="input"
              type="text"
              allowNegative={false}
              customInput={TextField}
              variant="outlined"
              size={inputSize}
              label="Phone for client app:"
              fullWidth={true}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Email for client app:"
          name={PARTNER_MODEL.emailForContact}
          control={control}
          type="email"
        />
      </div>

      <div className="partnerForm__column">
        <Controller
          name={PARTNER_MODEL.personalPhone}
          control={control}
          render={({ field }) => (
            <NumberFormat
              value={field.value}
              onChange={field.onChange}
              prefix="+"
              decimalSeparator="."
              displayType="input"
              type="tel"
              allowNegative={false}
              customInput={TextField}
              variant="outlined"
              size={inputSize}
              label="Personal phone:"
              fullWidth={true}
            />
          )}
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Personal email:"
          name={PARTNER_MODEL.personalEmail}
          control={control}
          type="email"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl placeholder="Website:" name={PARTNER_MODEL.website} control={control} />
      </div>

      <div className="partnerForm__column">
        <FormControl placeholder="Facebook:" name={PARTNER_MODEL.facebook} control={control} />
      </div>
      <div className="partnerForm__column">
        <FormControl placeholder="Instagram:" name={PARTNER_MODEL.instagram} control={control} />
      </div>

      <div className="partnerForm__column">
        <LanguagesSelect
          name={PARTNER_MODEL.localization}
          control={control}
          label="Language for emails:"
        />
      </div>

      <div className="partnerForm__column">
        <FormControl
          placeholder="Default description:"
          name={PARTNER_MODEL.description}
          control={control}
          isRequired={true}
          multiline={true}
        />
      </div>

      <ListManager
        className="partnerForm__row"
        name={PARTNER_MODEL.localizedDescriptions}
        control={control}
        newItemModel={newItemModel}
        maxItems={maxLangItems}
        buttonTitle="Add new description"
        PreviewComponent={
          <DescriptionPreview
            inputSize={inputSize}
            languages={languagesWithoutEnglish}
            disabledLanguages={disabledLanguages}
          />
        }
      />

      <Grid container>
        <Grid container item md={6} justifyContent="center" alignItems="center">
          <Grid item style={{ margin: '0.5rem' }}>
            <Button variant="contained" onClick={onShowModalPasswordUpdate}>
              Update Password
            </Button>
          </Grid>
        </Grid>
        <Grid container item md={6} justifyContent="center" alignItems="center">
          <Grid item style={{ margin: '0.5rem' }}>
            <Button variant="contained" onClick={goToTransactionPage}>
              Transactions
            </Button>
          </Grid>
          <Grid item style={{ margin: '0.5rem' }}>
            <Button variant="contained" color="secondary" onClick={onShowModalConfirmDelete}>
              Delete
            </Button>
          </Grid>
          <Grid item style={{ margin: '0.5rem' }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isDirty}
              onClick={handleSubmit(handlePartnerUpdate)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {modalConfirmActionIsOpen && (
        <ModalConfirmAction onAccept={toggleWorksOnline} onDismiss={onHideModalConfirmAction} />
      )}
    </form>
  );
};
