import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

// Selectors
import {
  languagesSelector,
  languagesWithoutEnglishSelector,
} from 'features/Dictionaries/Languages/store/selectors';

// Utils
import {
  getDisabledOption,
  getOptionForValue,
  getOptionLabel,
  getOptionSelected,
} from 'utils/functionsForAutocomplete';

import { LANGUAGE_MODEL } from 'features/Dictionaries/Languages/utils/models';

const inputSize = 'small';

export const LanguagesSelect = ({
  control,
  name,
  label,
  disabledLanguages = [],
  withoutLang = false,
  isDisabled = false,
}) => {
  const { isLoading, data, error: langError } = useSelector(languagesSelector);
  const languagesWithoutEnglish = useSelector(languagesWithoutEnglishSelector);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState: { invalid, error } }) => {
        const isError = invalid || Boolean(langError);
        const helperText = (invalid && error.message) || (langError?.message && error.message);

        return (
          <Autocomplete
            size={inputSize}
            disableClearable={true}
            options={withoutLang ? languagesWithoutEnglish : data}
            loading={isLoading}
            fullWidth={true}
            disabled={isDisabled}
            getOptionLabel={getOptionLabel(LANGUAGE_MODEL.title)}
            getOptionSelected={getOptionSelected(LANGUAGE_MODEL.title)}
            getOptionDisabled={getDisabledOption(disabledLanguages)}
            value={getOptionForValue({
              value: value,
              options: data,
              valueKey: LANGUAGE_MODEL.title,
              optionKey: LANGUAGE_MODEL.value,
            })}
            onChange={(_, newValue) => {
              onChange(newValue[LANGUAGE_MODEL.value]);
            }}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputRef={ref}
                label={label}
                error={isError}
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};
