import { saveAs } from 'file-saver';

const imagesFileExtensions = ['jpg', 'jpeg', 'png'];
const videosFileExtensions = ['mpg', 'mpeg', 'mp4', 'avi'];

export const fileTypes = Object.freeze({
  image: 'image',
  video: 'video',
  other: 'other',
});

/**
 * Download file from api response
 * @param {object} response   Api response object with data field
 * @param {string} filename   Name of file to saving
 */
function downloadBlobFileFromResponse({ data, headers }, filename = 'report.csv') {
  const file = new Blob([data], { type: headers['content-type'] });
  saveAs(file, filename);
}

function getFileExtensionByFileName(fileName) {
  const groups = fileName.match(/(\.[^.]*?)(#.*$|\?.*$|$)/) || [];
  if (groups.length >= 2) {
    return groups[1].split('.').pop();
  }

  return fileName;
}

function getFileTypeByFileName(fileName) {
  const fileExtension = getFileExtensionByFileName(fileName);

  if (imagesFileExtensions.includes(fileExtension)) {
    return fileTypes.image;
  }

  if (videosFileExtensions.includes(fileExtension)) {
    return fileTypes.video;
  }

  return fileTypes.other;
}

export function getFileFromEvent(event) {
  return event.target.files[0];
}

export default downloadBlobFileFromResponse;

export { getFileTypeByFileName, getFileExtensionByFileName };
