import React, { useMemo, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { FormMutate } from '../FormMutate/FormMutate';

// Utils
import { DEFAULT_VALUES, VALIDATION_SCHEMA } from '../../utils/form.utils';
import { getDirtyValues } from 'utils/functionsForForm';

// Hooks
import { useResetForm } from 'common/hooks/useResetForm';
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

export const ModalMutate = ({ isLoading, onAccept, onCancel, editableItem }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const handleAccept = useCallback(
    (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, formValues);

      onAccept(changedValues);
    },
    [dirtyFields, onAccept],
  );

  const renderFooter = useMemo(
    () => (
      <>
        <button
          className="btn btn-primary"
          disabled={!isDirty}
          onClick={handleSubmit(handleAccept)}
          type="button"
        >
          Update
        </button>
        <button className="btn btn-secondary" onClick={onCancel} type="button">
          Cancel
        </button>
      </>
    ),
    [handleAccept, handleSubmit, isDirty, onCancel],
  );

  useResetForm({ resetFunction: reset, initialValues: DEFAULT_VALUES, incomeValues: editableItem });

  useBlockerPrompt(isDirty);

  return (
    <Modal
      name="Edit version"
      show={true}
      isLoading={isLoading}
      renderFooter={renderFooter}
      handleClose={onCancel}
    >
      <FormMutate control={control} />
    </Modal>
  );
};
