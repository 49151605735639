import actionsTypes from './types';
import service from 'services/countries.service';
import { mapResponseToCountries } from './utils';
import { countriesSelector } from './selectors';

export default {
  aGetDictionaryOfCountries,
  aGetDictionaryOfCountriesIfNotLoaded,
};

export function aGetDictionaryOfCountriesIfNotLoaded() {
  return (dispatch, getStore) => {
    const store = getStore();
    const { isCountriesLoaded, isCountriesLoading } = countriesSelector(store);

    if (!isCountriesLoaded && !isCountriesLoading) {
      return dispatch(aGetDictionaryOfCountries());
    }
  };
}

export function aGetDictionaryOfCountries() {
  return (dispatch) => {
    dispatch(request());

    return service.getCountries().then(
      (data) => {
        dispatch(success(mapResponseToCountries(data)));
        return Promise.resolve(data);
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error);
      },
    );
  };

  function request() {
    return { type: actionsTypes.GET_REQUEST };
  }

  function success(data) {
    return { type: actionsTypes.GET_SUCCESS, data };
  }

  function failure() {
    return { type: actionsTypes.GET_FAILURE };
  }
}
