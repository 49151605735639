import React from 'react';

// UIKit
import IconButton from '@material-ui/core/IconButton';

// Components
import { FormControl } from 'components/base/FormControl/FormControl';

// Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Models
import {
  NOTIFICATION_LOCALIZATION_MODEL,
  NOTIFICATION_LOCALIZATION_VALUE_MODEL,
} from 'features/Notifications/utils/models';

import styles from './NewLocalization.module.scss';

import { LanguagesSelect } from 'components/base/Selects';

export const NewLocalization = ({
  idx,
  control,
  name,
  inputSize,
  disabledLanguages,
  canRemove = true,
  onRemove,
}) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.row}>
        <LanguagesSelect
          control={control}
          name={`${name}.${idx}.${NOTIFICATION_LOCALIZATION_MODEL.locale}`}
          size={inputSize}
          label="Localization:"
          disabledLanguages={disabledLanguages}
          withoutLang
        />
      </div>
      <div className={styles.row}>
        <FormControl
          placeholder="Title:"
          name={`${name}.${idx}.${NOTIFICATION_LOCALIZATION_MODEL.value}.${NOTIFICATION_LOCALIZATION_VALUE_MODEL.title}`}
          control={control}
        />
      </div>
      <div className={styles.row}>
        <FormControl
          placeholder="Message:"
          name={`${name}.${idx}.${NOTIFICATION_LOCALIZATION_MODEL.value}.${NOTIFICATION_LOCALIZATION_VALUE_MODEL.message}`}
          control={control}
          multiline
        />
      </div>
    </div>

    <IconButton disabled={!canRemove} onClick={onRemove}>
      <RemoveCircleOutlineIcon />
    </IconButton>
  </div>
);

export default NewLocalization;
