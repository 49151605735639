import React from 'react';
import { Chip } from '@material-ui/core';

import { PROMOTION_STATUS_ENUM } from './enums';
import { PROMOTION_MODEL } from './models';
import { TableStatus } from '../components/TableStatus/TableStatus';

export const MENU_OPTIONS = Object.freeze({
  approve: {
    name: 'approve',
    value: PROMOTION_STATUS_ENUM.approved,
  },
  reject: {
    name: 'reject',
    value: PROMOTION_STATUS_ENUM.rejected,
  },
  enable: {
    name: 'enable',
    value: PROMOTION_STATUS_ENUM.enabled,
  },
  disable: {
    name: 'disable',
    value: PROMOTION_STATUS_ENUM.disabled,
  },
});

export const MODAL_INITIAL_SETTINGS = {
  isShown: false,
  action: null,
  payload: null,
};

const statusesColors = {
  IN_DRAFT: '#DFDFDF',
  PENDING: '#FFF5D9',
  PUBLISHED: '#CBDFFB',
  REJECTED: '#FAC7CC',
  FINISHED: '#F8EFFF',
  APPROVED: '#D1F3DF',
  DISABLED: '#E2E2E2',
};

export const moderationColumnSettings = [
  { selector: PROMOTION_MODEL.title, name: 'Title' },
  {
    selector: PROMOTION_MODEL.status,
    name: <TableStatus />,
    cell: ({ status }) => {
      const bgColor = statusesColors[status] ?? statusesColors.APPROVED;

      return (
        <Chip
          style={{ backgroundColor: bgColor, color: '#333' }}
          size="small"
          variant="outlined"
          color="default"
          label={status}
        />
      );
    },
  },
];

export const publishedColumnSettings = [
  ...moderationColumnSettings,
  { selector: PROMOTION_MODEL.active, name: 'Active' },
];
