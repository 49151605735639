import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// UIKit
import Grid from '@material-ui/core/Grid';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { ProductPreview } from '../ProductPreview/ProductPreview';
import { ListManager } from 'components/base/ListManager/ListManager';
import { FormControl } from 'components/base/FormControl/FormControl';

// Utils
import { creationProductValidationSchema } from '../../utils/validations';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';
import useDisabledLanguages from 'hooks/useDisabledLanguages';

// Models
import { LOCALIZED_MODEL, PRODUCT_MODEL } from 'features/Partners/utils/models';

// Selectors
import {
  languagesLengthSelector,
  languagesSelector,
} from 'features/Dictionaries/Languages/store/selectors';

const newItemModel = {
  [LOCALIZED_MODEL.locale]: null,
  [LOCALIZED_MODEL.value]: '',
};

export const ModalNewProduct = ({ isLoading, defaults, isNewProduct, onDismiss, onAccept }) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      title: '',
      clientPercent: '',
      localization: [],
    },
    resolver: yupResolver(creationProductValidationSchema),
  });

  const { data: languages } = useSelector(languagesSelector);
  const languagesLength = useSelector(languagesLengthSelector);
  const maxLangItems = languagesLength - 1;

  const getDisabledLanguages = useDisabledLanguages(watch, PRODUCT_MODEL.localization);

  const disabledLanguages = ['EN', ...getDisabledLanguages];

  const submitForm = useCallback(
    (formValues) => {
      onAccept(formValues);
    },
    [onAccept],
  );

  useEffect(() => {
    if (defaults) {
      reset(mapResponseToValues(defaults));
    }
  }, [defaults, reset]);

  useBlockerPrompt(isDirty);

  return (
    <Modal
      handleClose={onDismiss}
      name={isNewProduct ? 'Add new product' : 'Edit product'}
      show={true}
      isLoading={isLoading}
      renderFooter={
        <Fragment>
          <button
            className="btn btn-primary"
            disabled={!isDirty || isLoading}
            onClick={handleSubmit(submitForm)}
            type="button"
          >
            {isNewProduct ? 'Add' : 'Update'}
          </button>
          <button className="btn btn-secondary" onClick={onDismiss} type="button">
            Cancel
          </button>
        </Fragment>
      }
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl
              name={PRODUCT_MODEL.title}
              placeholder="Name of the product [EN]"
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              name={PRODUCT_MODEL.adminPercent}
              placeholder="Admin percent"
              control={control}
              type="number"
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              name={PRODUCT_MODEL.clientPercent}
              placeholder="Client percent"
              control={control}
              type="number"
            />
          </Grid>
          <ListManager
            className="partnerForm__row"
            name={PRODUCT_MODEL.localization}
            control={control}
            newItemModel={newItemModel}
            maxItems={maxLangItems}
            buttonTitle="Add new localization"
            PreviewComponent={
              <ProductPreview
                inputSize="small"
                languages={languages}
                disabledLanguages={disabledLanguages}
              />
            }
          />
        </Grid>
      </form>
    </Modal>
  );
};

function mapResponseToValues({ _id, ...otherValues }) {
  return otherValues;
}
