import Api from 'apis/default.api';

export const userService = {
  getUserById,
  deleteUserById,
  updateUserById,
};

const CLIENT_ENDPOINT = '/client';

export async function getUserById(userId) {
  return Api.get(`${CLIENT_ENDPOINT}/${userId}`);
}

export async function deleteUserById(userId) {
  return Api.delete(`${CLIENT_ENDPOINT}/${userId}`);
}

export async function updateUserById(userId, newUser) {
  return Api.put(`${CLIENT_ENDPOINT}/${userId}`, newUser);
}
