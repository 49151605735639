import React from 'react';

// Components
import { ErrorLayout } from 'components/layouts/ErrorLayout/ErrorLayout';

export const ForbiddenPage = () => {
  return (
    <ErrorLayout
      to="/login"
      caption="Sorry, you are not allowed to see the sensitive data. Please make sure you are logging
  as an Admin of the application. Please contact a system administrator if you still see
  this page"
    />
  );
};
