import produce from 'immer';

// Constants
import { INITIAL_FILTERS_STATE } from 'constants/filters';

// Models
import { PROMOTION_MODEL } from '../utils/models';

import actionsTypes from './types';

const initialState = {
  promotionsList: {
    isPromotionsLoading: true,
    isStatusChanging: false,
    isCreating: false,
    filters: {
      ...INITIAL_FILTERS_STATE,
      sortBy: PROMOTION_MODEL.order,
    },
    data: {
      items: [],
      count: 0,
    },
    error: null,
  },

  promotionItem: {
    isPromotionLoading: true,
    data: {},
    error: null,
  },
};

export const promotions = produce((draft = initialState, action) => {
  switch (action.type) {
    // -------------------------------------------------------- GET PROMOTIONS start
    case actionsTypes.GET_PROMOTIONS_REQUEST: {
      draft.promotionsList.isPromotionsLoading = true;
      draft.promotionsList.error = null;

      return draft;
    }
    case actionsTypes.GET_PROMOTIONS_SUCCESS: {
      draft.promotionsList.isPromotionsLoading = false;
      draft.promotionsList.data = {
        ...draft.promotionsList.data,
        ...action.promotionsList,
      };

      return draft;
    }
    case actionsTypes.GET_PROMOTIONS_FAILURE: {
      draft.promotionsList.isPromotionsLoading = false;
      draft.promotionsList.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- GET PROMOTIONS end

    // -------------------------------------------------------- CREATE PROMOTION start
    case actionsTypes.CREATE_REQUEST: {
      draft.promotionsList.isCreating = true;

      return draft;
    }
    case actionsTypes.CREATE_SUCCESS: {
      draft.promotionsList.isCreating = false;

      return draft;
    }
    case actionsTypes.CREATE_FAILURE: {
      draft.promotionsList.isCreating = false;

      return draft;
    }
    // -------------------------------------------------------- CREATE PROMOTION end

    // -------------------------------------------------------- GET PROMOTION start
    case actionsTypes.GET_PROMOTION_REQUEST: {
      draft.promotionItem.isPromotionLoading = true;
      draft.promotionItem.error = null;

      return draft;
    }
    case actionsTypes.GET_PROMOTION_SUCCESS: {
      draft.promotionItem.isPromotionLoading = false;
      draft.promotionItem.data = action.promotion;

      return draft;
    }
    case actionsTypes.GET_PROMOTION_FAILURE: {
      draft.promotionItem.isPromotionLoading = false;
      draft.promotionItem.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- GET PROMOTION end

    // -------------------------------------------------------- UPDATE STATUS start
    case actionsTypes.UPDATE_STATUS_REQUEST: {
      draft.promotionsList.isStatusChanging = true;
      draft.promotionsList.error = null;

      return draft;
    }
    case actionsTypes.UPDATE_STATUS_SUCCESS: {
      draft.promotionsList.isStatusChanging = false;

      return draft;
    }
    case actionsTypes.UPDATE_STATUS_FAILURE: {
      draft.promotionsList.isStatusChanging = false;
      draft.promotionsList.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- UPDATE STATUS end

    // -------------------------------------------------------- UPDATE PROMOTION start
    case actionsTypes.UPDATE_REQUEST: {
      draft.promotionItem.isPromotionLoading = true;
      draft.promotionItem.error = null;

      return draft;
    }
    case actionsTypes.UPDATE_SUCCESS: {
      draft.promotionItem.isPromotionLoading = false;

      return draft;
    }
    case actionsTypes.UPDATE_FAILURE: {
      draft.promotionItem.isPromotionLoading = false;
      draft.promotionItem.error = action.error;

      return draft;
    }
    // -------------------------------------------------------- UPDATE PROMOTION end

    // -------------------------------------------------------- CHANGE PROMOTIONS FILTERS start
    case actionsTypes.CHANGE_PROMOTIONS_FILTERS: {
      draft.promotionsList.filters = {
        ...draft.promotionsList.filters,
        ...action.filters,
      };

      return draft;
    }
    case actionsTypes.RESET_PROMOTIONS_FILTERS: {
      draft.promotionsList = initialState.promotionsList;

      return draft;
    }
    // -------------------------------------------------------- CHANGE PROMOTIONS FILTERS end

    default: {
      return draft;
    }
  }
});
