import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import { Grid, Box, CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';

// Components
import { ModalConfirmExit, ModalCreate } from './components/modals';
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { SearchInput } from 'features/Users/components/SearchInput/SearchInput';
import { TableWithQueryFilters } from 'components/base/TableWithFilters/TableWithQueryFilters';

// Constants
import { MODALS_ACTIONS_TYPES } from 'constants/modals.constants';
import { FILTERS_ENUM, SORTING_ORDER_TYPES } from 'constants/filters';

// Selectors
import { categoriesSelector } from 'features/Dictionaries/Categories/store/selectors';

// Utils
import {
  getOptionForValue,
  getOptionLabel,
  getOptionSelected,
} from 'utils/functionsForAutocomplete';
import { getCurrentPage, getPaginationOffsetByPage } from 'utils/functionsForPagination';
import { dateToFormat } from 'utils/functionsForDate';

// Hooks
import useSearchParams from 'hooks/useSearchParams';

// Services
import { createPartner, getPartners } from 'services/partner.service';

// Store
import { alertActions } from 'features/Alert';

// Helpers
import { queryClient } from 'helpers/queryClient';

const modalInitialSettings = {
  isShown: false,
  type: null,
  payload: null,
};

const tableColumnsSettings = [
  { selector: 'name', name: 'Name', width: '150' },
  { selector: 'email', name: 'Email for logging in to Partner app', width: '300' },
  { selector: 'categoryName', name: 'Category', width: 150 },
  {
    selector: 'transactionsCount',
    name: 'Transaction count',
    width: 150,
    sortable: true,
    sortField: 'active',
  },
  { selector: 'spent', name: 'Spent', width: '70px', sortable: true },
  { selector: 'brought', name: 'Brought', width: '70px', sortable: true },
  {
    selector: 'createdAt',
    name: 'Created',
    width: '200px',
    sortable: true,
    sortField: 'date',
    format: ({ createdAt }) => dateToFormat(createdAt),
  },
];

export const Partners = () => {
  const dispatch = useDispatch();

  const [modalSettings, changeModalSettings] = useState(modalInitialSettings);
  const [isModalConfirmationShown, changeIsModalConfirmationShown] = useState(false);

  const {
    isCategoriesLoading,
    data: categories,
    error: categoriesError,
  } = useSelector(categoriesSelector);

  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeSearch = useCallback((search) => setSearchParams({ search }), [setSearchParams]);

  const onChangeCategory = useCallback(
    (_, newValue) => {
      setSearchParams({ category: newValue?._id });
    },
    [setSearchParams],
  );

  const showModal = useCallback(
    () => changeModalSettings({ isShown: true, type: MODALS_ACTIONS_TYPES.create }),
    [],
  );

  const hideModal = () => changeModalSettings(modalInitialSettings);

  const showConfirmationModal = () => changeIsModalConfirmationShown(true);

  const hideConfirmationModal = () => changeIsModalConfirmationShown(false);

  const hideAllOpenedModals = useCallback(() => {
    hideModal();
    hideConfirmationModal();
  }, []);

  const category = searchParams.get('category');
  const search = searchParams.get('search');
  const page = getCurrentPage(searchParams.get(FILTERS_ENUM.page));
  const orderBy = searchParams.get(FILTERS_ENUM.orderBy) || SORTING_ORDER_TYPES.desc;
  const sortBy = searchParams.get(FILTERS_ENUM.sortBy) || 'date';

  const renderTableHeader = useMemo(() => {
    return (
      <Box className="btn btn-outline-dark" onClick={showModal}>
        Create a new partner <PersonAddTwoToneIcon />
      </Box>
    );
  }, [showModal]);

  const renderSubHeader = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            size="small"
            options={categories}
            loading={isCategoriesLoading}
            fullWidth={true}
            getOptionLabel={getOptionLabel('name')}
            getOptionSelected={getOptionSelected('name')}
            value={getOptionForValue({
              value: category,
              options: categories,
              valueKey: 'name',
              optionKey: '_id',
            })}
            onChange={onChangeCategory}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Category"
                error={Boolean(categoriesError)}
                helperText={categoriesError && categoriesError.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isCategoriesLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchInput
            defaultValue={search}
            label="Enter partner name or email here"
            onChange={onChangeSearch}
          />
        </Grid>
      </Grid>
    );
  }, [
    categories,
    categoriesError,
    category,
    isCategoriesLoading,
    onChangeCategory,
    onChangeSearch,
    search,
  ]);

  const partnersQuery = useQuery({
    queryKey: ['partners', { category, search, orderBy, sortBy, page }],
    queryFn: async () => {
      const defaultFilters = getPaginationOffsetByPage({ category, search, orderBy, sortBy, page });
      const partners = await getPartners(defaultFilters);
      return partners;
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const createPartnerMutation = useMutation({
    mutationFn: (newPartner) => {
      return createPartner(newPartner);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['partners'] });
      hideAllOpenedModals();
      dispatch(alertActions.success('Partner was created'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  return (
    <LayoutShowcase title="Partners">
      <TableWithQueryFilters
        title="List of Partners"
        renderHeader={renderTableHeader}
        renderSubHeader={renderSubHeader}
        progressPending={partnersQuery.isLoading}
        data={partnersQuery.data?.items}
        columns={tableColumnsSettings}
        paginationTotalRows={partnersQuery.data?.count}
        defaultSortFieldId="date"
      />

      {modalSettings.type === MODALS_ACTIONS_TYPES.create && modalSettings.isShown && (
        <ModalCreate
          isLoading={createPartnerMutation.isLoading}
          onAccept={createPartnerMutation.mutate}
          onDismiss={showConfirmationModal}
        />
      )}

      {isModalConfirmationShown && (
        <ModalConfirmExit onAccept={hideAllOpenedModals} onDismiss={hideConfirmationModal} />
      )}
    </LayoutShowcase>
  );
};
