import { LOCALIZED_MODEL, PARTNER_MODEL } from '../../utils/models';

// Models

export const MODALS_TYPES = Object.freeze({
  delete: 'deletePartner',
  updateEmail: 'updatePartnerEmail',
  updatePassword: 'updatePartnerPassword',
  confirmWorksOnline: 'confirmWorksOnline',
});

export const newItemModel = {
  [LOCALIZED_MODEL.locale]: null,
  [LOCALIZED_MODEL.value]: '',
};

export const defaultValues = {
  [PARTNER_MODEL.avatar]: '',
  [PARTNER_MODEL.name]: '',
  [PARTNER_MODEL.category]: null,
  [PARTNER_MODEL.country]: null,
  [PARTNER_MODEL.worksOnline]: false,
  [PARTNER_MODEL.locations]: '',
  [PARTNER_MODEL.percent]: '',
  [PARTNER_MODEL.clientPercent]: '',
  [PARTNER_MODEL.adminPercent]: '',
  [PARTNER_MODEL.email]: '',
  [PARTNER_MODEL.contactsOfPartner]: '',
  [PARTNER_MODEL.emailForContact]: '',
  [PARTNER_MODEL.personalPhone]: '',
  [PARTNER_MODEL.personalEmail]: '',
  [PARTNER_MODEL.website]: '',
  [PARTNER_MODEL.facebook]: '',
  [PARTNER_MODEL.instagram]: '',
  [PARTNER_MODEL.localization]: null,
  [PARTNER_MODEL.description]: '',
  [PARTNER_MODEL.localizedDescriptions]: [],
};
