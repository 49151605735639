import React, { useEffect, useState } from 'react';

// Services
import { getDishes } from 'services/dishes.service';

// Components
import FormSelect from 'components/base/FormSelect/FormSelect';

export const DishesSelect = ({
  locationId,
  placeholder = 'Dishes',
  name,
  control,
  isMultiple = true,
  isDisabled = false,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useState({
    isLoading: true,
    options: [],
    error: null,
  });

  useEffect(() => {
    if (!locationId) return;

    let active = true;

    const getDishesOptions = async () => {
      try {
        const dishes = await getDishes({ locationId });

        if (active) {
          setState({
            isLoading: false,
            error: null,
            options: dishes?.items.map(({ _id, name }) => {
              const label = name[0]?.value ?? 'No name';

              return { value: _id, label };
            }),
          });
        }
      } catch (error) {
        setState({
          isLoading: false,
          error: error,
          options: [],
        });
      }
    };

    getDishesOptions();

    return () => {
      active = false;
    };
  }, [locationId]);

  return (
    <FormSelect
      isDisabled={isDisabled}
      placeholder={placeholder}
      inputName="label"
      inputValue="value"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      control={control}
      name={name}
      options={state.options}
      isLoading={state.isLoading}
      isError={Boolean(state.error)}
      helperText={state.error?.message}
      isMultiple={isMultiple}
      {...otherProps}
    />
  );
};
