import Api from 'apis/default.api';

export default {
  getFaq,
  createFaq,
  updateFaq,
  deleteFaq,
};

const FAQ_ENDPOINT = 'faq';

export function getFaq() {
  return Api.get(`${FAQ_ENDPOINT}`);
}

export function createFaq(data) {
  return Api.post(FAQ_ENDPOINT, data);
}

export function updateFaq(id, data) {
  return Api.put(`${FAQ_ENDPOINT}/${id}`, data);
}

export function deleteFaq(id) {
  return Api.delete(`${FAQ_ENDPOINT}/${id}`);
}
