import Api from 'apis/default.api';

export default {
  getCharityEvents,
  createCharityEvent,
  getCharityEventById,
  updateCharityEventById,
  deleteCharityEventById,
  getVlogs,
  createVlog,
  getVlogById,
  updateVlogById,
  deleteVlogById,
  getPolls,
  createPoll,
  getPollById,
  updatePollById,
  deletePollById,
  getGiveaways,
  createGiveaway,
  getGiveawayById,
  updateGiveawayById,
  deleteGiveawayById,
};

const CHARITIES_ENDPOINT = '/charitys';
const EVENTS_ENDPOINT = 'events';
const VLOGS_ENDPOINT = 'vlogs';
const POLLS_ENDPOINT = 'polls';
const GIVEAWAYS_ENDPOINT = 'giveaways';
const GIVEAWAYS_WINNER_ENDPOINT = 'winner';

// -------------------------------------------------------- EVENTS start
export async function getCharityEvents(params) {
  return Api.get(`${CHARITIES_ENDPOINT}/${EVENTS_ENDPOINT}`, { params });
}
export async function createCharityEvent(newEvent) {
  return Api.post(`${CHARITIES_ENDPOINT}/${EVENTS_ENDPOINT}`, newEvent);
}
export async function getCharityEventById(eventId) {
  return Api.get(`${CHARITIES_ENDPOINT}/${EVENTS_ENDPOINT}/${eventId}`);
}
export async function updateCharityEventById(eventId, newEvent) {
  return Api.patch(`${CHARITIES_ENDPOINT}/${EVENTS_ENDPOINT}/${eventId}`, newEvent);
}
export async function deleteCharityEventById(eventId) {
  return Api.delete(`${CHARITIES_ENDPOINT}/${EVENTS_ENDPOINT}/${eventId}`);
}
// -------------------------------------------------------- EVENTS end

// -------------------------------------------------------- VLOGS start
export async function getVlogs(params) {
  return Api.get(`${CHARITIES_ENDPOINT}/${VLOGS_ENDPOINT}`, { params });
}
export async function createVlog(newVlog) {
  return Api.post(`${CHARITIES_ENDPOINT}/${VLOGS_ENDPOINT}`, newVlog);
}
export async function getVlogById(vlogId) {
  return Api.get(`${CHARITIES_ENDPOINT}/${VLOGS_ENDPOINT}/${vlogId}`);
}
export async function updateVlogById(vlogId, newVlog) {
  return Api.patch(`${CHARITIES_ENDPOINT}/${VLOGS_ENDPOINT}/${vlogId}`, newVlog);
}
export async function deleteVlogById(vlogId) {
  return Api.delete(`${CHARITIES_ENDPOINT}/${VLOGS_ENDPOINT}/${vlogId}`);
}
// -------------------------------------------------------- VLOGS end

// -------------------------------------------------------- POLLS start
export async function getPolls(params) {
  return Api.get(`${CHARITIES_ENDPOINT}/${POLLS_ENDPOINT}`, { params });
}
export async function createPoll(newPoll) {
  return Api.post(`${CHARITIES_ENDPOINT}/${POLLS_ENDPOINT}`, newPoll);
}
export async function getPollById(pollId) {
  return Api.get(`${CHARITIES_ENDPOINT}/${POLLS_ENDPOINT}/${pollId}`);
}
export async function updatePollById(pollId, newPoll) {
  return Api.patch(`${CHARITIES_ENDPOINT}/${POLLS_ENDPOINT}/${pollId}`, newPoll);
}
export async function deletePollById(pollId) {
  return Api.delete(`${CHARITIES_ENDPOINT}/${POLLS_ENDPOINT}/${pollId}`);
}
// -------------------------------------------------------- POLLS end

// -------------------------------------------------------- GIVEAWAYS start
export async function getGiveaways(params) {
  return Api.get(`${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}`, { params });
}
export async function createGiveaway(newGiveaway) {
  return Api.post(`${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}`, newGiveaway);
}
export async function getGiveawayById(giveawayId) {
  return Api.get(`${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}/${giveawayId}`);
}
export async function getGiveawayWinnerById(giveawayId) {
  return Api.get(
    `${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}/${giveawayId}/${GIVEAWAYS_WINNER_ENDPOINT}`,
  );
}
export async function updateGiveawayById(giveawayId, newGiveaway) {
  return Api.patch(`${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}/${giveawayId}`, newGiveaway);
}
export async function deleteGiveawayById(giveawayId) {
  return Api.delete(`${CHARITIES_ENDPOINT}/${GIVEAWAYS_ENDPOINT}/${giveawayId}`);
}
// -------------------------------------------------------- GIVEAWAYS end
