import { alertActions } from 'features/Alert';

import actionsTypes, { WINNER_TYPES } from './types';
import {
  createGiveaway,
  deleteGiveawayById,
  getGiveawayById,
  getGiveaways,
  updateGiveawayById,
} from 'services/charity.services';

export default {
  aGetGiveaways,
  aCreateGiveaway,
  aDeleteGiveawayById,
  aGetGiveawayById,
  aUpdateGiveawayById,
  aResetGiveaway,
  aChangeFilter,
};

export function aGetGiveaways(params) {
  return (dispatch) => {
    dispatch(actionRequest());

    getGiveaways(params).then(
      (res) => dispatch(actionSuccess(res)),
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.GET_GIVEAWAYS_REQUEST,
    };
  }

  function actionSuccess(data) {
    return {
      type: actionsTypes.GET_GIVEAWAYS_SUCCESS,
      data,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.GET_GIVEAWAYS_FAILURE,
      error,
    };
  }
}

export function aCreateGiveaway(newGiveaway) {
  return async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(actionRequest());

    return createGiveaway(newGiveaway).then(
      (res) => {
        dispatch(actionSuccess(res));
        dispatch(alertActions.success('Giveaway was created'));
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject(error);
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.CREATE_GIVEAWAY_REQUEST,
    };
  }

  function actionSuccess(newGiveaway) {
    return {
      type: actionsTypes.CREATE_GIVEAWAY_SUCCESS,
      newGiveaway,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.CREATE_GIVEAWAY_FAILURE,
      error,
    };
  }
}

export function aDeleteGiveawayById(id) {
  return async (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(actionRequest());

    return deleteGiveawayById(id).then(
      () => {
        dispatch(actionSuccess(id));
        dispatch(alertActions.success('Giveaway was deleted'));
      },
      (error) => {
        dispatch(actionFailure(error));
        dispatch(alertActions.error(error.message));

        return Promise.reject(error);
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.DELETE_GIVEAWAY_REQUEST,
    };
  }

  function actionSuccess(id) {
    return {
      type: actionsTypes.DELETE_GIVEAWAY_SUCCESS,
      id,
    };
  }

  function actionFailure(error) {
    return {
      type: actionsTypes.DELETE_GIVEAWAY_FAILURE,
      error,
    };
  }
}

export function aGetGiveawayById(id) {
  return (dispatch) => {
    dispatch(actionRequest());

    getGiveawayById(id).then(
      (res) => dispatch(actionSuccess(res)),
      (error) => {
        dispatch(actionError(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.GET_GIVEAWAY_REQUEST,
    };
  }
  function actionSuccess(data) {
    return {
      type: actionsTypes.GET_GIVEAWAY_SUCCESS,
      data,
    };
  }
  function actionError(error) {
    return {
      type: actionsTypes.GET_GIVEAWAY_FAILURE,
      error,
    };
  }
}

export function aUpdateGiveawayById(id, newGiveaway) {
  return (dispatch) => {
    dispatch(alertActions.clear());
    dispatch(actionRequest());

    updateGiveawayById(id, newGiveaway).then(
      (res) => {
        dispatch(actionSuccess(res));
        dispatch(alertActions.success('Giveaway was updated'));
      },
      (error) => {
        dispatch(actionError(error));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function actionRequest() {
    return {
      type: actionsTypes.UPDATE_GIVEAWAY_REQUEST,
    };
  }
  function actionSuccess(data) {
    return {
      type: actionsTypes.UPDATE_GIVEAWAY_SUCCESS,
      data,
    };
  }
  function actionError(error) {
    return {
      type: actionsTypes.GET_GIVEAWAY_FAILURE,
      error,
    };
  }
}

export function aResetGiveaway() {
  return {
    type: actionsTypes.RESET_GIVEAWAY_SUCCESS,
  };
}

// -------------------------------------------------------- WINNER start
export const winnerActions = {
  aGetWinnerRequest,
  aGetWinnerSuccess,
  aGetWinnerFailure,
};
function aGetWinnerRequest() {
  return {
    type: WINNER_TYPES.GET_WINNER_REQUEST,
  };
}
function aGetWinnerSuccess(winner) {
  return {
    type: WINNER_TYPES.GET_WINNER_SUCCESS,
    winner,
  };
}
function aGetWinnerFailure(error) {
  return {
    type: WINNER_TYPES.GET_WINNER_FAILURE,
    error,
  };
}
// -------------------------------------------------------- WINNER end

export function aChangeFilter(filters) {
  return {
    type: actionsTypes.CHANGE_GIVEAWAY_FILTER,
    filters,
  };
}
