import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// UIKit
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';

// Redux
import { alertSelector } from './store/selectors';
import { alertActions } from './store/actions';

// Styles
import styles from './Alert.module.scss';

export const Alert = () => {
  const dispatch = useDispatch();
  const alertState = useSelector(alertSelector);

  const onClose = useCallback(() => {
    if (alertState.message) {
      dispatch(alertActions.clear());
    }
  }, [alertState.message, dispatch]);

  useEffect(() => {
    const timer = setTimeout(onClose, 15000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  if (!alertState.message) {
    return null;
  }

  return (
    <MuiAlert className={styles.root} variant="filled" severity={alertState.type} onClose={onClose}>
      <AlertTitle className={styles.title}>{alertState.type}</AlertTitle>
      {alertState.message}
    </MuiAlert>
  );
};
