import { useEffect, useState } from 'react';
import { useCountries } from './useCountries';

export const useCities = () => {
  const [cities, setCities] = useState([]);

  const { isCountriesFetching, countries } = useCountries();

  useEffect(() => {
    if (isCountriesFetching || !countries) return;

    const getCurrentCountry = (countryItem) => countryItem.country === countries.country;

    const currentCountry = countries.find(getCurrentCountry);

    if (currentCountry) {
      setCities(currentCountry.cities);
    }
  }, [countries, isCountriesFetching]);

  return [cities, setCities];
};
