import React from 'react';
import { useController } from 'react-hook-form';

import { Input } from 'components/base/Input/Input';

export const FormControl = ({ control, name, helperText, rules, ...props }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Input
      isError={invalid}
      helperText={invalid ? error.message : helperText}
      {...field}
      {...props}
    />
  );
};

export default FormControl;
