import { generatePath } from 'react-router-dom';

export const getCurrentPathWithoutLastPart = (pathname) => {
  return pathname.slice(0, pathname.lastIndexOf('/'));
};

export const getLastPath = (pathname) => {
  return pathname.split('/').pop();
};

export const generatePathByParams = (params) => {
  const callback = (prevValue, currentValue, index, array) => {
    const isLastItem = index === array.length - 1;
    const path = `:${currentValue}${!isLastItem ? '/' : ''}`;
    return (prevValue += path);
  };
  const pathString = Object.keys(params).reduce(callback, '');

  const path = generatePath(pathString, params);

  return path;
};
