import produce from 'immer';

import { categoriesConstants } from '../constants';

const initialState = {
  isCategoriesLoading: true,
  data: [],
  meta: { limit: 10, page: 1, pages: 1, total: 1 },
  error: null,
};

export const categories = produce((draft = initialState, action) => {
  switch (action.type) {
    case categoriesConstants.FIND_ALL_REQUEST: {
      draft.isCategoriesLoading = true;

      return draft;
    }

    case categoriesConstants.FIND_ALL_SUCCESS: {
      draft.isCategoriesLoading = false;
      draft.data = action.categories.data;
      draft.meta = action.categories.meta;

      return draft;
    }

    case categoriesConstants.FIND_ALL_FAILURE: {
      draft.isCategoriesLoading = false;
      draft.error = action.error;

      return draft;
    }

    default: {
      return draft;
    }
  }
});
