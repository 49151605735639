import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// UIKit
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Services
import { getTransactions } from 'services/transactions.service';

// Components
import { Loader } from 'components/base/Loader/Loader';
import { Table } from 'components/base/Table/Table';

// Utils
import { dateToFormat } from 'utils/functionsForDate';
import { formatReferral } from 'utils/functionsForTransactions';
import { formatLongRowByAddTitle } from 'utils/functionsForTable';
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';

// Constants
import { SORTING_ORDER_TYPES } from 'constants/filters';

const ITEMS_PER_PAGE = 5;

const columns = [
  { selector: 'date', name: 'Date', maxWidth: '100px', sortable: true },
  {
    selector: 'type',
    name: 'Type',
    maxWidth: '150px',
    format: formatType,
  },
  { selector: 'amount', name: 'Paid amount', maxWidth: '120px', sortable: true },
  {
    selector: 'adminIncomeBonuses',
    name: 'Cashback for admin',
    maxWidth: '200px',
  },
  {
    selector: 'clientIncomeBonuses',
    name: 'Description',
    minWidth: '300px',
    wrap: true,
    format: ({ clientIncomeBonuses }) => formatLongRowByAddTitle(clientIncomeBonuses),
  },
  {
    selector: 'partnerName',
    name: 'Partner',
    sortable: true,
    minWidth: '150px',
  },
  {
    selector: 'product',
    name: 'Product name',
    minWidth: '150px',
    format: ({ product }) => product && product.title,
  },
  {
    selector: 'clientBonusesAfterCancel',
    name: 'Balance',
  },
  {
    selector: 'Referral',
    name: 'Referral',
    format: formatReferral,
    maxWidth: '150px',
  },
  {
    selector: 'isCanceled',
    name: 'Cancellations Status',
    format: ({ isCanceled }) => (isCanceled ? 'Canceled' : 'Not canceled'),
    maxWidth: '200px',
  },
  {
    selector: 'isPaid',
    name: 'Settlement Status',
    ignoreRowClick: true,
    format: ({ isPaid }) => (isPaid ? 'Payment made' : 'Not paid'),
    maxWidth: '200px',
  },
];

const filtersNames = {
  sortBy: 'sortBy',
  orderBy: 'orderBy',
  limit: 'limit',
  offset: 'offset',
  paid: 'paid',
  canceled: 'canceled',
  referral: 'referral',
  type: 'type',
};

const selectFilterTypes = [
  {
    title: 'All',
    filterName: filtersNames.type,
    value: null,
  },
  {
    title: 'Money',
    filterName: filtersNames.type,
    value: 'MONEY',
  },
  {
    title: 'Money bonuses',
    filterName: filtersNames.type,
    value: 'MONEY_BONUSES',
  },
  {
    title: 'Sharing bonuses',
    filterName: filtersNames.type,
    value: 'SHARING_BONUSES',
  },
  {
    title: 'Bonuses',
    filterName: filtersNames.type,
    value: 'BONUSES',
  },
  {
    title: 'Referral',
    filterName: filtersNames.referral,
    value: true,
  },
  {
    title: 'Not Referral',
    filterName: filtersNames.referral,
    value: false,
  },
  {
    title: 'Paid',
    filterName: filtersNames.paid,
    value: true,
  },
  {
    title: 'Not Paid',
    filterName: filtersNames.paid,
    value: false,
  },
  {
    title: 'Canceled',
    filterName: filtersNames.canceled,
    value: true,
  },
  {
    title: 'Not canceled',
    filterName: filtersNames.canceled,
    value: false,
  },
];

const defaultFilters = {
  [filtersNames.sortBy]: 'date',
  [filtersNames.orderBy]: SORTING_ORDER_TYPES.desc,
  [filtersNames.limit]: ITEMS_PER_PAGE,
  [filtersNames.offset]: 0,
  [filtersNames.paid]: null,
  [filtersNames.canceled]: null,
  [filtersNames.referral]: null,
  [filtersNames.type]: null,
};

const useStyles = makeStyles(() => ({
  root: {
    marginRight: '50px',
  },
  select: {
    minWidth: '200px',
  },
}));

export const UserTransactions = () => {
  const classes = useStyles();

  const { id: userId } = useParams();

  const [selectValue, setSelectValue] = useState(selectFilterTypes[0]);
  const [rows, setRows] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);

  const [filters, setFilters] = useState(defaultFilters);

  const showLoader = () => setLoading(true);

  const hideLoader = () => setLoading(false);

  const changeFilters = useCallback((filter) => {
    setFilters((filters) => ({ ...filters, ...filter }));
  }, []);

  const handleSortList = useCallback(
    ({ selector }, sorting) => {
      changeFilters({
        [filtersNames.sortBy]: selector,
        [filtersNames.orderBy]: sorting,
      });
    },
    [changeFilters],
  );

  const setPage = useCallback(
    (page) => {
      changeFilters({ [filtersNames.offset]: (page - 1) * ITEMS_PER_PAGE });
    },
    [changeFilters],
  );

  const onSelectChange = useCallback(
    (_, selected) => {
      setSelectValue(selected);
      const newFilters = {
        [filtersNames.paid]: null,
        [filtersNames.canceled]: null,
        [filtersNames.referral]: null,
        [filtersNames.type]: null,
        [filtersNames.offset]: null,
        [selected.filterName]: selected.value,
      };

      changeFilters(newFilters);
      setLoadingFilters(true);
    },
    [changeFilters],
  );

  const getAllTransactions = useCallback(() => {
    showLoader();
    getTransactions(userId, filters)
      .then((response) => {
        setRows(mapResponseToRows(response));
        setTotalSize(response.count);
      })
      .finally(() => {
        hideLoader();
        setLoadingFilters(false);
      });
  }, [filters, userId]);

  useEffect(getAllTransactions, [getAllTransactions]);

  return (
    <div className={classes.root}>
      {loadingFilters ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          data={rows}
          renderSubHeader={
            <Autocomplete
              id="filters"
              className={classes.select}
              options={selectFilterTypes}
              getOptionLabel={getOptionLabel('title')}
              getOptionSelected={getOptionSelected('title')}
              onChange={onSelectChange}
              value={selectValue}
              disableClearable={true}
              size="small"
              renderInput={(params) => <TextField variant="outlined" {...params} />}
            />
          }
          progressPending={loading}
          highlightOnHover={true}
          pagination={true}
          paginationServer={true}
          paginationTotalRows={totalSize}
          paginationPerPage={ITEMS_PER_PAGE}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          onChangePage={setPage}
          sortServer={true}
          onSort={handleSortList}
        />
      )}
    </div>
  );
};

function mapResponseToRows(response) {
  return response.payments?.map((payment) => {
    return {
      date: dateToFormat(payment.date),
      type: payment.type,
      amount: payment.price,
      adminIncomeBonuses: payment.adminBonuses,
      clientIncomeBonuses: `+${payment.clientBonuses} bonuses from ${
        payment.senderClientName || payment.partnerName || 'partner which was deleted'
      }`,
      promoterBonuses: payment.promoterBonuses,
      promoterId: payment.promoterId,
      isReferral: payment.isReferral,
      isCanceled: payment.isCanceled,
      isPaid: payment.isPaid,
      partnerName: payment.partnerName,
      product: payment.product,
      clientBonusesAfterCancel: payment.clientBonusesAfterCancel,
    };
  });
}

function formatType({ type }) {
  const findingType = selectFilterTypes.find((filter) => filter.value === type);

  if (findingType) return findingType.title;

  return type;
}
