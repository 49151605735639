import React, { useState, useCallback } from 'react';
import cn from 'classnames';

// UIKit
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';

// Components
import { Input } from '../Input/Input';

// Services
import service from 'services/files.service';

// Utilities
import { getFileFromEvent } from 'utils/functionsForFiles';

// Styles
import styles from './ImageUploaderWithPreview.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  img: {
    objectFit: 'contain',
  },
}));

export const ImageUploaderWithPreview = ({
  id = 'imageUploaderWithPreview-1',
  src,
  onChange,
  isError = false,
  helperText,
  accept = 'image/*',
  buttonLabel = 'Browse',
}) => {
  const avatarClasses = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const resetErrorText = useCallback(() => setErrorText(''), []);

  const removeFileFromServer = useCallback(
    (file) => service.deleteFile(file).catch((error) => console.error(error)),
    [],
  );

  const uploadFile = useCallback(
    async (event) => {
      resetErrorText();
      setLoading(true);

      const file = getFileFromEvent(event);

      if (src) await removeFileFromServer(src);

      try {
        const filePath = await service.addNewFile(file);
        onChange(filePath.fileUrl);
      } catch (error) {
        setErrorText(error.message);
      } finally {
        setLoading(false);
      }
    },
    [onChange, removeFileFromServer, resetErrorText, src],
  );

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Avatar src={src} classes={avatarClasses} />
        <Input
          className={styles.input}
          placeholder="Choose new avatar"
          isDisabled={true}
          readOnly={true}
          value={src}
          isRequired
        />
        <div>
          <input
            className={styles.inputFile}
            accept={accept}
            id={id}
            onChange={uploadFile}
            type="file"
          />
          <label className={styles.label} htmlFor={isLoading ? '' : id}>
            <span className={cn('btn btn-primary', styles.button, { disabled: isLoading })}>
              {!isLoading && buttonLabel}
              {isLoading && <CircularProgress size={16} color="secondary" />}
            </span>
          </label>
        </div>
      </div>

      {(helperText || !!errorText) && (
        <FormHelperText error={isError || !!errorText} variant="outlined">
          {helperText || errorText}
        </FormHelperText>
      )}
    </div>
  );
};

export default ImageUploaderWithPreview;
