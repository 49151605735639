import Api from 'apis/default.api';

export default {
  geVersion,
  updateVersion,
};

const ROOT_ENDPOINT = '/versions';

export async function geVersion() {
  return Api.get(ROOT_ENDPOINT);
}

export async function updateVersion(versionId, data) {
  return Api.patch(`${ROOT_ENDPOINT}/${versionId}`, data);
}
