export const PRODUCT_MODEL = Object.freeze({
  id: '_id',
  title: 'title',
  clientPercent: 'clientPercent',
  adminPercent: 'adminPercent',
  localization: 'localization',
});

export const LOCALIZED_MODEL = Object.freeze({
  locale: 'locale',
  value: 'value',
});

export const PARTNER_LOCATION_MODEL = Object.freeze({
  id: '_id',
  photos: 'photos',
  name: 'name',
  address: 'address',
  workingDays: 'workingDays',
  coordinates: 'coordinates',
});

export const PARTNER_MODEL = Object.freeze({
  id: '_id',
  name: 'name',
  email: 'email',
  avatar: 'avatar',
  password: 'password',
  percent: 'percent',
  clientPercent: 'clientPercent',
  adminPercent: 'adminPercent',
  description: 'description',
  contactsOfPartner: 'contactsOfPartner',
  emailForContact: 'emailForContact',
  website: 'website',
  facebook: 'facebook',
  instagram: 'instagram',
  category: 'category',
  logo: 'logo',
  localization: 'localisation',
  country: 'country',
  isBonuses: 'isBonuses',
  localizedDescriptions: 'localizedDescriptions',
  locations: 'locations',
  personalEmail: 'personalEmail',
  personalPhone: 'personalPhone',
  worksOnline: 'worksOnline',
  products: 'products',
  userId: 'userId',
});

export const PARTNERS_MODEL = Object.freeze({
  id: '_id',
  brought: 'brought',
  categoryName: 'categoryName',
  createdAt: 'createdAt',
  email: 'email',
  name: 'name',
  spent: 'spent',
  transactionsCount: 'transactionsCount',
  worksOnline: 'worksOnline',
});
