import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { FormNotification } from './components/FormNotification/FormNotification';
import { createNewNotification } from 'services/notifications.service';

import { alertActions } from 'features/Alert';

// Utilities
import { defaultValues, validationSchema } from './utils/form.utils';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

export const Notifications = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const showLoader = useCallback(() => {
    setIsLoading(true);
  }, []);

  const hideLoader = useCallback(() => {
    setIsLoading(false);
  }, []);

  const onNotificationCreateSuccess = useCallback(() => {
    dispatch(alertActions.success('Push notification was sent'));
    reset(defaultValues);
  }, [dispatch, reset]);

  const onNotificationCreateFailure = useCallback(
    (error) => dispatch(alertActions.error(error.message)),
    [dispatch],
  );

  const createNotification = useCallback(
    (formValues) => {
      showLoader();

      createNewNotification(formValues)
        .then(onNotificationCreateSuccess)
        .catch(onNotificationCreateFailure)
        .finally(hideLoader);
    },
    [hideLoader, onNotificationCreateFailure, onNotificationCreateSuccess, showLoader],
  );

  const onClearPartnerValue = useCallback(() => {
    setValue('partner', undefined);
  }, [setValue]);

  useBlockerPrompt(isDirty);

  return (
    <LayoutShowcase title="Push Notifications">
      <FormNotification
        isLoading={isLoading}
        isDirty={isDirty}
        control={control}
        watch={watch}
        reset={reset}
        onSubmit={handleSubmit(createNotification)}
        onClearPartnerValue={onClearPartnerValue}
      />
    </LayoutShowcase>
  );
};
