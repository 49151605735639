export const DEFAULT_DATES_FORMAT = 'DD.MM.YYYY';
export const DEFAULT_DATEPICKER_TIME_FORMAT = 'HH:mm';
export const DEFAULT_DATEPICKER_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATEPICKER_DATES_FORMAT = `${DEFAULT_DATEPICKER_DATE_FORMAT} ${DEFAULT_DATEPICKER_TIME_FORMAT}`;

export const DATE_UNITS_ENUM = Object.freeze({
  date: 'D',
  day: 'd',
  week: 'w',
  month: 'M',
  year: 'y',
  hour: 'h',
  minute: 'm',
  second: 's',
  millisecond: 'ms',
});
