import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirmAction = ({ watchWorksOnline, onAccept, onDismiss }) => (
  <Modal
    show={true}
    handleClose={onDismiss}
    name="Do you confirm this action?"
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" onClick={onAccept}>
          Yes
        </button>
        <button className="btn btn-secondary" onClick={onDismiss}>
          No
        </button>
      </Fragment>
    }
  >
    {watchWorksOnline
      ? "The online location won't be shown in the app."
      : 'The online location will be shown in the app.'}
  </Modal>
);
