import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';

// Components
import { FormCreatingPoll } from './components/FormCreatingPoll';
import { PageDetails } from 'components/layouts/PageDetails/PageDetails';

// Utilities
import { defaultValues, schema } from './utils/form';
import {
  mapAnswerOptionToDisabled,
  mapFormValuesToRequest,
  mapResponseToFormValues,
} from './utils/snippets';
import { getDirtyValues } from 'utils/functionsForForm';

// Services
import { getPollById, updatePollById } from 'services/charity.services';

// Models
import { POLL_MODEL } from './utils/models';

// Constants
import { ROUTES } from 'constants/routes.constants';

// Hooks
import { useCities } from 'common/hooks/api/useCities';
import { useCountries } from 'common/hooks/api/useCountries';
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';
import { useGoBack } from 'hooks/useGoBack';

// Utils
import { getLastPath } from 'utils/functionsForRouter';

// Helpers
import { queryClient } from 'helpers/queryClient';

// Actions
import { alertActions } from 'features/Alert';

const initialDisabledFields = { [POLL_MODEL.country]: true, [POLL_MODEL.city]: true };

export const PollDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: pollId } = useParams();

  const { isCountriesFetching, countries } = useCountries();
  const [cities, setCities] = useCities();

  const { isLoading, data } = useQuery({
    queryKey: ['poll', pollId],
    queryFn: () => getPollById(pollId),
    select: mapResponseToFormValues,
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const goBack = useGoBack();

  const goToResultsPage = useCallback(() => {
    navigate(getLastPath(ROUTES.charityPollsResults));
  }, [navigate]);

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const userHasVoted = data?.isVoted;

  const disabledFields = useMemo(() => {
    if (isLoading || !userHasVoted) return [];

    return {
      ...initialDisabledFields,
      [POLL_MODEL.question]: true,
      [POLL_MODEL.answerOption]: data[POLL_MODEL.answerOption].map(mapAnswerOptionToDisabled),
    };
  }, [data, isLoading, userHasVoted]);

  const updatePollMutation = useMutation({
    mutationFn: (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, mapFormValuesToRequest(formValues));

      return updatePollById(pollId, changedValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['poll'] });
      dispatch(alertActions.success('Poll was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const renderedFooter = useMemo(
    () => (
      <Fragment>
        <button
          className="btn btn-primary"
          style={{ marginRight: '1rem' }}
          onClick={goToResultsPage}
          type="button"
          disabled={!userHasVoted}
        >
          View results
        </button>
        <button
          className="btn btn-primary"
          style={{ marginRight: '1rem' }}
          onClick={handleSubmit(updatePollMutation.mutate)}
          type="button"
          disabled={!isDirty}
        >
          Save
        </button>
        <button className="btn btn-secondary" onClick={goBack} type="button">
          Cancel
        </button>
      </Fragment>
    ),
    [goBack, goToResultsPage, handleSubmit, isDirty, updatePollMutation.mutate, userHasVoted],
  );

  const resetCity = useCallback(() => setValue(POLL_MODEL.city, null), [setValue]);

  useEffect(() => {
    if (!isLoading && data) {
      reset({ ...defaultValues, ...data });
    }
  }, [data, isLoading, reset]);

  useEffect(() => {
    if (isLoading || !data) return;

    const getCurrentCountry = (countryItem) => countryItem.country === data.country;
    const currentCountry = countries.find(getCurrentCountry);

    if (currentCountry) {
      setCities(currentCountry.cities);
    }
  }, [countries, data, isLoading, setCities]);

  useBlockerPrompt(isDirty);

  return (
    <PageDetails title="View and edit the poll" isLoading={isLoading} renderFooter={renderedFooter}>
      <FormCreatingPoll
        isLoading={updatePollMutation.isLoading}
        control={control}
        countries={countries}
        isCountriesLoading={isCountriesFetching}
        cities={cities}
        onCitiesChange={setCities}
        resetCity={resetCity}
        disabledFields={disabledFields}
      />
    </PageDetails>
  );
};
