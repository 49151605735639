import React, { Fragment, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

// UIKit
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';

// Selectors
import { notificationsSelector } from 'features/Dictionaries/Notifications/store/selectors';

// Utils
import { getOptionLabel, getOptionSelected } from 'utils/functionsForAutocomplete';

const primaryColor = red[500];
const inputSize = 'small';

export const NotificationTypesSelect = ({ control, name, isRequire }) => {
  const { isNotificationTypesLoading, data, error } = useSelector(notificationsSelector);

  const Label = useMemo(() => {
    if (isRequire) {
      return (
        <Fragment>
          Type:<span style={{ color: primaryColor }}>*</span>
        </Fragment>
      );
    }

    return 'Type';
  }, [isRequire]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const isError = fieldState.invalid || Boolean(error);
        const helperText =
          (fieldState.invalid && fieldState.error.message) || (error && error.message);

        return (
          <Autocomplete
            size={inputSize}
            disableClearable={true}
            options={data}
            loading={isNotificationTypesLoading}
            fullWidth={true}
            getOptionLabel={getOptionLabel()}
            getOptionSelected={getOptionSelected()}
            value={field.value}
            onChange={(_, newValue) => field.onChange(newValue)}
            onBlur={field.onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={Label}
                error={isError}
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isNotificationTypesLoading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

export default NotificationTypesSelect;
