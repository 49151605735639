import React, { cloneElement, Fragment, useState } from 'react';

// UIKit
import AppBar from '@material-ui/core/AppBar';
import UITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Utils
import { a11yProps } from './Tabs.utils';

export const Tabs = ({ list = [], children }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <AppBar position="static" color="default">
        <UITabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
        >
          {list.map((item, index) => (
            <Tab key={item} label={item} {...a11yProps(index)} />
          ))}
        </UITabs>
      </AppBar>
      {React.Children.map(children, (child, index) => cloneElement(child, { value, index }))}
    </Fragment>
  );
};

export default Tabs;
