import { makeStyles } from '@material-ui/core';
import { useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  trigger: {
    display: 'inline-block',
    marginTop: '-0.7rem',
    marginLeft: '0.1rem',
    verticalAlign: 'top',
  },

  paper: {
    display: 'flex',
    width: '300px',
    padding: '10px',
    flexDirection: 'column',
    border: '1px solid',
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const usePopperState = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);

  const onPopperTigerClick = useCallback(
    (event) => setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl],
  );

  return [isOpen, anchorEl, onPopperTigerClick, classes];
};
