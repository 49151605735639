import React from 'react';

// Styles
import styles from './NewLocation.module.scss';

// UIKit
import IconButton from '@material-ui/core/IconButton';

// Components
import { LocationsSelect } from 'components/base/Selects/LocationsSelect';

// Icons
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { DishesSelect } from 'components/base/Selects/DishesSelect';
import useUpdate from 'hooks/useUpdate';

export const NewLocation = ({
  idx,
  control,
  name,
  isDisabled = false,
  canRemove = true,
  onRemove,
  partnerId,
  watch,
  setValue,
}) => {
  const locationName = `${name}.${idx}.location`;
  const dishesName = `${name}.${idx}.dishes`;

  const arrayValues = watch(name);
  const selectedLocation = watch(locationName);

  const disabledOptions = arrayValues.map((field) => field.location);

  useUpdate(() => {
    if (!selectedLocation) return;

    setValue(dishesName, []);
  }, [dishesName, selectedLocation, setValue]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.row}>
          <LocationsSelect
            control={control}
            name={locationName}
            size="small"
            label="Location:"
            isDisabled={isDisabled}
            partnerId={partnerId}
            isMultiple={false}
            disabledOptions={disabledOptions}
          />
        </div>
        <div className={styles.row}>
          <DishesSelect
            control={control}
            name={dishesName}
            size="small"
            label="Dishes:"
            // not required for now
            // isDisabled={isDisabled || Boolean(!selectedLocation)}
            isDisabled={true}
            locationId={selectedLocation}
          />
        </div>
      </div>

      <IconButton disabled={!canRemove || isDisabled} onClick={onRemove}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </div>
  );
};
