import actionsTypes from './types';

import { ALERT_TYPES } from '../utils/constants';

const initialState = {};

export const alert = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SUCCESS:
      return {
        type: ALERT_TYPES[action.type],
        message: action.message,
      };

    case actionsTypes.ERROR:
      return {
        type: ALERT_TYPES[action.type],
        message: action.message,
      };

    case actionsTypes.CLEAR:
      return {};

    default:
      return state;
  }
};
