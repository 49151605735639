import { combineReducers } from 'redux';

import { auth } from 'reducers/auth.reducer';
import { user } from 'reducers/user.reducer';
import { categories } from 'reducers/categories.reducer';
import { transactions } from 'reducers/transactions.reducer';

import { alert } from 'features/Alert';
import { statistic } from 'features/Analytics';
import { charity } from 'features/Charity';
import { dictionaries } from 'features/Dictionaries';
import { promotions } from 'features/Promotions';

const rootReducer = combineReducers({
  auth,
  alert,
  transactions,
  user,
  categories,
  charity,
  dictionaries,
  statistic,
  promotions,
});

export default rootReducer;
