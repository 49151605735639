import produce from 'immer';

import actionTypes from 'constants/transactions.constants';

const initialState = {
  isLoading: true,
  data: {},
  error: null,
  filters: {}, // TODO: filters logic
};

export const transactions = produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTIONS_REQUEST: {
      draft = initialState;

      return draft;
    }

    case actionTypes.GET_TRANSACTIONS_SUCCESS: {
      draft.isLoading = false;
      draft.data = action.data;

      return draft;
    }

    case actionTypes.GET_TRANSACTIONS_FAILURE: {
      draft.isLoading = false;
      draft.error = action.error;

      return draft;
    }

    case actionTypes.UPDATE_TRANSACTION_SUCCESS: {
      const transactionIndex = draft.data.payments.findIndex(
        ({ _id }) => _id === action.transactionId,
      );

      if (transactionIndex !== -1) {
        draft.data.payments[transactionIndex] = {
          ...draft.data.payments[transactionIndex],
          ...action.transactionData,
        };
      }

      return draft;
    }

    case actionTypes.UPDATE_TRANSACTIONS_BY_IDS_SUCCESS: {
      draft.data.payments = draft.data.payments.map((payment) => {
        const hasTransaction = action.transactionItems.find(({ _id }) => _id === payment._id);
        if (hasTransaction) {
          return { ...payment, ...action.transactionData };
        }

        return payment;
      });

      return draft;
    }

    default: {
      return draft;
    }
  }
});
