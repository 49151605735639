import Api from 'apis/default.api';

export default {
  getDishes,
};

const ROOT_ENDPOINT = '/menu-category-dishes';

export async function getDishes(params) {
  return Api.get(ROOT_ENDPOINT, { params });
}
