export default {
  GET_PROMOTIONS_REQUEST: 'GET_PROMOTIONS_REQUEST',
  GET_PROMOTIONS_SUCCESS: 'GET_PROMOTIONS_SUCCESS',
  GET_PROMOTIONS_FAILURE: 'GET_PROMOTIONS_FAILURE',

  CREATE_REQUEST: 'CREATE_PROMOTIONS_REQUEST',
  CREATE_SUCCESS: 'CREATE_PROMOTIONS_SUCCESS',
  CREATE_FAILURE: 'CREATE_PROMOTIONS_FAILURE',

  GET_PROMOTION_REQUEST: 'GET_PROMOTION_REQUEST',
  GET_PROMOTION_SUCCESS: 'GET_PROMOTION_SUCCESS',
  GET_PROMOTION_FAILURE: 'GET_PROMOTION_FAILURE',

  UPDATE_STATUS_REQUEST: 'UPDATE_STATUS_PROMOTION_REQUEST',
  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_PROMOTION_SUCCESS',
  UPDATE_STATUS_FAILURE: 'UPDATE_STATUS_PROMOTION_FAILURE',

  UPDATE_REQUEST: 'UPDATE_PROMOTION_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_PROMOTION_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_PROMOTION_FAILURE',

  CHANGE_PROMOTIONS_FILTERS: 'CHANGE_PROMOTIONS_FILTERS',
  RESET_PROMOTIONS_FILTERS: 'RESET_PROMOTIONS_FILTERS',

  CHANGE_PROMOTIONS_ORDER_POSITION: 'CHANGE_PROMOTIONS_ORDER_POSITION',
};
