import React from 'react';
import DataTable from 'react-data-table-component';

// UIKit
import { Typography } from '@material-ui/core';

// Components
import { Loader } from 'components/base/Loader/Loader';

// Constants
import { TABLE_ROWS_PER_PAGE } from 'constants/table';

// Styles
import './Table.styles.css';

export const Table = ({
  title,
  renderHeader,
  renderSubHeader,
  paginationPerPage = TABLE_ROWS_PER_PAGE,
  ...tableProps
}) => (
  <div className="table">
    {(title || renderHeader) && (
      <div className="table__header">
        <div className="table__title">
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </div>

        {renderHeader && <div className="table__actions">{renderHeader}</div>}
      </div>
    )}

    {renderSubHeader && <div className="table__subHeader">{renderSubHeader}</div>}

    <div className="table__body">
      <DataTable
        noHeader={true}
        progressComponent={<Loader />}
        paginationPerPage={paginationPerPage}
        {...tableProps}
      />
    </div>
  </div>
);

export default Table;
