import produce from 'immer';

import { EMPTY_ARRAY } from 'constants/empties';

import actionsTypes from './types';

const initialState = {
  isLoaded: false,
  isLoading: true,
  data: EMPTY_ARRAY,
  error: null,
};

export const promotions = produce((draft = {}, action) => {
  switch (action.type) {
    case actionsTypes.GET_REQUEST: {
      draft[action.dictionaryType] = initialState;
      return draft;
    }
    case actionsTypes.GET_SUCCESS: {
      draft[action.dictionaryType].isLoaded = true;
      draft[action.dictionaryType].isLoading = false;
      draft[action.dictionaryType].data = action.data[action.dictionaryType] || EMPTY_ARRAY;

      return draft;
    }
    case actionsTypes.GET_FAILURE: {
      draft[action.dictionaryType].isLoaded = false;
      draft[action.dictionaryType].isLoading = false;
      draft[action.dictionaryType].error = action.error;

      return draft;
    }

    default:
      return draft;
  }
});
