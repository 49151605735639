import React, { Fragment, useMemo } from 'react';
import DataTable from 'react-data-table-component';

// UIKit
import { Box, IconButton } from '@material-ui/core';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Create';

// Models
import { FAQ_MODEL } from '../utils/models';

export const FaqTable = ({
  title,
  list,
  isLoading,
  role,
  onCreateModal,
  onUpdateModal,
  onRemoveModal,
}) => {
  const tableActions = useMemo(
    () => (
      <Box className="btn btn-outline-dark" onClick={onCreateModal.bind(null, role)}>
        Create a new FAQ
        <HelpIcon />
      </Box>
    ),
    [onCreateModal, role],
  );

  const tableColumnsSettings = useMemo(
    () => [
      { selector: FAQ_MODEL.title, wrap: true },
      {
        button: true,
        cell: (row) => (
          <Fragment>
            <IconButton onClick={onUpdateModal.bind(null, row)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onRemoveModal.bind(null, row[FAQ_MODEL.id])}>
              <DeleteIcon />
            </IconButton>
          </Fragment>
        ),
      },
    ],
    [onRemoveModal, onUpdateModal],
  );

  return (
    <DataTable
      title={title}
      actions={tableActions}
      progressPending={isLoading}
      data={list}
      columns={tableColumnsSettings}
      noTableHead={true}
    />
  );
};
