import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from 'constants/empties';

const countriesSelector = (store) => store.dictionaries.countries;

const citiesListSelector = createSelector(countriesSelector, (countries) => countries.citiesList);

const selectCurrentCountry = (_, currentCountry) => currentCountry;

const getCityByIdSelector = createSelector(
  citiesListSelector,
  selectCurrentCountry,
  (citiesList, currentCountry) => {
    if (currentCountry) {
      const currentCities = citiesList.find(({ id }) => id === currentCountry);

      return currentCities ? currentCities.cities : EMPTY_ARRAY;
    }

    return EMPTY_ARRAY;
  },
);

export { countriesSelector, getCityByIdSelector };
