function mapResponseToCountries(countriesResponse) {
  const data = { countriesList: [], citiesList: [] };

  countriesResponse.forEach(({ cities, country }) => {
    data.countriesList.push(country);
    data.citiesList.push({ id: country, cities });
  });

  return data;
}

export { mapResponseToCountries };
