export const VLOG_MODEL = Object.freeze({
  city: 'city',
  country: 'country',
  collectedMoney: 'collectedMoney',
  publishAt: 'publishAt',
  createdAt: 'createdAt',
  description: 'description',
  eventDate: 'eventDate',
  images: 'images',
  name: 'name',
  preview: 'preview',
  video: 'video',
  id: '_id',
});
