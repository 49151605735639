import React from 'react';

// Components
import DropdownMenu from 'components/base/DropdownMenu/DropdownMenu';
import DropdownMenuItem from 'components/base/DropdownMenu/DropdownMenuItem';

// Models
import { PROMOTION_MODEL } from 'features/Promotions/utils/models';

export const TableDropdownMenu = ({ menuProps, row, onClick }) => {
  const {
    menuAvailableOptions = [],
    menuEnabledOption = [],
    menuCheckedField = PROMOTION_MODEL.status,
  } = menuProps;

  return (
    <DropdownMenu>
      {menuAvailableOptions.map((option) => {
        const isEnabled = menuEnabledOption[row[menuCheckedField]]?.includes(option.name);
        const handleClick = () => onClick && onClick(row, option);

        return (
          <DropdownMenuItem key={option.name} isDisabled={!isEnabled} onClick={handleClick}>
            {option.name}
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );
};

export default TableDropdownMenu;
