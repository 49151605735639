import React, { useCallback, useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from 'react-datepicker';

// Components
import { SearchInput } from '../SearchInput/SearchInput';

// Constants
import { userTypesOptions } from 'features/Users/utils/constants';

import {
  getOptionForValue,
  getOptionLabel,
  getOptionSelected,
} from 'utils/functionsForAutocomplete';

// Utils
import dateUtils, { dateToFormat, isValidDate } from 'utils/functionsForDate';

export const UsersTableSubhead = ({ queryParams, isDisabled, onChange }) => {
  const dates = useMemo(() => {
    const startDate = isValidDate(queryParams.lastUsedFrom)
      ? dateUtils.dayjsDate(queryParams.lastUsedFrom).toDate()
      : '';
    const endDate = isValidDate(queryParams.lastUsedTo)
      ? dateUtils.dayjsDate(queryParams.lastUsedTo).toDate()
      : '';

    return {
      startDate,
      endDate,
    };
  }, [queryParams.lastUsedFrom, queryParams.lastUsedTo]);

  const onChangeSearch = useCallback((search) => onChange({ search }), [onChange]);

  const onChangeUserType = useCallback(
    (e, prop) => {
      onChange({ filter: prop?.value, lastUsedFrom: null, lastUsedTo: null });
    },
    [onChange],
  );

  console.log(dates.startDate);

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12} sm={6}>
        <SearchInput
          label="Enter user name or email here"
          defaultValue={queryParams.search}
          onChange={onChangeSearch}
          isDisabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          size="small"
          options={userTypesOptions}
          fullWidth={true}
          value={
            queryParams.filter
              ? getOptionForValue({
                  value: parseInt(queryParams.filter),
                  options: userTypesOptions,
                  valueKey: 'label',
                  optionKey: 'value',
                })
              : null
          }
          getOptionLabel={getOptionLabel('label')}
          getOptionSelected={getOptionSelected('label')}
          onChange={onChangeUserType}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Filter" />}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <DatePicker
          wrapperClassName="asBlock"
          popperPlacement="auto-start"
          dateFormat="dd.MM.yyyy"
          selectsStart={true}
          startDate={dates.startDate}
          endDate={dates.endDate}
          maxDate={dates.endDate}
          selected={dates.startDate}
          onChange={(lastUsedFrom) => {
            onChange({ lastUsedFrom: dateToFormat(lastUsedFrom, 'YYYY-MM-DD'), filter: null });
          }}
          customInput={
            <TextField
              label="Last used date from"
              variant="outlined"
              fullWidth={true}
              size="small"
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePicker
          wrapperClassName="asBlock"
          popperPlacement="auto-start"
          selectsEnd={true}
          startDate={dates.startDate}
          endDate={dates.endDate}
          selected={dates.endDate}
          minDate={dates.startDate}
          dateFormat="dd.MM.yyyy"
          onChange={(lastUsedTo) => {
            onChange({ lastUsedTo: dateToFormat(lastUsedTo, 'YYYY-MM-DD'), filter: null });
          }}
          customInput={
            <TextField label="Last used date to" variant="outlined" fullWidth={true} size="small" />
          }
        />
      </Grid>
    </Grid>
  );
};
