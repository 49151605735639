import axios from 'axios';
import { serverConfig } from '../config';
import Api from 'apis/default.api';

export const usersService = {
  findAll,
  getUserTransactions,
};

const FIND_ALL_ENDPOINT = 'api/v1/client/all';
const GET_TRANSACTIONS_BY_USER_ID = 'api/v1/transaction/history/user';

function findAll(page, limit, search) {
  const offset = (page - 1) * limit;
  const token = localStorage.getItem('token');
  const searchParam = `&search=${search}`;
  return axios.get(
    `${serverConfig._domain}/${FIND_ALL_ENDPOINT}?offset=${offset}&limit=${limit}
    ${search ? searchParam : ''}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
}

function getUserTransactions(
  userId,
  page,
  limit,
  sort,
  paid,
  type,
  dateFrom,
  dateTo,
  referral = false,
) {
  const offset = (page - 1) * limit;
  const token = localStorage.getItem('token');
  const dateFromParam = dateFrom ? `&dateFrom=${dateFrom}` : '';
  const dateToParam = dateTo ? `&dateTo=${dateTo}` : '';
  const paidToParam = paid && paid !== 'All transactions' ? `&paid=${paid}` : '';
  const typeToParam = type && type !== 'All' ? `&type=${type}` : '';
  const referralToParam = referral ? `&referral=${referral}` : '';

  return axios.get(
    `${serverConfig._domain}/${GET_TRANSACTIONS_BY_USER_ID}/${userId}?offset=${offset}&limit=${limit}
    &sortBy=${sort.column}&orderBy=${sort.order}${paidToParam}${typeToParam}${dateFromParam}${dateToParam}${referralToParam}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
}

export default {
  getUsers,
};

const CLIENT_ENDPOINT = '/client';
const ALL_USERS_ENDPOINT = 'all';

export function getUsers(params) {
  return Api.get(`${CLIENT_ENDPOINT}/${ALL_USERS_ENDPOINT}`, { params });
}
