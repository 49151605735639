import React from 'react';

import { Button, Typography } from '@material-ui/core/';
import SecurityIcon from '@material-ui/icons/Security';

// Styles
import styles from './ErrorLayout.module.scss';

export const ErrorLayout = ({
  to = '/',
  title = 'OPPSSS!!!! Sorry...',
  caption = 'Something went wrong.',
}) => {
  const onClick = () => window.location.replace(to);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.iconWrap}>
          <SecurityIcon fontSize="inherit" className={styles.icon} />
        </div>
        <div className={styles.content}>
          <Typography className={styles.title} paragraph variant="h5" component="h3">
            {title}
          </Typography>
          <Typography className={styles.caption} paragraph variant="body1" component="p">
            {caption}
          </Typography>
          <Button className={styles.button} variant="contained" color="primary" onClick={onClick}>
            Go to main page
          </Button>
        </div>
      </div>
    </div>
  );
};
