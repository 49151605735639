import React from 'react';

// UIKit
import InfoIcon from '@material-ui/icons/Info';
import {
  IconButton,
  Popper,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';

// Hooks
import { usePopperState } from 'common/hooks/usePopperState';

// Icons
import { ReactComponent as IconInDraft } from './icons/icon_in_draft.svg';
import { ReactComponent as IconDisabled } from './icons/icon_disabled.svg';
import { ReactComponent as IconFinished } from './icons/icon_finished.svg';
import { ReactComponent as IconApproved } from './icons/icon_approved.svg';
import { ReactComponent as IconPending } from './icons/icon_pending.svg';
import { ReactComponent as IconRejected } from './icons/icon_rejected.svg';
import { ReactComponent as IconPublished } from './icons/icon_published.svg';

const useStyles = makeStyles((theme) => ({
  trigger: {
    display: 'inline-block',
    marginTop: '-0.7rem',
    marginLeft: '0.1rem',
    verticalAlign: 'top',
  },

  paper: {
    display: 'flex',
    width: '300px',
    flexDirection: 'column',
    border: '1px solid',
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },

  item: {
    padding: '0 16px',
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '35px',
    minWidth: '35px',
    height: '35px',
    marginRight: '10px',
    borderRadius: '50%',
  },
}));

export const statusesData = [
  {
    title: 'In draft',
    label: 'IN_DRAFT',
    describe: 'Your promotion is in draft',
    icon: <IconInDraft />,
    bgc: '#DFDFDF',
  },
  {
    title: 'Pending',
    label: 'PENDING',
    describe: 'The Promotion is being moderated by Admin',
    icon: <IconPending />,
    bgc: '#FFF5D9',
  },
  {
    title: 'Published',
    label: 'PUBLISHED',
    describe: 'Your promotion is successfully published',
    icon: <IconPublished />,
    bgc: '#CBDFFB',
  },
  {
    title: 'Rejected',
    label: 'REJECTED',
    describe: 'Your promotion was rejected, because fields were filled in incorrectly',
    icon: <IconRejected />,
    bgc: '#FAC7CC',
  },
  {
    title: 'Finished',
    label: 'FINISHED',
    describe: 'Your promotion is finished',
    icon: <IconFinished />,
    bgc: '#F8EFFF',
  },
  {
    title: 'Approved',
    label: 'APPROVED',
    describe: 'Your promotion is approved by Admin',
    icon: <IconApproved />,
    bgc: '#D1F3DF',
  },
  {
    title: 'Disabled',
    label: 'DISABLED',
    describe: 'Your promotion is disabled by Admin',
    icon: <IconDisabled />,
    bgc: '#E2E2E2',
  },
];

export const TableStatus = () => {
  const classes = useStyles();

  const [isPopperOpen, popperRef, onPopperToggle] = usePopperState();

  return (
    <>
      Status
      <IconButton size="small" className={classes.trigger} onClick={onPopperToggle}>
        <InfoIcon fontSize="small" />
      </IconButton>
      <Popper id="popover" open={isPopperOpen} anchorEl={popperRef}>
        <div className={classes.paper}>
          <List className={classes.root}>
            {statusesData.map((item) => (
              <ListItem key={item.title} classes={{ root: classes.item }}>
                <ListItemIcon
                  classes={{ root: classes.icon }}
                  style={{ backgroundColor: item.bgc }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} secondary={item.describe} />
              </ListItem>
            ))}
          </List>
          <Button variant="contained" color="primary" onClick={onPopperToggle}>
            Got it
          </Button>
        </div>
      </Popper>
    </>
  );
};
