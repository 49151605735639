import './styles.css';

import React from 'react';

import { Alert } from 'features/Alert';

import { Routing } from 'routes/Router';

export const App = () => (
  <>
    <Routing />
    <Alert />
  </>
);
