import { useQuery } from '@tanstack/react-query';
import { getPartnerById } from 'services/partner.service';

export const useGetPartner = ({ partnerID, queryParams = {} }) => {
  return useQuery({
    queryKey: ['partner', partnerID],
    queryFn: () => getPartnerById(partnerID),
    enabled: Boolean(partnerID),
    ...queryParams,
  });
};
