import Api from 'apis/default.api';

export default {
  getStatistic,
};

const ROOT_ENDPOINT = 'statistic';

export function getStatistic(params) {
  return Api.get(`/${ROOT_ENDPOINT}`, { params });
}
