import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirmDeleteProduct = ({ isLoading, onAccept, onDismiss }) => (
  <Modal
    show={true}
    handleClose={onDismiss}
    name="Confirm delete product"
    isLoading={isLoading}
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" disabled={isLoading} onClick={onAccept}>
          Yes
        </button>
        <button className="btn btn-secondary" onClick={onDismiss}>
          No
        </button>
      </Fragment>
    }
  >
    Do you really want to delete this product?
  </Modal>
);
