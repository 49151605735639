import { DEFAULT_CITY, DEFAULT_COUNTRY } from 'constants/countries.constants';
import { object, string, date, array } from 'yup';
import { VLOG_MODEL } from './models';

export const VLOG_FIELDS_NAMES = {
  [VLOG_MODEL.name]: VLOG_MODEL.name,
  [VLOG_MODEL.description]: VLOG_MODEL.description,
  [VLOG_MODEL.eventDate]: VLOG_MODEL.eventDate,
  [VLOG_MODEL.collectedMoney]: VLOG_MODEL.collectedMoney,
  [VLOG_MODEL.country]: VLOG_MODEL.country,
  [VLOG_MODEL.city]: VLOG_MODEL.city,
  [VLOG_MODEL.preview]: VLOG_MODEL.preview,
  [VLOG_MODEL.images]: VLOG_MODEL.images,
  [VLOG_MODEL.video]: VLOG_MODEL.video,
};

export const VLOG_FIELDS_PLACEHOLDERS = {
  [VLOG_MODEL.name]: 'Title',
  [VLOG_MODEL.description]: 'Description',
  [VLOG_MODEL.eventDate]: 'Date of event',
  [VLOG_MODEL.collectedMoney]: 'Money raised',
  [VLOG_MODEL.country]: 'Country',
  [VLOG_MODEL.city]: 'City',
  [VLOG_MODEL.preview]: 'Upload vlog preview',
  [VLOG_MODEL.images]: 'Add vlog images',
  [VLOG_MODEL.video]: 'Upload vlog video',
};

export const defaultValues = {
  [VLOG_FIELDS_NAMES.name]: '',
  [VLOG_FIELDS_NAMES.description]: '',
  [VLOG_FIELDS_NAMES.eventDate]: null,
  [VLOG_FIELDS_NAMES.collectedMoney]: '',
  [VLOG_FIELDS_NAMES.country]: DEFAULT_COUNTRY,
  [VLOG_FIELDS_NAMES.city]: DEFAULT_CITY,
  [VLOG_FIELDS_NAMES.preview]: [],
  [VLOG_FIELDS_NAMES.images]: [],
  [VLOG_FIELDS_NAMES.video]: [],
};

export const schema = object({
  [VLOG_FIELDS_NAMES.name]: string().label(VLOG_FIELDS_PLACEHOLDERS.name).min(2).required(),
  [VLOG_FIELDS_NAMES.description]: string()
    .label(VLOG_FIELDS_PLACEHOLDERS.description)
    .min(2)
    .required(),
  [VLOG_FIELDS_NAMES.eventDate]: date()
    .label(VLOG_FIELDS_PLACEHOLDERS.eventDate)
    .default(() => new Date())
    .required()
    .nullable(),
  [VLOG_FIELDS_NAMES.collectedMoney]: string()
    .label(VLOG_FIELDS_PLACEHOLDERS.collectedMoney)
    .test('Is positive?', 'The number must be positive!', (value) => value >= 0)
    .notRequired(),
  [VLOG_FIELDS_NAMES.country]: string()
    .label(VLOG_FIELDS_PLACEHOLDERS.country)
    .required()
    .nullable(),
  [VLOG_FIELDS_NAMES.city]: string().label(VLOG_FIELDS_PLACEHOLDERS.city).required().nullable(),
  [VLOG_FIELDS_NAMES.preview]: array()
    .label(VLOG_FIELDS_PLACEHOLDERS.preview)
    .min(1)
    .max(1)
    .required(),
  [VLOG_FIELDS_NAMES.images]: array().label(VLOG_FIELDS_PLACEHOLDERS.images).min(1).required(),
}).required();

export function mapResponseToFormValues(response) {
  const { _id, createdAt, publishAt, ...otherItems } = response;
  return {
    ...otherItems,
    video: otherItems.video ? [otherItems.video] : [],
    preview: otherItems.preview ? [otherItems.preview] : [],
  };
}

export function mapFormValuesToRequest(formValues) {
  return {
    ...formValues,
    video: formValues.video.length ? formValues.video[0] : '',
    preview: formValues.preview.length ? formValues.preview[0] : '',
  };
}
