import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';

// Components
import { LinearProgress } from 'components/base/LinearProgress/LinearProgress';

// Utilities
import { dateToFormat } from 'utils/functionsForDate';

// Styles
import './PollsResultsView.styles.css';

function mapResultsToArray(obj) {
  return Object.keys(obj).map((key) => ({ name: key, count: obj[key] }));
}

function sortByDesc(a, b) {
  return b.count - a.count;
}

function getSum(acc, item) {
  return (acc += item.count);
}

const columnsSettings = [
  { selector: '_id', name: 'ID', sortable: true },
  { selector: 'other', name: 'Other', sortable: true, wrap: true },
];

export const PollsResultsView = ({ title, endDate, results, additions, otherOptionName = '' }) => {
  const [shownAdditions, setShownAdditions] = useState(false);

  const allItems = useMemo(() => mapResultsToArray(results).sort(sortByDesc), [results]);

  const items = useMemo(
    () => allItems.filter(({ name }) => name !== otherOptionName),
    [allItems, otherOptionName],
  );

  const otherItems = useMemo(
    () => allItems.filter(({ name }) => name === otherOptionName),
    [allItems, otherOptionName],
  );

  const votesCount = useMemo(() => allItems.reduce(getSum, 0), [allItems]);

  const showAdditions = useCallback(() => setShownAdditions((state) => !state), []);

  return (
    <div className="PollsResultsView">
      <div className="PollsResultsView__row">
        <div className="PollsResultsView__col">Name of poll:</div>
        <div className="PollsResultsView__col">{title}</div>
      </div>
      <div className="PollsResultsView__row">
        <div className="PollsResultsView__col">End date of poll:</div>
        <div className="PollsResultsView__col">{dateToFormat(endDate)}</div>
      </div>

      <div className="PollsResultsView__graph">
        {items.map(({ name, count }) => {
          return (
            <div className="PollsResultsView__item" key={name}>
              <div className="PollsResultsView__line">
                <div className="PollsResultsView__name">{name}</div>
                <div className="PollsResultsView__value">
                  <LinearProgress progress={count} total={votesCount} />
                </div>
              </div>
            </div>
          );
        })}

        {otherItems.map(({ name, count }) => {
          return (
            <div className="PollsResultsView__item" key={name}>
              <div className="PollsResultsView__line">
                <div className="PollsResultsView__name">
                  <button className="btn btn-primary" onClick={showAdditions}>
                    {name}
                  </button>
                </div>
                <div className="PollsResultsView__value">
                  <LinearProgress progress={count} total={votesCount} />
                </div>
              </div>
              {shownAdditions && (
                <div className="PollsResultsView__additions">
                  <DataTable
                    noHeader={true}
                    data={additions}
                    columns={columnsSettings}
                    pagination={false}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PollsResultsView.propTypes = {
  title: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  results: PropTypes.shape({
    [PropTypes.string]: PropTypes.number,
  }),
  otherOptionName: PropTypes.string.isRequired,
  additions: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      other: PropTypes.string,
      answerOption: PropTypes.string,
    }),
  ),
};
