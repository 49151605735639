import React, { cloneElement, Fragment, useCallback } from 'react';
import cn from 'classnames';
import { useController, useFieldArray } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';

// Icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  footer: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  buttonIcon: {
    marginTop: '-0.3rem',
    marginLeft: '0.5rem',
  },
}));

export const ListManager = ({
  className,
  name,
  control,
  newItemModel,
  maxItems = 10,
  PreviewComponent,
  buttonTitle = 'Add new item',
  cantRemoveIds = [],
  isDisabled = false,
}) => {
  const classes = useStyles();

  const { fieldState } = useController({
    name,
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const isMaxedFiles = maxItems <= fields.length;

  const removeItem = useCallback((index) => () => remove(index), [remove]);

  const addItem = useCallback(() => append(newItemModel), [append, newItemModel]);

  return (
    <Fragment>
      {fields && (
        <Fragment>
          {fields.map(({ id }, idx) => {
            const onRemove = removeItem(idx);
            const canRemove = !cantRemoveIds.includes(idx) || !isDisabled;

            return (
              <Fragment key={id}>
                {cloneElement(PreviewComponent, {
                  idx,
                  id,
                  name,
                  control,
                  canRemove,
                  onRemove,
                })}
              </Fragment>
            );
          })}
        </Fragment>
      )}

      {!isMaxedFiles && (
        <div className={cn(classes.footer, className)}>
          <button
            type="button"
            className={`btn btn-primary ${classes.button}`}
            onClick={addItem}
            disabled={isDisabled}
          >
            {buttonTitle}
            <AddCircleOutlineIcon className={classes.buttonIcon} />
          </button>
        </div>
      )}

      {Boolean(fieldState.error) && (
        <FormHelperText error={fieldState.error?.message} variant="outlined">
          {fieldState.error?.message}
        </FormHelperText>
      )}
    </Fragment>
  );
};

export default ListManager;
