import React, { useLayoutEffect } from 'react';

// UIKit
import { Typography, Button, Grid } from '@material-ui/core';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Components
import { Loader } from 'components/base/Loader/Loader';

import { useGoBack } from 'hooks/useGoBack';

export const PageDetails = ({
  title,
  isLoading = false,
  isUpdating = false,
  onLoading = () => {},
  children,
  renderHeader,
  renderFooter,
  errorMessage,
}) => {
  const goBack = useGoBack();

  // Fetch data from server
  useLayoutEffect(onLoading, [onLoading]);

  return (
    <div className="PageDetails">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" onClick={goBack}>
            <ArrowBackIcon />
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div" align="center">
            {errorMessage || title}
          </Typography>
        </Grid>

        {renderHeader && !isLoading && (
          <Grid item xs={12} align="right">
            {renderHeader}
          </Grid>
        )}

        <Grid item xs={12} style={{ position: 'relative' }}>
          {(isLoading || isUpdating) && <Loader withBackdrop={isUpdating} />}
          {!isLoading && children}
        </Grid>

        {renderFooter && !isLoading && (
          <Grid item xs={12} align="right">
            {renderFooter}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PageDetails;
