import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { Modal } from 'components/base/Modal/Modal';
import { FormCreatingGiveaway } from '../FormCreatingGiveaway/FormCreatingGiveaway';

// Utilities
import { defaultValues, schema } from '../../utils/form';
import { getDirtyValues } from 'utils/functionsForForm';

// Hooks
import { useCountries } from 'common/hooks/api/useCountries';
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';

// Models
import { GIVEAWAY_MODEL } from '../../utils/models';

export const ModalCreate = ({ onAccept, onDismiss, isLoading }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isCountriesFetching, countries } = useCountries();
  const [cities, setCities] = useState([]);

  const handleAccept = useCallback(
    (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, formValues);
      onAccept(changedValues);
    },
    [dirtyFields, onAccept],
  );

  const resetCity = useCallback(() => {
    setValue(GIVEAWAY_MODEL.city, null);
  }, [setValue]);

  const renderedFooter = useMemo(
    () => (
      <Fragment>
        <div>After you tap save, users will get a push notification.</div>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={handleSubmit(handleAccept)}
          type="button"
        >
          Save
        </button>
        <button
          className="btn btn-secondary"
          onClick={onDismiss}
          data-dismiss="modal"
          type="button"
        >
          Cancel
        </button>
      </Fragment>
    ),
    [handleAccept, onDismiss, handleSubmit, isLoading],
  );

  useBlockerPrompt(isDirty);

  return (
    <Modal
      handleClose={onDismiss}
      name="Create a new giveaway"
      show={true}
      renderFooter={renderedFooter}
    >
      <FormCreatingGiveaway
        isLoading={isLoading}
        isCountriesFetching={isCountriesFetching}
        control={control}
        countries={countries}
        cities={cities}
        onCitiesChange={setCities}
        resetCity={resetCity}
      />
    </Modal>
  );
};
