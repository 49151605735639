export const userActionsTypes = {
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_FAILURE: 'GET_USER_BY_ID_FAILURE',
  GET_USER_BY_ID_REQUEST: 'GET_USER_BY_ID_REQUEST',

  DELETE_USER_BY_ID_SUCCESS: 'DELETE_USER_BY_ID_SUCCESS',
  DELETE_USER_BY_ID_FAILURE: 'DELETE_USER_BY_ID_FAILURE',
  DELETE_USER_BY_ID_REQUEST: 'DELETE_USER_BY_ID_REQUEST',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  RESET_USER_STATE: 'RESET_USER_STATE',
};
