import { useQuery } from '@tanstack/react-query';
import { getPromotions } from 'services/promotions.service';
import { NOTICES_KEYS } from 'constants/notices';
import { invalidateQueries } from 'helpers/queryClient';

export const useNotices = () => {
  const { isLoading, isFetching, isError, data } = useQuery({
    queryKey: ['notices'],
    queryFn: () => getPromotions({ status: 'PENDING' }),
    select: ({ count }) => {
      return {
        [NOTICES_KEYS.promotions]: count,
        [NOTICES_KEYS.pending]: count,
      };
    },
    refetchInterval: 1000 * 60,
  });

  return {
    isNoticesLoading: isFetching || isLoading,
    isNoticesError: isError,
    notices: data ?? [],
  };
};

export const invalidateNotices = () => invalidateQueries({ queryKey: ['notices'] });
