import { isObject, isPrimitive, isUndef } from './functionsForCoreJS';

export function getOptionLabel(fieldIsWantsToGet) {
  return function (option) {
    if (isPrimitive(fieldIsWantsToGet) && isObject(option)) {
      return option[fieldIsWantsToGet];
    }

    return option;
  };
}

export function getOptionSelected(field) {
  return function (option, value) {
    return getOptionLabel(field)(option) === getOptionLabel(field)(value);
  };
}

export function getOptionForValue({ value, options, valueKey, optionKey }) {
  if (isUndef(value) || !Array.isArray(options) || isUndef(valueKey) || isUndef(optionKey)) {
    return value;
  }

  const currentOption = options.find((option) => option[optionKey] === value);

  if (!currentOption || !currentOption[valueKey]) return value;

  return currentOption[valueKey];
}

export function getDisabledOption(disabledOptions) {
  return function ({ value }) {
    return disabledOptions.includes(value);
  };
}
