import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, date, object } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// UIKit
import { Box, Checkbox, FormHelperText } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { IconPopover } from 'components/base/IconPopover/IconPopover';

// Actions
import { userActions } from 'actions/user.actions';

// Selectors
import { userSelector } from 'selectors/user.selectors';

//Components
import { Loader } from 'components/base/Loader/Loader';
import { UserTransactions } from '../UserTransactions';
import { ModalConfirmDelete } from './modals/ModalConfirmDelete';
import { ErrorMessage } from 'components/base/ErrorMessage/ErrorMessage';

// Utils
import { dateToFormat } from 'utils/functionsForDate';
import { dateToIsoString, getTomorrow, isoToDate, isValidDate } from 'utils/functionsForDate';

// Hooks
import { useBlockerPrompt } from 'hooks/useBlockerPrompt';
import { useGoBack } from 'hooks/useGoBack';

// Models
import { USER_TYPES } from 'models/users';

// Styles
import './style.css';

function mapResponseToFormValues(response) {
  return {
    name: `${response.name} ${response.surname}`,
    bonuses: response.bonuses,
    email: response.email,
    id: response.userId,
    createdAt: dateToFormat(response.createdAt),
    phone: response.phone,
    expirationDate: response.expirationDate,
    defaultReferralBonuses: response.defaultReferralBonuses,
    celebrityReferralBonuses: response.celebrityReferralBonuses,
    removed: response.removed,
    isVerified: response.isVerified,
    isCelebrity: response.promoterType === USER_TYPES.celebrity,
    lastUsed: dateToFormat(response.lastUsed),
  };
}

export const defaultValues = {
  name: '',
  bonuses: '',
  email: '',
  id: '',
  createdAt: '',
  phone: '',
  expirationDate: null,
  defaultReferralBonuses: '',
  celebrityReferralBonuses: '',
  removed: false,
  isVerified: false,
  isCelebrity: false,
  lastUsed: '',
};

function mapFormValuesToRequest(formValues) {
  const { isCelebrity, expirationDate } = formValues;

  return {
    expirationDate: isCelebrity ? expirationDate : null,
    promoterType: isCelebrity ? USER_TYPES.celebrity : USER_TYPES.default,
  };
}

export const schema = object({
  isCelebrity: boolean().required(),
  expirationDate: date().when('isCelebrity', {
    is: true,
    then: (schema) => schema.default(() => new Date()),
    otherwise: (schema) => schema.nullable(),
  }),
}).required();

export const UserDetails = () => {
  const dispatch = useDispatch();
  // const classes = useStyles();

  const { id: userId } = useParams();

  const goBack = useGoBack();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const {
    isUserLoading,
    isUserDeleting,
    data: user,
    error: loadingError,
  } = useSelector(userSelector);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const hasLoadingError = Boolean(loadingError);

  const celebrityWatched = watch('isCelebrity');

  const resetFieldOfExpirationDate = useCallback(() => {
    if (!celebrityWatched) {
      setValue('expirationDate', null, { shouldValidate: true });
    }
  }, [celebrityWatched, setValue]);

  const submitForm = useCallback(
    (formValues) => {
      const mappedValues = mapFormValuesToRequest(formValues);

      dispatch(userActions.aUpdateUserById(userId, mappedValues));
    },
    [dispatch, userId],
  );

  const showDeleteModal = useCallback(() => setIsOpenConfirmModal(true), []);

  const hideDeleteModal = useCallback(() => setIsOpenConfirmModal(false), []);

  const onDeleteUser = useCallback(() => {
    dispatch(userActions.deleteUserById(userId)).then(goBack, hideDeleteModal);
  }, [dispatch, goBack, hideDeleteModal, userId]);

  const getUser = useCallback(() => {
    dispatch(userActions.getUserById(userId));
  }, [dispatch, userId]);

  const getFormDefaults = useCallback(() => {
    if (isUserLoading || !user) return;
    reset(mapResponseToFormValues(user));
  }, [isUserLoading, reset, user]);

  useEffect(getUser, [getUser]);

  useEffect(getFormDefaults, [getFormDefaults]);

  useEffect(resetFieldOfExpirationDate, [resetFieldOfExpirationDate]);

  useEffect(() => {
    return () => {
      dispatch(userActions.aResetUserState());
    };
  }, [dispatch]);

  useBlockerPrompt(isDirty);

  if (isUserLoading || isUserDeleting) {
    return <Loader />;
  }

  if (hasLoadingError) {
    return <ErrorMessage code={loadingError.code} message={loadingError.message} />;
  }

  return (
    <div className="user-details">
      {isOpenConfirmModal && (
        <ModalConfirmDelete onAccept={onDeleteUser} onDismiss={hideDeleteModal} />
      )}

      <div className="user-details__header">
        <Box className="btn btn-outline-dark" onClick={goBack}>
          <KeyboardBackspaceIcon />
        </Box>
        {!user.removed && (
          <Box onClick={showDeleteModal} className="btn btn-outline-dark">
            Delete user <DeleteForeverIcon />
          </Box>
        )}
      </div>

      {user.removed && (
        <ErrorMessage
          style={{ marginBottom: '1rem' }}
          message="The user is removed!"
          withTry={false}
        />
      )}

      <form className="user-details__form">
        <div className="user-details__form-item">
          <label htmlFor="name" className="user-details__form-item-label">
            User name:
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>
        <div className="user-details__form-item">
          <label htmlFor="bonuses" className="user-details__form-item-label">
            Bonuses:
          </label>
          <Controller
            name="bonuses"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input-money" {...field} disabled />
            )}
          />
        </div>

        <div className="user-details__form-item">
          <label htmlFor="email" className="user-details__form-item-label">
            User email:
          </label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>
        <div className="user-details__form-item">
          <label htmlFor="id" className="user-details__form-item-label">
            User id:
          </label>
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>

        <div className="user-details__form-item">
          <label htmlFor="createdAt" className="user-details__form-item-label">
            User date of registration:
          </label>
          <Controller
            name="createdAt"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>
        <div className="user-details__form-item">
          <label htmlFor="phone" className="user-details__form-item-label">
            User phone:
          </label>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>

        <div className="user-details__form-item">
          {celebrityWatched && (
            <Fragment>
              <label htmlFor="celebrityReferralBonuses" className="user-details__form-item-label">
                Celebrity referral bonuses:
                {/* 
            <IconButton size="small" className={classes.trigger} onClick={handleClick}>
              <InfoIcon fontSize="small" />
            </IconButton>
            <Popper id="popoverCeleb" open={popoverIsOpen} anchorEl={anchorPopoverEl}>
              <div className={classes.paper}>
                <TextField
                  className={classes.item}
                  label="Cashback to Aller-Retour"
                  size="small"
                  name="totalCashBackToAdmin"
                  variant="outlined"
                  disabled={true}
                  value={''}
                />
                <TextField
                  className={classes.item}
                  label="Referral bonuses"
                  size="small"
                  name="Referral bonuses"
                  variant="outlined"
                  disabled={true}
                  value={''}
                />
              </div>
            </Popper>
            */}
              </label>
              <Controller
                name="celebrityReferralBonuses"
                control={control}
                render={({ field }) => (
                  <input className="user-details__form-item-input" {...field} disabled />
                )}
              />
            </Fragment>
          )}
        </div>
        <div className="user-details__form-item">
          <label htmlFor="defaultReferralBonuses" className="user-details__form-item-label">
            Referral bonuses:
          </label>
          <Controller
            name="defaultReferralBonuses"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>

        <div className="user-details__form-item">
          <label htmlFor="verified" className="user-details__form-item-label">
            Verified:
          </label>
          <div>
            <Controller
              name="isVerified"
              control={control}
              render={({ field }) => (
                <Checkbox color="default" checked={field.value} disabled={true} />
              )}
            />
          </div>
        </div>

        <div className="user-details__form-item">
          <label htmlFor="lastUsed" className="user-details__form-item-label">
            <IconPopover label="Last used:">
              Last used - is the date of the last transaction of the user.
            </IconPopover>
          </label>
          <Controller
            name="lastUsed"
            control={control}
            render={({ field }) => (
              <input className="user-details__form-item-input" {...field} disabled />
            )}
          />
        </div>

        <div className="user-details__form-item">
          <label htmlFor="isCelebrity" className="user-details__form-item-label">
            Celebrity:
          </label>
          <div>
            <Controller
              name="isCelebrity"
              control={control}
              render={({ field }) => (
                <Checkbox color="default" checked={field.value} onChange={field.onChange} />
              )}
            />
          </div>
        </div>

        <div className="user-details__form-item">
          <label htmlFor="expirationDate" className="user-details__form-item-label">
            Expiration date:
          </label>
          <Controller
            name="expirationDate"
            control={control}
            render={({ field, fieldState }) => (
              <Fragment>
                <ReactDatePicker
                  onChange={(value) => isValidDate(value) && field.onChange(dateToIsoString(value))}
                  onBlur={field.onBlur}
                  selected={isoToDate(field.value)}
                  wrapperClassName="asBlock"
                  popperPlacement="auto"
                  dateFormat="d.MM.yyyy"
                  minDate={getTomorrow()}
                  disabled={!celebrityWatched}
                  customInput={<input className="user-details__form-item-input" />}
                />
                {fieldState.invalid && (
                  <FormHelperText error={true} variant="outlined">
                    You didn't choose expiration date
                  </FormHelperText>
                )}
              </Fragment>
            )}
          />
        </div>

        <div className="user-details__form-item"></div>
        <div className="user-details__form-item user-details__form-item_align-right">
          <button
            className="btn btn-primary"
            type="button"
            disabled={!isDirty}
            onClick={handleSubmit(submitForm)}
          >
            Save
          </button>
        </div>
      </form>

      <UserTransactions />
    </div>
  );
};
