export const EVENT_FORM_MODEL = Object.freeze({
  name: 'name',
  description: 'description',
  images: 'images',
  date: 'date',
  city: 'city',
  country: 'country',
  place: 'place',
});

export const EVENT_ITEM_COORDINATES_MODEL = Object.freeze({
  lat: 'lat',
  long: 'long',
});

export const EVENT_ITEM_ADDRESS_MODEL = Object.freeze({
  country: 'country',
  city: 'city',
  coordinates: 'coordinates',
  place: 'place',
});

export const EVENT_ITEM_MODEL = Object.freeze({
  id: '_id',
  name: 'name',
  description: 'description',
  images: 'images',
  date: 'date',
  address: 'address',
});
