import { DEFAULT_CITY, DEFAULT_COUNTRY } from 'constants/countries.constants';
import { object, string, date, array } from 'yup';
import { POLL_MODEL, POLL_ANSWER_OPTION_MODEL, POLL_ANSWER_OPTION_ENUM } from './models';

export const singleAnswerOption = {
  [POLL_ANSWER_OPTION_MODEL.description]: '',
  [POLL_ANSWER_OPTION_MODEL.value]: '',
  [POLL_ANSWER_OPTION_MODEL.type]: POLL_ANSWER_OPTION_ENUM.singleSelect,
};
export const openAnswerOption = {
  [POLL_ANSWER_OPTION_MODEL.description]:
    'Here you can give your own variant with the detailed description of the place that needs Aller-Retour’s support',
  [POLL_ANSWER_OPTION_MODEL.value]: 'Other',
  [POLL_ANSWER_OPTION_MODEL.type]: POLL_ANSWER_OPTION_ENUM.open,
};

export const defaultValues = {
  [POLL_MODEL.question]: '',
  [POLL_MODEL.description]: '',
  [POLL_MODEL.country]: DEFAULT_COUNTRY,
  [POLL_MODEL.city]: DEFAULT_CITY,
  [POLL_MODEL.finishAt]: null,
  [POLL_MODEL.answerOption]: [singleAnswerOption, openAnswerOption],
};

const schemaOfAnswerOption = array()
  .of(
    object({
      [POLL_ANSWER_OPTION_MODEL.description]: string().required('this is a required field'),
      [POLL_ANSWER_OPTION_MODEL.value]: string().required('this is a required field'),
    }),
  )
  .required();

const schemaOfDate = date()
  .default(() => new Date())
  .required()
  .nullable();

export const schema = object({
  [POLL_MODEL.question]: string().min(2).required(),
  [POLL_MODEL.description]: string().min(2).required(),
  [POLL_MODEL.country]: string().required('country must be a selected').nullable(),
  [POLL_MODEL.city]: string().required('city must be a selected').nullable(),
  [POLL_MODEL.finishAt]: schemaOfDate,
  [POLL_MODEL.answerOption]: schemaOfAnswerOption,
}).required();
