import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';

// UIKit
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

// Components
import { LayoutShowcase } from 'components/layouts/LayoutShowcase/LayoutShowcase';
import { Table } from 'components/base/Table/Table';
import { ModalConfirmUnsaved } from 'components/base/Modals/ModalConfirmUnsaved';
import { ModalMutate } from './components/ModalMutate/ModalMutate';

// Actions
import { alertActions } from 'features/Alert';

// Services
import { geVersion, updateVersion } from 'services/version.service';

// Utils
import { FIELDS_PLACEHOLDERS } from './utils/form.utils';
import { VERSION_MODEL } from './utils/models';

// Constants
import { useModal } from 'hooks/useModal';

// Helpers
import { queryClient } from 'helpers/queryClient';

export const Versions = () => {
  const dispatch = useDispatch();

  // Confirm exit
  const [modalConfirmIsOpen, onShowModalConfirm, onHideModalConfirm] = useModal();

  // Modal update
  const [modalUpdateIsOpen, onShowModalUpdate, onHideModalUpdate, modalUpdatePayload] = useModal();

  const { isLoading, data } = useQuery({
    queryKey: ['versions'],
    queryFn: () => geVersion(),
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const updateMutation = useMutation({
    mutationFn: (formValues) => {
      return updateVersion(modalUpdatePayload[VERSION_MODEL.id], formValues);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['versions'] });
      hideOpenedModals();
      dispatch(alertActions.clear());
      dispatch(alertActions.success('Version was updated'));
    },
    onError: (error) => {
      dispatch(alertActions.error(error.message));
    },
  });

  const hideOpenedModals = useCallback(() => {
    onHideModalConfirm();
    onHideModalUpdate();
  }, [onHideModalConfirm, onHideModalUpdate]);

  const tableColumnsSettings = useMemo(
    () => [
      { selector: VERSION_MODEL.appType, name: FIELDS_PLACEHOLDERS.appType },
      { selector: VERSION_MODEL.platform, name: FIELDS_PLACEHOLDERS.platform },
      { selector: VERSION_MODEL.version, name: FIELDS_PLACEHOLDERS.version },
      {
        selector: VERSION_MODEL.mandatory,
        name: FIELDS_PLACEHOLDERS.mandatory,
        format: (row) => (row[VERSION_MODEL.mandatory] ? 'Yes' : 'No'),
      },
      {
        name: 'Action',
        ignoreRowClick: true,
        button: true,
        cell: (row) => (
          <IconButton onClick={onShowModalUpdate.bind(null, row)}>
            <EditIcon />
          </IconButton>
        ),
      },
    ],
    [onShowModalUpdate],
  );

  return (
    <LayoutShowcase title="App versions">
      <Table isLoading={isLoading} columns={tableColumnsSettings} data={data} />

      {modalUpdateIsOpen && (
        <ModalMutate
          isLoading={updateMutation.isLoading}
          onAccept={updateMutation.mutate}
          onCancel={onShowModalConfirm}
          editableItem={modalUpdatePayload}
        />
      )}

      {modalConfirmIsOpen && (
        <ModalConfirmUnsaved
          show={true}
          onAccept={hideOpenedModals}
          onDismiss={onHideModalConfirm}
        />
      )}
    </LayoutShowcase>
  );
};
