export const LIGHT_CLIENT_MODEL = Object.freeze({
  name: 'name',
  email: 'email',
  removed: 'removed',
  isVerified: 'isVerified',
  lastUsed: 'lastUsed',
  id: '_id',
});

export const CLIENT_FILTERS_ENUM = Object.freeze({
  search: 'search',
});
