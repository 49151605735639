import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components
import Select from '../Select/Select';

// Models
import { PROMOTION_DICTIONARY_STATUS_TYPES_MODEL } from 'features/Dictionaries/Promotions/utils/models';

// Hooks
import useParamsSelector from 'hooks/useParamsSelector';

// Store
import { aGetDictionaryOfPromotionsIfNotLoaded } from 'features/Dictionaries/Promotions/store/actions';
import { dictionaryOfPromotionsSelector } from 'features/Dictionaries/Promotions/store/selectors';

const PromotionStatusSelect = ({ dictionaryTypeKey, value, onChange, ...props }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data: options,
    error,
  } = useParamsSelector(dictionaryOfPromotionsSelector, dictionaryTypeKey);

  const getResources = useCallback(() => {
    dispatch(aGetDictionaryOfPromotionsIfNotLoaded(dictionaryTypeKey));
  }, [dictionaryTypeKey, dispatch]);

  useEffect(getResources, [getResources]);

  return (
    <Select
      placeholder="Status"
      disableClearable={false}
      inputName={PROMOTION_DICTIONARY_STATUS_TYPES_MODEL.title}
      inputValue={PROMOTION_DICTIONARY_STATUS_TYPES_MODEL.value}
      value={value}
      onChange={onChange}
      options={options}
      isLoading={isLoading}
      isError={Boolean(error)}
      helperText={error?.message}
      {...props}
    />
  );
};

export default PromotionStatusSelect;
