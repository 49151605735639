import Api from 'apis/default.api';

export default {
  getDictionaryOfLanguages,
};

const ROOT_ENDPOINT = 'public';
const INFO_ENDPOINT = 'info';
const LANG_ENDPOINT = 'dictionaryOfLanguages';
const PROMOTION_ENDPOINT = 'promotions';

export async function getDictionaryOfLanguages() {
  return Api.get(`/${ROOT_ENDPOINT}/${INFO_ENDPOINT}/${LANG_ENDPOINT}`);
}

export async function getDictionaryOfPromotions(dictionaryType) {
  return Api.get(`/${ROOT_ENDPOINT}/${INFO_ENDPOINT}/${PROMOTION_ENDPOINT}/${dictionaryType}`);
}
