export const FAQ_ROLE_TYPES = Object.freeze({
  client: 'CLIENT',
  partner: 'PARTNER',
});

export const FAQ_MODEL = Object.freeze({
  createdAt: 'createdAt',
  role: 'role',
  subject: 'subject',
  title: 'title',
  updatedAt: 'updatedAt',
  id: '_id',
});
