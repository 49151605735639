import React, { forwardRef } from 'react';

// Components
import { Loader } from 'components/base/Loader/Loader';

// Styles
import styles from './Form.module.scss';

export const Form = forwardRef(({ isLoading = false, children }, ref) => {
  return (
    <form ref={ref} noValidate className={styles.root}>
      {isLoading && <Loader withBackdrop />}
      {children}
    </form>
  );
});
