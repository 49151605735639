import React, { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import { FormFaq } from '../components/FormFaq';
import { Modal } from 'components/base/Modal/Modal';

import { defaultValues, validationSchema } from '../utils/formFaq';
import { getDirtyValues } from 'utils/functionsForForm';

export const ModalMutate = ({ onDismiss, onAccept, isLoading, values }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { dirtyFields },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submitForm = useCallback(
    (formValues) => {
      const changedValues = getDirtyValues(dirtyFields, formValues);
      onAccept(changedValues);
    },
    [dirtyFields, onAccept],
  );

  useEffect(() => {
    if (values) {
      reset(values);
    }
  }, [reset, values]);

  return (
    <Modal
      handleClose={onDismiss}
      name="Adding new item of FAQ"
      show={true}
      isLoading={isLoading}
      renderFooter={
        <Fragment>
          <button
            className="btn btn-primary"
            disabled={isLoading}
            onClick={handleSubmit(submitForm)}
            type="button"
          >
            Save
          </button>
          <button className="btn btn-secondary" onClick={onDismiss} type="button">
            Cancel
          </button>
        </Fragment>
      }
    >
      <FormFaq control={control} />
    </Modal>
  );
};
