import { object, string } from 'yup';

import { PROMOTION_MODEL } from 'features/Promotions/utils/models';

const DEFAULT_VALUES = Object.freeze({
  [PROMOTION_MODEL.adminMessage]: '',
});

const VALIDATION_SCHEMA = object({
  [PROMOTION_MODEL.adminMessage]: string().min(5).max(100).required(),
}).required();

export { DEFAULT_VALUES, VALIDATION_SCHEMA };
