import { INITIAL_FILTERS } from 'constants/filters';

export const getPaginationOffsetByFilters = ({ page, limit, ...rest }) => ({
  ...rest,
  limit,
  offset: limit * (page - 1),
});

export const getPaginationOffsetByPage = ({ page, limit = INITIAL_FILTERS.limit, ...rest }) => {
  return {
    ...rest,
    limit,
    offset: limit * (page - 1),
  };
};

export const getCurrentPage = (page) => {
  const parsedPage = parseInt(page);

  if (isNaN(parsedPage)) {
    return 1;
  }

  return parsedPage ?? 1;
};
