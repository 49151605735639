import React, { Fragment } from 'react';

import { Modal } from 'components/base/Modal/Modal';

export const ModalConfirmDelete = ({ onAccept, onDismiss }) => (
  <Modal
    show={true}
    handleClose={onDismiss}
    name="Confirm delete partner"
    renderFooter={
      <Fragment>
        <button className="btn btn-primary" onClick={onAccept}>
          Yes
        </button>
        <button className="btn btn-secondary" onClick={onDismiss}>
          No
        </button>
      </Fragment>
    }
  >
    Do you really want to delete this partner? <br />
    Please be aware that all partner's data will be deleted along with the partner
  </Modal>
);
